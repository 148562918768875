import { ClaimDailyTicketsModal } from '@/components/battle/ClaimDailyTicketsModal';
import { NotCoinModal } from '@/components/notcoin/NotCoinModal';
import {
  GAME_SETTINGS_TELEGRAM_DAILY_LOGIN_REWARDS,
  GAME_SETTINGS_TELEGRAM_TICKETS_PER_DAY,
  useGameSettings,
} from '@/components/battle/hooks/useGameSettings';
import { useUserData } from '@/context/FirestoreContext';
import { useState } from 'react';
import { StreakModal } from '@/components/streaks/StreakModal';
import { VITE_LOGIN_STREAK_ENABLED } from '@/config/constants';
import { TELEGRAM_DAILY_LOGIN_REWARDS_DEFAULT } from 'genopets-utils/src/pixelton-arena';
import { USER_CLAIM_TELEGRAM_DAILY_LOGIN_REWARDS_ENDPOINT } from '@/config/endpoints';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';

/**
 * Place any modal messages that need to be displayed to the user here.
 * Add code for specific modals in the order they should be displayed.
 */
export const ModalMessageManager = ({
  refreshHomeData,
}: {
  refreshHomeData?: () => void;
}) => {
  const { userData } = useUserData();
  const loginStreak = userData?.telegram?.loginStreak ?? 0;
  const lastStreakClaimed = userData?.telegram?.lastStreakClaimed ?? 0;
  const [showStreakModal, setShowStreakModal] = useState(
    loginStreak !== lastStreakClaimed,
  );
  const { apiPost } = useAPI();

  const alert = useAlert();

  const notCoinBonusTickets =
    userData?.NotcoinReferral?.bonusTelegramTickets ?? 0;
  const telegramNextDayTimestampMs = userData?.telegramNextDayTimestampMs ?? 0;
  // backend will prevent bad actors that change their system time
  // to after `telegramNextDayTimestampMs` since validation is done there
  const canClaimTickets = telegramNextDayTimestampMs < Date.now();

  const settings = useGameSettings();

  if (!settings) {
    return null;
  }

  /* // Remove T&C modal from showing since we want to no overwhelm NOTcoin users
  const [firstTimeSeeTerms, setFirstTimeSeeTerms] = useLocalStorage(
    'firstTimeSeeTerms',
    false,
  );

  if (!firstTimeSeeTerms) {
    return (
      <TermConditionsModal
        firstTimeSeeTerms={firstTimeSeeTerms}
        setFirstTimeSeeTerms={setFirstTimeSeeTerms}
      />
    );
  }
  */

  // We want to prioritize showing the not coin modal of the daily tickets modal
  if (notCoinBonusTickets > 0) {
    return <NotCoinModal ticketAmount={notCoinBonusTickets} />;
  }

  // wait for settings to load
  if (canClaimTickets && settings) {
    return (
      <ClaimDailyTicketsModal
        isOpen={canClaimTickets}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClose={() => {}}
        ticketsToClaim={settings[GAME_SETTINGS_TELEGRAM_TICKETS_PER_DAY] ?? 3}
      />
    );
  }

  if (showStreakModal && VITE_LOGIN_STREAK_ENABLED) {
    const dailyLoginRewards =
      settings[GAME_SETTINGS_TELEGRAM_DAILY_LOGIN_REWARDS] ??
      TELEGRAM_DAILY_LOGIN_REWARDS_DEFAULT;

    return (
      <StreakModal
        streakRewards={dailyLoginRewards}
        lastStreakClaimed={lastStreakClaimed}
        open={true}
        streak={loginStreak}
        onPrizesClaimed={() => {
          setShowStreakModal(false);
          refreshHomeData?.();
        }}
        onClaim={async () => {
          try {
            const resp = await apiPost(
              USER_CLAIM_TELEGRAM_DAILY_LOGIN_REWARDS_ENDPOINT,
              {},
            );
            return resp.reward.rewards;
          } catch (err: any) {
            console.log(err.message);
            alert(err.message);
            return undefined;
          }
        }}
      />
    );
  }

  return null;
};
