import { Modal } from '../ui/molecules/Modal';
import { Box } from '@chakra-ui/react';
import { Text } from '@/components/ui/atoms/Text';
import { Button } from '../ui/molecules/Button';
import { TranslatedText } from '../ui/atoms/TranslatedText';
import { PrizeIcon } from './StreakModal';
import { useState } from 'react';
import { StreakReward } from 'genopets-utils/src/pixelton-arena';

export const StreakClaimModal = ({
  open,
  claimedPrizes,
  onGoToSpin: onGoToSpin,
  onPrizesClaimed: onPrizesClaimed,
  title,
}: {
  open: boolean;
  onPrizesClaimed: () => void;
  onGoToSpin: () => void;
  claimedPrizes: StreakReward[];
  title?: 'dailyPrize' | 'earnBonus';
}) => {
  const [index, setIndex] = useState(0);
  const isOnePrize = claimedPrizes.length === 1;
  return (
    <>
      <Modal
        isOpen={open}
        onClose={() => null}
        cardProps={{ gap: 0, padding: 'none' }}
        footerProps={{ mt: 1 }}
        contentProps={{ width: 'fit-content', maxWidth: '330px' }}
        showBackButton={false}
      >
        <Box
          background={'var(--Yellow)'}
          borderBottom={`3px solid var(--Black)`}
          padding={'16px'}
        >
          <Text colorId="Black" style={{ textAlign: 'center' }}>
            <TranslatedText
              translationKey={title ?? 'dailyPrize'}
              defaultMessage="Daily Prize"
            />
          </Text>
        </Box>
        <Box
          padding={'8px 16px'}
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt="16px"
        >
          {!isOnePrize && (
            <Text
              lineHeight={1.5}
              colorId="Black"
              fontSize="10px"
              mb="16px"
              style={{ textAlign: 'center' }}
            >
              <TranslatedText
                translationKey={`youUnlockedTheStreakReward`}
                defaultMessage={`you unlocked 
the 7 day streak reward!`}
              />
              !
            </Text>
          )}

          {!isOnePrize && (
            <Text
              lineHeight={1.5}
              colorId="Black"
              fontSize="10px"
              style={{ textAlign: 'center' }}
            >
              <TranslatedText
                translationKey="prizeCounter"
                defaultMessage={`Prize ${index + 1} of ${claimedPrizes.length}`}
                props={{ index: index + 1, total: claimedPrizes.length }}
              />
            </Text>
          )}
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            width="fit-content"
            padding="none"
            gap="2px"
          >
            <PrizeIcon
              color="#B7A500"
              height={
                claimedPrizes[index].type == 'energy'
                  ? '24px'
                  : claimedPrizes[index].type == 'freeSpin'
                    ? '75px'
                    : '48px'
              }
              type={claimedPrizes[index].type}
            />

            <Text
              colorId="DarkYellow"
              fontSize={
                claimedPrizes[index].type != 'freeSpin' ? '32px' : '18px'
              }
              lineHeight="40px"
              style={{ textAlign: 'center' }}
            >
              {claimedPrizes[index].type != 'freeSpin' ? (
                claimedPrizes[index].amount ?? ''
              ) : (
                <>
                  <TranslatedText
                    translationKey="freeSpin"
                    defaultMessage="free spin"
                  />
                  !
                </>
              )}
            </Text>
          </Box>
          {claimedPrizes[index].type != 'freeSpin' && (
            <Text
              colorId="DarkYellow"
              fontSize="10px"
              lineHeight="14px"
              mb="8px"
              style={{ textAlign: 'center' }}
            >
              <TranslatedText
                translationKey="claimed"
                defaultMessage="claimed"
              />
              !
            </Text>
          )}

          {isOnePrize && (
            <Text
              lineHeight={1.5}
              colorId="Black"
              fontSize="10px"
              style={{ textAlign: 'center' }}
            >
              <TranslatedText
                translationKey="seeYouTomorrow"
                defaultMessage="see you tomorrow"
              />
              !
            </Text>
          )}
          <Button
            mt={2}
            colorId="Black"
            w="full"
            onClick={() => {
              if (claimedPrizes[index].type == 'freeSpin') {
                onGoToSpin();
              }
              if (index + 1 < claimedPrizes.length) {
                setIndex(index + 1);
              } else {
                onPrizesClaimed();
                setIndex(0);
              }
            }}
            marginBottom="8px"
          >
            {claimedPrizes[index].type != 'freeSpin' ? (
              index + 1 < claimedPrizes.length ? (
                <TranslatedText
                  translationKey="nextPrize"
                  defaultMessage="next prize"
                />
              ) : (
                <TranslatedText translationKey="ok" defaultMessage="ok" />
              )
            ) : (
              <>
                <TranslatedText
                  translationKey="goSpin"
                  defaultMessage="go spin"
                />
                !
              </>
            )}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
