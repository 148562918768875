export type StreakRewardType = `energy` | `ticket` | `freeSpin`;

export type StreakReward = {
  type: StreakRewardType;
  amount: number;
};

export type TelegramStreakReward = {
  rewards: StreakReward[];
  day: number;
};

export const TELEGRAM_DAILY_LOGIN_REWARDS_DEFAULT: TelegramStreakReward[] = [
  { day: 1, rewards: [{ type: `energy`, amount: 50 }] },
  { day: 2, rewards: [{ type: `energy`, amount: 75 }] },
  { day: 3, rewards: [{ type: `energy`, amount: 100 }] },
  { day: 4, rewards: [{ type: `energy`, amount: 125 }] },
  { day: 5, rewards: [{ type: `energy`, amount: 150 }] },
  { day: 6, rewards: [{ type: `energy`, amount: 450 }] },
  {
    day: 7,
    rewards: [
      { type: `energy`, amount: 1000 },
      { type: `ticket`, amount: 9 },
      { type: `freeSpin`, amount: 1 },
    ],
  },
];

export const TELEGRAM_BATTLE_DAY_REWARDS_DEFAULT: TelegramStreakReward[] = [
  { day: 1, rewards: [{ type: `energy`, amount: 50 }] },
  { day: 2, rewards: [{ type: `energy`, amount: 100 }] },
  { day: 3, rewards: [{ type: `energy`, amount: 300 }] },
  { day: 7, rewards: [{ type: `energy`, amount: 1500 }] },
  { day: 14, rewards: [{ type: `energy`, amount: 2000 }] },
  { day: 21, rewards: [{ type: `energy`, amount: 3000 }] },
  {
    day: 30,
    rewards: [
      { type: `energy`, amount: 5000 },
      { type: `ticket`, amount: 25 },
      { type: `freeSpin`, amount: 2 },
    ],
  },
];

export const TELEGRAM_FEED_DAY_REWARDS_DEFAULT: TelegramStreakReward[] = [
  { day: 1, rewards: [{ type: `energy`, amount: 25 }] },
  { day: 2, rewards: [{ type: `energy`, amount: 50 }] },
  { day: 3, rewards: [{ type: `energy`, amount: 150 }] },
  { day: 7, rewards: [{ type: `energy`, amount: 500 }] },
  { day: 14, rewards: [{ type: `energy`, amount: 1200 }] },
  { day: 21, rewards: [{ type: `energy`, amount: 2100 }] },
  {
    day: 30,
    rewards: [
      { type: `energy`, amount: 3500 },
      { type: `ticket`, amount: 15 },
      { type: `freeSpin`, amount: 1 },
    ],
  },
];

export function getRewardDay(currentStreak = 0, numOfRewards = 0) {
  return ((currentStreak - 1) % numOfRewards) + 1;
}

function getMaxStreakDayRewardLength(rewards: TelegramStreakReward[]) {
  return rewards.reduce((max, curr) => Math.max(max, curr.day), 0);
}

export function getBaseRewardDay(
  currentStreak = 0,
  rewards: TelegramStreakReward[] = [],
) {
  const maxStreakDayRewardLength = getMaxStreakDayRewardLength(rewards);
  let baseDay =
    Math.floor(currentStreak / maxStreakDayRewardLength) *
    maxStreakDayRewardLength;
  if (currentStreak % maxStreakDayRewardLength === 0) {
    baseDay -= maxStreakDayRewardLength;
  }
  return Math.max(baseDay, 0);
}

export function getRewardForStreak(
  streak = 0,
  rewards: TelegramStreakReward[] = [],
) {
  const maxStreakDayRewardLength = getMaxStreakDayRewardLength(rewards);
  const rewardDay = getRewardDay(streak, maxStreakDayRewardLength);
  const reward = rewards.reduce(
    (acc, curr) => {
      if (curr.day <= rewardDay) {
        return curr;
      }
      return acc;
    },
    {
      rewards: [],
      day: 0,
    },
  );
  return reward;
}

export function hasClaimableRewardForStreak(
  streak = 0,
  lastStreakClaimed = 0,
  rewards: TelegramStreakReward[] = [],
) {
  const reward = getRewardForStreak(streak, rewards);
  const baseDay = getBaseRewardDay(streak, rewards);
  return !(baseDay + reward.day <= lastStreakClaimed);
}

export function getNextRewardDayForStreak(
  streak = 0,
  baseDay = 0,
  rewards: TelegramStreakReward[] = [],
) {
  const nextReward = rewards.find((reward) => baseDay + reward.day >= streak);
  return nextReward ? baseDay + nextReward.day - streak : 0;
}

export function getPrizesForReward(streakReward: TelegramStreakReward) {
  const totalEnergyReward = streakReward.rewards
    .filter((reward) => reward.type === `energy`)
    .reduce((acc, curr) => acc + curr.amount, 0);
  const totalTicketReward = streakReward.rewards
    .filter((reward) => reward.type === `ticket`)
    .reduce((acc, curr) => acc + curr.amount, 0);
  const totalFreeSpinReward = streakReward.rewards
    .filter((reward) => reward.type === `freeSpin`)
    .reduce((acc, curr) => acc + curr.amount, 0);

  return {
    totalEnergyReward,
    totalTicketReward,
    totalFreeSpinReward,
  };
}
