import { Box, Card, Center, FlexProps } from '@chakra-ui/react';
import { TranslatedText } from '../ui/atoms/TranslatedText';
import { Text } from '../ui/atoms/Text';
import translations from '../../../../../packages/utils/src/i18n/en/index.json';
import { sounds } from '@/lib/sounds';
import { useEffectPlayer } from '@/context/EffectPlayerContext';

export const HomePetIcon = ({
  children,
  translateKey,
  translateProps,
  onClick,
  arrowPosition,
  width,
  height,
  color,
  ...props
}: {
  children: React.ReactNode;
  translateKey: keyof typeof translations;
  height: number;
  width: number;
  translateProps?: Record<string, any>;
  arrowPosition?: 'top' | 'bottom' | 'left' | 'right';
  color: string;
} & FlexProps) => {
  const effectPlayer = useEffectPlayer();
  const arrowSize = 12;
  const arrowStyles: Record<string, React.CSSProperties> = {
    top: {
      position: 'absolute',
      top: `-${arrowSize}px`,
      left: '50%',
      transform: 'translateX(-50%)',
      borderBottom: `${arrowSize}px solid black`,
      borderLeft: `${arrowSize}px solid transparent`,
      borderRight: `${arrowSize}px solid transparent`,
      borderTop: 'none',
    },
    bottom: {
      position: 'absolute',
      bottom: `-${arrowSize}px`,
      left: '50%',
      transform: 'translateX(-50%)',
      borderTop: `${arrowSize}px solid black`,
      borderLeft: `${arrowSize}px solid transparent`,
      borderRight: `${arrowSize}px solid transparent`,
      borderBottom: 'none',
    },
    left: {
      position: 'absolute',
      left: `-${arrowSize}px`,
      top: '50%',
      transform: 'translateY(-50%)',
      borderRight: `${arrowSize}px solid black`,
      borderTop: `${arrowSize}px solid transparent`,
      borderBottom: `${arrowSize}px solid transparent`,
      borderLeft: 'none',
    },
    right: {
      position: 'absolute',
      right: `-${arrowSize}px`,
      top: '50%',
      transform: 'translateY(-50%)',
      borderLeft: `${arrowSize}px solid black`,
      borderTop: `${arrowSize}px solid transparent`,
      borderBottom: `${arrowSize}px solid transparent`,
      borderRight: 'none',
    },
  };
  return (
    <Box position="relative" {...props}>
      {arrowPosition && <Box as="span" sx={arrowStyles[arrowPosition]} />}
      <Card
        background={color}
        height={`${height}px`}
        width={`${width}px`}
        flexDirection="column"
        gap={0}
        padding="8px 4px"
        border="3px solid black"
        borderRadius="0"
        bottom={0}
        onClick={(e) => {
          effectPlayer.playEffect(sounds.pressButton);
          onClick?.(e);
        }}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Center
          flexDirection={'column'}
          alignItems={'flex-start'}
          w="full"
          gap={1}
          {...props}
        >
          <Text
            colorId="Black"
            whiteSpace={'pre-wrap'}
            wordBreak={'break-all'}
            fontSize={'6px'}
            lineHeight={'10px'}
            w="full"
            textAlign={'center'}
          >
            <TranslatedText
              translationKey={translateKey}
              defaultMessage={`${translateKey}`}
              props={translateProps}
            />
          </Text>
          <Center w="full" cursor={'pointer'}>
            {children}
          </Center>
        </Center>
      </Card>
    </Box>
  );
};
