export const Tick = ({ ...props }) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4965_82103)">
      <rect y={13.9395} width={5.25} height={4.90352} fill="black" />
      <rect x={2.5} y={18} width={5.25} height={4.90352} fill="black" />
      <rect x={5} y={21} width={5.25} height={4.90352} fill="black" />
      <rect x={7.5} y={25} width={5.25} height={4.90352} fill="black" />
      <rect x={10.25} y={21} width={5.25} height={4.90352} fill="black" />
      <rect x={13} y={19} width={5.25} height={4.90352} fill="black" />
      <rect x={16} y={16} width={5.25} height={4.90352} fill="black" />
      <rect x={19} y={13} width={5.25} height={4.90352} fill="black" />
      <rect x={22} y={10} width={5.25} height={4.90352} fill="black" />
      <rect x={25} y={7} width={5.25} height={4.90352} fill="black" />
      <rect x={28} y={4} width={5.25} height={4.90352} fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_4965_82103">
        <rect width={32} height={32} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
