import { GAME_SETTINGS_ENDPOINT } from '@/config/endpoints';
import useAPI from '@/hooks/useAPI';
import { TelegramStreakReward } from 'genopets-utils/src/pixelton-arena';
import { useEffect, useState } from 'react';

export const GAME_SETTINGS_TELEGRAM_TICKETS_PER_DAY = `telegramTicketsPerDay`;
export const GAME_SETTINGS_TELEGRAM_PET_FEED_ENERGY_AMOUNT = `telegramPetFeedEnergyAmount`;
export const GAME_SETTINGS_TELEGRAM_DAILY_LOGIN_REWARDS = `telegramDailyLoginRewards`;
export const GAME_SETTINGS_TELEGRAM_BATTLE_DAY_STREAK_REWARDS = `telegramBattleDayStreakRewards`;
export const GAME_SETTINGS_TELEGRAM_FEED_DAY_STREAK_REWARDS = `telegramFeedDayStreakRewards`;
export const useGameSettings = () => {
  const [gameSettings, setGameSettings] = useState<{
    XPs: number[];
    globalEnergyBonusConfig?: Record<string, number>;
    referralEnergyPointsBonusConfig?: Record<string, number>;
    [GAME_SETTINGS_TELEGRAM_TICKETS_PER_DAY]?: number;
    [GAME_SETTINGS_TELEGRAM_PET_FEED_ENERGY_AMOUNT]?: number;
    [GAME_SETTINGS_TELEGRAM_DAILY_LOGIN_REWARDS]?: TelegramStreakReward[];
    [GAME_SETTINGS_TELEGRAM_BATTLE_DAY_STREAK_REWARDS]?: TelegramStreakReward[];
    [GAME_SETTINGS_TELEGRAM_FEED_DAY_STREAK_REWARDS]?: TelegramStreakReward[];
  }>();

  const { apiGet } = useAPI();

  useEffect(() => {
    if (!gameSettings) {
      apiGet(GAME_SETTINGS_ENDPOINT)
        .then((data) => {
          setGameSettings(data);
        })
        .catch((error: any) => console.error(error));
    }
  }, [gameSettings, apiGet]);

  return gameSettings;
};
