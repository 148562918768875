import { Text } from '@/components/ui/atoms/Text';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { Button } from '@/components/ui/molecules/Button';
import {
  REFERRAL_LINK_ENDPOINT,
  USER_EMIT_EVENT_ENDPOINT,
  USER_ENERGY_POINTS_SUMMARY,
} from '@/config/endpoints';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import { Box, Flex } from '@chakra-ui/react';
import { EnergyPointsSummary, copyToClipboard } from 'genopets-utils';
import { useCallback, useEffect, useState } from 'react';
import { initUtils } from '@tma.js/sdk-react';
import { useUserData } from '@/context/FirestoreContext';
import { Ticket } from '../ui/icons/Ticket';
import { ReferralData } from '@/components/earn/ReferralData';
import { useNavigate } from 'react-router-dom';

function extractLink(link: string): string {
  const url = new URL(link);
  const params = new URLSearchParams(url.search);
  return params.get('url') ?? '';
}

export const ReferralShare = () => {
  const navigate = useNavigate();
  const { apiGet } = useAPI();
  const [telegramLink, setTelegramLink] = useState('');
  const [referralLink, setReferralLink] = useState('');

  const alert = useAlert();

  const { apiPost } = useAPI();

  const { userData } = useUserData();

  const [energyPointsSummary, setEnergyPointsSummary] =
    useState<EnergyPointsSummary>();

  const mutateEnergyPointsSummary = useCallback(() => {
    if (!apiGet) return;
    return apiGet(USER_ENERGY_POINTS_SUMMARY + `?isTelegram=true`, true)
      .then((data) => {
        setEnergyPointsSummary(data);
      })
      .catch((error: any) => {
        console.error(error);
        alert({ title: error?.message ?? 'Error' });
      });
  }, [apiGet]);

  const getReferralLink = useCallback(() => {
    if (!apiGet) return;
    return apiGet(REFERRAL_LINK_ENDPOINT)
      .then((resp) => {
        if (resp?.link) {
          setTelegramLink(resp.link);

          setReferralLink(extractLink(resp.link));

          apiPost(USER_EMIT_EVENT_ENDPOINT, {
            userId: userData?.id,
            eventName: `telegramReferralLinkGenerated`,
            eventData: {
              referralCode: resp.code,
            },
          });
        }
      })
      .catch((error: any) => {
        console.error(error);
        alert({ title: error?.message ?? 'Error' });
      });
  }, [apiGet]);

  const share = () => {
    const utils = initUtils();
    utils.openTelegramLink(telegramLink);
  };

  useEffect(() => {
    if (!apiGet) return;

    mutateEnergyPointsSummary();
    getReferralLink();
  }, [apiGet]);

  return (
    <Flex
      flexDirection={'column'}
      background={`#FFF`}
      border={`solid 2px black`}
      h="full"
    >
      <Flex flexDir={'column'} w="full" gap={1} padding={`4px 8px`}>
        <Flex
          flexDir={'column'}
          padding={`8px`}
          justifyContent="center"
          alignItems="center"
        >
          <Text colorId={`Black`}>
            <TranslatedText
              translationKey={`inviteAFren`}
              defaultMessage={`Invite a fren`}
            />
          </Text>
          <Flex gap={`4px`} alignItems={`center`}>
            <Ticket style={{ width: 'auto', height: '32px' }} />
            <Text fontSize={`30px`} colorId={`Black`}>
              3
            </Text>
          </Flex>
        </Flex>

        <Button
          border={`3px solid black`}
          bgColor={`#B7A500`}
          w="full"
          padding={`12px`}
          onClick={() => share()}
        >
          <TranslatedText translationKey="share" defaultMessage="share" />
        </Button>
        <Button
          colorId="Black"
          w="full"
          padding={`12px`}
          onClick={() => {
            copyToClipboard(referralLink);
            alert({ title: `Link copied` });
          }}
        >
          <TranslatedText
            translationKey="copyLink"
            defaultMessage="copy link"
          />
        </Button>
      </Flex>

      <Box h="full">
        <ReferralData
          referredUsersCount={energyPointsSummary?.referredUsersCount}
        />
      </Box>
      <Box padding={`8px 8px 4px`}>
        <Button
          colorId="Black"
          w="full"
          onClick={() => {
            navigate('/home');
          }}
        >
          <TranslatedText
            translationKey={`goBack`}
            defaultMessage={`Go Back`}
          />
        </Button>
      </Box>
    </Flex>
  );
};
