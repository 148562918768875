import { Link } from '@/components/Link/Link';
import { Text } from '@/components/ui/atoms/Text';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { Ticket } from '@/components/ui/icons/Ticket';
import { Button } from '@/components/ui/molecules/Button';
import {
  CONVERT_ENERGY_TO_XP_ENDPOINT,
  TELEGRAM_WATCH_AD_REWARD_ENDPOINT,
  UPDATE_USER_TELEGRAM_TASK_RECORD_ENDPOINT,
} from '@/config/endpoints';
import { useUserData } from '@/context/FirestoreContext';
import {
  TaskVerificationProvider,
  useTaskVerification,
} from '@/context/TaskContext';
import useAPI from '@/hooks/useAPI';
import { Box, Flex } from '@chakra-ui/react';
import { TelegramTask } from 'genopets-utils';
import {
  MAX_ADSGRAM_VIEWS,
  calculateAdViews,
} from 'genopets-utils/src/adsgram';
import { useCallback, useState } from 'react';
import { Energy } from '../ui/icons/Energy';
import {
  VITE_ADSGRAM_ENABLED,
  VITE_ADSGRAM_BLOCK_ID,
} from '@/config/constants';
import { useAdsgram } from '@/hooks/useAdsgram';
import { useTasks } from '@/hooks/useTasks';

const TaskItem = ({
  task,
  mutateUserTelegramTaskData,
  isCompleted,
  onClickOverride,
}: {
  task: TelegramTask;
  isCompleted: boolean;
  mutateUserTelegramTaskData: any;
  onClickOverride?: () => void;
}) => {
  const { apiPut } = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const { processTask, verifyTask } = useTaskVerification();
  const { userData } = useUserData();

  const updateTaskCompletion = async () => {
    try {
      await apiPut(
        UPDATE_USER_TELEGRAM_TASK_RECORD_ENDPOINT,
        {
          telegramTaskId: task.id,
        },
        true,
      );
      // make validating task last for 5 sec
      setTimeout(() => {
        mutateUserTelegramTaskData();
        setIsLoading(false);
      }, 5000);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const completeTask = async () => {
    try {
      setIsLoading(true);

      // if user has already connected their ton wallet, we simply mark this task as completed
      if (task.id === 'connecttonwallet-t20yWz07' && userData?.tonWallet) {
        await updateTaskCompletion();
      } else {
        processTask(task.link);

        // if this task does not require verification
        // for example tasks where we cannot verify the user has actually done
        // we mark this task as completed ourselves
        if (!task.verificationPath) {
          await updateTaskCompletion();
        } else {
          // Set up visibility change listener for verification
          const handleVisibilityChange = () => {
            if (!document.hidden) {
              // once user returns to the app, we verify the task
              verifyTask(task.link).then((result: boolean) => {
                if (result) {
                  updateTaskCompletion();
                  window.removeEventListener('focus', handleVisibilityChange);
                }
              });
            }
          };
          window.addEventListener('focus', handleVisibilityChange);
        }
      }
    } catch (error: any) {
      console.error(error);
      alert({ title: error?.message ?? 'Error' });
    }
  };

  return (
    <Flex w={`full`} border={`solid 2px black`} opacity={isLoading ? 0.5 : 1}>
      <Box w={`full`} borderRight={`1px solid black`} padding={`4px 0 4px 6px`}>
        <Text colorId="Black" whiteSpace={`initial`} textAlign={`left`}>
          {isLoading ? `validating...` : task.name}
        </Text>
        <Flex alignItems={`center`} gap={`4px`}>
          {task.prizeType === `energy` ? (
            <Energy color="var(--Black)" />
          ) : (
            <Ticket />
          )}
          <Text colorId="Black" w="full" width={`auto`}>
            {task.prizeType === `energy` ? task.energy : task.tickets}
          </Text>
        </Flex>
      </Box>

      <Button
        borderLeft={`1px solid black`}
        colorId={`Yellow`}
        padding={`4px`}
        maxWidth={`70px`}
        minWidth={`0px`}
        w={`full`}
        isDisabled={isLoading || isCompleted}
        onClick={onClickOverride ?? completeTask}
      >
        <Text colorId="Black">
          {isCompleted ? (
            <TranslatedText translationKey={`done`} defaultMessage={`Done`} />
          ) : (
            `Go`
          )}
        </Text>
      </Button>
    </Flex>
  );
};

export const TasksContainer = () => {
  return (
    <Flex
      background={`var(--White)`}
      flexDirection={'column'}
      w={`full`}
      borderLeft={`solid 2px black`}
      borderRight={`solid 2px black`}
      h="full"
    >
      <Box w="full" h="full" position={'relative'}>
        <Box position="absolute" top={0} left={0} right={0} bottom={0}>
          <Tasks />
        </Box>
      </Box>
      <Box padding={`8px 8px 4px 8px`}>
        <Link to="/home" style={{ width: '100%' }}>
          <Button colorId="Black" w="full">
            <TranslatedText
              translationKey={`goHome`}
              defaultMessage={`Go Home`}
            />
          </Button>
        </Link>
      </Box>
    </Flex>
  );
};

const Tasks = () => {
  const { tasks, isLoading, userTelegramTaskData, mutateUserTelegramTaskData } =
    useTasks({ markAsViewed: true });
  const { apiPost } = useAPI();
  const userData = useUserData();

  const onReward = useCallback(() => {
    apiPost(TELEGRAM_WATCH_AD_REWARD_ENDPOINT, {})
      .then((result) => {
        console.log('telegram watch ad result: ', result);
        // convert pet energy to XP
        apiPost(CONVERT_ENERGY_TO_XP_ENDPOINT, {}).then((data) => {
          console.log('convert energy to xp result: ', data);
        });
      })
      .catch(console.error);
  }, [apiPost]);
  const onError = useCallback((result: any) => {
    console.error(
      'Adsgram error or user skipped ad: ',
      JSON.stringify(result, null, 4),
    );
  }, []);

  /**
   * insert your-block-id
   */
  const showAd = useAdsgram({
    blockId: VITE_ADSGRAM_BLOCK_ID,
    onReward,
    onError,
  });

  // Add ad task
  if (tasks && VITE_ADSGRAM_ENABLED) {
    const { currentViews } = calculateAdViews(userData.userData);

    tasks.unshift({
      id: 'ad',
      name: `Watch ad (${currentViews}/${MAX_ADSGRAM_VIEWS})`,
      position: 1,
      tickets: 0,
      prizeType: 'energy',
      energy: 45,
      link: '',
      isCompleted: currentViews >= MAX_ADSGRAM_VIEWS,
      onClickOverride: showAd,
    });
  }

  return (
    <Box
      borderTop={`1px solid black`}
      paddingTop={`4px`}
      h="full"
      display={'flex'}
      flexDirection={'column'}
      overflow={'hidden'}
      minHeight={`340px`}
      px={2}
    >
      <Text colorId="Black" w="full">
        <TranslatedText translationKey={`tasks`} defaultMessage={`Tasks`} />
      </Text>

      <Box h="full" overflow={'auto'} mt={`4px`}>
        <Box position={'relative'} w="full">
          {isLoading ? (
            <Text colorId="Black" w="full">
              <TranslatedText
                translationKey={`loading`}
                defaultMessage={`Loading`}
              />
              ...
            </Text>
          ) : (
            <TaskVerificationProvider>
              <Flex gap={`4px`} flexDirection={`column`}>
                {tasks?.map((task: TelegramTask) => (
                  <TaskItem
                    mutateUserTelegramTaskData={mutateUserTelegramTaskData}
                    key={task.id}
                    task={task}
                    isCompleted={
                      userTelegramTaskData?.[task.id] ??
                      task.isCompleted ??
                      false
                    }
                    onClickOverride={task.onClickOverride}
                  />
                ))}
              </Flex>
            </TaskVerificationProvider>
          )}
        </Box>
      </Box>
    </Box>
  );
};
