import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { Energy } from '@/components/ui/icons/Energy';
import { Ticket } from '@/components/ui/icons/Ticket';
import { Center, Box } from '@chakra-ui/react';
import { BattleLevelBar } from '../BattleLevelBar';
import { ClaimRewardButton } from './ClaimRewardButton';
import { useUserData } from '@/context/FirestoreContext';
import { useUIBattleContext } from '../context/useBattleContext';
import {
  RootState,
  BattleState,
  BattleEntity,
  BattlePlayerEntity,
} from 'genopets-utils';
import { useSelector } from 'react-redux';
import { Text } from '@/components/ui/atoms/Text';
import { Card } from '@/components/ui/molecules/Card';
import { useEffect, useState } from 'react';
import { StreakModal } from '@/components/streaks/StreakModal';
import {
  GAME_SETTINGS_TELEGRAM_BATTLE_DAY_STREAK_REWARDS,
  useGameSettings,
} from '../hooks/useGameSettings';
import { TELEGRAM_BATTLE_DAY_REWARDS_DEFAULT } from 'genopets-utils/src/pixelton-arena';
import useAPI from '@/hooks/useAPI';
import { USER_CLAIM_TELEGRAM_BATTLE_DAY_STREAK_REWARDS_ENDPOINT } from '@/config/endpoints';
import { useAlert } from '@/hooks/useAlert';
import { useNavigate } from 'react-router-dom';

export const BattleRewardsCard = ({
  battle,
  player,
}: {
  battle: BattleEntity;
  player?: BattlePlayerEntity | '';
}) => {
  const { user, pet } = useUIBattleContext();

  const { userData } = useUserData();

  const [showHomeButtons, setShowHomeButtons] = useState(false);

  const battleState = useSelector<RootState>(
    (state: RootState) => state.battle,
  ) as BattleState;

  const settings = useGameSettings();
  const { apiPost } = useAPI();
  const alert = useAlert();

  const battleStreak = userData?.telegram?.battleDayStreak ?? 0;
  const lastBattleDayStreakClaimed =
    userData?.telegram?.lastBattleDayStreakClaimed ?? 0;
  const battleDayStreakRewards =
    settings?.[GAME_SETTINGS_TELEGRAM_BATTLE_DAY_STREAK_REWARDS] ??
    TELEGRAM_BATTLE_DAY_REWARDS_DEFAULT;
  const [showStreakModal, setShowStreakModal] = useState(false);

  useEffect(() => {
    setShowStreakModal(battleStreak !== lastBattleDayStreakClaimed);
  }, [battleStreak, lastBattleDayStreakClaimed]);

  const getEnergyRewarded = () => {
    if (battleState.winner === user?.uid) {
      return !!battle?.winnerTelegramEnergy;
    } else if (battleState.winner !== user?.uid) {
      return !!battle?.loserTelegramEnergy;
    }
  };

  return (
    <>
      <StreakModal
        open={showStreakModal}
        onPrizesClaimed={() => setShowStreakModal(false)}
        streakRewards={battleDayStreakRewards}
        lastStreakClaimed={lastBattleDayStreakClaimed}
        streak={battleStreak}
        title={'earnBonus'}
        subtitle={'dailyBattleMessage'}
        titleBgColor={'var(--Teal)'}
        onClaim={async () => {
          try {
            const resp = await apiPost(
              USER_CLAIM_TELEGRAM_BATTLE_DAY_STREAK_REWARDS_ENDPOINT,
              {},
            );
            return resp.reward.rewards;
          } catch (err: any) {
            alert({ title: err.message, status: 'error' });
            return undefined;
          }
        }}
      />
      <Box position="absolute" bottom={0} right={0} left={0}>
        <Card>
          {(battle.telegramRewardsProcessed &&
            getEnergyRewarded() &&
            !showHomeButtons) ||
          (showHomeButtons && (userData?.telegramTickets ?? 0) > 0) ? (
            <Center w="full">
              <Text colorId="Black">
                <TranslatedText
                  translationKey={`ticketsLeft`}
                  defaultMessage={`Tickets Left`}
                />
              </Text>
              <Ticket />
              <Text colorId="Black">{userData?.telegramTickets}</Text>
            </Center>
          ) : (
            <Center w="full">
              {!battle.telegramRewardsProcessed ? (
                <>
                  <Text colorId={'Black'}>
                    <TranslatedText
                      translationKey={`loading`}
                      defaultMessage={`Loading`}
                    />
                    ...
                  </Text>
                </>
              ) : (
                <Center w="full" flexDirection={'column'} gap={1}>
                  <Text colorId={'Red'}>
                    <TranslatedText
                      translationKey={`youAreOutOfTickets`}
                      defaultMessage={`You're out of tickets`}
                    />
                  </Text>
                  {showHomeButtons &&
                    (userData?.telegramTickets ?? 0) === 0 && (
                      <Text colorId={'Red'}>
                        <TranslatedText
                          translationKey={`noTicketsNoEnergy`}
                          defaultMessage={`No Tickets = No Energy`}
                        />
                      </Text>
                    )}
                </Center>
              )}
            </Center>
          )}
          {(!showHomeButtons ||
            (showHomeButtons && (userData?.telegramTickets ?? 0) > 0)) && (
            <Center w="full" gap={2}>
              <Text colorId={getEnergyRewarded() ? 'Black' : 'Red'}>
                <TranslatedText
                  translationKey={`energyEarned`}
                  defaultMessage={`Energy Earned`}
                />
              </Text>
              <Energy
                color={getEnergyRewarded() ? 'var(--Black)' : 'var(--Red)'}
              />
              <Text colorId={getEnergyRewarded() ? 'Black' : 'Red'}>
                {!battle.telegramRewardsProcessed
                  ? '...'
                  : battleState.winner === user?.uid
                    ? battle?.winnerTelegramEnergy ?? 0
                    : battle?.loserTelegramEnergy ?? 0}
              </Text>
            </Center>
          )}
          {player && battle.telegramRewardsProcessed && (
            <BattleLevelBar
              pet={pet}
              energyRewarded={
                (battleState.winner === user?.uid
                  ? battle?.winnerTelegramEnergy
                  : battle?.loserTelegramEnergy) ?? 0
              }
            />
          )}

          <ClaimRewardButton
            battle={battle}
            setShowHomeButtons={setShowHomeButtons}
            showHomeButtons={showHomeButtons}
          />
        </Card>
      </Box>
    </>
  );
};
