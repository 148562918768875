export const HomeCake = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="36"
    fill="none"
    viewBox="0 0 40 36"
    {...props}
  >
    <path fill="#F8D18E" d="M5.455 31.816h-.91v.91h.91z"></path>
    <path fill="#F8D18E" d="M6.364 31.816h-.91v.91h.91z"></path>
    <path
      fill="#F8D18E"
      d="M7.273 31.816h-.91v.91h.91zM7.273 32.728h-.91v.91h.91z"
    ></path>
    <path fill="#FFE9B7" d="M8.182 31.816h-.91v.91h.91z"></path>
    <path fill="#F8D18E" d="M8.182 32.728h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M9.09 31.816h-.908v.91h.909z"></path>
    <path fill="#F8D18E" d="M9.09 32.728h-.908v.91h.909z"></path>
    <path
      fill="#FFE9B7"
      d="M10 31.816h-.91v.91H10zM10 32.728h-.91v.91H10zM10.91 31.816H10v.91h.91zM10.91 32.728H10v.91h.91z"
    ></path>
    <path
      fill="#FFE9B7"
      d="M11.818 31.816h-.909v.91h.91zM11.818 32.728h-.909v.91h.91z"
    ></path>
    <path
      fill="#FFE9B7"
      d="M12.727 31.816h-.909v.91h.91zM12.727 32.728h-.909v.91h.91z"
    ></path>
    <path
      fill="#FFE9B7"
      d="M13.636 31.816h-.909v.91h.91zM13.636 32.728h-.909v.91h.91z"
    ></path>
    <path
      fill="#FFE9B7"
      d="M14.545 31.816h-.909v.91h.91zM14.545 32.728h-.909v.91h.91z"
    ></path>
    <path fill="#FFE9B7" d="M15.455 31.816h-.91v.91h.91z"></path>
    <path
      fill="#FFFDFA"
      d="M15.455 32.728h-.91v.91h.91zM16.364 31.816h-.91v.91h.91z"
    ></path>
    <path fill="#FFFDFA" d="M16.364 32.728h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M17.273 31.816h-.91v.91h.91z"></path>
    <path fill="#FFFDFA" d="M17.273 32.728h-.91v.91h.91z"></path>
    <path
      fill="#FFE9B7"
      d="M18.182 31.816h-.91v.91h.91zM18.182 32.728h-.91v.91h.91zM19.09 31.816h-.908v.91h.909zM19.09 32.728h-.908v.91h.909z"
    ></path>
    <path
      fill="#FFE9B7"
      d="M20 31.816h-.91v.91H20zM20 32.728h-.91v.91H20zM20.91 31.816H20v.91h.91zM20.91 32.728H20v.91h.91z"
    ></path>
    <path
      fill="#FFE9B7"
      d="M21.818 31.816h-.909v.91h.91zM21.818 32.728h-.909v.91h.91z"
    ></path>
    <path
      fill="#FFE9B7"
      d="M22.727 31.816h-.909v.91h.91zM22.727 32.728h-.909v.91h.91z"
    ></path>
    <path
      fill="#FFE9B7"
      d="M23.636 31.816h-.909v.91h.91zM23.636 32.728h-.909v.91h.91z"
    ></path>
    <path
      fill="#FFE9B7"
      d="M24.545 31.816h-.909v.91h.91zM24.545 32.728h-.909v.91h.91z"
    ></path>
    <path fill="#FFE9B7" d="M25.455 31.816h-.91v.91h.91z"></path>
    <path fill="#F8D18E" d="M25.455 32.728h-.91v.91h.91z"></path>
    <path fill="#FFFDFA" d="M26.364 31.816h-.91v.91h.91z"></path>
    <path fill="#F8D18E" d="M26.364 32.728h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M27.273 31.816h-.91v.91h.91z"></path>
    <path
      fill="#F8D18E"
      d="M27.273 32.728h-.91v.91h.91zM28.182 31.816h-.91v.91h.91z"
    ></path>
    <path
      fill="#F8D18E"
      d="M28.182 32.728h-.91v.91h.91zM29.09 31.816h-.908v.91h.909zM29.09 32.728h-.908v.91h.909z"
    ></path>
    <path
      fill="#F8D18E"
      d="M30 31.816h-.91v.91H30zM30 32.728h-.91v.91H30zM30.91 31.816H30v.91h.91zM30.91 32.728H30v.91h.91zM10 33.635h-.91v.909H10zM10.91 33.635H10v.909h.91z"
    ></path>
    <path fill="#F8D18E" d="M11.818 33.635h-.909v.909h.91z"></path>
    <path fill="#F8D18E" d="M12.727 33.635h-.909v.909h.91z"></path>
    <path fill="#F8D18E" d="M13.636 33.635h-.909v.909h.91z"></path>
    <path fill="#FFE9B7" d="M14.545 33.635h-.909v.909h.91z"></path>
    <path fill="#FFE9B7" d="M15.455 33.635h-.91v.909h.91z"></path>
    <path fill="#FFFDFA" d="M16.364 33.635h-.91v.909h.91z"></path>
    <path fill="#FFE9B7" d="M17.273 33.635h-.91v.909h.91z"></path>
    <path
      fill="#FFE9B7"
      d="M18.182 33.635h-.91v.909h.91zM19.09 33.635h-.908v.909h.909z"
    ></path>
    <path
      fill="#FFE9B7"
      d="M20 33.635h-.91v.909H20zM20.91 33.635H20v.909h.91z"
    ></path>
    <path fill="#FFE9B7" d="M21.818 33.635h-.909v.909h.91z"></path>
    <path fill="#F8D18E" d="M22.727 33.635h-.909v.909h.91z"></path>
    <path fill="#F8D18E" d="M23.636 33.635h-.909v.909h.91z"></path>
    <path fill="#F8D18E" d="M24.545 33.635h-.909v.909h.91z"></path>
    <path
      fill="#F8D18E"
      d="M25.455 33.635h-.91v.909h.91zM14.545 34.545h-.909v.909h.91z"
    ></path>
    <path fill="#F8D18E" d="M15.455 34.545h-.91v.909h.91z"></path>
    <path fill="#F8D18E" d="M16.364 34.545h-.91v.909h.91z"></path>
    <path fill="#F8D18E" d="M17.273 34.545h-.91v.909h.91z"></path>
    <path
      fill="#F8D18E"
      d="M18.182 34.545h-.91v.909h.91zM19.09 34.545h-.908v.909h.909z"
    ></path>
    <path
      fill="#F8D18E"
      d="M20 34.545h-.91v.909H20zM20.91 34.545H20v.909h.91z"
    ></path>
    <path fill="#F8D18E" d="M21.818 34.545h-.909v.909h.91z"></path>
    <path fill="#F8D18E" d="M22.727 34.545h-.909v.909h.91z"></path>
    <path fill="#F8D18E" d="M23.636 34.545h-.909v.909h.91z"></path>
    <path fill="#F8D18E" d="M24.545 34.545h-.909v.909h.91z"></path>
    <path
      fill="#F8D18E"
      d="M25.455 34.545h-.91v.909h.91zM26.364 33.635h-.91v.909h.91z"
    ></path>
    <path fill="#F8D18E" d="M27.273 33.635h-.91v.909h.91z"></path>
    <path
      fill="#F8D18E"
      d="M28.182 33.635h-.91v.909h.91zM29.09 33.635h-.908v.909h.909z"
    ></path>
    <path
      fill="#F8D18E"
      d="M30 33.635h-.91v.909H30zM30.91 33.635H30v.909h.91zM31.818 31.816h-.909v.91h.91z"
    ></path>
    <path fill="#F8D18E" d="M31.818 32.728h-.909v.91h.91z"></path>
    <path fill="#FFFDFA" d="M32.727 31.816h-.909v.91h.91z"></path>
    <path
      fill="#F8D18E"
      d="M32.727 32.728h-.909v.91h.91zM33.636 31.816h-.909v.91h.91z"
    ></path>
    <path
      fill="#F8D18E"
      d="M33.636 32.728h-.909v.91h.91zM34.546 31.816h-.91v.91h.91z"
    ></path>
    <path
      fill="#F8D18E"
      d="M35.455 31.816h-.91v.91h.91zM4.545 30.91h-.909v.91h.91z"
    ></path>
    <path fill="#FFE9B7" d="M5.455 30.91h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M6.364 30.91h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M7.273 30.91h-.91v.91h.91z"></path>
    <path
      fill="#FFE9B7"
      d="M8.182 30.91h-.91v.91h.91zM9.09 30.91h-.908v.91h.909z"
    ></path>
    <path
      fill="#FFE9B7"
      d="M10 30.91h-.91v.91H10zM10.91 30.91H10v.91h.91z"
    ></path>
    <path fill="#FFE9B7" d="M11.818 30.91h-.909v.91h.91z"></path>
    <path fill="#FFE9B7" d="M12.727 30.91h-.909v.91h.91z"></path>
    <path fill="#F9ADC6" d="M13.636 30.91h-.909v.91h.91z"></path>
    <path fill="#F9ADC6" d="M14.545 30.91h-.909v.91h.91z"></path>
    <path fill="#F9ADC6" d="M15.455 30.91h-.91v.91h.91z"></path>
    <path fill="#F9ADC6" d="M16.364 30.91h-.91v.91h.91z"></path>
    <path fill="#F9ADC6" d="M17.273 30.91h-.91v.91h.91z"></path>
    <path
      fill="#F9ADC6"
      d="M18.182 30.91h-.91v.91h.91zM19.09 30.91h-.908v.91h.909z"
    ></path>
    <path
      fill="#F9ADC6"
      d="M20 30.91h-.91v.91H20zM20.91 30.91H20v.91h.91z"
    ></path>
    <path fill="#F9ADC6" d="M21.818 30.91h-.909v.91h.91z"></path>
    <path fill="#F9ADC6" d="M22.727 30.91h-.909v.91h.91z"></path>
    <path fill="#F9ADC6" d="M23.636 30.91h-.909v.91h.91z"></path>
    <path fill="#F9ADC6" d="M24.545 30.91h-.909v.91h.91z"></path>
    <path fill="#FFE9B7" d="M25.455 30.91h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M26.364 30.91h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M27.273 30.91h-.91v.91h.91z"></path>
    <path
      fill="#FFE9B7"
      d="M28.182 30.91h-.91v.91h.91zM29.09 30.91h-.908v.91h.909z"
    ></path>
    <path
      fill="#F8D18E"
      d="M30 30.91h-.91v.91H30zM30.91 30.91H30v.91h.91z"
    ></path>
    <path fill="#FFFDFA" d="M31.818 30.91h-.909v.91h.91z"></path>
    <path fill="#FFFDFA" d="M32.727 30.91h-.909v.91h.91z"></path>
    <path fill="#FFFDFA" d="M33.636 30.91h-.909v.91h.91z"></path>
    <path fill="#F8D18E" d="M34.546 30.91h-.91v.91h.91z"></path>
    <path fill="#F8D18E" d="M35.455 30.91h-.91v.91h.91z"></path>
    <path fill="#F8D18E" d="M36.364 30.91h-.91v.91h.91z"></path>
    <path fill="#F8D18E" d="M37.273 30.91h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M4.545 30h-.909v.91h.91z"></path>
    <path fill="#FFE9B7" d="M5.455 30h-.91v.91h.91z"></path>
    <path fill="#F8D18E" d="M3.636 30h-.909v.91h.91z"></path>
    <path fill="#FFFDFA" d="M6.364 30h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M7.273 30h-.91v.91h.91z"></path>
    <path
      fill="#FFE9B7"
      d="M8.182 30h-.91v.91h.91zM9.09 30h-.908v.91h.909z"
    ></path>
    <path fill="#F9ADC6" d="M10 30h-.91v.91H10zM10.91 30H10v.91h.91z"></path>
    <path fill="#F9ADC6" d="M11.818 30h-.909v.91h.91z"></path>
    <path fill="#F9ADC6" d="M12.727 30h-.909v.91h.91z"></path>
    <path fill="#F6E4F9" d="M13.636 30h-.909v.91h.91z"></path>
    <path fill="#F6E4F9" d="M14.545 30h-.909v.91h.91z"></path>
    <path fill="#F6E4F9" d="M15.455 30h-.91v.91h.91z"></path>
    <path fill="#F6E4F9" d="M16.364 30h-.91v.91h.91z"></path>
    <path fill="#F6E4F9" d="M17.273 30h-.91v.91h.91z"></path>
    <path
      fill="#F6E4F9"
      d="M18.182 30h-.91v.91h.91zM19.09 30h-.908v.91h.909z"
    ></path>
    <path fill="#F6E4F9" d="M20 30h-.91v.91H20zM20.91 30H20v.91h.91z"></path>
    <path fill="#F6E4F9" d="M21.818 30h-.909v.91h.91z"></path>
    <path fill="#F6E4F9" d="M22.727 30h-.909v.91h.91z"></path>
    <path fill="#F6E4F9" d="M23.636 30h-.909v.91h.91z"></path>
    <path fill="#F6E4F9" d="M24.545 30h-.909v.91h.91z"></path>
    <path fill="#F9ADC6" d="M25.455 30h-.91v.91h.91z"></path>
    <path fill="#F9ADC6" d="M26.364 30h-.91v.91h.91z"></path>
    <path fill="#F9ADC6" d="M27.273 30h-.91v.91h.91z"></path>
    <path
      fill="#F9ADC6"
      d="M28.182 30h-.91v.91h.91zM29.09 30h-.908v.91h.909z"
    ></path>
    <path fill="#F9ADC6" d="M30 30h-.91v.91H30zM30.91 30H30v.91h.91z"></path>
    <path fill="#FFE9B7" d="M31.818 30h-.909v.91h.91z"></path>
    <path fill="#FFFDFA" d="M32.727 30h-.909v.91h.91z"></path>
    <path fill="#F8D18E" d="M33.636 30h-.909v.91h.91z"></path>
    <path fill="#F8D18E" d="M34.546 30h-.91v.91h.91z"></path>
    <path fill="#F8D18E" d="M35.455 30h-.91v.91h.91z"></path>
    <path fill="#FFFDFA" d="M36.364 30h-.91v.91h.91z"></path>
    <path fill="#F8D18E" d="M37.273 30h-.91v.91h.91z"></path>
    <path fill="#F8D18E" d="M38.182 30h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M4.545 29.088h-.909v.909h.91z"></path>
    <path fill="#FFE9B7" d="M5.455 29.088h-.91v.909h.91z"></path>
    <path fill="#F8D18E" d="M2.727 29.088h-.909v.909h.91z"></path>
    <path
      fill="#FFE9B7"
      d="M3.636 29.088h-.909v.909h.91zM6.364 29.088h-.91v.909h.91z"
    ></path>
    <path fill="#FFE9B7" d="M7.273 29.088h-.91v.909h.91z"></path>
    <path
      fill="#F9ADC6"
      d="M8.182 29.088h-.91v.909h.91zM9.09 29.088h-.908v.909h.909z"
    ></path>
    <path
      fill="#F6E4F9"
      d="M10 29.088h-.91v.909H10zM10.91 29.088H10v.909h.91z"
    ></path>
    <path fill="#F6E4F9" d="M11.818 29.088h-.909v.909h.91z"></path>
    <path fill="#F6E4F9" d="M12.727 29.088h-.909v.909h.91z"></path>
    <path fill="#FEF3FF" d="M13.636 29.088h-.909v.909h.91z"></path>
    <path fill="#FEF3FF" d="M14.545 29.088h-.909v.909h.91z"></path>
    <path fill="#FEF3FF" d="M15.455 29.088h-.91v.909h.91z"></path>
    <path fill="#FEF3FF" d="M16.364 29.088h-.91v.909h.91z"></path>
    <path fill="#FEF3FF" d="M17.273 29.088h-.91v.909h.91z"></path>
    <path
      fill="#FEF3FF"
      d="M18.182 29.088h-.91v.909h.91zM19.09 29.088h-.908v.909h.909z"
    ></path>
    <path
      fill="#FEF3FF"
      d="M20 29.088h-.91v.909H20zM20.91 29.088H20v.909h.91z"
    ></path>
    <path fill="#FEF3FF" d="M21.818 29.088h-.909v.909h.91z"></path>
    <path fill="#FEF3FF" d="M22.727 29.088h-.909v.909h.91z"></path>
    <path fill="#FEF3FF" d="M23.636 29.088h-.909v.909h.91z"></path>
    <path fill="#FEF3FF" d="M24.545 29.088h-.909v.909h.91z"></path>
    <path fill="#FEF3FF" d="M25.455 29.088h-.91v.909h.91z"></path>
    <path fill="#FEF3FF" d="M26.364 29.088h-.91v.909h.91z"></path>
    <path fill="#FEF3FF" d="M27.273 29.088h-.91v.909h.91z"></path>
    <path
      fill="#FEF3FF"
      d="M28.182 29.088h-.91v.909h.91zM29.09 29.088h-.908v.909h.909z"
    ></path>
    <path
      fill="#FEF3FF"
      d="M30 29.088h-.91v.909H30zM30.91 29.088H30v.909h.91z"
    ></path>
    <path fill="#F9ADC6" d="M31.818 29.088h-.909v.909h.91z"></path>
    <path fill="#F9ADC6" d="M32.727 29.088h-.909v.909h.91z"></path>
    <path fill="#F9ADC6" d="M33.636 29.088h-.909v.909h.91z"></path>
    <path fill="#F8D18E" d="M34.546 29.088h-.91v.909h.91z"></path>
    <path fill="#F8D18E" d="M35.455 29.088h-.91v.909h.91z"></path>
    <path fill="#F8D18E" d="M36.364 29.088h-.91v.909h.91z"></path>
    <path fill="#F8D18E" d="M37.273 29.088h-.91v.909h.91z"></path>
    <path
      fill="#F8D18E"
      d="M38.182 29.088h-.91v.909h.91zM39.09 29.088h-.908v.909h.909z"
    ></path>
    <path fill="#FFE9B7" d="M4.545 28.182h-.909v.909h.91z"></path>
    <path fill="#F9ADC6" d="M5.455 28.182h-.91v.909h.91z"></path>
    <path fill="#FFE9B7" d="M2.727 28.182h-.909v.909h.91z"></path>
    <path fill="#FFE9B7" d="M3.636 28.182h-.909v.909h.91z"></path>
    <path fill="#F8D18E" d="M1.818 28.182H.91v.909h.91z"></path>
    <path fill="#F9ADC6" d="M6.364 28.182h-.91v.909h.91z"></path>
    <path fill="#F9ADC6" d="M7.273 28.182h-.91v.909h.91z"></path>
    <path
      fill="#F6E4F9"
      d="M8.182 28.182h-.91v.909h.91zM9.09 28.182h-.908v.909h.909z"
    ></path>
    <path
      fill="#FEF3FF"
      d="M10 28.182h-.91v.909H10zM10.91 28.182H10v.909h.91z"
    ></path>
    <path fill="#FEF3FF" d="M11.818 28.182h-.909v.909h.91z"></path>
    <path fill="#FEF3FF" d="M12.727 28.182h-.909v.909h.91z"></path>
    <path fill="#FEF3FF" d="M13.636 28.182h-.909v.909h.91z"></path>
    <path fill="#FEF3FF" d="M14.545 28.182h-.909v.909h.91z"></path>
    <path fill="#F9ADC6" d="M15.455 28.182h-.91v.909h.91z"></path>
    <path fill="#F9ADC6" d="M16.364 28.182h-.91v.909h.91z"></path>
    <path fill="#F9ADC6" d="M17.273 28.182h-.91v.909h.91z"></path>
    <path
      fill="#F9ADC6"
      d="M18.182 28.182h-.91v.909h.91zM19.09 28.182h-.908v.909h.909z"
    ></path>
    <path
      fill="#F9ADC6"
      d="M20 28.182h-.91v.909H20zM20.91 28.182H20v.909h.91z"
    ></path>
    <path fill="#F9ADC6" d="M21.818 28.182h-.909v.909h.91z"></path>
    <path fill="#F9ADC6" d="M22.727 28.182h-.909v.909h.91z"></path>
    <path fill="#F9ADC6" d="M23.636 28.182h-.909v.909h.91z"></path>
    <path fill="#F9ADC6" d="M24.545 28.182h-.909v.909h.91z"></path>
    <path fill="#F9ADC6" d="M25.455 28.182h-.91v.909h.91z"></path>
    <path fill="#F9ADC6" d="M26.364 28.182h-.91v.909h.91z"></path>
    <path fill="#F9ADC6" d="M27.273 28.182h-.91v.909h.91z"></path>
    <path
      fill="#F9ADC6"
      d="M28.182 28.182h-.91v.909h.91zM29.09 28.182h-.908v.909h.909z"
    ></path>
    <path
      fill="#FEF3FF"
      d="M30 28.182h-.91v.909H30zM30.91 28.182H30v.909h.91z"
    ></path>
    <path fill="#FEF3FF" d="M31.818 28.182h-.909v.909h.91z"></path>
    <path fill="#FEF3FF" d="M32.727 28.182h-.909v.909h.91z"></path>
    <path fill="#F6E4F9" d="M33.636 28.182h-.909v.909h.91z"></path>
    <path fill="#F9ADC6" d="M34.546 28.182h-.91v.909h.91z"></path>
    <path fill="#F9ADC6" d="M35.455 28.182h-.91v.909h.91z"></path>
    <path fill="#F8D18E" d="M36.364 28.182h-.91v.909h.91z"></path>
    <path fill="#F8D18E" d="M37.273 28.182h-.91v.909h.91z"></path>
    <path
      fill="#F8D18E"
      d="M38.182 28.182h-.91v.909h.91zM39.09 28.182h-.908v.909h.909z"
    ></path>
    <path fill="#F9ADC6" d="M4.545 27.272h-.909v.909h.91z"></path>
    <path fill="#F6E4F9" d="M5.455 27.272h-.91v.909h.91z"></path>
    <path fill="#FFE9B7" d="M2.727 27.272h-.909v.909h.91z"></path>
    <path fill="#F9ADC6" d="M3.636 27.272h-.909v.909h.91z"></path>
    <path fill="#FFE9B7" d="M1.818 27.272H.91v.909h.91z"></path>
    <path fill="#F6E4F9" d="M6.364 27.272h-.91v.909h.91z"></path>
    <path fill="#FEF3FF" d="M7.273 27.272h-.91v.909h.91z"></path>
    <path
      fill="#FEF3FF"
      d="M8.182 27.272h-.91v.909h.91zM9.09 27.272h-.908v.909h.909z"
    ></path>
    <path fill="#FEF3FF" d="M10 27.272h-.91v.909H10z"></path>
    <path fill="#F9ADC6" d="M10.91 27.272H10v.909h.91z"></path>
    <path fill="#F9ADC6" d="M11.818 27.272h-.909v.909h.91z"></path>
    <path fill="#F9ADC6" d="M12.727 27.272h-.909v.909h.91z"></path>
    <path fill="#F9ADC6" d="M13.636 27.272h-.909v.909h.91z"></path>
    <path fill="#F9ADC6" d="M14.545 27.272h-.909v.909h.91z"></path>
    <path fill="#FFE9B7" d="M15.455 27.272h-.91v.909h.91z"></path>
    <path fill="#FFE9B7" d="M16.364 27.272h-.91v.909h.91z"></path>
    <path fill="#FFE9B7" d="M17.273 27.272h-.91v.909h.91z"></path>
    <path
      fill="#FFE9B7"
      d="M18.182 27.272h-.91v.909h.91zM19.09 27.272h-.908v.909h.909z"
    ></path>
    <path
      fill="#FFE9B7"
      d="M20 27.272h-.91v.909H20zM20.91 27.272H20v.909h.91z"
    ></path>
    <path fill="#FFE9B7" d="M21.818 27.272h-.909v.909h.91z"></path>
    <path fill="#FFE9B7" d="M22.727 27.272h-.909v.909h.91z"></path>
    <path fill="#FFE9B7" d="M23.636 27.272h-.909v.909h.91z"></path>
    <path fill="#FFE9B7" d="M24.545 27.272h-.909v.909h.91z"></path>
    <path fill="#FFE9B7" d="M25.455 27.272h-.91v.909h.91z"></path>
    <path fill="#FFE9B7" d="M26.364 27.272h-.91v.909h.91z"></path>
    <path fill="#FFE9B7" d="M27.273 27.272h-.91v.909h.91z"></path>
    <path
      fill="#FFE9B7"
      d="M28.182 27.272h-.91v.909h.91zM29.09 27.272h-.908v.909h.909z"
    ></path>
    <path
      fill="#F9ADC6"
      d="M30 27.272h-.91v.909H30zM30.91 27.272H30v.909h.91z"
    ></path>
    <path fill="#F9ADC6" d="M31.818 27.272h-.909v.909h.91z"></path>
    <path fill="#FEF3FF" d="M32.727 27.272h-.909v.909h.91z"></path>
    <path fill="#FEF3FF" d="M33.636 27.272h-.909v.909h.91z"></path>
    <path fill="#F6E4F9" d="M34.546 27.272h-.91v.909h.91z"></path>
    <path fill="#F6E4F9" d="M35.455 27.272h-.91v.909h.91z"></path>
    <path fill="#F9ADC6" d="M36.364 27.272h-.91v.909h.91z"></path>
    <path fill="#F9ADC6" d="M37.273 27.272h-.91v.909h.91z"></path>
    <path
      fill="#F8D18E"
      d="M38.182 27.272h-.91v.909h.91zM39.09 27.272h-.908v.909h.909z"
    ></path>
    <path fill="#F6E4F9" d="M4.545 26.365h-.909v.91h.91z"></path>
    <path fill="#FEF3FF" d="M5.455 26.365h-.91v.91h.91z"></path>
    <path fill="#F9ADC6" d="M2.727 26.365h-.909v.91h.91z"></path>
    <path fill="#F6E4F9" d="M3.636 26.365h-.909v.91h.91z"></path>
    <path fill="#FFE9B7" d="M1.818 26.365H.91v.91h.91z"></path>
    <path fill="#FEF3FF" d="M6.364 26.365h-.91v.91h.91z"></path>
    <path fill="#F9ADC6" d="M7.273 26.365h-.91v.91h.91z"></path>
    <path
      fill="#F9ADC6"
      d="M8.182 26.365h-.91v.91h.91zM9.09 26.365h-.908v.91h.909z"
    ></path>
    <path fill="#F9ADC6" d="M10 26.365h-.91v.91H10z"></path>
    <path fill="#FFE9B7" d="M10.91 26.365H10v.91h.91z"></path>
    <path fill="#FFE9B7" d="M11.818 26.365h-.909v.91h.91z"></path>
    <path fill="#FFE9B7" d="M12.727 26.365h-.909v.91h.91z"></path>
    <path fill="#F8D18E" d="M13.636 26.365h-.909v.91h.91z"></path>
    <path fill="#F8D18E" d="M14.545 26.365h-.909v.91h.91z"></path>
    <path fill="#FFE9B7" d="M15.455 26.365h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M16.364 26.365h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M17.273 26.365h-.91v.91h.91z"></path>
    <path fill="#FFFDFA" d="M18.182 26.365h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M19.09 26.365h-.908v.91h.909z"></path>
    <path
      fill="#F8D18E"
      d="M20 26.365h-.91v.91H20zM20.91 26.365H20v.91h.91z"
    ></path>
    <path fill="#F8D18E" d="M21.818 26.365h-.909v.91h.91z"></path>
    <path fill="#FFE9B7" d="M22.727 26.365h-.909v.91h.91z"></path>
    <path fill="#FFE9B7" d="M23.636 26.365h-.909v.91h.91z"></path>
    <path fill="#FFE9B7" d="M24.545 26.365h-.909v.91h.91z"></path>
    <path fill="#FFFDFA" d="M25.455 26.365h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M26.364 26.365h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M27.273 26.365h-.91v.91h.91z"></path>
    <path
      fill="#FFE9B7"
      d="M28.182 26.365h-.91v.91h.91zM29.09 26.365h-.908v.91h.909z"
    ></path>
    <path
      fill="#FFE9B7"
      d="M30 26.365h-.91v.91H30zM30.91 26.365H30v.91h.91z"
    ></path>
    <path fill="#FFE9B7" d="M31.818 26.365h-.909v.91h.91z"></path>
    <path fill="#F9ADC6" d="M32.727 26.365h-.909v.91h.91z"></path>
    <path fill="#F9ADC6" d="M33.636 26.365h-.909v.91h.91z"></path>
    <path fill="#FEF3FF" d="M34.546 26.365h-.91v.91h.91z"></path>
    <path fill="#FEF3FF" d="M35.455 26.365h-.91v.91h.91z"></path>
    <path fill="#F6E4F9" d="M36.364 26.365h-.91v.91h.91z"></path>
    <path fill="#F6E4F9" d="M37.273 26.365h-.91v.91h.91z"></path>
    <path fill="#F9ADC6" d="M38.182 26.365h-.91v.91h.91z"></path>
    <path fill="#F8D18E" d="M39.09 26.365h-.908v.91h.909z"></path>
    <path fill="#FEF3FF" d="M4.545 25.455h-.909v.91h.91z"></path>
    <path fill="#F9ADC6" d="M5.455 25.455h-.91v.91h.91z"></path>
    <path fill="#F6E4F9" d="M2.727 25.455h-.909v.91h.91z"></path>
    <path fill="#FEF3FF" d="M3.636 25.455h-.909v.91h.91z"></path>
    <path
      fill="#F9ADC6"
      d="M1.818 25.455H.91v.91h.91zM6.364 25.455h-.91v.91h.91z"
    ></path>
    <path fill="#FFE9B7" d="M7.273 25.455h-.91v.91h.91z"></path>
    <path
      fill="#FFE9B7"
      d="M8.182 25.455h-.91v.91h.91zM9.09 25.455h-.908v.91h.909z"
    ></path>
    <path
      fill="#FFE9B7"
      d="M10 25.455h-.91v.91H10zM10.91 25.455H10v.91h.91z"
    ></path>
    <path fill="#FFE9B7" d="M11.818 25.455h-.909v.91h.91z"></path>
    <path fill="#FFE9B7" d="M12.727 25.455h-.909v.91h.91z"></path>
    <path fill="#E0AEF9" d="M13.636 25.455h-.909v.91h.91z"></path>
    <path fill="#E0AEF9" d="M14.545 25.455h-.909v.91h.91z"></path>
    <path fill="#F8D18E" d="M15.455 25.455h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M16.364 25.455h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M17.273 25.455h-.91v.91h.91z"></path>
    <path
      fill="#FFE9B7"
      d="M18.182 25.455h-.91v.91h.91zM19.09 25.455h-.908v.91h.909z"
    ></path>
    <path
      fill="#EBCAFA"
      d="M20 25.455h-.91v.91H20zM20.91 25.455H20v.91h.91z"
    ></path>
    <path fill="#EBCAFA" d="M21.818 25.455h-.909v.91h.91z"></path>
    <path fill="#F8D18E" d="M22.727 25.455h-.909v.91h.91z"></path>
    <path fill="#FFE9B7" d="M23.636 25.455h-.909v.91h.91z"></path>
    <path fill="#FFFDFA" d="M24.545 25.455h-.909v.91h.91z"></path>
    <path fill="#FFFDFA" d="M25.455 25.455h-.91v.91h.91z"></path>
    <path fill="#FFFDFA" d="M26.364 25.455h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M27.273 25.455h-.91v.91h.91z"></path>
    <path
      fill="#F8D18E"
      d="M28.182 25.455h-.91v.91h.91zM29.09 25.455h-.908v.91h.909z"
    ></path>
    <path fill="#FFE9B7" d="M30 25.455h-.91v.91H30z"></path>
    <path fill="#FFFDFA" d="M30.91 25.455H30v.91h.91z"></path>
    <path fill="#FFE9B7" d="M31.818 25.455h-.909v.91h.91z"></path>
    <path fill="#FFE9B7" d="M32.727 25.455h-.909v.91h.91z"></path>
    <path fill="#F9ADC6" d="M33.636 25.455h-.909v.91h.91z"></path>
    <path fill="#F9ADC6" d="M34.546 25.455h-.91v.91h.91z"></path>
    <path fill="#F9ADC6" d="M35.455 25.455h-.91v.91h.91z"></path>
    <path fill="#FEF3FF" d="M36.364 25.455h-.91v.91h.91z"></path>
    <path fill="#FEF3FF" d="M37.273 25.455h-.91v.91h.91z"></path>
    <path fill="#F6E4F9" d="M38.182 25.455h-.91v.91h.91z"></path>
    <path
      fill="#F9ADC6"
      d="M39.09 25.455h-.908v.91h.909zM4.545 24.543h-.909v.91h.91z"
    ></path>
    <path fill="#FFE9B7" d="M5.455 24.543h-.91v.91h.91z"></path>
    <path fill="#FEF3FF" d="M2.727 24.543h-.909v.91h.91z"></path>
    <path fill="#FEF3FF" d="M3.636 24.543h-.909v.91h.91z"></path>
    <path fill="#F6E4F9" d="M1.818 24.543H.91v.91h.91z"></path>
    <path fill="#FFFDFA" d="M6.364 24.543h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M7.273 24.543h-.91v.91h.91z"></path>
    <path
      fill="#FFE9B7"
      d="M8.182 24.543h-.91v.91h.91zM9.09 24.543h-.908v.91h.909z"
    ></path>
    <path
      fill="#FFE9B7"
      d="M10 24.543h-.91v.91H10zM10.91 24.543H10v.91h.91z"
    ></path>
    <path fill="#FFE9B7" d="M11.818 24.543h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M12.727 24.543h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M13.636 24.543h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M14.545 24.543h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M15.455 24.543h-.91v.91h.91z"></path>
    <path fill="#F8D18E" d="M16.364 24.543h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M17.273 24.543h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M18.182 24.543h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M19.09 24.543h-.908v.91h.909z"></path>
    <path
      fill="#EBCAFA"
      d="M20 24.543h-.91v.91H20zM20.91 24.543H20v.91h.91z"
    ></path>
    <path fill="#EBCAFA" d="M21.818 24.543h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M22.727 24.543h-.909v.91h.91z"></path>
    <path fill="#F8D18E" d="M23.636 24.543h-.909v.91h.91z"></path>
    <path fill="#FFE9B7" d="M24.545 24.543h-.909v.91h.91z"></path>
    <path fill="#FFFDFA" d="M25.455 24.543h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M26.364 24.543h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M27.273 24.543h-.91v.91h.91z"></path>
    <path
      fill="#E0AEF9"
      d="M28.182 24.543h-.91v.91h.91zM29.09 24.543h-.908v.91h.909z"
    ></path>
    <path fill="#F8D18E" d="M30 24.543h-.91v.91H30z"></path>
    <path fill="#FFE9B7" d="M30.91 24.543H30v.91h.91z"></path>
    <path fill="#FFE9B7" d="M31.818 24.543h-.909v.91h.91z"></path>
    <path fill="#F8D18E" d="M32.727 24.543h-.909v.91h.91z"></path>
    <path fill="#F8D18E" d="M33.636 24.543h-.909v.91h.91z"></path>
    <path fill="#FFE9B7" d="M34.546 24.543h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M35.455 24.543h-.91v.91h.91z"></path>
    <path fill="#F9ADC6" d="M36.364 24.543h-.91v.91h.91z"></path>
    <path fill="#F9ADC6" d="M37.273 24.543h-.91v.91h.91z"></path>
    <path fill="#FEF3FF" d="M38.182 24.543h-.91v.91h.91z"></path>
    <path fill="#F6E4F9" d="M39.09 24.543h-.908v.91h.909z"></path>
    <path fill="#FFE9B7" d="M4.545 23.637h-.909v.909h.91z"></path>
    <path fill="#FFFDFA" d="M5.455 23.637h-.91v.909h.91z"></path>
    <path fill="#FEF3FF" d="M2.727 23.637h-.909v.909h.91z"></path>
    <path fill="#F9ADC6" d="M3.636 23.637h-.909v.909h.91z"></path>
    <path fill="#FEF3FF" d="M1.818 23.637H.91v.909h.91z"></path>
    <path fill="#FFFDFA" d="M6.364 23.637h-.91v.909h.91z"></path>
    <path fill="#FFFDFA" d="M7.273 23.637h-.91v.909h.91z"></path>
    <path
      fill="#FFE9B7"
      d="M8.182 23.637h-.91v.909h.91zM9.09 23.637h-.908v.909h.909z"
    ></path>
    <path
      fill="#FFE9B7"
      d="M10 23.637h-.91v.909H10zM10.91 23.637H10v.909h.91z"
    ></path>
    <path fill="#EBCAFA" d="M11.818 23.637h-.909v.909h.91z"></path>
    <path fill="#F6E6FF" d="M12.727 23.637h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M13.636 23.637h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M14.545 23.637h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M15.455 23.637h-.91v.909h.91z"></path>
    <path fill="#F8D18E" d="M16.364 23.637h-.91v.909h.91z"></path>
    <path fill="#FFE9B7" d="M17.273 23.637h-.91v.909h.91z"></path>
    <path fill="#FFE9B7" d="M18.182 23.637h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M19.09 23.637h-.908v.909h.909z"></path>
    <path
      fill="#EBCAFA"
      d="M20 23.637h-.91v.909H20zM20.91 23.637H20v.909h.91z"
    ></path>
    <path fill="#EBCAFA" d="M21.818 23.637h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M22.727 23.637h-.909v.909h.91z"></path>
    <path fill="#F8D18E" d="M23.636 23.637h-.909v.909h.91z"></path>
    <path fill="#FFE9B7" d="M24.545 23.637h-.909v.909h.91z"></path>
    <path fill="#FFE9B7" d="M25.455 23.637h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M26.364 23.637h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M27.273 23.637h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M28.182 23.637h-.91v.909h.91z"></path>
    <path fill="#E0AEF9" d="M29.09 23.637h-.908v.909h.909z"></path>
    <path fill="#E0AEF9" d="M30 23.637h-.91v.909H30z"></path>
    <path fill="#F8D18E" d="M30.91 23.637H30v.909h.91z"></path>
    <path fill="#F8D18E" d="M31.818 23.637h-.909v.909h.91z"></path>
    <path fill="#E0AEF9" d="M32.727 23.637h-.909v.909h.91z"></path>
    <path fill="#E0AEF9" d="M33.636 23.637h-.909v.909h.91z"></path>
    <path fill="#F8D18E" d="M34.546 23.637h-.91v.909h.91z"></path>
    <path fill="#FFE9B7" d="M35.455 23.637h-.91v.909h.91z"></path>
    <path fill="#F8D18E" d="M36.364 23.637h-.91v.909h.91z"></path>
    <path fill="#F8D18E" d="M37.273 23.637h-.91v.909h.91z"></path>
    <path fill="#F9ADC6" d="M38.182 23.637h-.91v.909h.91z"></path>
    <path fill="#FEF3FF" d="M39.09 23.637h-.908v.909h.909z"></path>
    <path fill="#FFE9B7" d="M4.545 22.727h-.909v.909h.91z"></path>
    <path fill="#FFE9B7" d="M5.455 22.727h-.91v.909h.91z"></path>
    <path fill="#F9ADC6" d="M2.727 22.727h-.909v.909h.91z"></path>
    <path fill="#FFE9B7" d="M3.636 22.727h-.909v.909h.91z"></path>
    <path fill="#FEF3FF" d="M1.818 22.727H.91v.909h.91z"></path>
    <path fill="#FFFDFA" d="M6.364 22.727h-.91v.909h.91z"></path>
    <path fill="#FFE9B7" d="M7.273 22.727h-.91v.909h.91z"></path>
    <path
      fill="#FFE9B7"
      d="M8.182 22.727h-.91v.909h.91zM9.09 22.727h-.908v.909h.909z"
    ></path>
    <path
      fill="#FFE9B7"
      d="M10 22.727h-.91v.909H10zM10.91 22.727H10v.909h.91z"
    ></path>
    <path fill="#EBCAFA" d="M11.818 22.727h-.909v.909h.91z"></path>
    <path fill="#F6E6FF" d="M12.727 22.727h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M13.636 22.727h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M14.545 22.727h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M15.455 22.727h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M16.364 22.727h-.91v.909h.91z"></path>
    <path fill="#F8D18E" d="M17.273 22.727h-.91v.909h.91z"></path>
    <path fill="#F8D18E" d="M18.182 22.727h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M19.09 22.727h-.908v.909h.909z"></path>
    <path fill="#F6E6FF" d="M20 22.727h-.91v.909H20z"></path>
    <path fill="#EBCAFA" d="M20.91 22.727H20v.909h.91z"></path>
    <path fill="#EBCAFA" d="M21.818 22.727h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M22.727 22.727h-.909v.909h.91z"></path>
    <path fill="#F8D18E" d="M23.636 22.727h-.909v.909h.91z"></path>
    <path fill="#F8D18E" d="M24.545 22.727h-.909v.909h.91z"></path>
    <path fill="#F8D18E" d="M25.455 22.727h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M26.364 22.727h-.91v.909h.91z"></path>
    <path fill="#F6E6FF" d="M27.273 22.727h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M28.182 22.727h-.91v.909h.91z"></path>
    <path fill="#E0AEF9" d="M29.09 22.727h-.908v.909h.909z"></path>
    <path fill="#E0AEF9" d="M30 22.727h-.91v.909H30z"></path>
    <path fill="#F8D18E" d="M30.91 22.727H30v.909h.91z"></path>
    <path fill="#E0AEF9" d="M31.818 22.727h-.909v.909h.91z"></path>
    <path fill="#E0AEF9" d="M32.727 22.727h-.909v.909h.91z"></path>
    <path fill="#E0AEF9" d="M33.636 22.727h-.909v.909h.91z"></path>
    <path fill="#E0AEF9" d="M34.546 22.727h-.91v.909h.91z"></path>
    <path fill="#F8D18E" d="M35.455 22.727h-.91v.909h.91z"></path>
    <path fill="#F8D18E" d="M36.364 22.727h-.91v.909h.91z"></path>
    <path fill="#F8D18E" d="M37.273 22.727h-.91v.909h.91z"></path>
    <path fill="#F8D18E" d="M38.182 22.727h-.91v.909h.91z"></path>
    <path fill="#F9ADC6" d="M39.09 22.727h-.908v.909h.909z"></path>
    <path fill="#FFE9B7" d="M4.545 21.82h-.909v.91h.91z"></path>
    <path
      fill="#FFE9B7"
      d="M5.455 21.82h-.91v.91h.91zM2.727 21.82h-.909v.91h.91z"
    ></path>
    <path fill="#FFFDFA" d="M3.636 21.82h-.909v.91h.91z"></path>
    <path fill="#F9ADC6" d="M1.818 21.82H.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M6.364 21.82h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M7.273 21.82h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M8.182 21.82h-.91v.91h.91z"></path>
    <path fill="#F8D18E" d="M9.09 21.82h-.908v.91h.909z"></path>
    <path
      fill="#F8D18E"
      d="M10 21.82h-.91v.91H10zM10.91 21.82H10v.91h.91z"
    ></path>
    <path fill="#EBCAFA" d="M11.818 21.82h-.909v.91h.91z"></path>
    <path fill="#F6E6FF" d="M12.727 21.82h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M13.636 21.82h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M14.545 21.82h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M15.455 21.82h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M16.364 21.82h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M17.273 21.82h-.91v.91h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M18.182 21.82h-.91v.91h.91zM19.09 21.82h-.908v.91h.909z"
    ></path>
    <path fill="#F6E6FF" d="M20 21.82h-.91v.91H20z"></path>
    <path fill="#EBCAFA" d="M20.91 21.82H20v.91h.91z"></path>
    <path fill="#EBCAFA" d="M21.818 21.82h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M22.727 21.82h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M23.636 21.82h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M24.545 21.82h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M25.455 21.82h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M26.364 21.82h-.91v.91h.91z"></path>
    <path fill="#F6E6FF" d="M27.273 21.82h-.91v.91h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M28.182 21.82h-.91v.91h.91zM29.09 21.82h-.908v.91h.909z"
    ></path>
    <path
      fill="#E0AEF9"
      d="M30 21.82h-.91v.91H30zM30.91 21.82H30v.91h.91z"
    ></path>
    <path fill="#EBCAFA" d="M31.818 21.82h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M32.727 21.82h-.909v.91h.91z"></path>
    <path fill="#E0AEF9" d="M33.636 21.82h-.909v.91h.91z"></path>
    <path fill="#E0AEF9" d="M34.546 21.82h-.91v.91h.91z"></path>
    <path fill="#E0AEF9" d="M35.455 21.82h-.91v.91h.91z"></path>
    <path fill="#F8D18E" d="M36.364 21.82h-.91v.91h.91z"></path>
    <path fill="#F8D18E" d="M37.273 21.82h-.91v.91h.91z"></path>
    <path
      fill="#F8D18E"
      d="M38.182 21.82h-.91v.91h.91zM39.09 21.82h-.908v.91h.909z"
    ></path>
    <path fill="#FFE9B7" d="M4.545 20.908h-.909v.91h.91z"></path>
    <path
      fill="#FFE9B7"
      d="M5.455 20.908h-.91v.91h.91zM2.727 20.908h-.909v.91h.91z"
    ></path>
    <path
      fill="#FFE9B7"
      d="M3.636 20.908h-.909v.91h.91zM1.818 20.908H.91v.91h.91z"
    ></path>
    <path fill="#F8D18E" d="M6.364 20.908h-.91v.91h.91z"></path>
    <path fill="#F8D18E" d="M7.273 20.908h-.91v.91h.91z"></path>
    <path fill="#F8D18E" d="M8.182 20.908h-.91v.91h.91z"></path>
    <path fill="#E0AEF9" d="M9.09 20.908h-.908v.91h.909z"></path>
    <path
      fill="#E0AEF9"
      d="M10 20.908h-.91v.91H10zM10.91 20.908H10v.91h.91z"
    ></path>
    <path fill="#EBCAFA" d="M11.818 20.908h-.909v.91h.91z"></path>
    <path fill="#F6E6FF" d="M12.727 20.908h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M13.636 20.908h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M14.545 20.908h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M15.455 20.908h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M16.364 20.908h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M17.273 20.908h-.91v.91h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M18.182 20.908h-.91v.91h.91zM19.09 20.908h-.908v.91h.909z"
    ></path>
    <path fill="#F6E6FF" d="M20 20.908h-.91v.91H20z"></path>
    <path fill="#EBCAFA" d="M20.91 20.908H20v.91h.91z"></path>
    <path fill="#EBCAFA" d="M21.818 20.908h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M22.727 20.908h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M23.636 20.908h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M24.545 20.908h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M25.455 20.908h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M26.364 20.908h-.91v.91h.91z"></path>
    <path fill="#F6E6FF" d="M27.273 20.908h-.91v.91h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M28.182 20.908h-.91v.91h.91zM29.09 20.908h-.908v.91h.909z"
    ></path>
    <path fill="#EBCAFA" d="M30 20.908h-.91v.91H30z"></path>
    <path fill="#E0AEF9" d="M30.91 20.908H30v.91h.91z"></path>
    <path fill="#EBCAFA" d="M31.818 20.908h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M32.727 20.908h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M33.636 20.908h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M34.546 20.908h-.91v.91h.91z"></path>
    <path fill="#E0AEF9" d="M35.455 20.908h-.91v.91h.91z"></path>
    <path fill="#F8D18E" d="M36.364 20.908h-.91v.91h.91z"></path>
    <path fill="#F8D18E" d="M37.273 20.908h-.91v.91h.91z"></path>
    <path
      fill="#F8D18E"
      d="M38.182 20.908h-.91v.91h.91zM39.09 20.908h-.908v.91h.909zM4.545 19.998h-.909v.91h.91z"
    ></path>
    <path fill="#F8D18E" d="M5.455 19.998h-.91v.91h.91z"></path>
    <path fill="#FFE9B7" d="M2.727 19.998h-.909v.91h.91z"></path>
    <path fill="#F8D18E" d="M3.636 19.998h-.909v.91h.91z"></path>
    <path fill="#FFE9B7" d="M1.818 19.998H.91v.91h.91z"></path>
    <path fill="#E0AEF9" d="M6.364 19.998h-.91v.91h.91z"></path>
    <path fill="#E0AEF9" d="M7.273 19.998h-.91v.91h.91z"></path>
    <path
      fill="#E0AEF9"
      d="M8.182 19.998h-.91v.91h.91zM9.09 19.998h-.908v.91h.909z"
    ></path>
    <path
      fill="#EBCAFA"
      d="M10 19.998h-.91v.91H10zM10.91 19.998H10v.91h.91z"
    ></path>
    <path fill="#EBCAFA" d="M11.818 19.998h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M12.727 19.998h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M13.636 19.998h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M14.545 19.998h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M15.455 19.998h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M16.364 19.998h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M17.273 19.998h-.91v.91h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M18.182 19.998h-.91v.91h.91zM19.09 19.998h-.908v.91h.909z"
    ></path>
    <path
      fill="#EBCAFA"
      d="M20 19.998h-.91v.91H20zM20.91 19.998H20v.91h.91z"
    ></path>
    <path fill="#EBCAFA" d="M21.818 19.998h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M22.727 19.998h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M23.636 19.998h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M24.545 19.998h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M25.455 19.998h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M26.364 19.998h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M27.273 19.998h-.91v.91h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M28.182 19.998h-.91v.91h.91zM29.09 19.998h-.908v.91h.909z"
    ></path>
    <path
      fill="#EBCAFA"
      d="M30 19.998h-.91v.91H30zM30.91 19.998H30v.91h.91z"
    ></path>
    <path fill="#EBCAFA" d="M31.818 19.998h-.909v.91h.91z"></path>
    <path fill="#F6E6FF" d="M32.727 19.998h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M33.636 19.998h-.909v.91h.91z"></path>
    <path fill="#E0AEF9" d="M34.546 19.998h-.91v.91h.91z"></path>
    <path fill="#E0AEF9" d="M35.455 19.998h-.91v.91h.91z"></path>
    <path fill="#F8D18E" d="M36.364 19.998h-.91v.91h.91z"></path>
    <path fill="#E0AEF9" d="M37.273 19.998h-.91v.91h.91z"></path>
    <path fill="#E0AEF9" d="M38.182 19.998h-.91v.91h.91z"></path>
    <path fill="#F8D18E" d="M39.09 19.998h-.908v.91h.909z"></path>
    <path fill="#E0AEF9" d="M4.545 19.092h-.909V20h.91z"></path>
    <path fill="#E0AEF9" d="M5.455 19.092h-.91V20h.91z"></path>
    <path fill="#F8D18E" d="M2.727 19.092h-.909V20h.91z"></path>
    <path fill="#E0AEF9" d="M3.636 19.092h-.909V20h.91z"></path>
    <path fill="#FFE9B7" d="M1.818 19.092H.91V20h.91z"></path>
    <path fill="#E0AEF9" d="M6.364 19.092h-.91V20h.91z"></path>
    <path fill="#EBCAFA" d="M7.273 19.092h-.91V20h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M8.182 19.092h-.91V20h.91zM9.09 19.092h-.908V20h.909z"
    ></path>
    <path
      fill="#EBCAFA"
      d="M10 19.092h-.91V20H10zM10.91 19.092H10V20h.91z"
    ></path>
    <path fill="#EBCAFA" d="M11.818 19.092h-.909V20h.91z"></path>
    <path fill="#EBCAFA" d="M12.727 19.092h-.909V20h.91z"></path>
    <path fill="#EBCAFA" d="M13.636 19.092h-.909V20h.91z"></path>
    <path fill="#EBCAFA" d="M14.545 19.092h-.909V20h.91z"></path>
    <path fill="#EBCAFA" d="M15.455 19.092h-.91V20h.91z"></path>
    <path fill="#EBCAFA" d="M16.364 19.092h-.91V20h.91z"></path>
    <path fill="#EBCAFA" d="M17.273 19.092h-.91V20h.91z"></path>
    <path fill="#EBCAFA" d="M18.182 19.092h-.91V20h.91z"></path>
    <path fill="#F6E6FF" d="M19.09 19.092h-.908V20h.909z"></path>
    <path
      fill="#F6E6FF"
      d="M20 19.092h-.91V20H20zM20.91 19.092H20V20h.91z"
    ></path>
    <path fill="#F6E6FF" d="M21.818 19.092h-.909V20h.91z"></path>
    <path fill="#F6E6FF" d="M22.727 19.092h-.909V20h.91z"></path>
    <path fill="#EBCAFA" d="M23.636 19.092h-.909V20h.91z"></path>
    <path fill="#EBCAFA" d="M24.545 19.092h-.909V20h.91z"></path>
    <path fill="#EBCAFA" d="M25.455 19.092h-.91V20h.91z"></path>
    <path fill="#EBCAFA" d="M26.364 19.092h-.91V20h.91z"></path>
    <path fill="#EBCAFA" d="M27.273 19.092h-.91V20h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M28.182 19.092h-.91V20h.91zM29.09 19.092h-.908V20h.909z"
    ></path>
    <path
      fill="#EBCAFA"
      d="M30 19.092h-.91V20H30zM30.91 19.092H30V20h.91z"
    ></path>
    <path fill="#EBCAFA" d="M31.818 19.092h-.909V20h.91z"></path>
    <path fill="#F6E6FF" d="M32.727 19.092h-.909V20h.91z"></path>
    <path fill="#EBCAFA" d="M33.636 19.092h-.909V20h.91z"></path>
    <path fill="#EBCAFA" d="M34.546 19.092h-.91V20h.91z"></path>
    <path fill="#E0AEF9" d="M35.455 19.092h-.91V20h.91z"></path>
    <path fill="#E0AEF9" d="M36.364 19.092h-.91V20h.91z"></path>
    <path fill="#E0AEF9" d="M37.273 19.092h-.91V20h.91z"></path>
    <path
      fill="#E0AEF9"
      d="M38.182 19.092h-.91V20h.91zM39.09 19.092h-.908V20h.909z"
    ></path>
    <path fill="#EBCAFA" d="M4.545 18.182h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M5.455 18.182h-.91v.909h.91z"></path>
    <path fill="#E0AEF9" d="M2.727 18.182h-.909v.909h.91z"></path>
    <path fill="#E0AEF9" d="M3.636 18.182h-.909v.909h.91z"></path>
    <path fill="#F8D18E" d="M1.818 18.182H.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M6.364 18.182h-.91v.909h.91z"></path>
    <path fill="#F3C0FF" d="M7.273 18.182h-.91v.909h.91z"></path>
    <path
      fill="#F3C0FF"
      d="M8.182 18.182h-.91v.909h.91zM9.09 18.182h-.908v.909h.909z"
    ></path>
    <path
      fill="#EBCAFA"
      d="M10 18.182h-.91v.909H10zM10.91 18.182H10v.909h.91z"
    ></path>
    <path fill="#EBCAFA" d="M11.818 18.182h-.909v.909h.91z"></path>
    <path fill="#F6E6FF" d="M12.727 18.182h-.909v.909h.91z"></path>
    <path fill="#F6E6FF" d="M13.636 18.182h-.909v.909h.91z"></path>
    <path fill="#F6E6FF" d="M14.545 18.182h-.909v.909h.91z"></path>
    <path fill="#F6E6FF" d="M15.455 18.182h-.91v.909h.91z"></path>
    <path fill="#F6E6FF" d="M16.364 18.182h-.91v.909h.91z"></path>
    <path fill="#F6E6FF" d="M17.273 18.182h-.91v.909h.91z"></path>
    <path
      fill="#F6E6FF"
      d="M18.182 18.182h-.91v.909h.91zM19.09 18.182h-.908v.909h.909z"
    ></path>
    <path
      fill="#F6E6FF"
      d="M20 18.182h-.91v.909H20zM20.91 18.182H20v.909h.91z"
    ></path>
    <path fill="#F6E6FF" d="M21.818 18.182h-.909v.909h.91z"></path>
    <path fill="#F6E6FF" d="M22.727 18.182h-.909v.909h.91z"></path>
    <path fill="#F6E6FF" d="M23.636 18.182h-.909v.909h.91z"></path>
    <path fill="#F6E6FF" d="M24.545 18.182h-.909v.909h.91z"></path>
    <path fill="#F6E6FF" d="M25.455 18.182h-.91v.909h.91z"></path>
    <path fill="#F6E6FF" d="M26.364 18.182h-.91v.909h.91z"></path>
    <path fill="#F6E6FF" d="M27.273 18.182h-.91v.909h.91z"></path>
    <path
      fill="#F6E6FF"
      d="M28.182 18.182h-.91v.909h.91zM29.09 18.182h-.908v.909h.909z"
    ></path>
    <path
      fill="#EBCAFA"
      d="M30 18.182h-.91v.909H30zM30.91 18.182H30v.909h.91z"
    ></path>
    <path fill="#EBCAFA" d="M31.818 18.182h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M32.727 18.182h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M33.636 18.182h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M34.546 18.182h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M35.455 18.182h-.91v.909h.91z"></path>
    <path fill="#E0AEF9" d="M36.364 18.182h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M37.273 18.182h-.91v.909h.91z"></path>
    <path
      fill="#E0AEF9"
      d="M38.182 18.182h-.91v.909h.91zM39.09 18.182h-.908v.909h.909z"
    ></path>
    <path fill="#F3C0FF" d="M4.545 17.275h-.909v.91h.91z"></path>
    <path fill="#F3C0FF" d="M5.455 17.275h-.91v.91h.91z"></path>
    <path fill="#E0AEF9" d="M2.727 17.275h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M3.636 17.275h-.909v.91h.91z"></path>
    <path fill="#E0AEF9" d="M1.818 17.275H.91v.91h.91z"></path>
    <path fill="#F3C0FF" d="M6.364 17.275h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M7.273 17.275h-.91v.91h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M8.182 17.275h-.91v.91h.91zM9.09 17.275h-.908v.91h.909z"
    ></path>
    <path fill="#EBCAFA" d="M10 17.275h-.91v.91H10z"></path>
    <path fill="#F3C0FF" d="M10.91 17.275H10v.91h.91z"></path>
    <path fill="#F3C0FF" d="M11.818 17.275h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M12.727 17.275h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M13.636 17.275h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M14.545 17.275h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M15.455 17.275h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M16.364 17.275h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M17.273 17.275h-.91v.91h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M18.182 17.275h-.91v.91h.91zM19.09 17.275h-.908v.91h.909z"
    ></path>
    <path
      fill="#EBCAFA"
      d="M20 17.275h-.91v.91H20zM20.91 17.275H20v.91h.91z"
    ></path>
    <path fill="#EBCAFA" d="M21.818 17.275h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M22.727 17.275h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M23.636 17.275h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M24.545 17.275h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M25.455 17.275h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M26.364 17.275h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M27.273 17.275h-.91v.91h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M28.182 17.275h-.91v.91h.91zM29.09 17.275h-.908v.91h.909z"
    ></path>
    <path fill="#F6E6FF" d="M30 17.275h-.91v.91H30z"></path>
    <path fill="#EBCAFA" d="M30.91 17.275H30v.91h.91z"></path>
    <path fill="#EBCAFA" d="M31.818 17.275h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M32.727 17.275h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M33.636 17.275h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M34.546 17.275h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M35.455 17.275h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M36.364 17.275h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M37.273 17.275h-.91v.91h.91z"></path>
    <path
      fill="#E0AEF9"
      d="M38.182 17.275h-.91v.91h.91zM39.09 17.275h-.908v.91h.909z"
    ></path>
    <path fill="#EBCAFA" d="M4.545 16.363h-.909v.91h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M5.455 16.363h-.91v.91h.91zM2.727 16.363h-.909v.91h.91z"
    ></path>
    <path fill="#F3C0FF" d="M3.636 16.363h-.909v.91h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M1.818 16.363H.91v.91h.91zM6.364 16.363h-.91v.91h.91z"
    ></path>
    <path fill="#EBCAFA" d="M7.273 16.363h-.91v.91h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M8.182 16.363h-.91v.91h.91zM9.09 16.363h-.908v.91h.909z"
    ></path>
    <path
      fill="#EBCAFA"
      d="M10 16.363h-.91v.91H10zM10.91 16.363H10v.91h.91z"
    ></path>
    <path fill="#EBCAFA" d="M11.818 16.363h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M12.727 16.363h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M13.636 16.363h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M14.545 16.363h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M15.455 16.363h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M16.364 16.363h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M17.273 16.363h-.91v.91h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M18.182 16.363h-.91v.91h.91zM19.09 16.363h-.908v.91h.909z"
    ></path>
    <path
      fill="#EBCAFA"
      d="M20 16.363h-.91v.91H20zM20.91 16.363H20v.91h.91z"
    ></path>
    <path fill="#EBCAFA" d="M21.818 16.363h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M22.727 16.363h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M23.636 16.363h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M24.545 16.363h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M25.455 16.363h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M26.364 16.363h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M27.273 16.363h-.91v.91h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M28.182 16.363h-.91v.91h.91zM29.09 16.363h-.908v.91h.909z"
    ></path>
    <path
      fill="#EBCAFA"
      d="M30 16.363h-.91v.91H30zM30.91 16.363H30v.91h.91z"
    ></path>
    <path fill="#EBCAFA" d="M31.818 16.363h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M32.727 16.363h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M33.636 16.363h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M34.546 16.363h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M35.455 16.363h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M36.364 16.363h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M37.273 16.363h-.91v.91h.91z"></path>
    <path
      fill="#E0AEF9"
      d="M38.182 16.363h-.91v.91h.91zM39.09 16.363h-.908v.91h.909z"
    ></path>
    <path fill="#EBCAFA" d="M4.545 15.453h-.909v.91h.91z"></path>
    <path
      fill="#F3C0FF"
      d="M5.455 15.453h-.91v.91h.91zM2.727 15.453h-.909v.91h.91z"
    ></path>
    <path fill="#F3C0FF" d="M3.636 15.453h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M1.818 15.453H.91v.91h.91z"></path>
    <path fill="#F3C0FF" d="M6.364 15.453h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M7.273 15.453h-.91v.91h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M8.182 15.453h-.91v.91h.91zM9.09 15.453h-.908v.91h.909z"
    ></path>
    <path
      fill="#EBCAFA"
      d="M10 15.453h-.91v.91H10zM10.91 15.453H10v.91h.91z"
    ></path>
    <path fill="#EBCAFA" d="M11.818 15.453h-.909v.91h.91z"></path>
    <path fill="#E0AEF9" d="M12.727 15.453h-.909v.91h.91z"></path>
    <path fill="#E0AEF9" d="M13.636 15.453h-.909v.91h.91z"></path>
    <path fill="#E0AEF9" d="M14.545 15.453h-.909v.91h.91z"></path>
    <path fill="#E0AEF9" d="M15.455 15.453h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M16.364 15.453h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M17.273 15.453h-.91v.91h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M18.182 15.453h-.91v.91h.91zM19.09 15.453h-.908v.91h.909z"
    ></path>
    <path
      fill="#EBCAFA"
      d="M20 15.453h-.91v.91H20zM20.91 15.453H20v.91h.91z"
    ></path>
    <path fill="#EBCAFA" d="M21.818 15.453h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M22.727 15.453h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M23.636 15.453h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M24.545 15.453h-.909v.91h.91z"></path>
    <path fill="#E0AEF9" d="M25.455 15.453h-.91v.91h.91z"></path>
    <path fill="#E0AEF9" d="M26.364 15.453h-.91v.91h.91z"></path>
    <path fill="#E0AEF9" d="M27.273 15.453h-.91v.91h.91z"></path>
    <path fill="#E0AEF9" d="M28.182 15.453h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M29.09 15.453h-.908v.91h.909z"></path>
    <path
      fill="#EBCAFA"
      d="M30 15.453h-.91v.91H30zM30.91 15.453H30v.91h.91z"
    ></path>
    <path fill="#EBCAFA" d="M31.818 15.453h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M32.727 15.453h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M33.636 15.453h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M34.546 15.453h-.91v.91h.91z"></path>
    <path fill="#E0AEF9" d="M35.455 15.453h-.91v.91h.91z"></path>
    <path fill="#E0AEF9" d="M36.364 15.453h-.91v.91h.91z"></path>
    <path fill="#E0AEF9" d="M37.273 15.453h-.91v.91h.91z"></path>
    <path
      fill="#E0AEF9"
      d="M38.182 15.453h-.91v.91h.91zM39.09 15.453h-.908v.91h.909z"
    ></path>
    <path fill="#F3C0FF" d="M4.545 14.547h-.909v.909h.91z"></path>
    <path
      fill="#F3C0FF"
      d="M5.455 14.547h-.91v.909h.91zM2.727 14.547h-.909v.909h.91z"
    ></path>
    <path
      fill="#F3C0FF"
      d="M3.636 14.547h-.909v.909h.91zM1.818 14.547H.91v.909h.91zM6.364 14.547h-.91v.909h.91z"
    ></path>
    <path fill="#EBCAFA" d="M7.273 14.547h-.91v.909h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M8.182 14.547h-.91v.909h.91zM9.09 14.547h-.908v.909h.909z"
    ></path>
    <path
      fill="#EBCAFA"
      d="M10 14.547h-.91v.909H10zM10.91 14.547H10v.909h.91z"
    ></path>
    <path fill="#E83B3A" d="M11.818 14.547h-.909v.909h.91z"></path>
    <path fill="#E83B3A" d="M12.727 14.547h-.909v.909h.91z"></path>
    <path fill="#E83B3A" d="M13.636 14.547h-.909v.909h.91z"></path>
    <path fill="#E83B3A" d="M14.545 14.547h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M15.455 14.547h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M16.364 14.547h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M17.273 14.547h-.91v.909h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M18.182 14.547h-.91v.909h.91zM19.09 14.547h-.908v.909h.909z"
    ></path>
    <path
      fill="#EBCAFA"
      d="M20 14.547h-.91v.909H20zM20.91 14.547H20v.909h.91z"
    ></path>
    <path fill="#EBCAFA" d="M21.818 14.547h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M22.727 14.547h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M23.636 14.547h-.909v.909h.91z"></path>
    <path fill="#E83B3A" d="M24.545 14.547h-.909v.909h.91z"></path>
    <path fill="#E83B3A" d="M25.455 14.547h-.91v.909h.91z"></path>
    <path fill="#E83B3A" d="M26.364 14.547h-.91v.909h.91z"></path>
    <path fill="#E83B3A" d="M27.273 14.547h-.91v.909h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M28.182 14.547h-.91v.909h.91zM29.09 14.547h-.908v.909h.909z"
    ></path>
    <path
      fill="#EBCAFA"
      d="M30 14.547h-.91v.909H30zM30.91 14.547H30v.909h.91z"
    ></path>
    <path fill="#EBCAFA" d="M31.818 14.547h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M32.727 14.547h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M33.636 14.547h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M34.546 14.547h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M35.455 14.547h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M36.364 14.547h-.91v.909h.91z"></path>
    <path fill="#E0AEF9" d="M37.273 14.547h-.91v.909h.91z"></path>
    <path
      fill="#E0AEF9"
      d="M38.182 14.547h-.91v.909h.91zM39.09 14.547h-.908v.909h.909z"
    ></path>
    <path fill="#F3C0FF" d="M4.545 13.637h-.909v.909h.91z"></path>
    <path
      fill="#F3C0FF"
      d="M5.455 13.637h-.91v.909h.91zM2.727 13.637h-.909v.909h.91z"
    ></path>
    <path
      fill="#F3C0FF"
      d="M3.636 13.637h-.909v.909h.91zM1.818 13.637H.91v.909h.91zM6.364 13.637h-.91v.909h.91z"
    ></path>
    <path fill="#F3C0FF" d="M7.273 13.637h-.91v.909h.91z"></path>
    <path
      fill="#F3C0FF"
      d="M8.182 13.637h-.91v.909h.91zM9.09 13.637h-.908v.909h.909z"
    ></path>
    <path fill="#F3C0FF" d="M10 13.637h-.91v.909H10z"></path>
    <path fill="#FF8E92" d="M10.91 13.637H10v.909h.91z"></path>
    <path fill="#FDFFE9" d="M11.818 13.637h-.909v.909h.91z"></path>
    <path fill="#FF595A" d="M12.727 13.637h-.909v.909h.91z"></path>
    <path fill="#FF595A" d="M13.636 13.637h-.909v.909h.91z"></path>
    <path fill="#FDFFE9" d="M14.545 13.637h-.909v.909h.91z"></path>
    <path fill="#E83B3A" d="M15.455 13.637h-.91v.909h.91z"></path>
    <path fill="#F3C0FF" d="M16.364 13.637h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M17.273 13.637h-.91v.909h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M18.182 13.637h-.91v.909h.91zM19.09 13.637h-.908v.909h.909z"
    ></path>
    <path
      fill="#EBCAFA"
      d="M20 13.637h-.91v.909H20zM20.91 13.637H20v.909h.91z"
    ></path>
    <path fill="#EBCAFA" d="M21.818 13.637h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M22.727 13.637h-.909v.909h.91z"></path>
    <path fill="#FF8E92" d="M23.636 13.637h-.909v.909h.91z"></path>
    <path fill="#FDFFE9" d="M24.545 13.637h-.909v.909h.91z"></path>
    <path fill="#FF595A" d="M25.455 13.637h-.91v.909h.91z"></path>
    <path fill="#FF595A" d="M26.364 13.637h-.91v.909h.91z"></path>
    <path fill="#FDFFE9" d="M27.273 13.637h-.91v.909h.91z"></path>
    <path fill="#E83B3A" d="M28.182 13.637h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M29.09 13.637h-.908v.909h.909z"></path>
    <path
      fill="#EBCAFA"
      d="M30 13.637h-.91v.909H30zM30.91 13.637H30v.909h.91z"
    ></path>
    <path fill="#EBCAFA" d="M31.818 13.637h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M32.727 13.637h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M33.636 13.637h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M34.546 13.637h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M35.455 13.637h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M36.364 13.637h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M37.273 13.637h-.91v.909h.91z"></path>
    <path
      fill="#E0AEF9"
      d="M38.182 13.637h-.91v.909h.91zM39.09 13.637h-.908v.909h.909z"
    ></path>
    <path fill="#F3C0FF" d="M4.545 12.73h-.909v.91h.91z"></path>
    <path
      fill="#F3C0FF"
      d="M5.455 12.73h-.91v.91h.91zM2.727 12.73h-.909v.91h.91z"
    ></path>
    <path
      fill="#F3C0FF"
      d="M3.636 12.73h-.909v.91h.91zM1.818 12.73H.91v.91h.91zM6.364 12.73h-.91v.91h.91z"
    ></path>
    <path fill="#F3C0FF" d="M7.273 12.73h-.91v.91h.91z"></path>
    <path
      fill="#F3C0FF"
      d="M8.182 12.73h-.91v.91h.91zM9.09 12.73h-.908v.91h.909z"
    ></path>
    <path fill="#F3C0FF" d="M10 12.73h-.91v.91H10z"></path>
    <path fill="#FF8E92" d="M10.91 12.73H10v.91h.91z"></path>
    <path fill="#FFBFBD" d="M11.818 12.73h-.909v.91h.91z"></path>
    <path fill="#FF595A" d="M12.727 12.73h-.909v.91h.91z"></path>
    <path fill="#FF595A" d="M13.636 12.73h-.909v.91h.91z"></path>
    <path fill="#FF595A" d="M14.545 12.73h-.909v.91h.91z"></path>
    <path fill="#E83B3A" d="M15.455 12.73h-.91v.91h.91z"></path>
    <path fill="#F3C0FF" d="M16.364 12.73h-.91v.91h.91z"></path>
    <path fill="#F3C0FF" d="M17.273 12.73h-.91v.91h.91z"></path>
    <path
      fill="#F3C0FF"
      d="M18.182 12.73h-.91v.91h.91zM19.09 12.73h-.908v.91h.909z"
    ></path>
    <path
      fill="#EBCAFA"
      d="M20 12.73h-.91v.91H20zM20.91 12.73H20v.91h.91z"
    ></path>
    <path fill="#EBCAFA" d="M21.818 12.73h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M22.727 12.73h-.909v.91h.91z"></path>
    <path fill="#FF8E92" d="M23.636 12.73h-.909v.91h.91z"></path>
    <path fill="#FFBFBD" d="M24.545 12.73h-.909v.91h.91z"></path>
    <path fill="#FF595A" d="M25.455 12.73h-.91v.91h.91z"></path>
    <path fill="#FF595A" d="M26.364 12.73h-.91v.91h.91z"></path>
    <path fill="#FF595A" d="M27.273 12.73h-.91v.91h.91z"></path>
    <path fill="#E83B3A" d="M28.182 12.73h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M29.09 12.73h-.908v.91h.909z"></path>
    <path
      fill="#EBCAFA"
      d="M30 12.73h-.91v.91H30zM30.91 12.73H30v.91h.91z"
    ></path>
    <path fill="#EBCAFA" d="M31.818 12.73h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M32.727 12.73h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M33.636 12.73h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M34.546 12.73h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M35.455 12.73h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M36.364 12.73h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M37.273 12.73h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M38.182 12.73h-.91v.91h.91z"></path>
    <path fill="#E0AEF9" d="M39.09 12.73h-.908v.91h.909z"></path>
    <path fill="#F3C0FF" d="M4.545 11.818h-.909v.91h.91z"></path>
    <path
      fill="#F3C0FF"
      d="M5.455 11.818h-.91v.91h.91zM2.727 11.818h-.909v.91h.91z"
    ></path>
    <path
      fill="#F3C0FF"
      d="M3.636 11.818h-.909v.91h.91zM1.818 11.818H.91v.91h.91zM6.364 11.818h-.91v.91h.91z"
    ></path>
    <path fill="#F3C0FF" d="M7.273 11.818h-.91v.91h.91z"></path>
    <path
      fill="#F3C0FF"
      d="M8.182 11.818h-.91v.91h.91zM9.09 11.818h-.908v.91h.909z"
    ></path>
    <path fill="#F3C0FF" d="M10 11.818h-.91v.91H10z"></path>
    <path fill="#FF8E92" d="M10.91 11.818H10v.91h.91z"></path>
    <path fill="#FDFFE9" d="M11.818 11.818h-.909v.91h.91z"></path>
    <path fill="#FF8E92" d="M12.727 11.818h-.909v.91h.91z"></path>
    <path fill="#FF595A" d="M13.636 11.818h-.909v.91h.91z"></path>
    <path fill="#FDFFE9" d="M14.545 11.818h-.909v.91h.91z"></path>
    <path fill="#E83B3A" d="M15.455 11.818h-.91v.91h.91z"></path>
    <path fill="#F3C0FF" d="M16.364 11.818h-.91v.91h.91z"></path>
    <path fill="#F3C0FF" d="M17.273 11.818h-.91v.91h.91z"></path>
    <path
      fill="#F3C0FF"
      d="M18.182 11.818h-.91v.91h.91zM19.09 11.818h-.908v.91h.909z"
    ></path>
    <path
      fill="#F3C0FF"
      d="M20 11.818h-.91v.91H20zM20.91 11.818H20v.91h.91z"
    ></path>
    <path fill="#F3C0FF" d="M21.818 11.818h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M22.727 11.818h-.909v.91h.91z"></path>
    <path fill="#FF8E92" d="M23.636 11.818h-.909v.91h.91z"></path>
    <path fill="#FDFFE9" d="M24.545 11.818h-.909v.91h.91z"></path>
    <path fill="#FF8E92" d="M25.455 11.818h-.91v.91h.91z"></path>
    <path fill="#FF595A" d="M26.364 11.818h-.91v.91h.91z"></path>
    <path fill="#FDFFE9" d="M27.273 11.818h-.91v.91h.91z"></path>
    <path fill="#E83B3A" d="M28.182 11.818h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M29.09 11.818h-.908v.91h.909z"></path>
    <path
      fill="#EBCAFA"
      d="M30 11.818h-.91v.91H30zM30.91 11.818H30v.91h.91z"
    ></path>
    <path fill="#EBCAFA" d="M31.818 11.818h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M32.727 11.818h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M33.636 11.818h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M34.546 11.818h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M35.455 11.818h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M36.364 11.818h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M37.273 11.818h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M38.182 11.818h-.91v.91h.91z"></path>
    <path fill="#E0AEF9" d="M39.09 11.818h-.908v.91h.909z"></path>
    <path fill="#F3C0FF" d="M4.545 10.908h-.909v.91h.91z"></path>
    <path
      fill="#F3C0FF"
      d="M5.455 10.908h-.91v.91h.91zM2.727 10.908h-.909v.91h.91z"
    ></path>
    <path fill="#F6E6FF" d="M3.636 10.908h-.909v.91h.91z"></path>
    <path fill="#F3C0FF" d="M1.818 10.908H.91v.91h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M.91 16.363H0v.91h.91zM.91 15.453H0v.91h.91z"
    ></path>
    <path
      fill="#EBCAFA"
      d="M.91 14.547H0v.909h.91zM.91 13.637H0v.909h.91z"
    ></path>
    <path
      fill="#F3C0FF"
      d="M.91 12.73H0v.91h.91zM.91 11.818H0v.91h.91zM.91 10.908H0v.91h.91zM6.364 10.908h-.91v.91h.91z"
    ></path>
    <path fill="#F3C0FF" d="M7.273 10.908h-.91v.91h.91z"></path>
    <path
      fill="#F3C0FF"
      d="M8.182 10.908h-.91v.91h.91zM9.09 10.908h-.908v.91h.909z"
    ></path>
    <path
      fill="#F3C0FF"
      d="M10 10.908h-.91v.91H10zM10.91 10.908H10v.91h.91z"
    ></path>
    <path fill="#FFBFBD" d="M11.818 10.908h-.909v.91h.91z"></path>
    <path fill="#FF8E92" d="M12.727 10.908h-.909v.91h.91z"></path>
    <path fill="#FF8E92" d="M13.636 10.908h-.909v.91h.91z"></path>
    <path fill="#FF595A" d="M14.545 10.908h-.909v.91h.91z"></path>
    <path fill="#F3C0FF" d="M15.455 10.908h-.91v.91h.91z"></path>
    <path fill="#F3C0FF" d="M16.364 10.908h-.91v.91h.91z"></path>
    <path fill="#F3C0FF" d="M17.273 10.908h-.91v.91h.91z"></path>
    <path
      fill="#F3C0FF"
      d="M18.182 10.908h-.91v.91h.91zM19.09 10.908h-.908v.91h.909z"
    ></path>
    <path
      fill="#F3C0FF"
      d="M20 10.908h-.91v.91H20zM20.91 10.908H20v.91h.91z"
    ></path>
    <path fill="#F3C0FF" d="M21.818 10.908h-.909v.91h.91z"></path>
    <path fill="#F3C0FF" d="M22.727 10.908h-.909v.91h.91z"></path>
    <path fill="#F3C0FF" d="M23.636 10.908h-.909v.91h.91z"></path>
    <path fill="#FFBFBD" d="M24.545 10.908h-.909v.91h.91z"></path>
    <path fill="#FF8E92" d="M25.455 10.908h-.91v.91h.91z"></path>
    <path fill="#FF8E92" d="M26.364 10.908h-.91v.91h.91z"></path>
    <path fill="#FF595A" d="M27.273 10.908h-.91v.91h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M28.182 10.908h-.91v.91h.91zM29.09 10.908h-.908v.91h.909z"
    ></path>
    <path
      fill="#EBCAFA"
      d="M30 10.908h-.91v.91H30zM30.91 10.908H30v.91h.91z"
    ></path>
    <path fill="#EBCAFA" d="M31.818 10.908h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M32.727 10.908h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M33.636 10.908h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M34.546 10.908h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M35.455 10.908h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M36.364 10.908h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M37.273 10.908h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M38.182 10.908h-.91v.91h.91z"></path>
    <path
      fill="#E0AEF9"
      d="M39.09 10.908h-.908v.91h.909zM40 18.182h-.91v.909H40z"
    ></path>
    <path
      fill="#E0AEF9"
      d="M40 17.275h-.91v.91H40zM40 16.363h-.91v.91H40zM40 15.453h-.91v.91H40z"
    ></path>
    <path
      fill="#E0AEF9"
      d="M40 14.547h-.91v.909H40zM40 13.637h-.91v.909H40z"
    ></path>
    <path
      fill="#E0AEF9"
      d="M40 12.73h-.91v.91H40zM40 11.818h-.91v.91H40zM40 10.908h-.91v.91H40z"
    ></path>
    <path fill="#F6E6FF" d="M4.545 10.002h-.909v.909h.91z"></path>
    <path fill="#F6E6FF" d="M5.455 10.002h-.91v.909h.91z"></path>
    <path fill="#F3C0FF" d="M2.727 10.002h-.909v.909h.91z"></path>
    <path
      fill="#F3C0FF"
      d="M3.636 10.002h-.909v.909h.91zM1.818 10.002H.91v.909h.91zM6.364 10.002h-.91v.909h.91z"
    ></path>
    <path fill="#F3C0FF" d="M7.273 10.002h-.91v.909h.91z"></path>
    <path
      fill="#F3C0FF"
      d="M8.182 10.002h-.91v.909h.91zM9.09 10.002h-.908v.909h.909z"
    ></path>
    <path
      fill="#F3C0FF"
      d="M10 10.002h-.91v.909H10zM10.91 10.002H10v.909h.91z"
    ></path>
    <path fill="#FF8E92" d="M11.818 10.002h-.909v.909h.91z"></path>
    <path fill="#FFBFBD" d="M12.727 10.002h-.909v.909h.91z"></path>
    <path fill="#FF8E92" d="M13.636 10.002h-.909v.909h.91z"></path>
    <path fill="#FF8E92" d="M14.545 10.002h-.909v.909h.91z"></path>
    <path fill="#F3C0FF" d="M15.455 10.002h-.91v.909h.91z"></path>
    <path fill="#F3C0FF" d="M16.364 10.002h-.91v.909h.91z"></path>
    <path fill="#F3C0FF" d="M17.273 10.002h-.91v.909h.91z"></path>
    <path
      fill="#F3C0FF"
      d="M18.182 10.002h-.91v.909h.91zM19.09 10.002h-.908v.909h.909z"
    ></path>
    <path
      fill="#F3C0FF"
      d="M20 10.002h-.91v.909H20zM20.91 10.002H20v.909h.91z"
    ></path>
    <path fill="#F3C0FF" d="M21.818 10.002h-.909v.909h.91z"></path>
    <path fill="#F3C0FF" d="M22.727 10.002h-.909v.909h.91z"></path>
    <path fill="#F3C0FF" d="M23.636 10.002h-.909v.909h.91z"></path>
    <path fill="#FF8E92" d="M24.545 10.002h-.909v.909h.91z"></path>
    <path fill="#FFBFBD" d="M25.455 10.002h-.91v.909h.91z"></path>
    <path fill="#FFBFBD" d="M26.364 10.002h-.91v.909h.91z"></path>
    <path fill="#FF8E92" d="M27.273 10.002h-.91v.909h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M28.182 10.002h-.91v.909h.91zM29.09 10.002h-.908v.909h.909z"
    ></path>
    <path
      fill="#EBCAFA"
      d="M30 10.002h-.91v.909H30zM30.91 10.002H30v.909h.91z"
    ></path>
    <path fill="#EBCAFA" d="M31.818 10.002h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M32.727 10.002h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M33.636 10.002h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M34.546 10.002h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M35.455 10.002h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M36.364 10.002h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M37.273 10.002h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M38.182 10.002h-.91v.909h.91z"></path>
    <path fill="#E0AEF9" d="M39.09 10.002h-.908v.909h.909z"></path>
    <path fill="#F3C0FF" d="M4.545 9.09h-.909v.909h.91z"></path>
    <path fill="#F6E6FF" d="M5.455 9.09h-.91v.909h.91z"></path>
    <path fill="#F3C0FF" d="M2.727 9.09h-.909v.909h.91z"></path>
    <path
      fill="#F3C0FF"
      d="M3.636 9.09h-.909v.909h.91zM6.364 9.09h-.91v.909h.91z"
    ></path>
    <path fill="#E0AEF9" d="M7.273 9.09h-.91v.909h.91z"></path>
    <path
      fill="#E0AEF9"
      d="M8.182 9.09h-.91v.909h.91zM9.09 9.09h-.908v.909h.909z"
    ></path>
    <path fill="#E0AEF9" d="M10 9.09h-.91v.909H10z"></path>
    <path fill="#F3C0FF" d="M10.91 9.09H10v.909h.91z"></path>
    <path fill="#F3C0FF" d="M11.818 9.09h-.909v.909h.91z"></path>
    <path fill="#FF8E92" d="M12.727 9.09h-.909v.909h.91z"></path>
    <path fill="#FFBFBD" d="M13.636 9.09h-.909v.909h.91z"></path>
    <path fill="#F3C0FF" d="M14.545 9.09h-.909v.909h.91z"></path>
    <path fill="#F3C0FF" d="M15.455 9.09h-.91v.909h.91z"></path>
    <path fill="#F3C0FF" d="M16.364 9.09h-.91v.909h.91z"></path>
    <path fill="#F3C0FF" d="M17.273 9.09h-.91v.909h.91z"></path>
    <path
      fill="#F3C0FF"
      d="M18.182 9.09h-.91v.909h.91zM19.09 9.09h-.908v.909h.909z"
    ></path>
    <path
      fill="#F3C0FF"
      d="M20 9.09h-.91v.909H20zM20.91 9.09H20v.909h.91z"
    ></path>
    <path fill="#F3C0FF" d="M21.818 9.09h-.909v.909h.91z"></path>
    <path fill="#F3C0FF" d="M22.727 9.09h-.909v.909h.91z"></path>
    <path fill="#F3C0FF" d="M23.636 9.09h-.909v.909h.91z"></path>
    <path fill="#F3C0FF" d="M24.545 9.09h-.909v.909h.91z"></path>
    <path fill="#FF8E92" d="M25.455 9.09h-.91v.909h.91z"></path>
    <path fill="#FF8E92" d="M26.364 9.09h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M27.273 9.09h-.91v.909h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M28.182 9.09h-.91v.909h.91zM29.09 9.09h-.908v.909h.909z"
    ></path>
    <path
      fill="#EBCAFA"
      d="M30 9.09h-.91v.909H30zM30.91 9.09H30v.909h.91z"
    ></path>
    <path fill="#E0AEF9" d="M31.818 9.09h-.909v.909h.91z"></path>
    <path fill="#E0AEF9" d="M32.727 9.09h-.909v.909h.91z"></path>
    <path fill="#E0AEF9" d="M33.636 9.09h-.909v.909h.91z"></path>
    <path fill="#E0AEF9" d="M34.546 9.09h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M35.455 9.09h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M36.364 9.09h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M37.273 9.09h-.91v.909h.91z"></path>
    <path fill="#E0AEF9" d="M38.182 9.09h-.91v.909h.91z"></path>
    <path fill="#F3C0FF" d="M4.545 8.18h-.909v.909h.91z"></path>
    <path
      fill="#F3C0FF"
      d="M5.455 8.18h-.91v.909h.91zM3.636 8.18h-.909v.909h.91z"
    ></path>
    <path fill="#E83B3A" d="M6.364 8.18h-.91v.909h.91z"></path>
    <path fill="#E83B3A" d="M7.273 8.18h-.91v.909h.91z"></path>
    <path
      fill="#E83B3A"
      d="M8.182 8.18h-.91v.909h.91zM9.09 8.18h-.908v.909h.909z"
    ></path>
    <path fill="#EBCAFA" d="M10 8.18h-.91v.909H10z"></path>
    <path fill="#F3C0FF" d="M10.91 8.18H10v.909h.91z"></path>
    <path fill="#F3C0FF" d="M11.818 8.18h-.909v.909h.91z"></path>
    <path fill="#F3C0FF" d="M12.727 8.18h-.909v.909h.91z"></path>
    <path fill="#F3C0FF" d="M13.636 8.18h-.909v.909h.91z"></path>
    <path fill="#F3C0FF" d="M14.545 8.18h-.909v.909h.91z"></path>
    <path fill="#F3C0FF" d="M15.455 8.18h-.91v.909h.91z"></path>
    <path fill="#F3C0FF" d="M16.364 8.18h-.91v.909h.91z"></path>
    <path fill="#F3C0FF" d="M17.273 8.18h-.91v.909h.91z"></path>
    <path
      fill="#F3C0FF"
      d="M18.182 8.18h-.91v.909h.91zM19.09 8.18h-.908v.909h.909z"
    ></path>
    <path
      fill="#F3C0FF"
      d="M20 8.18h-.91v.909H20zM20.91 8.18H20v.909h.91z"
    ></path>
    <path fill="#F3C0FF" d="M21.818 8.18h-.909v.909h.91z"></path>
    <path fill="#F3C0FF" d="M22.727 8.18h-.909v.909h.91z"></path>
    <path fill="#F3C0FF" d="M23.636 8.18h-.909v.909h.91z"></path>
    <path fill="#F3C0FF" d="M24.545 8.18h-.909v.909h.91z"></path>
    <path fill="#F3C0FF" d="M25.455 8.18h-.91v.909h.91z"></path>
    <path fill="#F3C0FF" d="M26.364 8.18h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M27.273 8.18h-.91v.909h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M28.182 8.18h-.91v.909h.91zM29.09 8.18h-.908v.909h.909z"
    ></path>
    <path fill="#EBCAFA" d="M30 8.18h-.91v.909H30z"></path>
    <path fill="#E83B3A" d="M30.91 8.18H30v.909h.91z"></path>
    <path fill="#E83B3A" d="M31.818 8.18h-.909v.909h.91z"></path>
    <path fill="#E83B3A" d="M32.727 8.18h-.909v.909h.91z"></path>
    <path fill="#E83B3A" d="M33.636 8.18h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M34.546 8.18h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M35.455 8.18h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M36.364 8.18h-.91v.909h.91z"></path>
    <path fill="#E0AEF9" d="M37.273 8.18h-.91v.909h.91z"></path>
    <path fill="#F3C0FF" d="M4.545 7.273h-.909v.91h.91z"></path>
    <path fill="#FF8E92" d="M5.455 7.273h-.91v.91h.91z"></path>
    <path fill="#FDFFE9" d="M6.364 7.273h-.91v.91h.91z"></path>
    <path fill="#FF595A" d="M7.273 7.273h-.91v.91h.91z"></path>
    <path fill="#FF595A" d="M8.182 7.273h-.91v.91h.91z"></path>
    <path fill="#FDFFE9" d="M9.09 7.273h-.908v.91h.909z"></path>
    <path fill="#E83B3A" d="M10 7.273h-.91v.91H10z"></path>
    <path fill="#F6E6FF" d="M10.91 7.273H10v.91h.91z"></path>
    <path fill="#F3C0FF" d="M11.818 7.273h-.909v.91h.91z"></path>
    <path fill="#F3C0FF" d="M12.727 7.273h-.909v.91h.91z"></path>
    <path fill="#F3C0FF" d="M13.636 7.273h-.909v.91h.91z"></path>
    <path fill="#F3C0FF" d="M14.545 7.273h-.909v.91h.91z"></path>
    <path fill="#F3C0FF" d="M15.455 7.273h-.91v.91h.91z"></path>
    <path fill="#F3C0FF" d="M16.364 7.273h-.91v.91h.91z"></path>
    <path fill="#F3C0FF" d="M17.273 7.273h-.91v.91h.91z"></path>
    <path
      fill="#F3C0FF"
      d="M18.182 7.273h-.91v.91h.91zM19.09 7.273h-.908v.91h.909z"
    ></path>
    <path
      fill="#F3C0FF"
      d="M20 7.273h-.91v.91H20zM20.91 7.273H20v.91h.91z"
    ></path>
    <path fill="#F3C0FF" d="M21.818 7.273h-.909v.91h.91z"></path>
    <path fill="#F3C0FF" d="M22.727 7.273h-.909v.91h.91z"></path>
    <path fill="#F3C0FF" d="M23.636 7.273h-.909v.91h.91z"></path>
    <path fill="#F3C0FF" d="M24.545 7.273h-.909v.91h.91z"></path>
    <path fill="#F3C0FF" d="M25.455 7.273h-.91v.91h.91z"></path>
    <path fill="#F3C0FF" d="M26.364 7.273h-.91v.91h.91z"></path>
    <path fill="#F3C0FF" d="M27.273 7.273h-.91v.91h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M28.182 7.273h-.91v.91h.91zM29.09 7.273h-.908v.91h.909z"
    ></path>
    <path fill="#FF8E92" d="M30 7.273h-.91v.91H30z"></path>
    <path fill="#FDFFE9" d="M30.91 7.273H30v.91h.91z"></path>
    <path fill="#FF595A" d="M31.818 7.273h-.909v.91h.91z"></path>
    <path fill="#FF595A" d="M32.727 7.273h-.909v.91h.91z"></path>
    <path fill="#FDFFE9" d="M33.636 7.273h-.909v.91h.91z"></path>
    <path fill="#E83B3A" d="M34.546 7.273h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M35.455 7.273h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M36.364 7.273h-.91v.91h.91z"></path>
    <path fill="#FF8E92" d="M5.455 6.363h-.91v.91h.91z"></path>
    <path fill="#FFBFBD" d="M6.364 6.363h-.91v.91h.91z"></path>
    <path fill="#FF595A" d="M7.273 6.363h-.91v.91h.91z"></path>
    <path
      fill="#FF595A"
      d="M8.182 6.363h-.91v.91h.91zM9.09 6.363h-.908v.91h.909z"
    ></path>
    <path fill="#E83B3A" d="M10 6.363h-.91v.91H10z"></path>
    <path fill="#F6E6FF" d="M10.91 6.363H10v.91h.91z"></path>
    <path fill="#F6E6FF" d="M11.818 6.363h-.909v.91h.91z"></path>
    <path fill="#F6E6FF" d="M12.727 6.363h-.909v.91h.91z"></path>
    <path fill="#F6E6FF" d="M13.636 6.363h-.909v.91h.91z"></path>
    <path fill="#F6E6FF" d="M14.545 6.363h-.909v.91h.91z"></path>
    <path fill="#F3C0FF" d="M15.455 6.363h-.91v.91h.91z"></path>
    <path fill="#F3C0FF" d="M16.364 6.363h-.91v.91h.91z"></path>
    <path fill="#F3C0FF" d="M17.273 6.363h-.91v.91h.91z"></path>
    <path
      fill="#F3C0FF"
      d="M18.182 6.363h-.91v.91h.91zM19.09 6.363h-.908v.91h.909z"
    ></path>
    <path
      fill="#E0AEF9"
      d="M20 6.363h-.91v.91H20zM20.91 6.363H20v.91h.91z"
    ></path>
    <path fill="#E0AEF9" d="M21.818 6.363h-.909v.91h.91z"></path>
    <path fill="#E0AEF9" d="M22.727 6.363h-.909v.91h.91z"></path>
    <path fill="#F3C0FF" d="M23.636 6.363h-.909v.91h.91z"></path>
    <path fill="#F3C0FF" d="M24.545 6.363h-.909v.91h.91z"></path>
    <path fill="#F3C0FF" d="M25.455 6.363h-.91v.91h.91z"></path>
    <path fill="#F3C0FF" d="M26.364 6.363h-.91v.91h.91z"></path>
    <path fill="#F3C0FF" d="M27.273 6.363h-.91v.91h.91z"></path>
    <path fill="#F3C0FF" d="M28.182 6.363h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M29.09 6.363h-.908v.91h.909z"></path>
    <path fill="#FF8E92" d="M30 6.363h-.91v.91H30z"></path>
    <path fill="#FFBFBD" d="M30.91 6.363H30v.91h.91z"></path>
    <path fill="#FF595A" d="M31.818 6.363h-.909v.91h.91z"></path>
    <path fill="#FF595A" d="M32.727 6.363h-.909v.91h.91z"></path>
    <path fill="#FF595A" d="M33.636 6.363h-.909v.91h.91z"></path>
    <path fill="#E83B3A" d="M34.546 6.363h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M35.455 6.363h-.91v.91h.91z"></path>
    <path fill="#FF8E92" d="M5.455 5.457h-.91v.91h.91z"></path>
    <path fill="#FDFFE9" d="M6.364 5.457h-.91v.91h.91z"></path>
    <path fill="#FF8E92" d="M7.273 5.457h-.91v.91h.91z"></path>
    <path fill="#FF595A" d="M8.182 5.457h-.91v.91h.91z"></path>
    <path fill="#FDFFE9" d="M9.09 5.457h-.908v.91h.909z"></path>
    <path fill="#E83B3A" d="M10 5.457h-.91v.91H10z"></path>
    <path fill="#EBCAFA" d="M10.91 5.457H10v.91h.91z"></path>
    <path fill="#EBCAFA" d="M11.818 5.457h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M12.727 5.457h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M13.636 5.457h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M14.545 5.457h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M15.455 5.457h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M16.364 5.457h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M17.273 5.457h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M18.182 5.457h-.91v.91h.91z"></path>
    <path fill="#E83B3A" d="M19.09 5.457h-.908v.91h.909z"></path>
    <path
      fill="#E83B3A"
      d="M20 5.457h-.91v.91H20zM20.91 5.457H20v.91h.91z"
    ></path>
    <path fill="#E83B3A" d="M21.818 5.457h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M22.727 5.457h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M23.636 5.457h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M24.545 5.457h-.909v.91h.91z"></path>
    <path fill="#EBCAFA" d="M25.455 5.457h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M26.364 5.457h-.91v.91h.91z"></path>
    <path fill="#EBCAFA" d="M27.273 5.457h-.91v.91h.91z"></path>
    <path
      fill="#EBCAFA"
      d="M28.182 5.457h-.91v.91h.91zM29.09 5.457h-.908v.91h.909z"
    ></path>
    <path fill="#FF8E92" d="M30 5.457h-.91v.91H30z"></path>
    <path fill="#FDFFE9" d="M30.91 5.457H30v.91h.91z"></path>
    <path fill="#FF8E92" d="M31.818 5.457h-.909v.91h.91z"></path>
    <path fill="#FF595A" d="M32.727 5.457h-.909v.91h.91z"></path>
    <path fill="#FDFFE9" d="M33.636 5.457h-.909v.91h.91z"></path>
    <path fill="#E83B3A" d="M34.546 5.457h-.91v.91h.91z"></path>
    <path fill="#FFBFBD" d="M6.364 4.545h-.91v.909h.91z"></path>
    <path fill="#FF8E92" d="M7.273 4.545h-.91v.909h.91z"></path>
    <path fill="#FF8E92" d="M8.182 4.545h-.91v.909h.91z"></path>
    <path fill="#FF595A" d="M9.09 4.545h-.908v.909h.909z"></path>
    <path fill="#EBCAFA" d="M12.727 4.545h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M13.636 4.545h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M14.545 4.545h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M15.455 4.545h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M16.364 4.545h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M17.273 4.545h-.91v.909h.91z"></path>
    <path fill="#FF8E92" d="M18.182 4.545h-.91v.909h.91z"></path>
    <path fill="#FDFFE9" d="M19.09 4.545h-.908v.909h.909z"></path>
    <path
      fill="#FF595A"
      d="M20 4.545h-.91v.909H20zM20.91 4.545H20v.909h.91z"
    ></path>
    <path fill="#FDFFE9" d="M21.818 4.545h-.909v.909h.91z"></path>
    <path fill="#E83B3A" d="M22.727 4.545h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M23.636 4.545h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M24.545 4.545h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M25.455 4.545h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M26.364 4.545h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M27.273 4.545h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M28.182 4.545h-.91v.909h.91z"></path>
    <path fill="#FFBFBD" d="M30.91 4.545H30v.909h.91z"></path>
    <path fill="#FF8E92" d="M31.818 4.545h-.909v.909h.91z"></path>
    <path fill="#FF8E92" d="M32.727 4.545h-.909v.909h.91z"></path>
    <path fill="#FF595A" d="M33.636 4.545h-.909v.909h.91z"></path>
    <path fill="#FF8E92" d="M6.364 3.635h-.91v.909h.91z"></path>
    <path fill="#FFBFBD" d="M7.273 3.635h-.91v.909h.91z"></path>
    <path fill="#FFBFBD" d="M8.182 3.635h-.91v.909h.91z"></path>
    <path fill="#FF8E92" d="M9.09 3.635h-.908v.909h.909z"></path>
    <path fill="#EBCAFA" d="M16.364 3.635h-.91v.909h.91z"></path>
    <path fill="#EBCAFA" d="M17.273 3.635h-.91v.909h.91z"></path>
    <path fill="#FF8E92" d="M18.182 3.635h-.91v.909h.91z"></path>
    <path fill="#FFBFBD" d="M19.09 3.635h-.908v.909h.909z"></path>
    <path
      fill="#FF595A"
      d="M20 3.635h-.91v.909H20zM20.91 3.635H20v.909h.91z"
    ></path>
    <path fill="#FF595A" d="M21.818 3.635h-.909v.909h.91z"></path>
    <path fill="#E83B3A" d="M22.727 3.635h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M23.636 3.635h-.909v.909h.91z"></path>
    <path fill="#EBCAFA" d="M24.545 3.635h-.909v.909h.91z"></path>
    <path fill="#FF8E92" d="M30.91 3.635H30v.909h.91z"></path>
    <path fill="#FFBFBD" d="M31.818 3.635h-.909v.909h.91z"></path>
    <path fill="#FFBFBD" d="M32.727 3.635h-.909v.909h.91z"></path>
    <path
      fill="#FF8E92"
      d="M33.636 3.635h-.909v.909h.91zM7.273 2.729h-.91v.909h.91z"
    ></path>
    <path
      fill="#FF8E92"
      d="M8.182 2.729h-.91v.909h.91zM18.182 2.729h-.91v.909h.91z"
    ></path>
    <path fill="#FDFFE9" d="M19.09 2.729h-.908v.909h.909z"></path>
    <path fill="#FF8E92" d="M20 2.729h-.91v.909H20z"></path>
    <path fill="#FF595A" d="M20.91 2.729H20v.909h.91z"></path>
    <path fill="#FDFFE9" d="M21.818 2.729h-.909v.909h.91z"></path>
    <path fill="#E83B3A" d="M22.727 2.729h-.909v.909h.91z"></path>
    <path fill="#FF8E92" d="M31.818 2.729h-.909v.909h.91z"></path>
    <path fill="#FF8E92" d="M32.727 2.729h-.909v.909h.91z"></path>
    <path fill="#FFBFBD" d="M19.09 1.816h-.908v.91h.909z"></path>
    <path
      fill="#FF8E92"
      d="M20 1.816h-.91v.91H20zM20.91 1.816H20v.91h.91z"
    ></path>
    <path fill="#FF595A" d="M21.818 1.816h-.909v.91h.91z"></path>
    <path fill="#FF8E92" d="M19.09.91h-.908v.91h.909z"></path>
    <path fill="#FFBFBD" d="M20 .91h-.91v.91H20zM20.91.91H20v.91h.91z"></path>
    <path
      fill="#FF8E92"
      d="M21.818.91h-.909v.91h.91zM20 0h-.91v.91H20zM20.91 0H20v.91h.91z"
    ></path>
  </svg>
);
