import { Text } from '@/components/ui/atoms/Text';
import { Button } from '@/components/ui/molecules/Button';
import PrivyWalletProvider, {
  privyWalletList,
} from '@/components/auth/PrivyWalletProvider';
import { usePrivy, useWallets } from '@privy-io/react-auth';
import { useUserData } from '@/context/FirestoreContext';
import { useState, useEffect } from 'react';
import {
  USER_ADD_EVM_WALLET_ENDPOINT,
  USER_REMOVE_EVM_WALLET_ENDPOINT,
} from '@/config/endpoints';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import { Box } from '@chakra-ui/react';

const ConnectOkxWalletDesktopContainer = () => {
  return (
    <PrivyWalletProvider>
      <ConnectOkxWalletDesktop />
    </PrivyWalletProvider>
  );
};

const ConnectOkxWalletDesktop = () => {
  const { apiPut } = useAPI();
  const alert = useAlert();
  const { userData } = useUserData();

  const { wallets } = useWallets();
  const { ready, authenticated, connectWallet } = usePrivy();
  const isPrivyInitializing = !ready || !authenticated;
  const [isSaving, setIsSaving] = useState(false);

  const [isEvmWalletConnected, setIsEvmWalletConnected] = useState(false);

  const evmWallet = wallets.find(
    (wallet) =>
      (wallet as any).type === 'ethereum' &&
      wallet.chainId.startsWith(`eip155`) &&
      wallet.connectorType !== `embedded` &&
      wallet.walletClientType === `okx_wallet`,
  );

  // once the user has connected their wallet, save it to their game account
  useEffect(() => {
    // exit if no wallet is connected
    if (!isEvmWalletConnected || !evmWallet?.address) return;

    // if new wallet has been connected, save it to their game account
    if (userData?.evmWallet !== evmWallet?.address) {
      setIsSaving(true);
      apiPut(USER_ADD_EVM_WALLET_ENDPOINT, {
        walletAddress: evmWallet?.address,
      })
        .then(() => {
          alert({ title: `wallet connected` });
        })
        .catch((error) => {
          alert({ title: `Err: ${error?.message}` });
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  }, [isEvmWalletConnected, evmWallet?.address, userData?.evmWallet]);

  useEffect(() => {
    if (evmWallet && !isEvmWalletConnected) {
      evmWallet?.isConnected().then((result) => {
        if (result) {
          setIsEvmWalletConnected(true);
        } else {
          setIsEvmWalletConnected(false);
        }
      });
    } else if (isEvmWalletConnected && !evmWallet) {
      setIsEvmWalletConnected(false);
    }
  }, [evmWallet, isEvmWalletConnected]);

  return (
    <>
      {userData?.evmWallet ? (
        <Box w={`full`}>
          <Button
            colorId={`Black`}
            w={`full`}
            border={`solid 3px black`}
            isDisabled={isSaving}
            onClick={async () => {
              try {
                setIsSaving(true);
                setIsEvmWalletConnected(false);
                await apiPut(USER_REMOVE_EVM_WALLET_ENDPOINT, {});
                alert({ title: `wallet disconnected` });
                evmWallet?.disconnect();
              } catch (error: any) {
                alert({ title: `Err: ${error?.message}` });
              } finally {
                setIsSaving(false);
              }
            }}
          >
            <Text colorId={`White`}>disconnect</Text>
          </Button>
        </Box>
      ) : (
        <Button
          mb={2}
          borderLeft={`1px solid black`}
          colorId={`Black`}
          padding={`8px`}
          minWidth={`0px`}
          w={`full`}
          isDisabled={isPrivyInitializing || isSaving}
          onClick={() => {
            connectWallet({
              walletList: privyWalletList,
            });
          }}
        >
          <Text colorId={`White`}>Connect okx wallet</Text>
        </Button>
      )}
    </>
  );
};

export default ConnectOkxWalletDesktopContainer;
