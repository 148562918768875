import { Box, Center, FlexProps } from '@chakra-ui/react';
import { TranslatedText } from '../ui/atoms/TranslatedText';
import { Text } from '../ui/atoms/Text';
import translations from '../../../../../packages/utils/src/i18n/en/index.json';
import { sounds } from '@/lib/sounds';
import { useEffectPlayer } from '@/context/EffectPlayerContext';
import { NumberIndicator } from '../ui/atoms/NumberIndicator';

export const HomeIcon2 = ({
  children,
  translateKey,
  translateProps,
  onClick,
  numIndicator,
  ...props
}: {
  children: React.ReactNode;
  translateKey: keyof typeof translations;
  translateProps?: Record<string, any>;
  numIndicator?: number;
} & FlexProps) => {
  const effectPlayer = useEffectPlayer();
  return (
    <Center
      flexDirection={'column'}
      alignItems={'flex-start'}
      position={'relative'}
      w="full"
      gap={1}
      onClick={(e) => {
        effectPlayer.playEffect(sounds.pressButton);
        onClick?.(e);
      }}
      {...props}
    >
      {numIndicator ? (
        <Box position={'absolute'} top={-1} right={1}>
          <NumberIndicator num={numIndicator} />
        </Box>
      ) : null}
      <Center w="full" cursor={'pointer'}>
        {children}
      </Center>
      <Text
        colorId="Black"
        whiteSpace={'pre-wrap'}
        wordBreak={'break-all'}
        fontSize={'6px'}
        lineHeight={'10px'}
        w="full"
        textAlign={'center'}
      >
        <TranslatedText
          translationKey={translateKey}
          defaultMessage={`${translateKey}`}
          props={translateProps}
        />
      </Text>
    </Center>
  );
};
