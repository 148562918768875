export const GrandPrize = ({ ...props }) => (
  <svg
    width={174}
    height={75}
    viewBox="0 0 174 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M61.7388 29.6281L60.3246 28.2139L58.9104 29.6281L60.3246 31.0423L61.7388 29.6281Z"
      fill="#FE4F99"
    />
    <path
      d="M63.1487 31.0275L61.7345 29.6133L60.3203 31.0275L61.7345 32.4417L63.1487 31.0275Z"
      fill="black"
    />
    <path
      d="M60.3288 28.2238L58.9146 26.8096L57.5003 28.2238L58.9146 29.638L60.3288 28.2238Z"
      fill="#FE4F99"
    />
    <path
      d="M58.9187 26.8097L57.5045 25.3955L56.0903 26.8097L57.5045 28.2239L58.9187 26.8097Z"
      fill="#FE4F99"
    />
    <path
      d="M57.5087 25.4054L56.0945 23.9912L54.6803 25.4054L56.0945 26.8196L57.5087 25.4054Z"
      fill="#FE4F99"
    />
    <path
      d="M56.0988 23.9914L54.6846 22.5771L53.2704 23.9914L54.6846 25.4056L56.0988 23.9914Z"
      fill="#FE4F99"
    />
    <path
      d="M54.6817 22.5705L53.2675 21.1562L51.8532 22.5705L53.2675 23.9847L54.6817 22.5705Z"
      fill="#FE4F99"
    />
    <path
      d="M53.2787 21.1593L51.8645 19.7451L50.4503 21.1593L51.8645 22.5735L53.2787 21.1593Z"
      fill="#FE4F99"
    />
    <path
      d="M51.8688 19.7453L50.4546 18.3311L49.0404 19.7453L50.4546 21.1595L51.8688 19.7453Z"
      fill="#FF8DCC"
    />
    <path
      d="M50.4588 18.341L49.0446 16.9268L47.6303 18.341L49.0446 19.7552L50.4588 18.341Z"
      fill="#FF8DCC"
    />
    <path
      d="M49.0487 16.9269L47.6345 15.5127L46.2203 16.9269L47.6345 18.3411L49.0487 16.9269Z"
      fill="#FF8DCC"
    />
    <path
      d="M47.6387 15.5236L46.2245 14.1094L44.8103 15.5236L46.2245 16.9378L47.6387 15.5236Z"
      fill="#FF8DCC"
    />
    <path
      d="M46.2288 14.1095L44.8146 12.6953L43.4004 14.1095L44.8146 15.5237L46.2288 14.1095Z"
      fill="#FF8DCC"
    />
    <path
      d="M44.8188 12.6955L43.4045 11.2812L41.9903 12.6955L43.4045 14.1097L44.8188 12.6955Z"
      fill="#FF8DCC"
    />
    <path
      d="M43.4087 11.2912L41.9945 9.87695L40.5803 11.2912L41.9945 12.7054L43.4087 11.2912Z"
      fill="black"
    />
    <path
      d="M63.1498 28.2111L61.7356 26.7969L60.3214 28.2111L61.7356 29.6253L63.1498 28.2111Z"
      fill="black"
    />
    <path
      d="M61.7399 26.8068L60.3257 25.3926L58.9115 26.8068L60.3257 28.221L61.7399 26.8068Z"
      fill="black"
    />
    <path
      d="M60.3299 25.3927L58.9156 23.9785L57.5014 25.3927L58.9156 26.8069L60.3299 25.3927Z"
      fill="black"
    />
    <path
      d="M58.9198 23.9787L57.5056 22.5645L56.0914 23.9787L57.5056 25.3929L58.9198 23.9787Z"
      fill="black"
    />
    <path
      d="M57.5099 22.5744L56.0957 21.1602L54.6815 22.5744L56.0957 23.9886L57.5099 22.5744Z"
      fill="black"
    />
    <path
      d="M56.0999 21.1603L54.6857 19.7461L53.2715 21.1603L54.6857 22.5745L56.0999 21.1603Z"
      fill="black"
    />
    <path
      d="M54.6828 19.7492L53.2686 18.335L51.8543 19.7492L53.2686 21.1634L54.6828 19.7492Z"
      fill="black"
    />
    <path
      d="M53.2727 18.3351L51.8585 16.9209L50.4443 18.3351L51.8585 19.7493L53.2727 18.3351Z"
      fill="black"
    />
    <path
      d="M51.8628 16.921L50.4486 15.5068L49.0344 16.921L50.4486 18.3353L51.8628 16.921Z"
      fill="black"
    />
    <path
      d="M50.4599 15.5099L49.0457 14.0957L47.6314 15.5099L49.0457 16.9241L50.4599 15.5099Z"
      fill="black"
    />
    <path
      d="M49.0498 14.0959L47.6356 12.6816L46.2214 14.0959L47.6356 15.5101L49.0498 14.0959Z"
      fill="black"
    />
    <path
      d="M47.6399 12.6925L46.2257 11.2783L44.8115 12.6925L46.2257 14.1067L47.6399 12.6925Z"
      fill="black"
    />
    <path
      d="M46.2299 11.2785L44.8157 9.86426L43.4015 11.2785L44.8157 12.6927L46.2299 11.2785Z"
      fill="black"
    />
    <path
      d="M60.3318 31.0304L58.9176 29.6162L57.5034 31.0304L58.9176 32.4446L60.3318 31.0304Z"
      fill="#FE4F99"
    />
    <path
      d="M58.9218 29.6164L57.5076 28.2021L56.0934 29.6164L57.5076 31.0306L58.9218 29.6164Z"
      fill="#F41740"
    />
    <path
      d="M57.5117 28.213L56.0975 26.7988L54.6833 28.213L56.0975 29.6273L57.5117 28.213Z"
      fill="#F41740"
    />
    <path
      d="M56.1018 26.799L54.6876 25.3848L53.2734 26.799L54.6876 28.2132L56.1018 26.799Z"
      fill="#F41740"
    />
    <path
      d="M54.6918 25.3849L53.2776 23.9707L51.8634 25.3849L53.2776 26.7991L54.6918 25.3849Z"
      fill="#F41740"
    />
    <path
      d="M53.2818 23.9806L51.8676 22.5664L50.4533 23.9806L51.8676 25.3948L53.2818 23.9806Z"
      fill="#F41740"
    />
    <path
      d="M51.8717 22.5666L50.4575 21.1523L49.0433 22.5666L50.4575 23.9808L51.8717 22.5666Z"
      fill="#F41740"
    />
    <path
      d="M50.4618 21.1623L49.0476 19.748L47.6334 21.1623L49.0476 22.5765L50.4618 21.1623Z"
      fill="#F7345E"
    />
    <path
      d="M49.0518 19.7482L47.6376 18.334L46.2234 19.7482L47.6376 21.1624L49.0518 19.7482Z"
      fill="#F7345E"
    />
    <path
      d="M47.6418 18.3449L46.2275 16.9307L44.8133 18.3449L46.2275 19.7591L47.6418 18.3449Z"
      fill="#F7345E"
    />
    <path
      d="M46.2318 16.9308L44.8176 15.5166L43.4034 16.9308L44.8176 18.345L46.2318 16.9308Z"
      fill="#F7345E"
    />
    <path
      d="M44.8289 15.5089L43.4147 14.0947L42.0005 15.5089L43.4147 16.9232L44.8289 15.5089Z"
      fill="#F7345E"
    />
    <path
      d="M43.4189 14.1056L42.0046 12.6914L40.5904 14.1056L42.0046 15.5198L43.4189 14.1056Z"
      fill="#FF8DCC"
    />
    <path
      d="M58.9419 32.4308L57.5277 31.0166L56.1135 32.4308L57.5277 33.845L58.9419 32.4308Z"
      fill="#F41740"
    />
    <path
      d="M57.5219 31.0168L56.1077 29.6025L54.6935 31.0168L56.1077 32.431L57.5219 31.0168Z"
      fill="#FE4F99"
    />
    <path
      d="M56.1118 29.6125L54.6976 28.1982L53.2834 29.6125L54.6976 31.0267L56.1118 29.6125Z"
      fill="#F41740"
    />
    <path
      d="M54.7019 28.1984L53.2877 26.7842L51.8735 28.1984L53.2877 29.6126L54.7019 28.1984Z"
      fill="#FE4F99"
    />
    <path
      d="M53.2919 26.7951L51.8777 25.3809L50.4635 26.7951L51.8777 28.2093L53.2919 26.7951Z"
      fill="#FE4F99"
    />
    <path
      d="M51.8819 25.381L50.4677 23.9668L49.0534 25.381L50.4677 26.7952L51.8819 25.381Z"
      fill="#FE4F99"
    />
    <path
      d="M50.4718 23.9767L49.0576 22.5625L47.6434 23.9767L49.0576 25.3909L50.4718 23.9767Z"
      fill="#FE4F99"
    />
    <path
      d="M49.0619 22.5627L47.6477 21.1484L46.2335 22.5627L47.6477 23.9769L49.0619 22.5627Z"
      fill="#FD64B6"
    />
    <path
      d="M47.6519 21.1486L46.2377 19.7344L44.8235 21.1486L46.2377 22.5628L47.6519 21.1486Z"
      fill="#FD64B6"
    />
    <path
      d="M46.2419 19.7443L44.8276 18.3301L43.4134 19.7443L44.8276 21.1585L46.2419 19.7443Z"
      fill="#FD64B6"
    />
    <path
      d="M44.8319 18.3302L43.4177 16.916L42.0035 18.3302L43.4177 19.7444L44.8319 18.3302Z"
      fill="#F41740"
    />
    <path
      d="M43.4148 16.9191L42.0006 15.5049L40.5864 16.9191L42.0006 18.3333L43.4148 16.9191Z"
      fill="#FF64B4"
    />
    <path
      d="M42.0048 15.505L40.5906 14.0908L39.1764 15.505L40.5906 16.9192L42.0048 15.505Z"
      fill="#F7345E"
    />
    <path
      d="M57.5349 33.838L56.1207 32.4238L54.7065 33.838L56.1207 35.2523L57.5349 33.838Z"
      fill="#F41740"
    />
    <path
      d="M56.1249 32.4337L54.7107 31.0195L53.2965 32.4337L54.7107 33.848L56.1249 32.4337Z"
      fill="#FE4F99"
    />
    <path
      d="M54.7149 31.0197L53.3007 29.6055L51.8864 31.0197L53.3007 32.4339L54.7149 31.0197Z"
      fill="#F41740"
    />
    <path
      d="M53.305 29.6154L51.8907 28.2012L50.4765 29.6154L51.8907 31.0296L53.305 29.6154Z"
      fill="#FE4F99"
    />
    <path
      d="M51.8849 28.2013L50.4707 26.7871L49.0565 28.2013L50.4707 29.6155L51.8849 28.2013Z"
      fill="#9D0B27"
    />
    <path
      d="M50.4749 26.7736L49.0607 25.3594L47.6465 26.7736L49.0607 28.1878L50.4749 26.7736Z"
      fill="#F63273"
    />
    <path
      d="M49.065 25.3693L47.6508 23.9551L46.2365 25.3693L47.6508 26.7835L49.065 25.3693Z"
      fill="#9D0B27"
    />
    <path
      d="M47.6549 23.9552L46.2407 22.541L44.8265 23.9552L46.2407 25.3694L47.6549 23.9552Z"
      fill="#FF65AD"
    />
    <path
      d="M46.2449 22.5509L44.8307 21.1367L43.4165 22.5509L44.8307 23.9651L46.2449 22.5509Z"
      fill="#9D0B27"
    />
    <path
      d="M44.8349 21.1378L43.4207 19.7236L42.0064 21.1378L43.4207 22.5521L44.8349 21.1378Z"
      fill="#BE0820"
    />
    <path
      d="M43.425 19.7335L42.0107 18.3193L40.5965 19.7335L42.0107 21.1478L43.425 19.7335Z"
      fill="#F41740"
    />
    <path
      d="M42.0149 18.3195L40.6007 16.9053L39.1865 18.3195L40.6007 19.7337L42.0149 18.3195Z"
      fill="#FF64B4"
    />
    <path
      d="M40.6049 16.9054L39.1907 15.4912L37.7765 16.9054L39.1907 18.3196L40.6049 16.9054Z"
      fill="#F7345E"
    />
    <path
      d="M56.128 35.2404L54.7137 33.8262L53.2995 35.2404L54.7137 36.6546L56.128 35.2404Z"
      fill="#F41740"
    />
    <path
      d="M54.7179 33.8263L53.3037 32.4121L51.8895 33.8263L53.3037 35.2405L54.7179 33.8263Z"
      fill="#FE4F99"
    />
    <path
      d="M53.3079 32.4123L51.8937 30.998L50.4795 32.4123L51.8937 33.8265L53.3079 32.4123Z"
      fill="#F41740"
    />
    <path
      d="M51.898 31.0089L50.4838 29.5947L49.0696 31.0089L50.4838 32.4232L51.898 31.0089Z"
      fill="#FE4F99"
    />
    <path
      d="M50.4879 29.5949L49.0737 28.1807L47.6595 29.5949L49.0737 31.0091L50.4879 29.5949Z"
      fill="#F61340"
    />
    <path
      d="M49.0779 28.1906L47.6637 26.7764L46.2495 28.1906L47.6637 29.6048L49.0779 28.1906Z"
      fill="#FE4F99"
    />
    <path
      d="M47.658 26.7765L46.2438 25.3623L44.8296 26.7765L46.2438 28.1907L47.658 26.7765Z"
      fill="#9D0B27"
    />
    <path
      d="M46.248 25.3722L44.8337 23.958L43.4195 25.3722L44.8337 26.7864L46.248 25.3722Z"
      fill="#F63273"
    />
    <path
      d="M44.8379 23.9582L43.4237 22.5439L42.0095 23.9582L43.4237 25.3724L44.8379 23.9582Z"
      fill="#F63273"
    />
    <path
      d="M43.4279 22.5441L42.0137 21.1299L40.5995 22.5441L42.0137 23.9583L43.4279 22.5441Z"
      fill="#FD64B6"
    />
    <path
      d="M42.018 21.1408L40.6038 19.7266L39.1895 21.1408L40.6038 22.555L42.018 21.1408Z"
      fill="#F41740"
    />
    <path
      d="M40.615 19.7189L39.2008 18.3047L37.7866 19.7189L39.2008 21.1331L40.615 19.7189Z"
      fill="#FF64B4"
    />
    <path
      d="M39.205 18.3156L37.7908 16.9014L36.3766 18.3156L37.7908 19.7298L39.205 18.3156Z"
      fill="#F7345E"
    />
    <path
      d="M54.7281 36.6408L53.3138 35.2266L51.8996 36.6408L53.3138 38.055L54.7281 36.6408Z"
      fill="#F41740"
    />
    <path
      d="M53.318 35.2267L51.9038 33.8125L50.4896 35.2267L51.9038 36.6409L53.318 35.2267Z"
      fill="#FE4F99"
    />
    <path
      d="M51.908 33.8224L50.4938 32.4082L49.0796 33.8224L50.4938 35.2366L51.908 33.8224Z"
      fill="#F41740"
    />
    <path
      d="M50.4981 32.4084L49.0839 30.9941L47.6696 32.4084L49.0839 33.8226L50.4981 32.4084Z"
      fill="#FE4F99"
    />
    <path
      d="M49.088 31.005L47.6738 29.5908L46.2596 31.005L47.6738 32.4192L49.088 31.005Z"
      fill="#FE4F99"
    />
    <path
      d="M47.678 29.591L46.2638 28.1768L44.8496 29.591L46.2638 31.0052L47.678 29.591Z"
      fill="#FE4F99"
    />
    <path
      d="M46.2681 28.1867L44.8539 26.7725L43.4397 28.1867L44.8539 29.6009L46.2681 28.1867Z"
      fill="#FE4F99"
    />
    <path
      d="M44.8581 26.7726L43.4438 25.3584L42.0296 26.7726L43.4438 28.1868L44.8581 26.7726Z"
      fill="#FD64B6"
    />
    <path
      d="M43.438 25.3585L42.0238 23.9443L40.6096 25.3585L42.0238 26.7728L43.438 25.3585Z"
      fill="#FD64B6"
    />
    <path
      d="M42.028 23.9543L40.6138 22.54L39.1996 23.9543L40.6138 25.3685L42.028 23.9543Z"
      fill="#FD64B6"
    />
    <path
      d="M40.6181 22.5402L39.2039 21.126L37.7896 22.5402L39.2039 23.9544L40.6181 22.5402Z"
      fill="#F41740"
    />
    <path
      d="M39.208 21.1359L37.7938 19.7217L36.3796 21.1359L37.7938 22.5501L39.208 21.1359Z"
      fill="#FF64B4"
    />
    <path
      d="M37.7909 19.715L36.3767 18.3008L34.9625 19.715L36.3767 21.1292L37.7909 19.715Z"
      fill="#F7345E"
    />
    <path
      d="M53.3211 38.048L51.9069 36.6338L50.4926 38.048L51.9069 39.4622L53.3211 38.048Z"
      fill="#F41740"
    />
    <path
      d="M51.911 36.6437L50.4968 35.2295L49.0826 36.6437L50.4968 38.0579L51.911 36.6437Z"
      fill="#FE4F99"
    />
    <path
      d="M50.5011 35.2296L49.0869 33.8154L47.6727 35.2296L49.0869 36.6439L50.5011 35.2296Z"
      fill="#F41740"
    />
    <path
      d="M49.0911 33.8253L47.6769 32.4111L46.2627 33.8253L47.6769 35.2396L49.0911 33.8253Z"
      fill="#FE4F99"
    />
    <path
      d="M47.6881 32.4044L46.2739 30.9902L44.8597 32.4044L46.2739 33.8187L47.6881 32.4044Z"
      fill="#BE0820"
    />
    <path
      d="M46.2781 30.9904L44.8639 29.5762L43.4497 30.9904L44.8639 32.4046L46.2781 30.9904Z"
      fill="#F61044"
    />
    <path
      d="M44.8611 29.5793L43.4469 28.165L42.0327 29.5793L43.4469 30.9935L44.8611 29.5793Z"
      fill="#F21548"
    />
    <path
      d="M43.4511 28.1652L42.0369 26.751L40.6227 28.1652L42.0369 29.5794L43.4511 28.1652Z"
      fill="#F21548"
    />
    <path
      d="M42.041 26.7619L40.6268 25.3477L39.2126 26.7619L40.6268 28.1761L42.041 26.7619Z"
      fill="#F93A77"
    />
    <path
      d="M40.631 25.3478L39.2168 23.9336L37.8026 25.3478L39.2168 26.762L40.631 25.3478Z"
      fill="#FD64B6"
    />
    <path
      d="M39.2111 23.9435L37.7969 22.5293L36.3827 23.9435L37.7969 25.3577L39.2111 23.9435Z"
      fill="#F41740"
    />
    <path
      d="M37.8011 22.5294L36.3868 21.1152L34.9726 22.5294L36.3868 23.9437L37.8011 22.5294Z"
      fill="#FF64B4"
    />
    <path
      d="M36.391 21.1154L34.9768 19.7012L33.5626 21.1154L34.9768 22.5296L36.391 21.1154Z"
      fill="#F7345E"
    />
    <path
      d="M51.9141 39.4503L50.4999 38.0361L49.0857 39.4503L50.4999 40.8646L51.9141 39.4503Z"
      fill="#F41740"
    />
    <path
      d="M50.5041 38.0373L49.0898 36.623L47.6756 38.0373L49.0898 39.4515L50.5041 38.0373Z"
      fill="#FE4F99"
    />
    <path
      d="M49.0941 36.6232L47.6799 35.209L46.2657 36.6232L47.6799 38.0374L49.0941 36.6232Z"
      fill="#F41740"
    />
    <path
      d="M47.6841 35.2189L46.2699 33.8047L44.8557 35.2189L46.2699 36.6331L47.6841 35.2189Z"
      fill="#FE4F99"
    />
    <path
      d="M46.2741 33.8048L44.8599 32.3906L43.4456 33.8048L44.8599 35.2191L46.2741 33.8048Z"
      fill="#FE4F99"
    />
    <path
      d="M44.8642 32.4005L43.45 30.9863L42.0357 32.4005L43.4499 33.8148L44.8642 32.4005Z"
      fill="#F61044"
    />
    <path
      d="M43.4541 30.9865L42.0399 29.5723L40.6257 30.9865L42.0399 32.4007L43.4541 30.9865Z"
      fill="#BE0820"
    />
    <path
      d="M42.0441 29.5822L40.6299 28.168L39.2157 29.5822L40.6299 30.9964L42.0441 29.5822Z"
      fill="#BE0820"
    />
    <path
      d="M40.6341 28.1681L39.2198 26.7539L37.8056 28.1681L39.2198 29.5823L40.6341 28.1681Z"
      fill="#F61340"
    />
    <path
      d="M39.2242 26.7541L37.8099 25.3398L36.3957 26.7541L37.8099 28.1683L39.2242 26.7541Z"
      fill="#FD64B6"
    />
    <path
      d="M37.8212 25.3429L36.407 23.9287L34.9928 25.3429L36.407 26.7571L37.8212 25.3429Z"
      fill="#F41740"
    />
    <path
      d="M36.4112 23.9289L34.9969 22.5146L33.5827 23.9289L34.9969 25.3431L36.4112 23.9289Z"
      fill="#FF64B4"
    />
    <path
      d="M35.0011 22.5255L33.5869 21.1113L32.1727 22.5255L33.5869 23.9398L35.0011 22.5255Z"
      fill="#F7345E"
    />
    <path
      d="M50.5142 40.8507L49.1 39.4365L47.6858 40.8507L49.1 42.265L50.5142 40.8507Z"
      fill="#ED3589"
    />
    <path
      d="M61.7488 32.4377L60.3346 31.0234L58.9204 32.4377L60.3346 33.8519L61.7488 32.4377Z"
      fill="#ED3589"
    />
    <path
      d="M60.3349 33.838L58.9207 32.4238L57.5064 33.838L58.9207 35.2523L60.3349 33.838Z"
      fill="#ED3589"
    />
    <path
      d="M58.935 35.2375L57.5208 33.8232L56.1065 35.2375L57.5208 36.6517L58.935 35.2375Z"
      fill="#ED3589"
    />
    <path
      d="M57.5351 36.6476L56.1208 35.2334L54.7066 36.6476L56.1208 38.0618L57.5351 36.6476Z"
      fill="#ED3589"
    />
    <path
      d="M56.121 38.048L54.7068 36.6338L53.2926 38.048L54.7068 39.4622L56.121 38.048Z"
      fill="#ED3589"
    />
    <path
      d="M54.7211 39.4474L53.3069 38.0332L51.8927 39.4474L53.3069 40.8616L54.7211 39.4474Z"
      fill="#ED3589"
    />
    <path
      d="M53.3212 40.8478L51.907 39.4336L50.4928 40.8478L51.907 42.262L53.3212 40.8478Z"
      fill="#ED3589"
    />
    <path
      d="M51.9171 42.258L50.5029 40.8438L49.0887 42.258L50.5029 43.6722L51.9171 42.258Z"
      fill="#ED3589"
    />
    <path
      d="M63.1618 33.8634L61.7476 32.4492L60.3333 33.8634L61.7476 35.2776L63.1618 33.8634Z"
      fill="black"
    />
    <path
      d="M61.7619 35.2628L60.3477 33.8486L58.9334 35.2628L60.3477 36.6771L61.7619 35.2628Z"
      fill="black"
    />
    <path
      d="M60.3549 36.6564L58.9407 35.2422L57.5265 36.6564L58.9407 38.0706L60.3549 36.6564Z"
      fill="black"
    />
    <path
      d="M58.9479 38.0734L57.5337 36.6592L56.1195 38.0734L57.5337 39.4876L58.9479 38.0734Z"
      fill="black"
    />
    <path
      d="M57.548 39.4728L56.1338 38.0586L54.7196 39.4728L56.1338 40.887L57.548 39.4728Z"
      fill="black"
    />
    <path
      d="M56.151 40.8664L54.7368 39.4521L53.3226 40.8664L54.7368 42.2806L56.151 40.8664Z"
      fill="black"
    />
    <path
      d="M54.744 42.2834L53.3298 40.8691L51.9156 42.2834L53.3298 43.6976L54.744 42.2834Z"
      fill="black"
    />
    <path
      d="M53.3441 43.6828L51.9299 42.2686L50.5157 43.6828L51.9299 45.097L53.3441 43.6828Z"
      fill="black"
    />
    <path
      d="M40.5818 11.2804L39.1676 9.86621L37.7534 11.2804L39.1676 12.6946L40.5818 11.2804Z"
      fill="black"
    />
    <path
      d="M39.1819 12.6798L37.7677 11.2656L36.3535 12.6798L37.7677 14.0941L39.1819 12.6798Z"
      fill="black"
    />
    <path
      d="M37.7849 14.0871L36.3707 12.6729L34.9565 14.0871L36.3707 15.5013L37.7849 14.0871Z"
      fill="black"
    />
    <path
      d="M36.378 15.4904L34.9637 14.0762L33.5495 15.4904L34.9637 16.9046L36.378 15.4904Z"
      fill="black"
    />
    <path
      d="M34.9781 16.8898L33.5638 15.4756L32.1496 16.8898L33.5638 18.304L34.9781 16.8898Z"
      fill="black"
    />
    <path
      d="M33.5711 18.297L32.1569 16.8828L30.7426 18.297L32.1569 19.7112L33.5711 18.297Z"
      fill="black"
    />
    <path
      d="M32.1641 19.7003L30.7499 18.2861L29.3357 19.7003L30.7499 21.1146L32.1641 19.7003Z"
      fill="black"
    />
    <path
      d="M30.7642 21.0998L29.35 19.6855L27.9358 21.0998L29.35 22.514L30.7642 21.0998Z"
      fill="black"
    />
    <path
      d="M49.1042 39.4367L47.6899 38.0225L46.2757 39.4367L47.6899 40.8509L49.1042 39.4367Z"
      fill="#F41740"
    />
    <path
      d="M47.6942 38.0324L46.28 36.6182L44.8658 38.0324L46.28 39.4466L47.6942 38.0324Z"
      fill="#F41740"
    />
    <path
      d="M46.2842 36.6183L44.87 35.2041L43.4558 36.6183L44.87 38.0325L46.2842 36.6183Z"
      fill="#F41740"
    />
    <path
      d="M44.8742 35.215L43.46 33.8008L42.0457 35.215L43.46 36.6292L44.8742 35.215Z"
      fill="#F41740"
    />
    <path
      d="M43.4643 33.8009L42.05 32.3867L40.6358 33.8009L42.05 35.2151L43.4643 33.8009Z"
      fill="#F41740"
    />
    <path
      d="M42.0542 32.3869L40.64 30.9727L39.2258 32.3869L40.64 33.8011L42.0542 32.3869Z"
      fill="#F41740"
    />
    <path
      d="M40.6442 30.9826L39.23 29.5684L37.8158 30.9826L39.23 32.3968L40.6442 30.9826Z"
      fill="#F41740"
    />
    <path
      d="M39.2342 29.5685L37.8199 28.1543L36.4057 29.5685L37.8199 30.9827L39.2342 29.5685Z"
      fill="#F41740"
    />
    <path
      d="M37.8242 28.1642L36.41 26.75L34.9958 28.1642L36.41 29.5784L37.8242 28.1642Z"
      fill="#F41740"
    />
    <path
      d="M36.4142 26.7502L35 25.3359L33.5858 26.7502L35 28.1644L36.4142 26.7502Z"
      fill="#F41740"
    />
    <path
      d="M35.0042 25.3468L33.59 23.9326L32.1758 25.3468L33.59 26.761L35.0042 25.3468Z"
      fill="#F41740"
    />
    <path
      d="M33.5941 23.9328L32.1799 22.5186L30.7657 23.9328L32.1799 25.347L33.5941 23.9328Z"
      fill="#FF64B4"
    />
    <path
      d="M42.0088 12.6916L40.5946 11.2773L39.1804 12.6916L40.5946 14.1058L42.0088 12.6916Z"
      fill="#FF8DCC"
    />
    <path
      d="M40.5948 14.1007L39.1805 12.6865L37.7663 14.1007L39.1805 15.515L40.5948 14.1007Z"
      fill="#FF8DCC"
    />
    <path
      d="M39.1949 15.5011L37.7806 14.0869L36.3664 15.5011L37.7806 16.9153L39.1949 15.5011Z"
      fill="#FF8DCC"
    />
    <path
      d="M37.7951 16.9015L36.3809 15.4873L34.9666 16.9015L36.3809 18.3157L37.7951 16.9015Z"
      fill="#FF8DCC"
    />
    <path
      d="M36.381 18.3117L34.9668 16.8975L33.5526 18.3117L34.9668 19.7259L36.381 18.3117Z"
      fill="#FF8DCC"
    />
    <path
      d="M34.9811 19.7111L33.5669 18.2969L32.1527 19.7111L33.5669 21.1253L34.9811 19.7111Z"
      fill="#FF8DCC"
    />
    <path
      d="M33.5812 21.1115L32.167 19.6973L30.7528 21.1115L32.167 22.5257L33.5812 21.1115Z"
      fill="#FF8DCC"
    />
    <path
      d="M32.1772 22.5109L30.7629 21.0967L29.3487 22.5109L30.7629 23.9251L32.1772 22.5109Z"
      fill="#FF64B4"
    />
    <path
      d="M49.1072 42.258L47.693 40.8438L46.2788 42.258L47.693 43.6722L49.1072 42.258Z"
      fill="#ED3589"
    />
    <path
      d="M50.5172 43.6574L49.103 42.2432L47.6888 43.6574L49.103 45.0716L50.5172 43.6574Z"
      fill="black"
    />
    <path
      d="M47.6972 40.8537L46.283 39.4395L44.8687 40.8537L46.283 42.2679L47.6972 40.8537Z"
      fill="#ED3589"
    />
    <path
      d="M46.2873 39.4396L44.873 38.0254L43.4588 39.4396L44.873 40.8538L46.2873 39.4396Z"
      fill="#ED3589"
    />
    <path
      d="M44.8772 38.0353L43.463 36.6211L42.0488 38.0353L43.463 39.4495L44.8772 38.0353Z"
      fill="#ED3589"
    />
    <path
      d="M43.4743 36.6144L42.0601 35.2002L40.6458 36.6144L42.0601 38.0286L43.4743 36.6144Z"
      fill="#ED3589"
    />
    <path
      d="M42.0644 35.2003L40.6501 33.7861L39.2359 35.2003L40.6501 36.6146L42.0644 35.2003Z"
      fill="#ED3589"
    />
    <path
      d="M40.6543 33.797L39.2401 32.3828L37.8259 33.797L39.2401 35.2112L40.6543 33.797Z"
      fill="#ED3589"
    />
    <path
      d="M39.2372 32.3752L37.823 30.9609L36.4088 32.3752L37.823 33.7894L39.2372 32.3752Z"
      fill="#ED3589"
    />
    <path
      d="M37.8272 30.9718L36.413 29.5576L34.9988 30.9718L36.413 32.386L37.8272 30.9718Z"
      fill="#ED3589"
    />
    <path
      d="M36.4173 29.5578L35.0031 28.1436L33.5888 29.5578L35.0031 30.972L36.4173 29.5578Z"
      fill="#ED3589"
    />
    <path
      d="M35.0072 28.1535L33.593 26.7393L32.1788 28.1535L33.593 29.5677L35.0072 28.1535Z"
      fill="#ED3589"
    />
    <path
      d="M33.5972 26.7394L32.183 25.3252L30.7688 26.7394L32.183 28.1536L33.5972 26.7394Z"
      fill="#ED3589"
    />
    <path
      d="M32.1873 25.3253L30.7731 23.9111L29.3589 25.3253L30.7731 26.7396L32.1873 25.3253Z"
      fill="#ED3589"
    />
    <path
      d="M30.7773 23.921L29.363 22.5068L27.9488 23.921L29.363 25.3353L30.7773 23.921Z"
      fill="black"
    />
    <path
      d="M47.6961 43.671L46.2819 42.2568L44.8676 43.671L46.2819 45.0853L47.6961 43.671Z"
      fill="black"
    />
    <path
      d="M46.2862 42.257L44.8719 40.8428L43.4577 42.257L44.8719 43.6712L46.2862 42.257Z"
      fill="black"
    />
    <path
      d="M44.8761 40.8527L43.4619 39.4385L42.0477 40.8527L43.4619 42.2669L44.8761 40.8527Z"
      fill="black"
    />
    <path
      d="M43.4661 39.4386L42.0519 38.0244L40.6377 39.4386L42.0519 40.8528L43.4661 39.4386Z"
      fill="black"
    />
    <path
      d="M42.0561 38.0246L40.6418 36.6104L39.2276 38.0246L40.6418 39.4388L42.0561 38.0246Z"
      fill="black"
    />
    <path
      d="M40.6461 36.6203L39.2319 35.2061L37.8177 36.6203L39.2319 38.0345L40.6461 36.6203Z"
      fill="black"
    />
    <path
      d="M39.2361 35.2062L37.8219 33.792L36.4077 35.2062L37.8219 36.6204L39.2361 35.2062Z"
      fill="black"
    />
    <path
      d="M37.8261 33.8029L36.4119 32.3887L34.9977 33.8029L36.4119 35.2171L37.8261 33.8029Z"
      fill="black"
    />
    <path
      d="M36.4162 32.3888L35.002 30.9746L33.5877 32.3888L35.002 33.803L36.4162 32.3888Z"
      fill="black"
    />
    <path
      d="M34.9961 30.9845L33.5819 29.5703L32.1677 30.9845L33.5819 32.3987L34.9961 30.9845Z"
      fill="black"
    />
    <path
      d="M33.5861 29.5705L32.1719 28.1562L30.7577 29.5705L32.1719 30.9847L33.5861 29.5705Z"
      fill="black"
    />
    <path
      d="M32.1831 28.1496L30.7689 26.7354L29.3547 28.1496L30.7689 29.5638L32.1831 28.1496Z"
      fill="black"
    />
    <path
      d="M30.7732 26.7453L29.359 25.3311L27.9448 26.7453L29.359 28.1595L30.7732 26.7453Z"
      fill="black"
    />
    <path
      d="M45.8699 37.4297H43.8699V39.4297H45.8699V37.4297Z"
      fill="#FE4F99"
    />
    <path d="M47.8499 37.4297H45.8499V39.4297H47.8499V37.4297Z" fill="black" />
    <path
      d="M43.8699 37.4297H41.8699V39.4297H43.8699V37.4297Z"
      fill="#FE4F99"
    />
    <path
      d="M41.8799 37.4297H39.8799V39.4297H41.8799V37.4297Z"
      fill="#FE4F99"
    />
    <path
      d="M39.8799 37.4297H37.8799V39.4297H39.8799V37.4297Z"
      fill="#FE4F99"
    />
    <path
      d="M37.8899 37.4297H35.8899V39.4297H37.8899V37.4297Z"
      fill="#FE4F99"
    />
    <path
      d="M35.8899 37.4297H33.8899V39.4297H35.8899V37.4297Z"
      fill="#FE4F99"
    />
    <path
      d="M33.8999 37.4297H31.8999V39.4297H33.8999V37.4297Z"
      fill="#FE4F99"
    />
    <path
      d="M31.8999 37.4297H29.8999V39.4297H31.8999V37.4297Z"
      fill="#FF8DCC"
    />
    <path
      d="M29.9099 37.4297H27.9099V39.4297H29.9099V37.4297Z"
      fill="#FF8DCC"
    />
    <path
      d="M27.9099 37.4297H25.9099V39.4297H27.9099V37.4297Z"
      fill="#FF8DCC"
    />
    <path
      d="M25.9199 37.4297H23.9199V39.4297H25.9199V37.4297Z"
      fill="#FF8DCC"
    />
    <path
      d="M23.9299 37.4297H21.9299V39.4297H23.9299V37.4297Z"
      fill="#FF8DCC"
    />
    <path
      d="M21.9299 37.4297H19.9299V39.4297H21.9299V37.4297Z"
      fill="#FF8DCC"
    />
    <path d="M19.9399 37.4297H17.9399V39.4297H19.9399V37.4297Z" fill="black" />
    <path d="M45.8599 35.4297H43.8599V37.4297H45.8599V35.4297Z" fill="black" />
    <path d="M43.8699 35.4297H41.8699V37.4297H43.8699V35.4297Z" fill="black" />
    <path d="M41.8699 35.4297H39.8699V37.4297H41.8699V35.4297Z" fill="black" />
    <path d="M39.8799 35.4297H37.8799V37.4297H39.8799V35.4297Z" fill="black" />
    <path d="M37.8799 35.4297H35.8799V37.4297H37.8799V35.4297Z" fill="black" />
    <path d="M35.8899 35.4297H33.8899V37.4297H35.8899V35.4297Z" fill="black" />
    <path d="M33.8899 35.4297H31.8899V37.4297H33.8899V35.4297Z" fill="black" />
    <path d="M31.8999 35.4297H29.8999V37.4297H31.8999V35.4297Z" fill="black" />
    <path d="M29.8999 35.4297H27.8999V37.4297H29.8999V35.4297Z" fill="black" />
    <path d="M27.9099 35.4297H25.9099V37.4297H27.9099V35.4297Z" fill="black" />
    <path d="M25.9099 35.4297H23.9099V37.4297H25.9099V35.4297Z" fill="black" />
    <path d="M23.9199 35.4297H21.9199V37.4297H23.9199V35.4297Z" fill="black" />
    <path d="M21.9299 35.4297H19.9299V37.4297H21.9299V35.4297Z" fill="black" />
    <path
      d="M45.8699 39.4102H43.8699V41.4102H45.8699V39.4102Z"
      fill="#FE4F99"
    />
    <path
      d="M43.8699 39.4102H41.8699V41.4102H43.8699V39.4102Z"
      fill="#F41740"
    />
    <path
      d="M41.8799 39.4102H39.8799V41.4102H41.8799V39.4102Z"
      fill="#F41740"
    />
    <path
      d="M39.8799 39.4102H37.8799V41.4102H39.8799V39.4102Z"
      fill="#F41740"
    />
    <path
      d="M37.8899 39.4102H35.8899V41.4102H37.8899V39.4102Z"
      fill="#F41740"
    />
    <path
      d="M35.8899 39.4102H33.8899V41.4102H35.8899V39.4102Z"
      fill="#F41740"
    />
    <path
      d="M33.8999 39.4102H31.8999V41.4102H33.8999V39.4102Z"
      fill="#F41740"
    />
    <path
      d="M31.8999 39.4102H29.8999V41.4102H31.8999V39.4102Z"
      fill="#F7345E"
    />
    <path
      d="M29.9099 39.4102H27.9099V41.4102H29.9099V39.4102Z"
      fill="#F7345E"
    />
    <path
      d="M27.9099 39.4102H25.9099V41.4102H27.9099V39.4102Z"
      fill="#F7345E"
    />
    <path
      d="M25.9199 39.4102H23.9199V41.4102H25.9199V39.4102Z"
      fill="#F7345E"
    />
    <path
      d="M23.9299 39.4102H21.9299V41.4102H23.9299V39.4102Z"
      fill="#F7345E"
    />
    <path
      d="M21.9299 39.4102H19.9299V41.4102H21.9299V39.4102Z"
      fill="#FF8DCC"
    />
    <path
      d="M45.8699 41.3896H43.8699V43.3896H45.8699V41.3896Z"
      fill="#F41740"
    />
    <path
      d="M43.8699 41.3896H41.8699V43.3896H43.8699V41.3896Z"
      fill="#FE4F99"
    />
    <path
      d="M41.8799 41.3896H39.8799V43.3896H41.8799V41.3896Z"
      fill="#F41740"
    />
    <path
      d="M39.8799 41.3896H37.8799V43.3896H39.8799V41.3896Z"
      fill="#FE4F99"
    />
    <path
      d="M37.8899 41.3896H35.8899V43.3896H37.8899V41.3896Z"
      fill="#FE4F99"
    />
    <path
      d="M35.8899 41.3896H33.8899V43.3896H35.8899V41.3896Z"
      fill="#FE4F99"
    />
    <path
      d="M33.8999 41.3896H31.8999V43.3896H33.8999V41.3896Z"
      fill="#FE4F99"
    />
    <path
      d="M31.8999 41.3896H29.8999V43.3896H31.8999V41.3896Z"
      fill="#FD64B6"
    />
    <path
      d="M29.9099 41.3896H27.9099V43.3896H29.9099V41.3896Z"
      fill="#FD64B6"
    />
    <path
      d="M27.9099 41.3896H25.9099V43.3896H27.9099V41.3896Z"
      fill="#FD64B6"
    />
    <path
      d="M25.9199 41.3896H23.9199V43.3896H25.9199V41.3896Z"
      fill="#F41740"
    />
    <path
      d="M23.9299 41.3896H21.9299V43.3896H23.9299V41.3896Z"
      fill="#FF64B4"
    />
    <path
      d="M21.9299 41.3896H19.9299V43.3896H21.9299V41.3896Z"
      fill="#F7345E"
    />
    <path
      d="M45.8699 43.3799H43.8699V45.3799H45.8699V43.3799Z"
      fill="#F41740"
    />
    <path
      d="M43.8699 43.3799H41.8699V45.3799H43.8699V43.3799Z"
      fill="#FE4F99"
    />
    <path
      d="M41.8799 43.3799H39.8799V45.3799H41.8799V43.3799Z"
      fill="#F41740"
    />
    <path
      d="M39.8799 43.3799H37.8799V45.3799H39.8799V43.3799Z"
      fill="#FE4F99"
    />
    <path
      d="M37.8899 43.3799H35.8899V45.3799H37.8899V43.3799Z"
      fill="#9D0B27"
    />
    <path
      d="M35.8899 43.3799H33.8899V45.3799H35.8899V43.3799Z"
      fill="#F63273"
    />
    <path
      d="M33.8999 43.3799H31.8999V45.3799H33.8999V43.3799Z"
      fill="#9D0B27"
    />
    <path
      d="M31.8999 43.3799H29.8999V45.3799H31.8999V43.3799Z"
      fill="#FF65AD"
    />
    <path
      d="M29.9099 43.3799H27.9099V45.3799H29.9099V43.3799Z"
      fill="#9D0B27"
    />
    <path
      d="M27.9099 43.3799H25.9099V45.3799H27.9099V43.3799Z"
      fill="#BE0820"
    />
    <path
      d="M25.9199 43.3799H23.9199V45.3799H25.9199V43.3799Z"
      fill="#F41740"
    />
    <path
      d="M23.9299 43.3799H21.9299V45.3799H23.9299V43.3799Z"
      fill="#FF64B4"
    />
    <path
      d="M21.9299 43.3799H19.9299V45.3799H21.9299V43.3799Z"
      fill="#F7345E"
    />
    <path
      d="M45.8699 45.3604H43.8699V47.3604H45.8699V45.3604Z"
      fill="#F41740"
    />
    <path
      d="M43.8699 45.3604H41.8699V47.3604H43.8699V45.3604Z"
      fill="#FE4F99"
    />
    <path
      d="M41.8799 45.3604H39.8799V47.3604H41.8799V45.3604Z"
      fill="#F41740"
    />
    <path
      d="M39.8799 45.3604H37.8799V47.3604H39.8799V45.3604Z"
      fill="#FE4F99"
    />
    <path
      d="M37.8899 45.3604H35.8899V47.3604H37.8899V45.3604Z"
      fill="#F61340"
    />
    <path
      d="M35.8899 45.3604H33.8899V47.3604H35.8899V45.3604Z"
      fill="#FE4F99"
    />
    <path
      d="M33.8999 45.3604H31.8999V47.3604H33.8999V45.3604Z"
      fill="#9D0B27"
    />
    <path
      d="M31.8999 45.3604H29.8999V47.3604H31.8999V45.3604Z"
      fill="#F63273"
    />
    <path
      d="M29.9099 45.3604H27.9099V47.3604H29.9099V45.3604Z"
      fill="#F63273"
    />
    <path
      d="M27.9099 45.3604H25.9099V47.3604H27.9099V45.3604Z"
      fill="#FD64B6"
    />
    <path
      d="M25.9199 45.3604H23.9199V47.3604H25.9199V45.3604Z"
      fill="#F41740"
    />
    <path
      d="M23.9299 45.3604H21.9299V47.3604H23.9299V45.3604Z"
      fill="#FF64B4"
    />
    <path
      d="M21.9299 45.3604H19.9299V47.3604H21.9299V45.3604Z"
      fill="#F7345E"
    />
    <path
      d="M45.8699 47.3506H43.8699V49.3506H45.8699V47.3506Z"
      fill="#F41740"
    />
    <path
      d="M43.8699 47.3506H41.8699V49.3506H43.8699V47.3506Z"
      fill="#FE4F99"
    />
    <path
      d="M41.8799 47.3506H39.8799V49.3506H41.8799V47.3506Z"
      fill="#F41740"
    />
    <path
      d="M39.8799 47.3506H37.8799V49.3506H39.8799V47.3506Z"
      fill="#FE4F99"
    />
    <path
      d="M37.8899 47.3506H35.8899V49.3506H37.8899V47.3506Z"
      fill="#FE4F99"
    />
    <path
      d="M35.8899 47.3506H33.8899V49.3506H35.8899V47.3506Z"
      fill="#FE4F99"
    />
    <path
      d="M33.8999 47.3506H31.8999V49.3506H33.8999V47.3506Z"
      fill="#FE4F99"
    />
    <path
      d="M31.8999 47.3506H29.8999V49.3506H31.8999V47.3506Z"
      fill="#FD64B6"
    />
    <path
      d="M29.9099 47.3506H27.9099V49.3506H29.9099V47.3506Z"
      fill="#FD64B6"
    />
    <path
      d="M27.9099 47.3506H25.9099V49.3506H27.9099V47.3506Z"
      fill="#FD64B6"
    />
    <path
      d="M25.9199 47.3506H23.9199V49.3506H25.9199V47.3506Z"
      fill="#F41740"
    />
    <path
      d="M23.9299 47.3506H21.9299V49.3506H23.9299V47.3506Z"
      fill="#FF64B4"
    />
    <path
      d="M21.9299 47.3506H19.9299V49.3506H21.9299V47.3506Z"
      fill="#F7345E"
    />
    <path
      d="M45.8699 49.3301H43.8699V51.3301H45.8699V49.3301Z"
      fill="#F41740"
    />
    <path
      d="M43.8699 49.3301H41.8699V51.3301H43.8699V49.3301Z"
      fill="#FE4F99"
    />
    <path
      d="M41.8799 49.3301H39.8799V51.3301H41.8799V49.3301Z"
      fill="#F41740"
    />
    <path
      d="M39.8799 49.3301H37.8799V51.3301H39.8799V49.3301Z"
      fill="#FE4F99"
    />
    <path
      d="M37.8899 49.3301H35.8899V51.3301H37.8899V49.3301Z"
      fill="#BE0820"
    />
    <path
      d="M35.8899 49.3301H33.8899V51.3301H35.8899V49.3301Z"
      fill="#F61044"
    />
    <path
      d="M33.8999 49.3301H31.8999V51.3301H33.8999V49.3301Z"
      fill="#F21548"
    />
    <path
      d="M31.8999 49.3301H29.8999V51.3301H31.8999V49.3301Z"
      fill="#F21548"
    />
    <path
      d="M29.9099 49.3301H27.9099V51.3301H29.9099V49.3301Z"
      fill="#F93A77"
    />
    <path
      d="M27.9099 49.3301H25.9099V51.3301H27.9099V49.3301Z"
      fill="#FD64B6"
    />
    <path
      d="M25.9199 49.3301H23.9199V51.3301H25.9199V49.3301Z"
      fill="#F41740"
    />
    <path
      d="M23.9299 49.3301H21.9299V51.3301H23.9299V49.3301Z"
      fill="#FF64B4"
    />
    <path
      d="M21.9299 49.3301H19.9299V51.3301H21.9299V49.3301Z"
      fill="#F7345E"
    />
    <path
      d="M45.8699 51.3203H43.8699V53.3203H45.8699V51.3203Z"
      fill="#F41740"
    />
    <path
      d="M43.8699 51.3203H41.8699V53.3203H43.8699V51.3203Z"
      fill="#FE4F99"
    />
    <path
      d="M41.8799 51.3203H39.8799V53.3203H41.8799V51.3203Z"
      fill="#F41740"
    />
    <path
      d="M39.8799 51.3203H37.8799V53.3203H39.8799V51.3203Z"
      fill="#FE4F99"
    />
    <path
      d="M37.8899 51.3203H35.8899V53.3203H37.8899V51.3203Z"
      fill="#FE4F99"
    />
    <path
      d="M35.8899 51.3203H33.8899V53.3203H35.8899V51.3203Z"
      fill="#F61044"
    />
    <path
      d="M33.8999 51.3203H31.8999V53.3203H33.8999V51.3203Z"
      fill="#BE0820"
    />
    <path
      d="M31.8999 51.3203H29.8999V53.3203H31.8999V51.3203Z"
      fill="#BE0820"
    />
    <path
      d="M29.9099 51.3203H27.9099V53.3203H29.9099V51.3203Z"
      fill="#F61340"
    />
    <path
      d="M27.9099 51.3203H25.9099V53.3203H27.9099V51.3203Z"
      fill="#FD64B6"
    />
    <path
      d="M25.9199 51.3203H23.9199V53.3203H25.9199V51.3203Z"
      fill="#F41740"
    />
    <path
      d="M23.9299 51.3203H21.9299V53.3203H23.9299V51.3203Z"
      fill="#FF64B4"
    />
    <path
      d="M21.9299 51.3203H19.9299V53.3203H21.9299V51.3203Z"
      fill="#F7345E"
    />
    <path
      d="M45.8699 53.2998H43.8699V55.2998H45.8699V53.2998Z"
      fill="#ED3589"
    />
    <path
      d="M47.8499 39.4102H45.8499V41.4102H47.8499V39.4102Z"
      fill="#ED3589"
    />
    <path
      d="M47.8499 41.3896H45.8499V43.3896H47.8499V41.3896Z"
      fill="#ED3589"
    />
    <path
      d="M47.8499 43.3799H45.8499V45.3799H47.8499V43.3799Z"
      fill="#ED3589"
    />
    <path
      d="M47.8499 45.3604H45.8499V47.3604H47.8499V45.3604Z"
      fill="#ED3589"
    />
    <path
      d="M47.8499 47.3506H45.8499V49.3506H47.8499V47.3506Z"
      fill="#ED3589"
    />
    <path
      d="M47.8499 49.3301H45.8499V51.3301H47.8499V49.3301Z"
      fill="#ED3589"
    />
    <path
      d="M47.8499 51.3203H45.8499V53.3203H47.8499V51.3203Z"
      fill="#ED3589"
    />
    <path
      d="M47.8499 53.2998H45.8499V55.2998H47.8499V53.2998Z"
      fill="#ED3589"
    />
    <path d="M49.8599 39.4102H47.8599V41.4102H49.8599V39.4102Z" fill="black" />
    <path d="M49.8599 41.3896H47.8599V43.3896H49.8599V41.3896Z" fill="black" />
    <path d="M49.8599 43.3799H47.8599V45.3799H49.8599V43.3799Z" fill="black" />
    <path d="M49.8599 45.3604H47.8599V47.3604H49.8599V45.3604Z" fill="black" />
    <path d="M49.8599 47.3506H47.8599V49.3506H49.8599V47.3506Z" fill="black" />
    <path d="M49.8599 49.3301H47.8599V51.3301H49.8599V49.3301Z" fill="black" />
    <path d="M49.8599 51.3203H47.8599V53.3203H49.8599V51.3203Z" fill="black" />
    <path d="M49.8599 53.2998H47.8599V55.2998H49.8599V53.2998Z" fill="black" />
    <path d="M17.9399 39.4102H15.9399V41.4102H17.9399V39.4102Z" fill="black" />
    <path d="M17.9399 41.3896H15.9399V43.3896H17.9399V41.3896Z" fill="black" />
    <path d="M17.9399 43.3799H15.9399V45.3799H17.9399V43.3799Z" fill="black" />
    <path d="M17.9399 45.3604H15.9399V47.3604H17.9399V45.3604Z" fill="black" />
    <path d="M17.9399 47.3506H15.9399V49.3506H17.9399V47.3506Z" fill="black" />
    <path d="M17.9399 49.3301H15.9399V51.3301H17.9399V49.3301Z" fill="black" />
    <path d="M17.9399 51.3203H15.9399V53.3203H17.9399V51.3203Z" fill="black" />
    <path d="M17.9399 53.2998H15.9399V55.2998H17.9399V53.2998Z" fill="black" />
    <path
      d="M43.8699 53.2998H41.8699V55.2998H43.8699V53.2998Z"
      fill="#F41740"
    />
    <path
      d="M41.8799 53.2998H39.8799V55.2998H41.8799V53.2998Z"
      fill="#F41740"
    />
    <path
      d="M39.8799 53.2998H37.8799V55.2998H39.8799V53.2998Z"
      fill="#F41740"
    />
    <path
      d="M37.8899 53.2998H35.8899V55.2998H37.8899V53.2998Z"
      fill="#F41740"
    />
    <path
      d="M35.8899 53.2998H33.8899V55.2998H35.8899V53.2998Z"
      fill="#F41740"
    />
    <path
      d="M33.8999 53.2998H31.8999V55.2998H33.8999V53.2998Z"
      fill="#F41740"
    />
    <path
      d="M31.8999 53.2998H29.8999V55.2998H31.8999V53.2998Z"
      fill="#F41740"
    />
    <path
      d="M29.9099 53.2998H27.9099V55.2998H29.9099V53.2998Z"
      fill="#F41740"
    />
    <path
      d="M27.9099 53.2998H25.9099V55.2998H27.9099V53.2998Z"
      fill="#F41740"
    />
    <path
      d="M25.9199 53.2998H23.9199V55.2998H25.9199V53.2998Z"
      fill="#F41740"
    />
    <path
      d="M23.9299 53.2998H21.9299V55.2998H23.9299V53.2998Z"
      fill="#F41740"
    />
    <path
      d="M21.9299 53.2998H19.9299V55.2998H21.9299V53.2998Z"
      fill="#FF64B4"
    />
    <path
      d="M19.9399 39.4102H17.9399V41.4102H19.9399V39.4102Z"
      fill="#FF8DCC"
    />
    <path
      d="M19.9399 41.3896H17.9399V43.3896H19.9399V41.3896Z"
      fill="#FF8DCC"
    />
    <path
      d="M19.9399 43.3799H17.9399V45.3799H19.9399V43.3799Z"
      fill="#FF8DCC"
    />
    <path
      d="M19.9399 45.3604H17.9399V47.3604H19.9399V45.3604Z"
      fill="#FF8DCC"
    />
    <path
      d="M19.9399 47.3506H17.9399V49.3506H19.9399V47.3506Z"
      fill="#FF8DCC"
    />
    <path
      d="M19.9399 49.3301H17.9399V51.3301H19.9399V49.3301Z"
      fill="#FF8DCC"
    />
    <path
      d="M19.9399 51.3203H17.9399V53.3203H19.9399V51.3203Z"
      fill="#FF8DCC"
    />
    <path
      d="M19.9399 53.2998H17.9399V55.2998H19.9399V53.2998Z"
      fill="#FF64B4"
    />
    <path d="M45.8699 55.29H43.8699V57.29H45.8699V55.29Z" fill="#ED3589" />
    <path d="M47.8499 55.29H45.8499V57.29H47.8499V55.29Z" fill="black" />
    <path d="M43.8699 55.29H41.8699V57.29H43.8699V55.29Z" fill="#ED3589" />
    <path d="M41.8799 55.29H39.8799V57.29H41.8799V55.29Z" fill="#ED3589" />
    <path d="M39.8799 55.29H37.8799V57.29H39.8799V55.29Z" fill="#ED3589" />
    <path d="M37.8899 55.29H35.8899V57.29H37.8899V55.29Z" fill="#ED3589" />
    <path d="M35.8899 55.29H33.8899V57.29H35.8899V55.29Z" fill="#ED3589" />
    <path d="M33.8999 55.29H31.8999V57.29H33.8999V55.29Z" fill="#ED3589" />
    <path d="M31.8999 55.29H29.8999V57.29H31.8999V55.29Z" fill="#ED3589" />
    <path d="M29.9099 55.29H27.9099V57.29H29.9099V55.29Z" fill="#ED3589" />
    <path d="M27.9099 55.29H25.9099V57.29H27.9099V55.29Z" fill="#ED3589" />
    <path d="M25.9199 55.29H23.9199V57.29H25.9199V55.29Z" fill="#ED3589" />
    <path d="M23.9299 55.29H21.9299V57.29H23.9299V55.29Z" fill="#ED3589" />
    <path d="M21.9299 55.29H19.9299V57.29H21.9299V55.29Z" fill="#ED3589" />
    <path d="M19.9399 55.29H17.9399V57.29H19.9399V55.29Z" fill="black" />
    <path d="M45.8699 57.29H43.8699V59.29H45.8699V57.29Z" fill="black" />
    <path d="M43.8699 57.29H41.8699V59.29H43.8699V57.29Z" fill="black" />
    <path d="M41.8799 57.29H39.8799V59.29H41.8799V57.29Z" fill="black" />
    <path d="M39.8799 57.29H37.8799V59.29H39.8799V57.29Z" fill="black" />
    <path d="M37.8899 57.29H35.8899V59.29H37.8899V57.29Z" fill="black" />
    <path d="M35.8899 57.29H33.8899V59.29H35.8899V57.29Z" fill="black" />
    <path d="M33.8999 57.29H31.8999V59.29H33.8999V57.29Z" fill="black" />
    <path d="M31.8999 57.29H29.8999V59.29H31.8999V57.29Z" fill="black" />
    <path d="M29.9099 57.29H27.9099V59.29H29.9099V57.29Z" fill="black" />
    <path d="M27.9099 57.29H25.9099V59.29H27.9099V57.29Z" fill="black" />
    <path d="M25.9199 57.29H23.9199V59.29H25.9199V57.29Z" fill="black" />
    <path d="M23.9299 57.29H21.9299V59.29H23.9299V57.29Z" fill="black" />
    <path d="M21.9299 57.29H19.9299V59.29H21.9299V57.29Z" fill="black" />
    <path
      d="M16.8199 11.1299H15.9399V12.0099H16.8199V11.1299Z"
      fill="#FDAE35"
    />
    <path d="M16.8199 12H15.9399V12.88H16.8199V12Z" fill="#FDE962" />
    <path d="M15.9399 12H15.0599V12.88H15.9399V12Z" fill="#FDAE35" />
    <path
      d="M16.8199 12.8799H15.9399V13.7599H16.8199V12.8799Z"
      fill="#FDE962"
    />
    <path
      d="M15.9399 12.8799H15.0599V13.7599H15.9399V12.8799Z"
      fill="#FDAE35"
    />
    <path
      d="M16.8199 13.7598H15.9399V14.6398H16.8199V13.7598Z"
      fill="#FDE962"
    />
    <path
      d="M15.9399 13.7598H15.0599V14.6398H15.9399V13.7598Z"
      fill="#FDE962"
    />
    <path
      d="M16.8199 14.6396H15.9399V15.5196H16.8199V14.6396Z"
      fill="#FDE962"
    />
    <path
      d="M15.9399 14.6396H15.0599V15.5196H15.9399V14.6396Z"
      fill="#FDE962"
    />
    <path
      d="M15.0599 13.7598H14.1799V14.6398H15.0599V13.7598Z"
      fill="#FDAE35"
    />
    <path
      d="M15.0599 14.6396H14.1799V15.5196H15.0599V14.6396Z"
      fill="#FDAE35"
    />
    <path
      d="M16.8199 15.5205H15.9399V16.4005H16.8199V15.5205Z"
      fill="#EBEFF0"
    />
    <path
      d="M15.9399 15.5205H15.0599V16.4005H15.9399V15.5205Z"
      fill="#FDE962"
    />
    <path
      d="M15.0599 15.5205H14.1799V16.4005H15.0599V15.5205Z"
      fill="#FDE962"
    />
    <path
      d="M14.1799 15.5205H13.2999V16.4005H14.1799V15.5205Z"
      fill="#FDAE35"
    />
    <path
      d="M13.3099 15.5205H12.4299V16.4005H13.3099V15.5205Z"
      fill="#FDAE35"
    />
    <path
      d="M15.0599 16.4004H14.1799V17.2804H15.0599V16.4004Z"
      fill="#FDE962"
    />
    <path
      d="M14.1799 16.4004H13.2999V17.2804H14.1799V16.4004Z"
      fill="#FDE962"
    />
    <path
      d="M13.3099 16.4004H12.4299V17.2804H13.3099V16.4004Z"
      fill="#FDAE35"
    />
    <path
      d="M12.4299 16.4004H11.5499V17.2804H12.4299V16.4004Z"
      fill="#FDAE35"
    />
    <path
      d="M11.5499 16.4004H10.6699V17.2804H11.5499V16.4004Z"
      fill="#FDAE35"
    />
    <path
      d="M16.8199 16.4004H15.9399V17.2804H16.8199V16.4004Z"
      fill="#EBEFF0"
    />
    <path
      d="M15.9399 16.4004H15.0599V17.2804H15.9399V16.4004Z"
      fill="#FDE962"
    />
    <path
      d="M15.0599 17.2705H14.1799V18.1505H15.0599V17.2705Z"
      fill="#EBEFF0"
    />
    <path
      d="M14.1799 17.2705H13.2999V18.1505H14.1799V17.2705Z"
      fill="#FDE962"
    />
    <path
      d="M13.3099 17.2705H12.4299V18.1505H13.3099V17.2705Z"
      fill="#FDE962"
    />
    <path
      d="M12.4299 17.2705H11.5499V18.1505H12.4299V17.2705Z"
      fill="#FDE962"
    />
    <path
      d="M11.5499 17.2705H10.6699V18.1505H11.5499V17.2705Z"
      fill="#FDAE35"
    />
    <path
      d="M10.6699 17.2705H9.78992V18.1505H10.6699V17.2705Z"
      fill="#FDAE35"
    />
    <path
      d="M9.78991 17.2705H8.90991V18.1505H9.78991V17.2705Z"
      fill="#FDAE35"
    />
    <path
      d="M16.8199 17.2705H15.9399V18.1505H16.8199V17.2705Z"
      fill="#EBEFF0"
    />
    <path
      d="M15.9399 17.2705H15.0599V18.1505H15.9399V17.2705Z"
      fill="#EBEFF0"
    />
    <path d="M16.8199 10.25H15.9399V11.13H16.8199V10.25Z" fill="#FDAE35" />
    <path
      d="M16.8199 9.37012H15.9399V10.2501H16.8199V9.37012Z"
      fill="#FDAE35"
    />
    <path d="M15.9399 12H16.8199V11.12H15.9399V12Z" fill="#FDAE35" />
    <path
      d="M15.9399 12.8896H16.8199V12.0096H15.9399V12.8896Z"
      fill="#FDE962"
    />
    <path
      d="M16.8099 12.8896H17.6899V12.0096H16.8099V12.8896Z"
      fill="#FDAE35"
    />
    <path
      d="M15.9399 13.7598H16.8199V12.8798H15.9399V13.7598Z"
      fill="#FDE962"
    />
    <path
      d="M16.8099 13.7598H17.6899V12.8798H16.8099V13.7598Z"
      fill="#FDAE35"
    />
    <path
      d="M15.9399 14.6396H16.8199V13.7596H15.9399V14.6396Z"
      fill="#FDE962"
    />
    <path
      d="M16.8099 14.6396H17.6899V13.7596H16.8099V14.6396Z"
      fill="#FDE962"
    />
    <path
      d="M15.9399 15.5205H16.8199V14.6405H15.9399V15.5205Z"
      fill="#FDE962"
    />
    <path
      d="M16.8099 15.5205H17.6899V14.6405H16.8099V15.5205Z"
      fill="#FDE962"
    />
    <path
      d="M17.6899 14.6396H18.5699V13.7596H17.6899V14.6396Z"
      fill="#FDAE35"
    />
    <path
      d="M17.6899 15.5205H18.5699V14.6405H17.6899V15.5205Z"
      fill="#FDAE35"
    />
    <path
      d="M15.9399 16.3896H16.8199V15.5096H15.9399V16.3896Z"
      fill="#EBEFF0"
    />
    <path
      d="M16.8099 16.3896H17.6899V15.5096H16.8099V16.3896Z"
      fill="#FDE962"
    />
    <path
      d="M17.6899 16.3896H18.5699V15.5096H17.6899V16.3896Z"
      fill="#FDE962"
    />
    <path d="M18.58 16.3896H19.46V15.5096H18.58V16.3896Z" fill="#FDAE35" />
    <path d="M19.45 16.3896H20.33V15.5096H19.45V16.3896Z" fill="#FDAE35" />
    <path
      d="M17.6899 17.2705H18.5699V16.3905H17.6899V17.2705Z"
      fill="#FDE962"
    />
    <path d="M18.58 17.2705H19.46V16.3905H18.58V17.2705Z" fill="#FDE962" />
    <path d="M19.45 17.2705H20.33V16.3905H19.45V17.2705Z" fill="#FDAE35" />
    <path d="M20.33 17.2705H21.21V16.3905H20.33V17.2705Z" fill="#FDAE35" />
    <path d="M21.21 17.2705H22.09V16.3905H21.21V17.2705Z" fill="#FDAE35" />
    <path
      d="M15.9399 17.2705H16.8199V16.3905H15.9399V17.2705Z"
      fill="#EBEFF0"
    />
    <path
      d="M16.8099 17.2705H17.6899V16.3905H16.8099V17.2705Z"
      fill="#FDE962"
    />
    <path
      d="M17.6899 18.1602H18.5699V17.2802H17.6899V18.1602Z"
      fill="#EBEFF0"
    />
    <path d="M18.58 18.1602H19.46V17.2802H18.58V18.1602Z" fill="#FDE962" />
    <path d="M19.45 18.1602H20.33V17.2802H19.45V18.1602Z" fill="#FDE962" />
    <path d="M20.33 18.1602H21.21V17.2802H20.33V18.1602Z" fill="#FDE962" />
    <path d="M21.21 18.1602H22.09V17.2802H21.21V18.1602Z" fill="#FDAE35" />
    <path d="M22.08 18.1602H22.96V17.2802H22.08V18.1602Z" fill="#FDAE35" />
    <path d="M22.96 18.1602H23.84V17.2802H22.96V18.1602Z" fill="#FDAE35" />
    <path
      d="M15.9399 18.1602H16.8199V17.2802H15.9399V18.1602Z"
      fill="#EBEFF0"
    />
    <path
      d="M16.8099 18.1602H17.6899V17.2802H16.8099V18.1602Z"
      fill="#EBEFF0"
    />
    <path
      d="M15.9399 11.1201H16.8199V10.2401H15.9399V11.1201Z"
      fill="#FDAE35"
    />
    <path d="M15.9399 10.25H16.8199V9.37H15.9399V10.25Z" fill="#FDAE35" />
    <path
      d="M15.9399 24.3105H16.8199V23.4305H15.9399V24.3105Z"
      fill="#FDAE35"
    />
    <path
      d="M15.9399 23.4404H16.8199V22.5604H15.9399V23.4404Z"
      fill="#FDE962"
    />
    <path
      d="M16.8099 23.4404H17.6899V22.5604H16.8099V23.4404Z"
      fill="#FDAE35"
    />
    <path
      d="M15.9399 22.5498H16.8199V21.6698H15.9399V22.5498Z"
      fill="#FDE962"
    />
    <path
      d="M16.8099 22.5498H17.6899V21.6698H16.8099V22.5498Z"
      fill="#FDAE35"
    />
    <path
      d="M15.9399 21.6699H16.8199V20.7899H15.9399V21.6699Z"
      fill="#FDE962"
    />
    <path
      d="M16.8099 21.6699H17.6899V20.7899H16.8099V21.6699Z"
      fill="#FDE962"
    />
    <path
      d="M15.9399 20.7998H16.8199V19.9198H15.9399V20.7998Z"
      fill="#FDE962"
    />
    <path
      d="M16.8099 20.7998H17.6899V19.9198H16.8099V20.7998Z"
      fill="#FDE962"
    />
    <path
      d="M17.6899 21.6699H18.5699V20.7899H17.6899V21.6699Z"
      fill="#FDAE35"
    />
    <path
      d="M17.6899 20.7998H18.5699V19.9198H17.6899V20.7998Z"
      fill="#FDAE35"
    />
    <path
      d="M15.9399 19.9199H16.8199V19.0399H15.9399V19.9199Z"
      fill="#EBEFF0"
    />
    <path
      d="M16.8099 19.9199H17.6899V19.0399H16.8099V19.9199Z"
      fill="#FDE962"
    />
    <path
      d="M17.6899 19.9199H18.5699V19.0399H17.6899V19.9199Z"
      fill="#FDE962"
    />
    <path d="M18.58 19.9199H19.46V19.0399H18.58V19.9199Z" fill="#FDAE35" />
    <path d="M19.45 19.9199H20.33V19.0399H19.45V19.9199Z" fill="#FDAE35" />
    <path d="M17.6899 19.04H18.5699V18.16H17.6899V19.04Z" fill="#FDE962" />
    <path d="M18.58 19.04H19.46V18.16H18.58V19.04Z" fill="#FDE962" />
    <path d="M19.45 19.04H20.33V18.16H19.45V19.04Z" fill="#FDAE35" />
    <path d="M20.33 19.04H21.21V18.16H20.33V19.04Z" fill="#FDAE35" />
    <path d="M21.21 19.04H22.09V18.16H21.21V19.04Z" fill="#FDAE35" />
    <path d="M15.9399 19.04H16.8199V18.16H15.9399V19.04Z" fill="#EBEFF0" />
    <path d="M16.8099 19.04H17.6899V18.16H16.8099V19.04Z" fill="#FDE962" />
    <path
      d="M17.6899 18.1699H18.5699V17.2899H17.6899V18.1699Z"
      fill="#EBEFF0"
    />
    <path d="M18.58 18.1699H19.46V17.2899H18.58V18.1699Z" fill="#FDE962" />
    <path d="M19.45 18.1699H20.33V17.2899H19.45V18.1699Z" fill="#FDE962" />
    <path d="M20.33 18.1699H21.21V17.2899H20.33V18.1699Z" fill="#FDE962" />
    <path d="M21.21 18.1699H22.09V17.2899H21.21V18.1699Z" fill="#FDAE35" />
    <path d="M22.08 18.1699H22.96V17.2899H22.08V18.1699Z" fill="#FDAE35" />
    <path d="M22.96 18.1699H23.84V17.2899H22.96V18.1699Z" fill="#FDAE35" />
    <path
      d="M15.9399 18.1699H16.8199V17.2899H15.9399V18.1699Z"
      fill="#EBEFF0"
    />
    <path
      d="M16.8099 18.1699H17.6899V17.2899H16.8099V18.1699Z"
      fill="#EBEFF0"
    />
    <path
      d="M15.9399 25.1904H16.8199V24.3104H15.9399V25.1904Z"
      fill="#FDAE35"
    />
    <path
      d="M15.9399 26.0703H16.8199V25.1903H15.9399V26.0703Z"
      fill="#FDAE35"
    />
    <path
      d="M16.8199 23.4297H15.9399V24.3097H16.8199V23.4297Z"
      fill="#FDAE35"
    />
    <path
      d="M16.8199 22.5498H15.9399V23.4298H16.8199V22.5498Z"
      fill="#FDE962"
    />
    <path
      d="M15.9399 22.5498H15.0599V23.4298H15.9399V22.5498Z"
      fill="#FDAE35"
    />
    <path
      d="M16.8199 21.6797H15.9399V22.5597H16.8199V21.6797Z"
      fill="#FDE962"
    />
    <path
      d="M15.9399 21.6797H15.0599V22.5597H15.9399V21.6797Z"
      fill="#FDAE35"
    />
    <path
      d="M16.8199 20.7998H15.9399V21.6798H16.8199V20.7998Z"
      fill="#FDE962"
    />
    <path
      d="M15.9399 20.7998H15.0599V21.6798H15.9399V20.7998Z"
      fill="#FDE962"
    />
    <path
      d="M16.8199 19.9199H15.9399V20.7999H16.8199V19.9199Z"
      fill="#FDE962"
    />
    <path
      d="M15.9399 19.9199H15.0599V20.7999H15.9399V19.9199Z"
      fill="#FDE962"
    />
    <path
      d="M15.0599 20.7998H14.1799V21.6798H15.0599V20.7998Z"
      fill="#FDAE35"
    />
    <path
      d="M15.0599 19.9199H14.1799V20.7999H15.0599V19.9199Z"
      fill="#FDAE35"
    />
    <path d="M16.8199 19.04H15.9399V19.92H16.8199V19.04Z" fill="#EBEFF0" />
    <path d="M15.9399 19.04H15.0599V19.92H15.9399V19.04Z" fill="#FDE962" />
    <path d="M15.0599 19.04H14.1799V19.92H15.0599V19.04Z" fill="#FDE962" />
    <path d="M14.1799 19.04H13.2999V19.92H14.1799V19.04Z" fill="#FDAE35" />
    <path d="M13.3099 19.04H12.4299V19.92H13.3099V19.04Z" fill="#FDAE35" />
    <path
      d="M15.0599 18.1602H14.1799V19.0402H15.0599V18.1602Z"
      fill="#FDE962"
    />
    <path
      d="M14.1799 18.1602H13.2999V19.0402H14.1799V18.1602Z"
      fill="#FDE962"
    />
    <path
      d="M13.3099 18.1602H12.4299V19.0402H13.3099V18.1602Z"
      fill="#FDAE35"
    />
    <path
      d="M12.4299 18.1602H11.5499V19.0402H12.4299V18.1602Z"
      fill="#FDAE35"
    />
    <path
      d="M11.5499 18.1602H10.6699V19.0402H11.5499V18.1602Z"
      fill="#FDAE35"
    />
    <path
      d="M16.8199 18.1602H15.9399V19.0402H16.8199V18.1602Z"
      fill="#EBEFF0"
    />
    <path
      d="M15.9399 18.1602H15.0599V19.0402H15.9399V18.1602Z"
      fill="#FDE962"
    />
    <path
      d="M15.0599 17.2803H14.1799V18.1603H15.0599V17.2803Z"
      fill="#EBEFF0"
    />
    <path
      d="M14.1799 17.2803H13.2999V18.1603H14.1799V17.2803Z"
      fill="#FDE962"
    />
    <path
      d="M13.3099 17.2803H12.4299V18.1603H13.3099V17.2803Z"
      fill="#FDE962"
    />
    <path
      d="M12.4299 17.2803H11.5499V18.1603H12.4299V17.2803Z"
      fill="#FDE962"
    />
    <path
      d="M11.5499 17.2803H10.6699V18.1603H11.5499V17.2803Z"
      fill="#FDAE35"
    />
    <path
      d="M10.6699 17.2803H9.78992V18.1603H10.6699V17.2803Z"
      fill="#FDAE35"
    />
    <path
      d="M9.78991 17.2803H8.90991V18.1603H9.78991V17.2803Z"
      fill="#FDAE35"
    />
    <path
      d="M16.8199 17.2803H15.9399V18.1603H16.8199V17.2803Z"
      fill="#EBEFF0"
    />
    <path
      d="M15.9399 17.2803H15.0599V18.1603H15.9399V17.2803Z"
      fill="#EBEFF0"
    />
    <path
      d="M16.8199 24.3105H15.9399V25.1905H16.8199V24.3105Z"
      fill="#FDAE35"
    />
    <path
      d="M16.8199 25.1904H15.9399V26.0704H16.8199V25.1904Z"
      fill="#FDAE35"
    />
    <path
      d="M61.4699 45.4297H60.3899V46.5097H61.4699V45.4297Z"
      fill="#FDAE35"
    />
    <path d="M61.4699 46.5H60.3899V47.58H61.4699V46.5Z" fill="#FDE962" />
    <path d="M60.3899 46.5H59.3099V47.58H60.3899V46.5Z" fill="#FDAE35" />
    <path
      d="M61.4699 47.5801H60.3899V48.6601H61.4699V47.5801Z"
      fill="#FDE962"
    />
    <path
      d="M60.3899 47.5801H59.3099V48.6601H60.3899V47.5801Z"
      fill="#FDAE35"
    />
    <path
      d="M61.4699 48.6504H60.3899V49.7304H61.4699V48.6504Z"
      fill="#FDE962"
    />
    <path
      d="M60.3899 48.6504H59.3099V49.7304H60.3899V48.6504Z"
      fill="#FDE962"
    />
    <path
      d="M61.4699 49.7305H60.3899V50.8105H61.4699V49.7305Z"
      fill="#FDE962"
    />
    <path
      d="M60.3899 49.7305H59.3099V50.8105H60.3899V49.7305Z"
      fill="#FDE962"
    />
    <path
      d="M59.3099 48.6504H58.2299V49.7304H59.3099V48.6504Z"
      fill="#FDAE35"
    />
    <path
      d="M59.3099 49.7305H58.2299V50.8105H59.3099V49.7305Z"
      fill="#FDAE35"
    />
    <path
      d="M61.4699 50.8105H60.3899V51.8905H61.4699V50.8105Z"
      fill="#EBEFF0"
    />
    <path
      d="M60.3899 50.8105H59.3099V51.8905H60.3899V50.8105Z"
      fill="#FDE962"
    />
    <path
      d="M59.3099 50.8105H58.2299V51.8905H59.3099V50.8105Z"
      fill="#FDE962"
    />
    <path
      d="M58.2399 50.8105H57.1599V51.8905H58.2399V50.8105Z"
      fill="#FDAE35"
    />
    <path d="M57.16 50.8105H56.08V51.8905H57.16V50.8105Z" fill="#FDAE35" />
    <path
      d="M59.3099 51.8799H58.2299V52.9599H59.3099V51.8799Z"
      fill="#FDE962"
    />
    <path
      d="M58.2399 51.8799H57.1599V52.9599H58.2399V51.8799Z"
      fill="#FDE962"
    />
    <path d="M57.16 51.8799H56.08V52.9599H57.16V51.8799Z" fill="#FDAE35" />
    <path
      d="M56.0899 51.8799H55.0099V52.9599H56.0899V51.8799Z"
      fill="#FDAE35"
    />
    <path
      d="M55.0099 51.8799H53.9299V52.9599H55.0099V51.8799Z"
      fill="#FDAE35"
    />
    <path
      d="M61.4699 51.8799H60.3899V52.9599H61.4699V51.8799Z"
      fill="#EBEFF0"
    />
    <path
      d="M60.3899 51.8799H59.3099V52.9599H60.3899V51.8799Z"
      fill="#FDE962"
    />
    <path d="M59.3099 52.96H58.2299V54.04H59.3099V52.96Z" fill="#EBEFF0" />
    <path d="M58.2399 52.96H57.1599V54.04H58.2399V52.96Z" fill="#FDE962" />
    <path d="M57.16 52.96H56.08V54.04H57.16V52.96Z" fill="#FDE962" />
    <path d="M56.0899 52.96H55.0099V54.04H56.0899V52.96Z" fill="#FDE962" />
    <path d="M55.0099 52.96H53.9299V54.04H55.0099V52.96Z" fill="#FDAE35" />
    <path d="M53.9299 52.96H52.8499V54.04H53.9299V52.96Z" fill="#FDAE35" />
    <path d="M52.8599 52.96H51.7799V54.04H52.8599V52.96Z" fill="#FDAE35" />
    <path d="M61.4699 52.96H60.3899V54.04H61.4699V52.96Z" fill="#EBEFF0" />
    <path d="M60.3899 52.96H59.3099V54.04H60.3899V52.96Z" fill="#EBEFF0" />
    <path
      d="M61.4699 44.3506H60.3899V45.4306H61.4699V44.3506Z"
      fill="#FDAE35"
    />
    <path
      d="M61.4699 43.2705H60.3899V44.3505H61.4699V43.2705Z"
      fill="#FDAE35"
    />
    <path d="M60.38 46.5H61.46V45.42H60.38V46.5Z" fill="#FDAE35" />
    <path d="M60.38 47.5801H61.46V46.5001H60.38V47.5801Z" fill="#FDE962" />
    <path
      d="M61.4599 47.5801H62.5399V46.5001H61.4599V47.5801Z"
      fill="#FDAE35"
    />
    <path d="M60.38 48.6504H61.46V47.5704H60.38V48.6504Z" fill="#FDE962" />
    <path
      d="M61.4599 48.6504H62.5399V47.5704H61.4599V48.6504Z"
      fill="#FDAE35"
    />
    <path d="M60.38 49.7402H61.46V48.6602H60.38V49.7402Z" fill="#FDE962" />
    <path
      d="M61.4599 49.7402H62.5399V48.6602H61.4599V49.7402Z"
      fill="#FDE962"
    />
    <path d="M60.38 50.8105H61.46V49.7305H60.38V50.8105Z" fill="#FDE962" />
    <path
      d="M61.4599 50.8105H62.5399V49.7305H61.4599V50.8105Z"
      fill="#FDE962"
    />
    <path
      d="M62.5399 49.7402H63.6199V48.6602H62.5399V49.7402Z"
      fill="#FDAE35"
    />
    <path
      d="M62.5399 50.8105H63.6199V49.7305H62.5399V50.8105Z"
      fill="#FDAE35"
    />
    <path d="M60.38 51.8799H61.46V50.7999H60.38V51.8799Z" fill="#EBEFF0" />
    <path
      d="M61.4599 51.8799H62.5399V50.7999H61.4599V51.8799Z"
      fill="#FDE962"
    />
    <path
      d="M62.5399 51.8799H63.6199V50.7999H62.5399V51.8799Z"
      fill="#FDE962"
    />
    <path
      d="M63.6099 51.8799H64.6899V50.7999H63.6099V51.8799Z"
      fill="#FDAE35"
    />
    <path
      d="M64.6899 51.8799H65.7699V50.7999H64.6899V51.8799Z"
      fill="#FDAE35"
    />
    <path d="M62.5399 52.96H63.6199V51.88H62.5399V52.96Z" fill="#FDE962" />
    <path d="M63.6099 52.96H64.6899V51.88H63.6099V52.96Z" fill="#FDE962" />
    <path d="M64.6899 52.96H65.7699V51.88H64.6899V52.96Z" fill="#FDAE35" />
    <path d="M65.77 52.96H66.85V51.88H65.77V52.96Z" fill="#FDAE35" />
    <path d="M66.8399 52.96H67.9199V51.88H66.8399V52.96Z" fill="#FDAE35" />
    <path d="M60.38 52.96H61.46V51.88H60.38V52.96Z" fill="#EBEFF0" />
    <path d="M61.4599 52.96H62.5399V51.88H61.4599V52.96Z" fill="#FDE962" />
    <path d="M62.5399 54.04H63.6199V52.96H62.5399V54.04Z" fill="#EBEFF0" />
    <path d="M63.6099 54.04H64.6899V52.96H63.6099V54.04Z" fill="#FDE962" />
    <path d="M64.6899 54.04H65.7699V52.96H64.6899V54.04Z" fill="#FDE962" />
    <path d="M65.77 54.04H66.85V52.96H65.77V54.04Z" fill="#FDE962" />
    <path d="M66.8399 54.04H67.9199V52.96H66.8399V54.04Z" fill="#FDAE35" />
    <path d="M67.9199 54.04H68.9999V52.96H67.9199V54.04Z" fill="#FDAE35" />
    <path d="M68.9899 54.04H70.0699V52.96H68.9899V54.04Z" fill="#FDAE35" />
    <path d="M60.38 54.04H61.46V52.96H60.38V54.04Z" fill="#EBEFF0" />
    <path d="M61.4599 54.04H62.5399V52.96H61.4599V54.04Z" fill="#EBEFF0" />
    <path d="M60.38 45.4199H61.46V44.3399H60.38V45.4199Z" fill="#FDAE35" />
    <path d="M60.38 44.3506H61.46V43.2706H60.38V44.3506Z" fill="#FDAE35" />
    <path d="M60.38 61.5801H61.46V60.5001H60.38V61.5801Z" fill="#FDAE35" />
    <path d="M60.38 60.5098H61.46V59.4298H60.38V60.5098Z" fill="#FDE962" />
    <path
      d="M61.4599 60.5098H62.5399V59.4298H61.4599V60.5098Z"
      fill="#FDAE35"
    />
    <path d="M60.38 59.4404H61.46V58.3604H60.38V59.4404Z" fill="#FDE962" />
    <path
      d="M61.4599 59.4404H62.5399V58.3604H61.4599V59.4404Z"
      fill="#FDAE35"
    />
    <path d="M60.38 58.3506H61.46V57.2706H60.38V58.3506Z" fill="#FDE962" />
    <path
      d="M61.4599 58.3506H62.5399V57.2706H61.4599V58.3506Z"
      fill="#FDE962"
    />
    <path d="M60.38 57.2803H61.46V56.2003H60.38V57.2803Z" fill="#FDE962" />
    <path
      d="M61.4599 57.2803H62.5399V56.2003H61.4599V57.2803Z"
      fill="#FDE962"
    />
    <path
      d="M62.5399 58.3506H63.6199V57.2706H62.5399V58.3506Z"
      fill="#FDAE35"
    />
    <path
      d="M62.5399 57.2803H63.6199V56.2003H62.5399V57.2803Z"
      fill="#FDAE35"
    />
    <path d="M60.38 56.2002H61.46V55.1202H60.38V56.2002Z" fill="#EBEFF0" />
    <path
      d="M61.4599 56.2002H62.5399V55.1202H61.4599V56.2002Z"
      fill="#FDE962"
    />
    <path
      d="M62.5399 56.2002H63.6199V55.1202H62.5399V56.2002Z"
      fill="#FDE962"
    />
    <path
      d="M63.6099 56.2002H64.6899V55.1202H63.6099V56.2002Z"
      fill="#FDAE35"
    />
    <path
      d="M64.6899 56.2002H65.7699V55.1202H64.6899V56.2002Z"
      fill="#FDAE35"
    />
    <path
      d="M62.5399 55.1201H63.6199V54.0401H62.5399V55.1201Z"
      fill="#FDE962"
    />
    <path
      d="M63.6099 55.1201H64.6899V54.0401H63.6099V55.1201Z"
      fill="#FDE962"
    />
    <path
      d="M64.6899 55.1201H65.7699V54.0401H64.6899V55.1201Z"
      fill="#FDAE35"
    />
    <path d="M65.77 55.1201H66.85V54.0401H65.77V55.1201Z" fill="#FDAE35" />
    <path
      d="M66.8399 55.1201H67.9199V54.0401H66.8399V55.1201Z"
      fill="#FDAE35"
    />
    <path d="M60.38 55.1201H61.46V54.0401H60.38V55.1201Z" fill="#EBEFF0" />
    <path
      d="M61.4599 55.1201H62.5399V54.0401H61.4599V55.1201Z"
      fill="#FDE962"
    />
    <path
      d="M62.5399 54.0498H63.6199V52.9698H62.5399V54.0498Z"
      fill="#EBEFF0"
    />
    <path
      d="M63.6099 54.0498H64.6899V52.9698H63.6099V54.0498Z"
      fill="#FDE962"
    />
    <path
      d="M64.6899 54.0498H65.7699V52.9698H64.6899V54.0498Z"
      fill="#FDE962"
    />
    <path d="M65.77 54.0498H66.85V52.9698H65.77V54.0498Z" fill="#FDE962" />
    <path
      d="M66.8399 54.0498H67.9199V52.9698H66.8399V54.0498Z"
      fill="#FDAE35"
    />
    <path
      d="M67.9199 54.0498H68.9999V52.9698H67.9199V54.0498Z"
      fill="#FDAE35"
    />
    <path
      d="M68.9899 54.0498H70.0699V52.9698H68.9899V54.0498Z"
      fill="#FDAE35"
    />
    <path d="M60.38 54.0498H61.46V52.9698H60.38V54.0498Z" fill="#EBEFF0" />
    <path
      d="M61.4599 54.0498H62.5399V52.9698H61.4599V54.0498Z"
      fill="#EBEFF0"
    />
    <path d="M60.38 62.6602H61.46V61.5802H60.38V62.6602Z" fill="#FDAE35" />
    <path d="M60.38 63.7402H61.46V62.6602H60.38V63.7402Z" fill="#FDAE35" />
    <path
      d="M61.4699 60.5098H60.3899V61.5898H61.4699V60.5098Z"
      fill="#FDAE35"
    />
    <path
      d="M61.4699 59.4297H60.3899V60.5097H61.4699V59.4297Z"
      fill="#FDE962"
    />
    <path
      d="M60.3899 59.4297H59.3099V60.5097H60.3899V59.4297Z"
      fill="#FDAE35"
    />
    <path
      d="M61.4699 58.3506H60.3899V59.4306H61.4699V58.3506Z"
      fill="#FDE962"
    />
    <path
      d="M60.3899 58.3506H59.3099V59.4306H60.3899V58.3506Z"
      fill="#FDAE35"
    />
    <path
      d="M61.4699 57.2803H60.3899V58.3603H61.4699V57.2803Z"
      fill="#FDE962"
    />
    <path
      d="M60.3899 57.2803H59.3099V58.3603H60.3899V57.2803Z"
      fill="#FDE962"
    />
    <path
      d="M61.4699 56.2002H60.3899V57.2802H61.4699V56.2002Z"
      fill="#FDE962"
    />
    <path
      d="M60.3899 56.2002H59.3099V57.2802H60.3899V56.2002Z"
      fill="#FDE962"
    />
    <path
      d="M59.3099 57.2803H58.2299V58.3603H59.3099V57.2803Z"
      fill="#FDAE35"
    />
    <path
      d="M59.3099 56.2002H58.2299V57.2802H59.3099V56.2002Z"
      fill="#FDAE35"
    />
    <path
      d="M61.4699 55.1299H60.3899V56.2099H61.4699V55.1299Z"
      fill="#EBEFF0"
    />
    <path
      d="M60.3899 55.1299H59.3099V56.2099H60.3899V55.1299Z"
      fill="#FDE962"
    />
    <path
      d="M59.3099 55.1299H58.2299V56.2099H59.3099V55.1299Z"
      fill="#FDE962"
    />
    <path
      d="M58.2399 55.1299H57.1599V56.2099H58.2399V55.1299Z"
      fill="#FDAE35"
    />
    <path d="M57.16 55.1299H56.08V56.2099H57.16V55.1299Z" fill="#FDAE35" />
    <path
      d="M59.3099 54.0498H58.2299V55.1298H59.3099V54.0498Z"
      fill="#FDE962"
    />
    <path
      d="M58.2399 54.0498H57.1599V55.1298H58.2399V54.0498Z"
      fill="#FDE962"
    />
    <path d="M57.16 54.0498H56.08V55.1298H57.16V54.0498Z" fill="#FDAE35" />
    <path
      d="M56.0899 54.0498H55.0099V55.1298H56.0899V54.0498Z"
      fill="#FDAE35"
    />
    <path
      d="M55.0099 54.0498H53.9299V55.1298H55.0099V54.0498Z"
      fill="#FDAE35"
    />
    <path
      d="M61.4699 54.0498H60.3899V55.1298H61.4699V54.0498Z"
      fill="#EBEFF0"
    />
    <path
      d="M60.3899 54.0498H59.3099V55.1298H60.3899V54.0498Z"
      fill="#FDE962"
    />
    <path
      d="M59.3099 52.9697H58.2299V54.0497H59.3099V52.9697Z"
      fill="#EBEFF0"
    />
    <path
      d="M58.2399 52.9697H57.1599V54.0497H58.2399V52.9697Z"
      fill="#FDE962"
    />
    <path d="M57.16 52.9697H56.08V54.0497H57.16V52.9697Z" fill="#FDE962" />
    <path
      d="M56.0899 52.9697H55.0099V54.0497H56.0899V52.9697Z"
      fill="#FDE962"
    />
    <path
      d="M55.0099 52.9697H53.9299V54.0497H55.0099V52.9697Z"
      fill="#FDAE35"
    />
    <path
      d="M53.9299 52.9697H52.8499V54.0497H53.9299V52.9697Z"
      fill="#FDAE35"
    />
    <path
      d="M52.8599 52.9697H51.7799V54.0497H52.8599V52.9697Z"
      fill="#FDAE35"
    />
    <path
      d="M61.4699 52.9697H60.3899V54.0497H61.4699V52.9697Z"
      fill="#EBEFF0"
    />
    <path
      d="M60.3899 52.9697H59.3099V54.0497H60.3899V52.9697Z"
      fill="#EBEFF0"
    />
    <path
      d="M61.4699 61.5801H60.3899V62.6601H61.4699V61.5801Z"
      fill="#FDAE35"
    />
    <path
      d="M61.4699 62.6602H60.3899V63.7402H61.4699V62.6602Z"
      fill="#FDAE35"
    />
    <path d="M87 1.5H85V3.5H87V1.5Z" fill="black" />
    <path d="M87 3.5H85V5.5H87V3.5Z" fill="#F0D594" />
    <path d="M87 5.5H85V7.5H87V5.5Z" fill="#F0D594" />
    <path d="M87 7.5H85V9.5H87V7.5Z" fill="#F0D594" />
    <path d="M87 9.5H85V11.5H87V9.5Z" fill="#F0D594" />
    <path d="M87 11.5H85V13.5H87V11.5Z" fill="#F0D594" />
    <path d="M87 13.5H85V15.5H87V13.5Z" fill="#F0D594" />
    <path d="M87 15.5H85V17.5H87V15.5Z" fill="#F0D594" />
    <path d="M87 17.5H85V19.5H87V17.5Z" fill="black" />
    <path d="M87 19.5H85V21.5H87V19.5Z" fill="#292E3D" />
    <path d="M87 21.5H85V23.5H87V21.5Z" fill="black" />
    <path d="M87 23.5H85V25.5H87V23.5Z" fill="black" />
    <path d="M87 25.5H85V27.5H87V25.5Z" fill="black" />
    <path d="M87 27.5H85V29.5H87V27.5Z" fill="black" />
    <path d="M87 29.5H85V31.5H87V29.5Z" fill="black" />
    <path d="M87 31.5H85V33.5H87V31.5Z" fill="black" />
    <path d="M87 33.5H85V35.5H87V33.5Z" fill="black" />
    <path d="M87 35.5H85V37.5H87V35.5Z" fill="black" />
    <path d="M87 71.5H85V73.5H87V71.5Z" fill="black" />
    <path d="M87 69.5H85V71.5H87V69.5Z" fill="#6BECD4" />
    <path d="M87 67.5H85V69.5H87V67.5Z" fill="#6BECD4" />
    <path d="M87 65.5H85V67.5H87V65.5Z" fill="#6BECD4" />
    <path d="M87 63.5H85V65.5H87V63.5Z" fill="#6BECD4" />
    <path d="M87 61.5H85V63.5H87V61.5Z" fill="#6BECD4" />
    <path d="M87 59.5H85V61.5H87V59.5Z" fill="#6BECD4" />
    <path d="M87 57.5H85V59.5H87V57.5Z" fill="#6BECD4" />
    <path d="M87 55.5H85V57.5H87V55.5Z" fill="#6BECD4" />
    <path d="M87 53.5H85V55.5H87V53.5Z" fill="black" />
    <path d="M87 51.5H85V53.5H87V51.5Z" fill="black" />
    <path d="M87 49.5H85V51.5H87V49.5Z" fill="black" />
    <path d="M87 47.5H85V49.5H87V47.5Z" fill="black" />
    <path d="M87 45.5H85V47.5H87V45.5Z" fill="black" />
    <path d="M87 43.5H85V45.5H87V43.5Z" fill="black" />
    <path d="M87 41.5H85V43.5H87V41.5Z" fill="black" />
    <path d="M87 39.5H85V41.5H87V39.5Z" fill="black" />
    <path d="M87 37.5H85V39.5H87V37.5Z" fill="black" />
    <path d="M89 1.5H87V3.5H89V1.5Z" fill="black" />
    <path d="M91 1.5H89V3.5H91V1.5Z" fill="black" />
    <path d="M93 1.5H91V3.5H93V1.5Z" fill="black" />
    <path d="M95 1.5H93V3.5H95V1.5Z" fill="black" />
    <path d="M97 1.5H95V3.5H97V1.5Z" fill="black" />
    <path d="M89 3.5H87V5.5H89V3.5Z" fill="#FF9CEC" />
    <path d="M91 3.5H89V5.5H91V3.5Z" fill="#FF9CEC" />
    <path d="M93 3.5H91V5.5H93V3.5Z" fill="#FF9CEC" />
    <path d="M95 3.5H93V5.5H95V3.5Z" fill="#FF9CEC" />
    <path d="M97 3.5H95V5.5H97V3.5Z" fill="#FF9CEC" />
    <path d="M99 3.5H97V5.5H99V3.5Z" fill="#FF9CEC" />
    <path d="M99 1.5H97V3.5H99V1.5Z" fill="black" />
    <path d="M101 3.5H99V5.5H101V3.5Z" fill="black" />
    <path d="M103 3.5H101V5.5H103V3.5Z" fill="black" />
    <path d="M89 5.5H87V7.5H89V5.5Z" fill="#FF9CEC" />
    <path d="M91 5.5H89V7.5H91V5.5Z" fill="#F8B6EA" />
    <path d="M93 5.5H91V7.5H93V5.5Z" fill="#F8B6EA" />
    <path d="M95 5.5H93V7.5H95V5.5Z" fill="#F8B6EA" />
    <path d="M97 5.5H95V7.5H97V5.5Z" fill="#F8B6EA" />
    <path d="M99 5.5H97V7.5H99V5.5Z" fill="#F8B6EA" />
    <path d="M101 5.5H99V7.5H101V5.5Z" fill="#FF9CEC" />
    <path d="M103 5.5H101V7.5H103V5.5Z" fill="#FF9CEC" />
    <path d="M105 5.5H103V7.5H105V5.5Z" fill="black" />
    <path d="M107 5.5H105V7.5H107V5.5Z" fill="black" />
    <path d="M89 7.5H87V9.5H89V7.5Z" fill="#FF9CEC" />
    <path d="M91 7.5H89V9.5H91V7.5Z" fill="#F8B6EA" />
    <path d="M93 7.5H91V9.5H93V7.5Z" fill="#F8B6EA" />
    <path d="M95 7.5H93V9.5H95V7.5Z" fill="#F8B6EA" />
    <path d="M97 7.5H95V9.5H97V7.5Z" fill="#F8B6EA" />
    <path d="M99 7.5H97V9.5H99V7.5Z" fill="#F8B6EA" />
    <path d="M101 7.5H99V9.5H101V7.5Z" fill="#F8B6EA" />
    <path d="M103 7.5H101V9.5H103V7.5Z" fill="#F8B6EA" />
    <path d="M105 7.5H103V9.5H105V7.5Z" fill="#FF9CEC" />
    <path d="M107 7.5H105V9.5H107V7.5Z" fill="black" />
    <path d="M109 7.5H107V9.5H109V7.5Z" fill="black" />
    <path d="M89 9.5H87V11.5H89V9.5Z" fill="#FF9CEC" />
    <path d="M91 9.5H89V11.5H91V9.5Z" fill="#F8B6EA" />
    <path d="M93 9.5H91V11.5H93V9.5Z" fill="#F8B6EA" />
    <path d="M95 9.5H93V11.5H95V9.5Z" fill="#F8B6EA" />
    <path d="M97 9.5H95V11.5H97V9.5Z" fill="#F8B6EA" />
    <path d="M99 9.5H97V11.5H99V9.5Z" fill="#F8B6EA" />
    <path d="M101 9.5H99V11.5H101V9.5Z" fill="#F8B6EA" />
    <path d="M103 9.5H101V11.5H103V9.5Z" fill="#F8B6EA" />
    <path d="M105 9.5H103V11.5H105V9.5Z" fill="#F8B6EA" />
    <path d="M107 9.5H105V11.5H107V9.5Z" fill="#FF9CEC" />
    <path d="M109 9.5H107V11.5H109V9.5Z" fill="#FF9CEC" />
    <path d="M111 9.5H109V11.5H111V9.5Z" fill="black" />
    <path d="M113 9.5H111V11.5H113V9.5Z" fill="black" />
    <path d="M89 11.5H87V13.5H89V11.5Z" fill="#FF9CEC" />
    <path d="M91 11.5H89V13.5H91V11.5Z" fill="#F8B6EA" />
    <path d="M93 11.5H91V13.5H93V11.5Z" fill="#F8B6EA" />
    <path d="M95 11.5H93V13.5H95V11.5Z" fill="#F8B6EA" />
    <path d="M97 11.5H95V13.5H97V11.5Z" fill="#F8B6EA" />
    <path d="M99 11.5H97V13.5H99V11.5Z" fill="#F8B6EA" />
    <path d="M101 11.5H99V13.5H101V11.5Z" fill="#F8B6EA" />
    <path d="M107 11.5H105V13.5H107V11.5Z" fill="#F8B6EA" />
    <path d="M109 11.5H107V13.5H109V11.5Z" fill="#F8B6EA" />
    <path d="M111 11.5H109V13.5H111V11.5Z" fill="#FF9CEC" />
    <path d="M113 11.5H111V13.5H113V11.5Z" fill="#FF9CEC" />
    <path d="M115 11.5H113V13.5H115V11.5Z" fill="black" />
    <path d="M89 13.5H87V15.5H89V13.5Z" fill="#FF9CEC" />
    <path d="M91 13.5H89V15.5H91V13.5Z" fill="#F8B6EA" />
    <path d="M93 13.5H91V15.5H93V13.5Z" fill="#F8B6EA" />
    <path d="M95 13.5H93V15.5H95V13.5Z" fill="#F8B6EA" />
    <path d="M97 13.5H95V15.5H97V13.5Z" fill="#F8B6EA" />
    <path d="M99 13.5H97V15.5H99V13.5Z" fill="#F8B6EA" />
    <path d="M105 13.5H103V15.5H105V13.5Z" fill="#F8B6EA" />
    <path d="M107 13.5H105V15.5H107V13.5Z" fill="#F8B6EA" />
    <path d="M109 13.5H107V15.5H109V13.5Z" fill="#F8B6EA" />
    <path d="M111 13.5H109V15.5H111V13.5Z" fill="#F8B6EA" />
    <path d="M113 13.5H111V15.5H113V13.5Z" fill="#F8B6EA" />
    <path d="M115 13.5H113V15.5H115V13.5Z" fill="#FF9CEC" />
    <path d="M117 13.5H115V15.5H117V13.5Z" fill="black" />
    <path d="M89 15.5H87V17.5H89V15.5Z" fill="#FF9CEC" />
    <path d="M91 15.5H89V17.5H91V15.5Z" fill="#F8B6EA" />
    <path d="M93 15.5H91V17.5H93V15.5Z" fill="#F8B6EA" />
    <path d="M95 15.5H93V17.5H95V15.5Z" fill="#F8B6EA" />
    <path d="M97 15.5H95V17.5H97V15.5Z" fill="#F8B6EA" />
    <path d="M99 15.5H97V17.5H99V15.5Z" fill="#F8B6EA" />
    <path d="M101 15.5H99V17.5H101V15.5Z" fill="#F8B6EA" />
    <path d="M107 15.5H105V17.5H107V15.5Z" fill="#F8B6EA" />
    <path d="M109 15.5H107V17.5H109V15.5Z" fill="#F8B6EA" />
    <path d="M111 15.5H109V17.5H111V15.5Z" fill="#F8B6EA" />
    <path d="M113 15.5H111V17.5H113V15.5Z" fill="#F8B6EA" />
    <path d="M115 15.5H113V17.5H115V15.5Z" fill="#FF9CEC" />
    <path d="M117 15.5H115V17.5H117V15.5Z" fill="black" />
    <path d="M89 17.5H87V19.5H89V17.5Z" fill="black" />
    <path d="M91 17.5H89V19.5H91V17.5Z" fill="#F8B6EA" />
    <path d="M93 17.5H91V19.5H93V17.5Z" fill="#F8B6EA" />
    <path d="M95 17.5H93V19.5H95V17.5Z" fill="#F8B6EA" />
    <path d="M97 17.5H95V19.5H97V17.5Z" fill="#F8B6EA" />
    <path d="M99 17.5H97V19.5H99V17.5Z" fill="#F8B6EA" />
    <path d="M103 11.5H101V13.5H103V11.5Z" fill="#F8B6EA" />
    <path d="M105 11.5H103V13.5H105V11.5Z" fill="#F8B6EA" />
    <path d="M101 13.5H99V15.5H101V13.5Z" fill="#F8B6EA" />
    <path d="M103 13.5H101V15.5H103V13.5Z" fill="#F8B6EA" />
    <path d="M103 15.5H101V17.5H103V15.5Z" fill="#F8B6EA" />
    <path d="M105 15.5H103V17.5H105V15.5Z" fill="#F8B6EA" />
    <path d="M101 17.5H99V19.5H101V17.5Z" fill="#F8B6EA" />
    <path d="M103 17.5H101V19.5H103V17.5Z" fill="#F8B6EA" />
    <path d="M105 17.5H103V19.5H105V17.5Z" fill="#F8B6EA" />
    <path d="M107 17.5H105V19.5H107V17.5Z" fill="#F8B6EA" />
    <path d="M109 17.5H107V19.5H109V17.5Z" fill="#F8B6EA" />
    <path d="M111 17.5H109V19.5H111V17.5Z" fill="#F8B6EA" />
    <path d="M113 17.5H111V19.5H113V17.5Z" fill="#F8B6EA" />
    <path d="M115 17.5H113V19.5H115V17.5Z" fill="#F8B6EA" />
    <path d="M117 17.5H115V19.5H117V17.5Z" fill="#FF9CEC" />
    <path d="M119 17.5H117V19.5H119V17.5Z" fill="black" />
    <path d="M89 19.5H87V21.5H89V19.5Z" fill="black" />
    <path d="M91 19.5H89V21.5H91V19.5Z" fill="black" />
    <path d="M93 19.5H91V21.5H93V19.5Z" fill="#FF9CEC" />
    <path d="M95 19.5H93V21.5H95V19.5Z" fill="#FF9CEC" />
    <path d="M97 19.5H95V21.5H97V19.5Z" fill="#F8B6EA" />
    <path d="M99 19.5H97V21.5H99V19.5Z" fill="#F8B6EA" />
    <path d="M101 19.5H99V21.5H101V19.5Z" fill="#F8B6EA" />
    <path d="M103 19.5H101V21.5H103V19.5Z" fill="#F8B6EA" />
    <path d="M105 19.5H103V21.5H105V19.5Z" fill="#F8B6EA" />
    <path d="M107 19.5H105V21.5H107V19.5Z" fill="#F8B6EA" />
    <path d="M109 19.5H107V21.5H109V19.5Z" fill="#F8B6EA" />
    <path d="M111 19.5H109V21.5H111V19.5Z" fill="#F8B6EA" />
    <path d="M113 19.5H111V21.5H113V19.5Z" fill="#F8B6EA" />
    <path d="M115 19.5H113V21.5H115V19.5Z" fill="#F8B6EA" />
    <path d="M117 19.5H115V21.5H117V19.5Z" fill="#FF9CEC" />
    <path d="M119 19.5H117V21.5H119V19.5Z" fill="black" />
    <path d="M121 19.5H119V21.5H121V19.5Z" fill="black" />
    <path d="M89 21.5H87V23.5H89V21.5Z" fill="black" />
    <path d="M91 21.5H89V23.5H91V21.5Z" fill="black" />
    <path d="M93 21.5H91V23.5H93V21.5Z" fill="black" />
    <path d="M95 21.5H93V23.5H95V21.5Z" fill="black" />
    <path d="M97 21.5H95V23.5H97V21.5Z" fill="#FF9CEC" />
    <path d="M99 21.5H97V23.5H99V21.5Z" fill="#FF9CEC" />
    <path d="M101 21.5H99V23.5H101V21.5Z" fill="#F8B6EA" />
    <path d="M103 21.5H101V23.5H103V21.5Z" fill="#F8B6EA" />
    <path d="M105 21.5H103V23.5H105V21.5Z" fill="#F8B6EA" />
    <path d="M107 21.5H105V23.5H107V21.5Z" fill="#F8B6EA" />
    <path d="M109 21.5H107V23.5H109V21.5Z" fill="#F8B6EA" />
    <path d="M111 21.5H109V23.5H111V21.5Z" fill="#F8B6EA" />
    <path d="M113 21.5H111V23.5H113V21.5Z" fill="#FF9CEC" />
    <path d="M115 21.5H113V23.5H115V21.5Z" fill="#FF9CEC" />
    <path d="M117 21.5H115V23.5H117V21.5Z" fill="#7487CB" />
    <path d="M119 21.5H117V23.5H119V21.5Z" fill="#7487CB" />
    <path d="M121 21.5H119V23.5H121V21.5Z" fill="black" />
    <path d="M89 23.5H87V25.5H89V23.5Z" fill="black" />
    <path d="M91 23.5H89V25.5H91V23.5Z" fill="black" />
    <path d="M93 23.5H91V25.5H93V23.5Z" fill="black" />
    <path d="M95 23.5H93V25.5H95V23.5Z" fill="black" />
    <path d="M97 23.5H95V25.5H97V23.5Z" fill="black" />
    <path d="M99 23.5H97V25.5H99V23.5Z" fill="black" />
    <path d="M101 23.5H99V25.5H101V23.5Z" fill="#FF9CEC" />
    <path d="M103 23.5H101V25.5H103V23.5Z" fill="#F8B6EA" />
    <path d="M105 23.5H103V25.5H105V23.5Z" fill="#F8B6EA" />
    <path d="M107 23.5H105V25.5H107V23.5Z" fill="#F8B6EA" />
    <path d="M109 23.5H107V25.5H109V23.5Z" fill="#FF9CEC" />
    <path d="M111 23.5H109V25.5H111V23.5Z" fill="#FF9CEC" />
    <path d="M113 23.5H111V25.5H113V23.5Z" fill="#7487CB" />
    <path d="M115 23.5H113V25.5H115V23.5Z" fill="#7487CB" />
    <path d="M117 23.5H115V25.5H117V23.5Z" fill="#91A8FF" />
    <path d="M119 23.5H117V25.5H119V23.5Z" fill="#91A8FF" />
    <path d="M121 23.5H119V25.5H121V23.5Z" fill="#7487CB" />
    <path d="M123 23.5H121V25.5H123V23.5Z" fill="black" />
    <path d="M89 25.5H87V27.5H89V25.5Z" fill="black" />
    <path d="M91 25.5H89V27.5H91V25.5Z" fill="black" />
    <path d="M93 25.5H91V27.5H93V25.5Z" fill="black" />
    <path d="M95 25.5H93V27.5H95V25.5Z" fill="black" />
    <path d="M97 25.5H95V27.5H97V25.5Z" fill="black" />
    <path d="M99 25.5H97V27.5H99V25.5Z" fill="black" />
    <path d="M101 25.5H99V27.5H101V25.5Z" fill="black" />
    <path d="M103 25.5H101V27.5H103V25.5Z" fill="#FF9CEC" />
    <path d="M105 25.5H103V27.5H105V25.5Z" fill="#FF9CEC" />
    <path d="M107 25.5H105V27.5H107V25.5Z" fill="#FF9CEC" />
    <path d="M109 25.5H107V27.5H109V25.5Z" fill="#7487CB" />
    <path d="M111 25.5H109V27.5H111V25.5Z" fill="#7487CB" />
    <path d="M113 25.5H111V27.5H113V25.5Z" fill="#91A8FF" />
    <path d="M115 25.5H113V27.5H115V25.5Z" fill="#91A8FF" />
    <path d="M117 25.5H115V27.5H117V25.5Z" fill="#91A8FF" />
    <path d="M119 25.5H117V27.5H119V25.5Z" fill="#91A8FF" />
    <path d="M121 25.5H119V27.5H121V25.5Z" fill="#7487CB" />
    <path d="M123 25.5H121V27.5H123V25.5Z" fill="black" />
    <path d="M89 27.5H87V29.5H89V27.5Z" fill="black" />
    <path d="M91 27.5H89V29.5H91V27.5Z" fill="black" />
    <path d="M93 27.5H91V29.5H93V27.5Z" fill="black" />
    <path d="M95 27.5H93V29.5H95V27.5Z" fill="black" />
    <path d="M97 27.5H95V29.5H97V27.5Z" fill="black" />
    <path d="M99 27.5H97V29.5H99V27.5Z" fill="black" />
    <path d="M101 27.5H99V29.5H101V27.5Z" fill="black" />
    <path d="M103 27.5H101V29.5H103V27.5Z" fill="black" />
    <path d="M105 27.5H103V29.5H105V27.5Z" fill="#7487CB" />
    <path d="M107 27.5H105V29.5H107V27.5Z" fill="#7487CB" />
    <path d="M109 27.5H107V29.5H109V27.5Z" fill="#91A8FF" />
    <path d="M111 27.5H109V29.5H111V27.5Z" fill="#91A8FF" />
    <path d="M113 27.5H111V29.5H113V27.5Z" fill="#91A8FF" />
    <path d="M115 27.5H113V29.5H115V27.5Z" fill="#91A8FF" />
    <path d="M117 27.5H115V29.5H117V27.5Z" fill="#91A8FF" />
    <path d="M119 27.5H117V29.5H119V27.5Z" fill="#91A8FF" />
    <path d="M121 27.5H119V29.5H121V27.5Z" fill="#7487CB" />
    <path d="M123 27.5H121V29.5H123V27.5Z" fill="black" />
    <path d="M89 29.5H87V31.5H89V29.5Z" fill="black" />
    <path d="M91 29.5H89V31.5H91V29.5Z" fill="black" />
    <path d="M93 29.5H91V31.5H93V29.5Z" fill="black" />
    <path d="M95 29.5H93V31.5H95V29.5Z" fill="black" />
    <path d="M97 29.5H95V31.5H97V29.5Z" fill="black" />
    <path d="M99 29.5H97V31.5H99V29.5Z" fill="black" />
    <path d="M101 29.5H99V31.5H101V29.5Z" fill="black" />
    <path d="M103 29.5H101V31.5H103V29.5Z" fill="black" />
    <path d="M105 29.5H103V31.5H105V29.5Z" fill="#7487CB" />
    <path d="M107 29.5H105V31.5H107V29.5Z" fill="#91A8FF" />
    <path d="M109 29.5H107V31.5H109V29.5Z" fill="#91A8FF" />
    <path d="M111 29.5H109V31.5H111V29.5Z" fill="#91A8FF" />
    <path d="M113 29.5H111V31.5H113V29.5Z" fill="#91A8FF" />
    <path d="M115 29.5H113V31.5H115V29.5Z" fill="#91A8FF" />
    <path d="M117 29.5H115V31.5H117V29.5Z" fill="#91A8FF" />
    <path d="M119 29.5H117V31.5H119V29.5Z" fill="#91A8FF" />
    <path d="M121 29.5H119V31.5H121V29.5Z" fill="#7487CB" />
    <path d="M123 29.5H121V31.5H123V29.5Z" fill="black" />
    <path d="M89 31.5H87V33.5H89V31.5Z" fill="black" />
    <path d="M91 31.5H89V33.5H91V31.5Z" fill="black" />
    <path d="M93 31.5H91V33.5H93V31.5Z" fill="black" />
    <path d="M95 31.5H93V33.5H95V31.5Z" fill="black" />
    <path d="M97 31.5H95V33.5H97V31.5Z" fill="black" />
    <path d="M99 31.5H97V33.5H99V31.5Z" fill="black" />
    <path d="M101 31.5H99V33.5H101V31.5Z" fill="black" />
    <path d="M103 31.5H101V33.5H103V31.5Z" fill="black" />
    <path d="M105 31.5H103V33.5H105V31.5Z" fill="black" />
    <path d="M107 31.5H105V33.5H107V31.5Z" fill="#91A8FF" />
    <path d="M109 31.5H107V33.5H109V31.5Z" fill="#91A8FF" />
    <path d="M111 31.5H109V33.5H111V31.5Z" fill="#91A8FF" />
    <path d="M113 31.5H111V33.5H113V31.5Z" fill="#91A8FF" />
    <path d="M115 31.5H113V33.5H115V31.5Z" fill="#91A8FF" />
    <path d="M117 31.5H115V33.5H117V31.5Z" fill="#91A8FF" />
    <path d="M119 31.5H117V33.5H119V31.5Z" fill="#91A8FF" />
    <path d="M121 31.5H119V33.5H121V31.5Z" fill="#7487CB" />
    <path d="M123 31.5H121V33.5H123V31.5Z" fill="black" />
    <path d="M89 33.5H87V35.5H89V33.5Z" fill="black" />
    <path d="M91 33.5H89V35.5H91V33.5Z" fill="black" />
    <path d="M93 33.5H91V35.5H93V33.5Z" fill="black" />
    <path d="M95 33.5H93V35.5H95V33.5Z" fill="black" />
    <path d="M97 33.5H95V35.5H97V33.5Z" fill="black" />
    <path d="M99 33.5H97V35.5H99V33.5Z" fill="black" />
    <path d="M101 33.5H99V35.5H101V33.5Z" fill="black" />
    <path d="M103 33.5H101V35.5H103V33.5Z" fill="black" />
    <path d="M105 33.5H103V35.5H105V33.5Z" fill="black" />
    <path d="M107 33.5H105V35.5H107V33.5Z" fill="#91A8FF" />
    <path d="M109 33.5H107V35.5H109V33.5Z" fill="#91A8FF" />
    <path d="M111 33.5H109V35.5H111V33.5Z" fill="#91A8FF" />
    <path d="M113 33.5H111V35.5H113V33.5Z" fill="#91A8FF" />
    <path d="M115 33.5H113V35.5H115V33.5Z" fill="#91A8FF" />
    <path d="M117 33.5H115V35.5H117V33.5Z" fill="#91A8FF" />
    <path d="M119 33.5H117V35.5H119V33.5Z" fill="#91A8FF" />
    <path d="M121 33.5H119V35.5H121V33.5Z" fill="#7487CB" />
    <path d="M123 33.5H121V35.5H123V33.5Z" fill="black" />
    <path d="M89 35.5H87V37.5H89V35.5Z" fill="black" />
    <path d="M91 35.5H89V37.5H91V35.5Z" fill="black" />
    <path d="M93 35.5H91V37.5H93V35.5Z" fill="black" />
    <path d="M95 35.5H93V37.5H95V35.5Z" fill="black" />
    <path d="M97 35.5H95V37.5H97V35.5Z" fill="black" />
    <path d="M99 35.5H97V37.5H99V35.5Z" fill="black" />
    <path d="M101 35.5H99V37.5H101V35.5Z" fill="black" />
    <path d="M103 35.5H101V37.5H103V35.5Z" fill="black" />
    <path d="M105 35.5H103V37.5H105V35.5Z" fill="black" />
    <path d="M107 35.5H105V37.5H107V35.5Z" fill="#91A8FF" />
    <path d="M109 35.5H107V37.5H109V35.5Z" fill="#91A8FF" />
    <path d="M111 35.5H109V37.5H111V35.5Z" fill="#91A8FF" />
    <path d="M113 35.5H111V37.5H113V35.5Z" fill="#91A8FF" />
    <path d="M115 35.5H113V37.5H115V35.5Z" fill="#91A8FF" />
    <path d="M117 35.5H115V37.5H117V35.5Z" fill="#91A8FF" />
    <path d="M119 35.5H117V37.5H119V35.5Z" fill="#91A8FF" />
    <path d="M121 35.5H119V37.5H121V35.5Z" fill="#7487CB" />
    <path d="M123 35.5H121V37.5H123V35.5Z" fill="black" />
    <path d="M89 71.5H87V73.5H89V71.5Z" fill="black" />
    <path d="M91 71.5H89V73.5H91V71.5Z" fill="black" />
    <path d="M93 71.5H91V73.5H93V71.5Z" fill="black" />
    <path d="M95 71.5H93V73.5H95V71.5Z" fill="black" />
    <path d="M97 71.5H95V73.5H97V71.5Z" fill="black" />
    <path d="M89 69.5H87V71.5H89V69.5Z" fill="#B8CC3F" />
    <path d="M91 69.5H89V71.5H91V69.5Z" fill="#B8CC3F" />
    <path d="M93 69.5H91V71.5H93V69.5Z" fill="#B8CC3F" />
    <path d="M95 69.5H93V71.5H95V69.5Z" fill="#B8CC3F" />
    <path d="M97 69.5H95V71.5H97V69.5Z" fill="#B8CC3F" />
    <path d="M99 69.5H97V71.5H99V69.5Z" fill="#B8CC3F" />
    <path d="M99 71.5H97V73.5H99V71.5Z" fill="black" />
    <path d="M101 69.5H99V71.5H101V69.5Z" fill="black" />
    <path d="M103 69.5H101V71.5H103V69.5Z" fill="black" />
    <path d="M89 67.5H87V69.5H89V67.5Z" fill="#B8CC3F" />
    <path d="M91 67.5H89V69.5H91V67.5Z" fill="#E5FF4E" />
    <path d="M93 67.5H91V69.5H93V67.5Z" fill="#E5FF4E" />
    <path d="M95 67.5H93V69.5H95V67.5Z" fill="#E5FF4E" />
    <path d="M97 67.5H95V69.5H97V67.5Z" fill="#E5FF4E" />
    <path d="M99 67.5H97V69.5H99V67.5Z" fill="#E5FF4E" />
    <path d="M101 67.5H99V69.5H101V67.5Z" fill="#B8CC3F" />
    <path d="M103 67.5H101V69.5H103V67.5Z" fill="#B8CC3F" />
    <path d="M105 67.5H103V69.5H105V67.5Z" fill="black" />
    <path d="M107 67.5H105V69.5H107V67.5Z" fill="black" />
    <path d="M89 65.5H87V67.5H89V65.5Z" fill="#B8CC3F" />
    <path d="M91 65.5H89V67.5H91V65.5Z" fill="#E5FF4E" />
    <path d="M93 65.5H91V67.5H93V65.5Z" fill="#E5FF4E" />
    <path d="M95 65.5H93V67.5H95V65.5Z" fill="#E5FF4E" />
    <path d="M97 65.5H95V67.5H97V65.5Z" fill="#E5FF4E" />
    <path d="M99 65.5H97V67.5H99V65.5Z" fill="#E5FF4E" />
    <path d="M101 65.5H99V67.5H101V65.5Z" fill="#E5FF4E" />
    <path d="M103 65.5H101V67.5H103V65.5Z" fill="#E5FF4E" />
    <path d="M105 65.5H103V67.5H105V65.5Z" fill="#B8CC3F" />
    <path d="M107 65.5H105V67.5H107V65.5Z" fill="black" />
    <path d="M109 65.5H107V67.5H109V65.5Z" fill="black" />
    <path d="M89 63.5H87V65.5H89V63.5Z" fill="#B8CC3F" />
    <path d="M91 63.5H89V65.5H91V63.5Z" fill="#E5FF4E" />
    <path d="M93 63.5H91V65.5H93V63.5Z" fill="#E5FF4E" />
    <path d="M95 63.5H93V65.5H95V63.5Z" fill="#E5FF4E" />
    <path d="M97 63.5H95V65.5H97V63.5Z" fill="#E5FF4E" />
    <path d="M99 63.5H97V65.5H99V63.5Z" fill="#E5FF4E" />
    <path d="M101 63.5H99V65.5H101V63.5Z" fill="#E5FF4E" />
    <path d="M103 63.5H101V65.5H103V63.5Z" fill="#E5FF4E" />
    <path d="M105 63.5H103V65.5H105V63.5Z" fill="#E5FF4E" />
    <path d="M107 63.5H105V65.5H107V63.5Z" fill="#B8CC3F" />
    <path d="M109 63.5H107V65.5H109V63.5Z" fill="#B8CC3F" />
    <path d="M111 63.5H109V65.5H111V63.5Z" fill="black" />
    <path d="M113 63.5H111V65.5H113V63.5Z" fill="black" />
    <path d="M89 61.5H87V63.5H89V61.5Z" fill="#B8CC3F" />
    <path d="M91 61.5H89V63.5H91V61.5Z" fill="#E5FF4E" />
    <path d="M93 61.5H91V63.5H93V61.5Z" fill="#E5FF4E" />
    <path d="M95 61.5H93V63.5H95V61.5Z" fill="#E5FF4E" />
    <path d="M97 61.5H95V63.5H97V61.5Z" fill="#E5FF4E" />
    <path d="M99 61.5H97V63.5H99V61.5Z" fill="#E5FF4E" />
    <path d="M101 61.5H99V63.5H101V61.5Z" fill="#E5FF4E" />
    <path d="M103 61.5H101V63.5H103V61.5Z" fill="#E5FF4E" />
    <path d="M105 61.5H103V63.5H105V61.5Z" fill="#E5FF4E" />
    <path d="M107 61.5H105V63.5H107V61.5Z" fill="#E5FF4E" />
    <path d="M109 61.5H107V63.5H109V61.5Z" fill="#E5FF4E" />
    <path d="M111 61.5H109V63.5H111V61.5Z" fill="#B8CC3F" />
    <path d="M113 61.5H111V63.5H113V61.5Z" fill="#B8CC3F" />
    <path d="M115 61.5H113V63.5H115V61.5Z" fill="black" />
    <path d="M89 59.5H87V61.5H89V59.5Z" fill="#B8CC3F" />
    <path d="M91 59.5H89V61.5H91V59.5Z" fill="#E5FF4E" />
    <path d="M93 59.5H91V61.5H93V59.5Z" fill="#E5FF4E" />
    <path d="M95 59.5H93V61.5H95V59.5Z" fill="#E5FF4E" />
    <path d="M97 59.5H95V61.5H97V59.5Z" fill="#E5FF4E" />
    <path d="M99 59.5H97V61.5H99V59.5Z" fill="#E5FF4E" />
    <path d="M101 59.5H99V61.5H101V59.5Z" fill="#E5FF4E" />
    <path d="M103 59.5H101V61.5H103V59.5Z" fill="#E5FF4E" />
    <path d="M105 59.5H103V61.5H105V59.5Z" fill="#E5FF4E" />
    <path d="M107 59.5H105V61.5H107V59.5Z" fill="#E5FF4E" />
    <path d="M109 59.5H107V61.5H109V59.5Z" fill="#E5FF4E" />
    <path d="M111 59.5H109V61.5H111V59.5Z" fill="#E5FF4E" />
    <path d="M113 59.5H111V61.5H113V59.5Z" fill="#E5FF4E" />
    <path d="M115 59.5H113V61.5H115V59.5Z" fill="#B8CC3F" />
    <path d="M117 59.5H115V61.5H117V59.5Z" fill="black" />
    <path d="M89 57.5H87V59.5H89V57.5Z" fill="#B8CC3F" />
    <path d="M91 57.5H89V59.5H91V57.5Z" fill="#E5FF4E" />
    <path d="M93 57.5H91V59.5H93V57.5Z" fill="#E5FF4E" />
    <path d="M95 57.5H93V59.5H95V57.5Z" fill="#E5FF4E" />
    <path d="M97 57.5H95V59.5H97V57.5Z" fill="#E5FF4E" />
    <path d="M99 57.5H97V59.5H99V57.5Z" fill="#E5FF4E" />
    <path d="M101 57.5H99V59.5H101V57.5Z" fill="#E5FF4E" />
    <path d="M103 57.5H101V59.5H103V57.5Z" fill="#E5FF4E" />
    <path d="M105 57.5H103V59.5H105V57.5Z" fill="#E5FF4E" />
    <path d="M107 57.5H105V59.5H107V57.5Z" fill="#E5FF4E" />
    <path d="M109 57.5H107V59.5H109V57.5Z" fill="#E5FF4E" />
    <path d="M111 57.5H109V59.5H111V57.5Z" fill="#E5FF4E" />
    <path d="M113 57.5H111V59.5H113V57.5Z" fill="#E5FF4E" />
    <path d="M115 57.5H113V59.5H115V57.5Z" fill="#B8CC3F" />
    <path d="M117 57.5H115V59.5H117V57.5Z" fill="black" />
    <path d="M89 55.5H87V57.5H89V55.5Z" fill="#B8CC3F" />
    <path d="M91 55.5H89V57.5H91V55.5Z" fill="#B8CC3F" />
    <path d="M93 55.5H91V57.5H93V55.5Z" fill="#B8CC3F" />
    <path d="M95 55.5H93V57.5H95V55.5Z" fill="#B8CC3F" />
    <path d="M97 55.5H95V57.5H97V55.5Z" fill="#B8CC40" />
    <path d="M99 55.5H97V57.5H99V55.5Z" fill="#E5FF4E" />
    <path d="M101 55.5H99V57.5H101V55.5Z" fill="#E5FF4E" />
    <path d="M103 55.5H101V57.5H103V55.5Z" fill="#E5FF4E" />
    <path d="M105 55.5H103V57.5H105V55.5Z" fill="#E5FF4E" />
    <path d="M107 55.5H105V57.5H107V55.5Z" fill="#E5FF4E" />
    <path d="M109 55.5H107V57.5H109V55.5Z" fill="#E5FF4E" />
    <path d="M111 55.5H109V57.5H111V55.5Z" fill="#E5FF4E" />
    <path d="M113 55.5H111V57.5H113V55.5Z" fill="#E5FF4E" />
    <path d="M115 55.5H113V57.5H115V55.5Z" fill="#E5FF4E" />
    <path d="M117 55.5H115V57.5H117V55.5Z" fill="#B8CC3F" />
    <path d="M119 55.5H117V57.5H119V55.5Z" fill="black" />
    <path d="M89 53.5H87V55.5H89V53.5Z" fill="black" />
    <path d="M91 53.5H89V55.5H91V53.5Z" fill="black" />
    <path d="M93 53.5H91V55.5H93V53.5Z" fill="black" />
    <path d="M95 53.5H93V55.5H95V53.5Z" fill="black" />
    <path d="M99 53.5H97V55.5H99V53.5Z" fill="#B8CC3F" />
    <path d="M97 53.5H95V55.5H97V53.5Z" fill="#B8CC3F" />
    <path d="M101 53.5H99V55.5H101V53.5Z" fill="#E5FF4E" />
    <path d="M103 53.5H101V55.5H103V53.5Z" fill="#E5FF4E" />
    <path d="M105 53.5H103V55.5H105V53.5Z" fill="#E5FF4E" />
    <path d="M107 53.5H105V55.5H107V53.5Z" fill="#E5FF4E" />
    <path d="M109 53.5H107V55.5H109V53.5Z" fill="#E5FF4E" />
    <path d="M111 53.5H109V55.5H111V53.5Z" fill="#E5FF4E" />
    <path d="M113 53.5H111V55.5H113V53.5Z" fill="#E5FF4E" />
    <path d="M115 53.5H113V55.5H115V53.5Z" fill="#E5FF4E" />
    <path d="M117 53.5H115V55.5H117V53.5Z" fill="#B8CC3F" />
    <path d="M119 53.5H117V55.5H119V53.5Z" fill="black" />
    <path d="M121 53.5H119V55.5H121V53.5Z" fill="black" />
    <path d="M89 51.5H87V53.5H89V51.5Z" fill="black" />
    <path d="M91 51.5H89V53.5H91V51.5Z" fill="black" />
    <path d="M93 51.5H91V53.5H93V51.5Z" fill="black" />
    <path d="M95 51.5H93V53.5H95V51.5Z" fill="black" />
    <path d="M97 51.5H95V53.5H97V51.5Z" fill="black" />
    <path d="M99 51.5H97V53.5H99V51.5Z" fill="black" />
    <path d="M101 51.5H99V53.5H101V51.5Z" fill="#B8CC40" />
    <path d="M103 51.5H101V53.5H103V51.5Z" fill="#E5FF4E" />
    <path d="M105 51.5H103V53.5H105V51.5Z" fill="#E5FF4E" />
    <path d="M107 51.5H105V53.5H107V51.5Z" fill="#E5FF4E" />
    <path d="M109 51.5H107V53.5H109V51.5Z" fill="#E5FF4E" />
    <path d="M111 51.5H109V53.5H111V51.5Z" fill="#E5FF4E" />
    <path d="M113 51.5H111V53.5H113V51.5Z" fill="#B8CC3F" />
    <path d="M115 51.5H113V53.5H115V51.5Z" fill="#B8CC3F" />
    <path d="M117 51.5H115V53.5H117V51.5Z" fill="#7487CB" />
    <path d="M119 51.5H117V53.5H119V51.5Z" fill="#7487CB" />
    <path d="M121 51.5H119V53.5H121V51.5Z" fill="black" />
    <path d="M89 49.5H87V51.5H89V49.5Z" fill="black" />
    <path d="M91 49.5H89V51.5H91V49.5Z" fill="black" />
    <path d="M93 49.5H91V51.5H93V49.5Z" fill="black" />
    <path d="M95 49.5H93V51.5H95V49.5Z" fill="black" />
    <path d="M97 49.5H95V51.5H97V49.5Z" fill="black" />
    <path d="M99 49.5H97V51.5H99V49.5Z" fill="black" />
    <path d="M101 49.5H99V51.5H101V49.5Z" fill="black" />
    <path d="M103 49.5H101V51.5H103V49.5Z" fill="#B8CC3F" />
    <path d="M105 49.5H103V51.5H105V49.5Z" fill="#E5FF4E" />
    <path d="M107 49.5H105V51.5H107V49.5Z" fill="#E5FF4E" />
    <path d="M109 49.5H107V51.5H109V49.5Z" fill="#B8CC3F" />
    <path d="M111 49.5H109V51.5H111V49.5Z" fill="#B8CC3F" />
    <path d="M113 49.5H111V51.5H113V49.5Z" fill="#7487CB" />
    <path d="M115 49.5H113V51.5H115V49.5Z" fill="#7487CB" />
    <path d="M117 49.5H115V51.5H117V49.5Z" fill="#91A8FF" />
    <path d="M119 49.5H117V51.5H119V49.5Z" fill="#91A8FF" />
    <path d="M121 49.5H119V51.5H121V49.5Z" fill="#7487CB" />
    <path d="M123 49.5H121V51.5H123V49.5Z" fill="black" />
    <path d="M89 47.5H87V49.5H89V47.5Z" fill="black" />
    <path d="M91 47.5H89V49.5H91V47.5Z" fill="black" />
    <path d="M93 47.5H91V49.5H93V47.5Z" fill="black" />
    <path d="M95 47.5H93V49.5H95V47.5Z" fill="black" />
    <path d="M97 47.5H95V49.5H97V47.5Z" fill="black" />
    <path d="M99 47.5H97V49.5H99V47.5Z" fill="black" />
    <path d="M101 47.5H99V49.5H101V47.5Z" fill="black" />
    <path d="M103 47.5H101V49.5H103V47.5Z" fill="black" />
    <path d="M105 47.5H103V49.5H105V47.5Z" fill="#B8CC40" />
    <path d="M107 47.5H105V49.5H107V47.5Z" fill="#B8CC3F" />
    <path d="M109 47.5H107V49.5H109V47.5Z" fill="#7487CB" />
    <path d="M111 47.5H109V49.5H111V47.5Z" fill="#7487CB" />
    <path d="M113 47.5H111V49.5H113V47.5Z" fill="#91A8FF" />
    <path d="M115 47.5H113V49.5H115V47.5Z" fill="#91A8FF" />
    <path d="M117 47.5H115V49.5H117V47.5Z" fill="#91A8FF" />
    <path d="M119 47.5H117V49.5H119V47.5Z" fill="#91A8FF" />
    <path d="M121 47.5H119V49.5H121V47.5Z" fill="#7487CB" />
    <path d="M123 47.5H121V49.5H123V47.5Z" fill="black" />
    <path d="M89 45.5H87V47.5H89V45.5Z" fill="black" />
    <path d="M91 45.5H89V47.5H91V45.5Z" fill="black" />
    <path d="M93 45.5H91V47.5H93V45.5Z" fill="black" />
    <path d="M95 45.5H93V47.5H95V45.5Z" fill="black" />
    <path d="M97 45.5H95V47.5H97V45.5Z" fill="black" />
    <path d="M99 45.5H97V47.5H99V45.5Z" fill="black" />
    <path d="M101 45.5H99V47.5H101V45.5Z" fill="black" />
    <path d="M103 45.5H101V47.5H103V45.5Z" fill="black" />
    <path d="M105 45.5H103V47.5H105V45.5Z" fill="#B8CC40" />
    <path d="M107 45.5H105V47.5H107V45.5Z" fill="#7487CB" />
    <path d="M109 45.5H107V47.5H109V45.5Z" fill="#91A8FF" />
    <path d="M111 45.5H109V47.5H111V45.5Z" fill="#91A8FF" />
    <path d="M113 45.5H111V47.5H113V45.5Z" fill="#91A8FF" />
    <path d="M115 45.5H113V47.5H115V45.5Z" fill="#91A8FF" />
    <path d="M117 45.5H115V47.5H117V45.5Z" fill="#91A8FF" />
    <path d="M119 45.5H117V47.5H119V45.5Z" fill="#91A8FF" />
    <path d="M121 45.5H119V47.5H121V45.5Z" fill="#7487CB" />
    <path d="M123 45.5H121V47.5H123V45.5Z" fill="black" />
    <path d="M89 43.5H87V45.5H89V43.5Z" fill="black" />
    <path d="M91 43.5H89V45.5H91V43.5Z" fill="black" />
    <path d="M93 43.5H91V45.5H93V43.5Z" fill="black" />
    <path d="M95 43.5H93V45.5H95V43.5Z" fill="black" />
    <path d="M97 43.5H95V45.5H97V43.5Z" fill="black" />
    <path d="M99 43.5H97V45.5H99V43.5Z" fill="black" />
    <path d="M101 43.5H99V45.5H101V43.5Z" fill="black" />
    <path d="M103 43.5H101V45.5H103V43.5Z" fill="black" />
    <path d="M105 43.5H103V45.5H105V43.5Z" fill="black" />
    <path d="M107 43.5H105V45.5H107V43.5Z" fill="#91A8FF" />
    <path d="M109 43.5H107V45.5H109V43.5Z" fill="#91A8FF" />
    <path d="M111 43.5H109V45.5H111V43.5Z" fill="#91A8FF" />
    <path d="M113 43.5H111V45.5H113V43.5Z" fill="#91A8FF" />
    <path d="M115 43.5H113V45.5H115V43.5Z" fill="#91A8FF" />
    <path d="M117 43.5H115V45.5H117V43.5Z" fill="#91A8FF" />
    <path d="M119 43.5H117V45.5H119V43.5Z" fill="#91A8FF" />
    <path d="M121 43.5H119V45.5H121V43.5Z" fill="#7487CB" />
    <path d="M123 43.5H121V45.5H123V43.5Z" fill="black" />
    <path d="M89 41.5H87V43.5H89V41.5Z" fill="black" />
    <path d="M91 41.5H89V43.5H91V41.5Z" fill="black" />
    <path d="M93 41.5H91V43.5H93V41.5Z" fill="black" />
    <path d="M95 41.5H93V43.5H95V41.5Z" fill="black" />
    <path d="M97 41.5H95V43.5H97V41.5Z" fill="black" />
    <path d="M99 41.5H97V43.5H99V41.5Z" fill="black" />
    <path d="M101 41.5H99V43.5H101V41.5Z" fill="black" />
    <path d="M103 41.5H101V43.5H103V41.5Z" fill="black" />
    <path d="M105 41.5H103V43.5H105V41.5Z" fill="black" />
    <path d="M107 41.5H105V43.5H107V41.5Z" fill="#91A8FF" />
    <path d="M109 41.5H107V43.5H109V41.5Z" fill="#91A8FF" />
    <path d="M111 41.5H109V43.5H111V41.5Z" fill="#91A8FF" />
    <path d="M113 41.5H111V43.5H113V41.5Z" fill="#91A8FF" />
    <path d="M115 41.5H113V43.5H115V41.5Z" fill="#91A8FF" />
    <path d="M117 41.5H115V43.5H117V41.5Z" fill="#91A8FF" />
    <path d="M119 41.5H117V43.5H119V41.5Z" fill="#91A8FF" />
    <path d="M121 41.5H119V43.5H121V41.5Z" fill="#7487CB" />
    <path d="M123 41.5H121V43.5H123V41.5Z" fill="black" />
    <path d="M89 39.5H87V41.5H89V39.5Z" fill="black" />
    <path d="M91 39.5H89V41.5H91V39.5Z" fill="black" />
    <path d="M93 39.5H91V41.5H93V39.5Z" fill="black" />
    <path d="M95 39.5H93V41.5H95V39.5Z" fill="black" />
    <path d="M97 39.5H95V41.5H97V39.5Z" fill="black" />
    <path d="M99 39.5H97V41.5H99V39.5Z" fill="black" />
    <path d="M101 39.5H99V41.5H101V39.5Z" fill="black" />
    <path d="M103 39.5H101V41.5H103V39.5Z" fill="black" />
    <path d="M105 39.5H103V41.5H105V39.5Z" fill="black" />
    <path d="M107 39.5H105V41.5H107V39.5Z" fill="#91A8FF" />
    <path d="M109 39.5H107V41.5H109V39.5Z" fill="#91A8FF" />
    <path d="M111 39.5H109V41.5H111V39.5Z" fill="#91A8FF" />
    <path d="M113 39.5H111V41.5H113V39.5Z" fill="#91A8FF" />
    <path d="M115 39.5H113V41.5H115V39.5Z" fill="#91A8FF" />
    <path d="M117 39.5H115V41.5H117V39.5Z" fill="#91A8FF" />
    <path d="M119 39.5H117V41.5H119V39.5Z" fill="#91A8FF" />
    <path d="M121 39.5H119V41.5H121V39.5Z" fill="#7487CB" />
    <path d="M123 39.5H121V41.5H123V39.5Z" fill="black" />
    <path d="M89 37.5H87V39.5H89V37.5Z" fill="black" />
    <path d="M91 37.5H89V39.5H91V37.5Z" fill="black" />
    <path d="M93 37.5H91V39.5H93V37.5Z" fill="black" />
    <path d="M95 37.5H93V39.5H95V37.5Z" fill="black" />
    <path d="M97 37.5H95V39.5H97V37.5Z" fill="black" />
    <path d="M99 37.5H97V39.5H99V37.5Z" fill="black" />
    <path d="M101 37.5H99V39.5H101V37.5Z" fill="black" />
    <path d="M103 37.5H101V39.5H103V37.5Z" fill="black" />
    <path d="M105 37.5H103V39.5H105V37.5Z" fill="black" />
    <path d="M107 37.5H105V39.5H107V37.5Z" fill="#91A8FF" />
    <path d="M109 37.5H107V39.5H109V37.5Z" fill="#91A8FF" />
    <path d="M111 37.5H109V39.5H111V37.5Z" fill="#91A8FF" />
    <path d="M113 37.5H111V39.5H113V37.5Z" fill="#91A8FF" />
    <path d="M115 37.5H113V39.5H115V37.5Z" fill="#91A8FF" />
    <path d="M117 37.5H115V39.5H117V37.5Z" fill="#91A8FF" />
    <path d="M119 37.5H117V39.5H119V37.5Z" fill="#91A8FF" />
    <path d="M121 37.5H119V39.5H121V37.5Z" fill="#7487CB" />
    <path d="M123 37.5H121V39.5H123V37.5Z" fill="black" />
    <path d="M83 3.5L85 3.5V1.5L83 1.5V3.5Z" fill="black" />
    <path d="M81 3.5L83 3.5V1.5L81 1.5V3.5Z" fill="black" />
    <path d="M79 3.5L81 3.5V1.5L79 1.5V3.5Z" fill="black" />
    <path d="M77 3.5L79 3.5V1.5L77 1.5V3.5Z" fill="black" />
    <path d="M83 5.5H85V3.5L83 3.5V5.5Z" fill="#FFF6DE" />
    <path d="M81 5.5H83V3.5L81 3.5V5.5Z" fill="#FFF6DE" />
    <path d="M79 5.5H81V3.5L79 3.5V5.5Z" fill="#FFF6DE" />
    <path d="M77 5.5H79V3.5L77 3.5V5.5Z" fill="#FFF6DE" />
    <path d="M75 5.5H77V3.5L75 3.5V5.5Z" fill="#FFF6DE" />
    <path d="M75 3.5L77 3.5V1.5L75 1.5V3.5Z" fill="black" />
    <path d="M73 5.5H75V3.5L73 3.5V5.5Z" fill="black" />
    <path d="M71 5.5H73V3.5L71 3.5V5.5Z" fill="black" />
    <path d="M83 7.5H85V5.5H83V7.5Z" fill="#F9E49E" />
    <path d="M81 7.5H83V5.5H81V7.5Z" fill="#F9E49E" />
    <path d="M79 7.5H81V5.5H79V7.5Z" fill="#F9E49E" />
    <path d="M77 7.5H79V5.5H77V7.5Z" fill="#F9E49E" />
    <path d="M75 7.5H77V5.5H75V7.5Z" fill="#F9E49E" />
    <path d="M73 7.5H75V5.5H73V7.5Z" fill="#FFF6DE" />
    <path d="M71 7.5H73V5.5H71V7.5Z" fill="#FFF6DE" />
    <path d="M69 7.5H71V5.5H69V7.5Z" fill="black" />
    <path d="M67 7.5H69V5.5H67V7.5Z" fill="black" />
    <path d="M83 9.5H85V7.5H83V9.5Z" fill="#F9E49E" />
    <path d="M81 9.5H83V7.5H81V9.5Z" fill="#F9E49E" />
    <path d="M79 9.5H81V7.5H79V9.5Z" fill="#F9E49E" />
    <path d="M77 9.5H79V7.5H77V9.5Z" fill="#F9E49E" />
    <path d="M75 9.5H77V7.5H75V9.5Z" fill="#F9E49E" />
    <path d="M73 9.5H75V7.5H73V9.5Z" fill="#F9E49E" />
    <path d="M71 9.5H73V7.5H71V9.5Z" fill="#F9E49E" />
    <path d="M69 9.5H71V7.5H69V9.5Z" fill="#FFF6DE" />
    <path d="M67 9.5H69V7.5H67V9.5Z" fill="black" />
    <path d="M65 9.5H67V7.5H65V9.5Z" fill="black" />
    <path d="M83 11.5H85V9.5H83V11.5Z" fill="#F9E49E" />
    <path d="M81 11.5H83V9.5H81V11.5Z" fill="#F9E49E" />
    <path d="M79 11.5H81V9.5H79V11.5Z" fill="#F9E49E" />
    <path d="M77 11.5H79V9.5H77V11.5Z" fill="#F9E49E" />
    <path d="M75 11.5H77V9.5H75V11.5Z" fill="#F9E49E" />
    <path d="M73 11.5H75V9.5H73V11.5Z" fill="#F9E49E" />
    <path d="M71 11.5H73V9.5H71V11.5Z" fill="#F9E49E" />
    <path d="M69 11.5H71V9.5H69V11.5Z" fill="#F9E49E" />
    <path d="M67 11.5H69V9.5H67V11.5Z" fill="#FFF6DE" />
    <path d="M65 11.5H67V9.5H65V11.5Z" fill="#FFF6DE" />
    <path d="M63 11.5H65V9.5H63V11.5Z" fill="black" />
    <path d="M61 11.5H63V9.5H61V11.5Z" fill="black" />
    <path d="M83 13.5H85V11.5H83V13.5Z" fill="#F9E49E" />
    <path d="M81 13.5H83V11.5H81V13.5Z" fill="#F9E49E" />
    <path d="M79 13.5H81V11.5H79V13.5Z" fill="#F9E49E" />
    <path d="M77 13.5H79V11.5H77V13.5Z" fill="#F9E49E" />
    <path d="M75 13.5H77V11.5H75V13.5Z" fill="#F9E49E" />
    <path d="M73 13.5H75V11.5H73V13.5Z" fill="#F9E49E" />
    <path d="M71 13.5H73V11.5H71V13.5Z" fill="#F9E49E" />
    <path d="M69 13.5H71V11.5H69V13.5Z" fill="#F9E49E" />
    <path d="M67 13.5H69V11.5H67V13.5Z" fill="#F9E49E" />
    <path d="M65 13.5H67V11.5H65V13.5Z" fill="#F9E49E" />
    <path d="M63 13.5H65V11.5H63V13.5Z" fill="#FFF6DE" />
    <path d="M61 13.5H63V11.5H61V13.5Z" fill="#FFF6DE" />
    <path d="M59 13.5H61V11.5H59V13.5Z" fill="black" />
    <path d="M83 15.5H85V13.5H83V15.5Z" fill="#F9E49E" />
    <path d="M81 15.5H83V13.5H81V15.5Z" fill="#F9E49E" />
    <path d="M79 15.5H81V13.5H79V15.5Z" fill="#F9E49E" />
    <path d="M77 15.5H79V13.5H77V15.5Z" fill="#F9E49E" />
    <path d="M75 15.5H77V13.5H75V15.5Z" fill="#F9E49E" />
    <path d="M73 15.5H75V13.5H73V15.5Z" fill="#F9E49E" />
    <path d="M71 15.5H73V13.5H71V15.5Z" fill="#F9E49E" />
    <path d="M69 15.5H71V13.5H69V15.5Z" fill="#F9E49E" />
    <path d="M67 15.5H69V13.5H67V15.5Z" fill="#F9E49E" />
    <path d="M65 15.5H67V13.5H65V15.5Z" fill="#F9E49E" />
    <path d="M63 15.5H65V13.5H63V15.5Z" fill="#F9E49E" />
    <path d="M61 15.5H63V13.5H61V15.5Z" fill="#F9E49E" />
    <path d="M59 15.5H61V13.5H59V15.5Z" fill="#FFF6DE" />
    <path d="M57 15.5H59V13.5H57V15.5Z" fill="black" />
    <path d="M83 17.5H85V15.5H83V17.5Z" fill="#F9E49E" />
    <path d="M81 17.5H83V15.5H81V17.5Z" fill="#F9E49E" />
    <path d="M79 17.5H81V15.5H79V17.5Z" fill="#F9E49E" />
    <path d="M77 17.5H79V15.5H77V17.5Z" fill="#F9E49E" />
    <path d="M75 17.5H77V15.5H75V17.5Z" fill="#F9E49E" />
    <path d="M73 17.5H75V15.5H73V17.5Z" fill="#F9E49E" />
    <path d="M71 17.5H73V15.5H71V17.5Z" fill="#F9E49E" />
    <path d="M69 17.5H71V15.5H69V17.5Z" fill="#F9E49E" />
    <path d="M67 17.5H69V15.5H67V17.5Z" fill="#F9E49E" />
    <path d="M65 17.5H67V15.5H65V17.5Z" fill="#F9E49E" />
    <path d="M63 17.5H65V15.5H63V17.5Z" fill="#F9E49E" />
    <path d="M61 17.5H63V15.5H61V17.5Z" fill="#F9E49E" />
    <path d="M59 17.5H61V15.5H59V17.5Z" fill="#FFF6DE" />
    <path d="M57 17.5H59V15.5H57V17.5Z" fill="black" />
    <path d="M83 19.5H85V17.5H83V19.5Z" fill="#F9E49E" />
    <path d="M81 19.5H83V17.5H81V19.5Z" fill="#F9E49E" />
    <path d="M79 19.5H81V17.5H79V19.5Z" fill="#F9E49E" />
    <path d="M77 19.5H79V17.5H77V19.5Z" fill="#F9E49E" />
    <path d="M75 19.5H77V17.5H75V19.5Z" fill="#F9E49E" />
    <path d="M73 19.5H75V17.5H73V19.5Z" fill="#F9E49E" />
    <path d="M71 19.5H73V17.5H71V19.5Z" fill="#F9E49E" />
    <path d="M69 19.5H71V17.5H69V19.5Z" fill="#F9E49E" />
    <path d="M67 19.5H69V17.5H67V19.5Z" fill="#F9E49E" />
    <path d="M65 19.5H67V17.5H65V19.5Z" fill="#F9E49E" />
    <path d="M63 19.5H65V17.5H63V19.5Z" fill="#F9E49E" />
    <path d="M61 19.5H63V17.5H61V19.5Z" fill="#F9E49E" />
    <path d="M59 19.5H61V17.5H59V19.5Z" fill="#F9E49E" />
    <path d="M57 19.5H59V17.5H57V19.5Z" fill="#FFF6DE" />
    <path d="M55 19.5H57V17.5H55V19.5Z" fill="black" />
    <path d="M83 21.5H85V19.5H83V21.5Z" fill="black" />
    <path d="M81 21.5H83V19.5H81V21.5Z" fill="#F0D594" />
    <path d="M79 21.5H81V19.5H79V21.5Z" fill="#F0D594" />
    <path d="M77 21.5H79V19.5H77V21.5Z" fill="#F9E49E" />
    <path d="M75 21.5H77V19.5H75V21.5Z" fill="#F9E49E" />
    <path d="M73 21.5H75V19.5H73V21.5Z" fill="#F9E49E" />
    <path d="M71 21.5H73V19.5H71V21.5Z" fill="#F9E49E" />
    <path d="M69 21.5H71V19.5H69V21.5Z" fill="#F9E49E" />
    <path d="M67 21.5H69V19.5H67V21.5Z" fill="#F9E49E" />
    <path d="M65 21.5H67V19.5H65V21.5Z" fill="#F9E49E" />
    <path d="M63 21.5H65V19.5H63V21.5Z" fill="#F9E49E" />
    <path d="M61 21.5H63V19.5H61V21.5Z" fill="#F9E49E" />
    <path d="M59 21.5H61V19.5H59V21.5Z" fill="#F9E49E" />
    <path d="M57 21.5H59V19.5H57V21.5Z" fill="#FFF6DE" />
    <path d="M55 21.5H57V19.5H55V21.5Z" fill="black" />
    <path d="M53 21.5H55V19.5H53V21.5Z" fill="black" />
    <path d="M83 23.5H85V21.5H83V23.5Z" fill="#292E3D" />
    <path d="M81 23.5H83V21.5H81V23.5Z" fill="black" />
    <path d="M79 23.5H81V21.5H79V23.5Z" fill="black" />
    <path d="M77 23.5H79V21.5H77V23.5Z" fill="#F0D594" />
    <path d="M75 23.5H77V21.5H75V23.5Z" fill="#F9E49E" />
    <path d="M73 23.5H75V21.5H73V23.5Z" fill="#F9E49E" />
    <path d="M71 23.5H73V21.5H71V23.5Z" fill="#F9E49E" />
    <path d="M69 23.5H71V21.5H69V23.5Z" fill="#F9E49E" />
    <path d="M67 23.5H69V21.5H67V23.5Z" fill="#F9E49E" />
    <path d="M65 23.5H67V21.5H65V23.5Z" fill="#F9E49E" />
    <path d="M63 23.5H65V21.5H63V23.5Z" fill="#F9E49E" />
    <path d="M61 23.5H63V21.5H61V23.5Z" fill="#F0D594" />
    <path d="M59 23.5H61V21.5H59V23.5Z" fill="#F0D594" />
    <path d="M57 23.5H59V21.5H57V23.5Z" fill="#38859F" />
    <path d="M55 23.5H57V21.5H55V23.5Z" fill="#38859F" />
    <path d="M53 23.5H55V21.5H53V23.5Z" fill="black" />
    <path d="M83 25.5H85V23.5H83V25.5Z" fill="black" />
    <path d="M81 25.5H83V23.5H81V25.5Z" fill="#292E3D" />
    <path d="M79 25.5H81V23.5H79V25.5Z" fill="black" />
    <path d="M77 25.5H79V23.5H77V25.5Z" fill="black" />
    <path d="M75 25.5H77V23.5H75V25.5Z" fill="black" />
    <path d="M73 25.5H75V23.5H73V25.5Z" fill="#F9E49E" />
    <path d="M71 25.5H73V23.5H71V25.5Z" fill="#F9E49E" />
    <path d="M69 25.5H71V23.5H69V25.5Z" fill="#F9E49E" />
    <path d="M67 25.5H69V23.5H67V25.5Z" fill="#F9E49E" />
    <path d="M65 25.5H67V23.5H65V25.5Z" fill="#F0D594" />
    <path d="M63 25.5H65V23.5H63V25.5Z" fill="#F0D594" />
    <path d="M61 25.5H63V23.5H61V25.5Z" fill="#38859F" />
    <path d="M59 25.5H61V23.5H59V25.5Z" fill="#38859F" />
    <path d="M57 25.5H59V23.5H57V25.5Z" fill="#56C2E7" />
    <path d="M55 25.5H57V23.5H55V25.5Z" fill="#56C2E7" />
    <path d="M53 25.5H55V23.5H53V25.5Z" fill="#8FE7FF" />
    <path d="M51 25.5H53V23.5H51V25.5Z" fill="black" />
    <path d="M83 27.5H85V25.5H83V27.5Z" fill="black" />
    <path d="M81 27.5H83V25.5H81V27.5Z" fill="black" />
    <path d="M79 27.5H81V25.5H79V27.5Z" fill="#292E3D" />
    <path d="M77 27.5H79V25.5H77V27.5Z" fill="#292E3D" />
    <path d="M75 27.5H77V25.5H75V27.5Z" fill="black" />
    <path d="M73 27.5H75V25.5H73V27.5Z" fill="black" />
    <path d="M71 27.5H73V25.5H71V27.5Z" fill="#F9E49E" />
    <path d="M69 27.5H71V25.5H69V27.5Z" fill="#F0D594" />
    <path d="M67 27.5H69V25.5H67V27.5Z" fill="#F0D594" />
    <path d="M65 27.5H67V25.5H65V27.5Z" fill="#38859F" />
    <path d="M63 27.5H65V25.5H63V27.5Z" fill="#38859F" />
    <path d="M61 27.5H63V25.5H61V27.5Z" fill="#56C2E7" />
    <path d="M59 27.5H61V25.5H59V27.5Z" fill="#56C2E7" />
    <path d="M57 27.5H59V25.5H57V27.5Z" fill="#56C2E7" />
    <path d="M55 27.5H57V25.5H55V27.5Z" fill="#56C2E7" />
    <path d="M53 27.5H55V25.5H53V27.5Z" fill="#8FE7FF" />
    <path d="M51 27.5H53V25.5H51V27.5Z" fill="black" />
    <path d="M83 29.5H85V27.5H83V29.5Z" fill="black" />
    <path d="M81 29.5H83V27.5H81V29.5Z" fill="black" />
    <path d="M79 29.5H81V27.5H79V29.5Z" fill="black" />
    <path d="M77 29.5H79V27.5H77V29.5Z" fill="black" />
    <path d="M75 29.5H77V27.5H75V29.5Z" fill="#292E3D" />
    <path d="M73 29.5H75V27.5H73V29.5Z" fill="black" />
    <path d="M71 29.5H73V27.5H71V29.5Z" fill="black" />
    <path d="M69 29.5H71V27.5H69V29.5Z" fill="#38859F" />
    <path d="M67 29.5H69V27.5H67V29.5Z" fill="#38859F" />
    <path d="M65 29.5H67V27.5H65V29.5Z" fill="#56C2E7" />
    <path d="M63 29.5H65V27.5H63V29.5Z" fill="#56C2E7" />
    <path d="M61 29.5H63V27.5H61V29.5Z" fill="#56C2E7" />
    <path d="M59 29.5H61V27.5H59V29.5Z" fill="#56C2E7" />
    <path d="M57 29.5H59V27.5H57V29.5Z" fill="#56C2E7" />
    <path d="M55 29.5H57V27.5H55V29.5Z" fill="#56C2E7" />
    <path d="M53 29.5H55V27.5H53V29.5Z" fill="#8FE7FF" />
    <path d="M51 29.5H53V27.5H51V29.5Z" fill="black" />
    <path d="M83 31.5H85V29.5H83V31.5Z" fill="black" />
    <path d="M81 31.5H83V29.5H81V31.5Z" fill="black" />
    <path d="M79 31.5H81V29.5H79V31.5Z" fill="black" />
    <path d="M77 31.5H79V29.5H77V31.5Z" fill="black" />
    <path d="M75 31.5H77V29.5H75V31.5Z" fill="black" />
    <path d="M73 31.5H75V29.5H73V31.5Z" fill="#292E3D" />
    <path d="M71 31.5H73V29.5H71V31.5Z" fill="black" />
    <path d="M69 31.5H71V29.5H69V31.5Z" fill="#38859F" />
    <path d="M67 31.5H69V29.5H67V31.5Z" fill="#56C2E7" />
    <path d="M65 31.5H67V29.5H65V31.5Z" fill="#56C2E7" />
    <path d="M63 31.5H65V29.5H63V31.5Z" fill="#56C2E7" />
    <path d="M61 31.5H63V29.5H61V31.5Z" fill="#56C2E7" />
    <path d="M59 31.5H61V29.5H59V31.5Z" fill="#56C2E7" />
    <path d="M57 31.5H59V29.5H57V31.5Z" fill="#56C2E7" />
    <path d="M55 31.5H57V29.5H55V31.5Z" fill="#56C2E7" />
    <path d="M53 31.5H55V29.5H53V31.5Z" fill="#8FE7FF" />
    <path d="M51 31.5H53V29.5H51V31.5Z" fill="black" />
    <path d="M83 33.5H85V31.5H83V33.5Z" fill="black" />
    <path d="M81 33.5H83V31.5H81V33.5Z" fill="black" />
    <path d="M79 33.5H81V31.5H79V33.5Z" fill="black" />
    <path d="M77 33.5H79V31.5H77V33.5Z" fill="black" />
    <path d="M75 33.5H77V31.5H75V33.5Z" fill="black" />
    <path d="M73 33.5H75V31.5H73V33.5Z" fill="#292E3D" />
    <path d="M71 33.5H73V31.5H71V33.5Z" fill="black" />
    <path d="M69 33.5H71V31.5H69V33.5Z" fill="black" />
    <path d="M67 33.5H69V31.5H67V33.5Z" fill="#56C2E7" />
    <path d="M65 33.5H67V31.5H65V33.5Z" fill="#56C2E7" />
    <path d="M63 33.5H65V31.5H63V33.5Z" fill="#56C2E7" />
    <path d="M61 33.5H63V31.5H61V33.5Z" fill="#56C2E7" />
    <path d="M59 33.5H61V31.5H59V33.5Z" fill="#56C2E7" />
    <path d="M57 33.5H59V31.5H57V33.5Z" fill="#56C2E7" />
    <path d="M55 33.5H57V31.5H55V33.5Z" fill="#56C2E7" />
    <path d="M53 33.5H55V31.5H53V33.5Z" fill="#8FE7FF" />
    <path d="M51 33.5H53V31.5H51V33.5Z" fill="black" />
    <path d="M83 35.5H85V33.5H83V35.5Z" fill="black" />
    <path d="M81 35.5H83V33.5H81V35.5Z" fill="black" />
    <path d="M79 35.5H81V33.5H79V35.5Z" fill="black" />
    <path d="M77 35.5H79V33.5H77V35.5Z" fill="black" />
    <path d="M75 35.5H77V33.5H75V35.5Z" fill="black" />
    <path d="M73 35.5H75V33.5H73V35.5Z" fill="black" />
    <path d="M71 35.5H73V33.5H71V35.5Z" fill="#292E3D" />
    <path d="M69 35.5H71V33.5H69V35.5Z" fill="black" />
    <path d="M67 35.5H69V33.5H67V35.5Z" fill="#56C2E7" />
    <path d="M65 35.5H67V33.5H65V35.5Z" fill="#56C2E7" />
    <path d="M63 35.5H65V33.5H63V35.5Z" fill="#56C2E7" />
    <path d="M61 35.5H63V33.5H61V35.5Z" fill="#EF3A78" />
    <path d="M59 35.5H61V33.5H59V35.5Z" fill="#EF3A78" />
    <path d="M57 35.5H59V33.5H57V35.5Z" fill="#56C2E7" />
    <path d="M55 35.5H57V33.5H55V35.5Z" fill="#56C2E7" />
    <path d="M53 35.5H55V33.5H53V35.5Z" fill="#8FE7FF" />
    <path d="M51 35.5H53V33.5H51V35.5Z" fill="black" />
    <path d="M83 37.5H85V35.5H83V37.5Z" fill="black" />
    <path d="M81 37.5H83V35.5H81V37.5Z" fill="black" />
    <path d="M79 37.5H81V35.5H79V37.5Z" fill="black" />
    <path d="M77 37.5H79V35.5H77V37.5Z" fill="black" />
    <path d="M75 37.5H77V35.5H75V37.5Z" fill="black" />
    <path d="M73 37.5H75V35.5H73V37.5Z" fill="black" />
    <path d="M71 37.5H73V35.5H71V37.5Z" fill="#292E3D" />
    <path d="M69 37.5H71V35.5H69V37.5Z" fill="black" />
    <path d="M67 37.5H69V35.5H67V37.5Z" fill="#56C2E7" />
    <path d="M65 37.5H67V35.5H65V37.5Z" fill="#56C2E7" />
    <path d="M63 37.5H65V35.5H63V37.5Z" fill="#56C2E7" />
    <path d="M61 37.5H63V35.5H61V37.5Z" fill="#FE62B0" />
    <path d="M59 37.5H61V35.5H59V37.5Z" fill="#EF3A78" />
    <path d="M57 37.5H59V35.5H57V37.5Z" fill="#56C2E7" />
    <path d="M55 37.5H57V35.5H55V37.5Z" fill="#56C2E7" />
    <path d="M53 37.5H55V35.5H53V37.5Z" fill="#8FE7FF" />
    <path d="M51 37.5H53V35.5H51V37.5Z" fill="black" />
    <path d="M83 73.5H85V71.5H83V73.5Z" fill="black" />
    <path d="M81 73.5H83V71.5H81V73.5Z" fill="black" />
    <path d="M79 73.5H81V71.5H79V73.5Z" fill="black" />
    <path d="M77 73.5H79V71.5H77V73.5Z" fill="black" />
    <path d="M83 71.5H85V69.5H83V71.5Z" fill="#6BECD4" />
    <path d="M81 71.5H83V69.5H81V71.5Z" fill="#6BECD4" />
    <path d="M79 71.5H81V69.5H79V71.5Z" fill="#6BECD4" />
    <path d="M77 71.5H79V69.5H77V71.5Z" fill="#6BECD4" />
    <path d="M75 71.5H77V69.5H75V71.5Z" fill="#6BECD4" />
    <path d="M75 73.5H77V71.5H75V73.5Z" fill="black" />
    <path d="M73 71.5H75V69.5H73V71.5Z" fill="black" />
    <path d="M71 71.5H73V69.5H71V71.5Z" fill="black" />
    <path d="M83 69.5H85V67.5H83V69.5Z" fill="#76FFCF" />
    <path d="M81 69.5H83V67.5H81V69.5Z" fill="#76FFCF" />
    <path d="M79 69.5H81V67.5H79V69.5Z" fill="#76FFCF" />
    <path d="M77 69.5H79V67.5H77V69.5Z" fill="#76FFCF" />
    <path d="M75 69.5H77V67.5H75V69.5Z" fill="#76FFCF" />
    <path d="M73 69.5H75V67.5H73V69.5Z" fill="#6BECD4" />
    <path d="M71 69.5H73V67.5H71V69.5Z" fill="#6BECD4" />
    <path d="M69 69.5H71V67.5H69V69.5Z" fill="black" />
    <path d="M67 69.5H69V67.5H67V69.5Z" fill="black" />
    <path d="M83 67.5H85V65.5H83V67.5Z" fill="#76FFCF" />
    <path d="M81 67.5H83V65.5H81V67.5Z" fill="#76FFCF" />
    <path d="M79 67.5H81V65.5H79V67.5Z" fill="#76FFCF" />
    <path d="M77 67.5H79V65.5H77V67.5Z" fill="#76FFCF" />
    <path d="M75 67.5H77V65.5H75V67.5Z" fill="#76FFCF" />
    <path d="M73 67.5H75V65.5H73V67.5Z" fill="#76FFCF" />
    <path d="M71 67.5H73V65.5H71V67.5Z" fill="#76FFCF" />
    <path d="M69 67.5H71V65.5H69V67.5Z" fill="#6BECD4" />
    <path d="M67 67.5H69V65.5H67V67.5Z" fill="black" />
    <path d="M65 67.5H67V65.5H65V67.5Z" fill="black" />
    <path d="M83 65.5H85V63.5H83V65.5Z" fill="#76FFCF" />
    <path d="M81 65.5H83V63.5H81V65.5Z" fill="#76FFCF" />
    <path d="M79 65.5H81V63.5H79V65.5Z" fill="#76FFCF" />
    <path d="M77 65.5H79V63.5H77V65.5Z" fill="#76FFCF" />
    <path d="M75 65.5H77V63.5H75V65.5Z" fill="#76FFCF" />
    <path d="M73 65.5H75V63.5H73V65.5Z" fill="#76FFCF" />
    <path d="M71 65.5H73V63.5H71V65.5Z" fill="#76FFCF" />
    <path d="M69 65.5H71V63.5H69V65.5Z" fill="#76FFCF" />
    <path d="M67 65.5H69V63.5H67V65.5Z" fill="#6BECD4" />
    <path d="M65 65.5H67V63.5H65V65.5Z" fill="#6BECD4" />
    <path d="M63 65.5H65V63.5H63V65.5Z" fill="black" />
    <path d="M61 65.5H63V63.5H61V65.5Z" fill="black" />
    <path d="M83 63.5H85V61.5H83V63.5Z" fill="#76FFCF" />
    <path d="M81 63.5H83V61.5H81V63.5Z" fill="#76FFCF" />
    <path d="M79 63.5H81V61.5H79V63.5Z" fill="#76FFCF" />
    <path d="M77 63.5H79V61.5H77V63.5Z" fill="#76FFCF" />
    <path d="M75 63.5H77V61.5H75V63.5Z" fill="#76FFCF" />
    <path d="M73 63.5H75V61.5H73V63.5Z" fill="#76FFCF" />
    <path d="M71 63.5H73V61.5H71V63.5Z" fill="#76FFCF" />
    <path d="M69 63.5H71V61.5H69V63.5Z" fill="#76FFCF" />
    <path d="M67 63.5H69V61.5H67V63.5Z" fill="#76FFCF" />
    <path d="M65 63.5H67V61.5H65V63.5Z" fill="#76FFCF" />
    <path d="M63 63.5H65V61.5H63V63.5Z" fill="#6BECD4" />
    <path d="M61 63.5H63V61.5H61V63.5Z" fill="#6BECD4" />
    <path d="M59 63.5H61V61.5H59V63.5Z" fill="black" />
    <path d="M83 61.5H85V59.5H83V61.5Z" fill="#76FFCF" />
    <path d="M81 61.5H83V59.5H81V61.5Z" fill="#76FFCF" />
    <path d="M79 61.5H81V59.5H79V61.5Z" fill="#76FFCF" />
    <path d="M77 61.5H79V59.5H77V61.5Z" fill="#76FFCF" />
    <path d="M75 61.5H77V59.5H75V61.5Z" fill="#76FFCF" />
    <path d="M73 61.5H75V59.5H73V61.5Z" fill="#76FFCF" />
    <path d="M71 61.5H73V59.5H71V61.5Z" fill="#76FFCF" />
    <path d="M69 61.5H71V59.5H69V61.5Z" fill="#76FFCF" />
    <path d="M67 61.5H69V59.5H67V61.5Z" fill="#76FFCF" />
    <path d="M65 61.5H67V59.5H65V61.5Z" fill="#76FFCF" />
    <path d="M63 61.5H65V59.5H63V61.5Z" fill="#76FFCF" />
    <path d="M61 61.5H63V59.5H61V61.5Z" fill="#76FFCF" />
    <path d="M59 61.5H61V59.5H59V61.5Z" fill="#6BECD4" />
    <path d="M57 61.5H59V59.5H57V61.5Z" fill="black" />
    <path d="M83 59.5H85V57.5H83V59.5Z" fill="#76FFCF" />
    <path d="M81 59.5H83V57.5H81V59.5Z" fill="#76FFCF" />
    <path d="M79 59.5H81V57.5H79V59.5Z" fill="#76FFCF" />
    <path d="M77 59.5H79V57.5H77V59.5Z" fill="#76FFCF" />
    <path d="M75 59.5H77V57.5H75V59.5Z" fill="#76FFCF" />
    <path d="M73 59.5H75V57.5H73V59.5Z" fill="#76FFCF" />
    <path d="M71 59.5H73V57.5H71V59.5Z" fill="#76FFCF" />
    <path d="M69 59.5H71V57.5H69V59.5Z" fill="#76FFCF" />
    <path d="M67 59.5H69V57.5H67V59.5Z" fill="#76FFCF" />
    <path d="M65 59.5H67V57.5H65V59.5Z" fill="#76FFCF" />
    <path d="M63 59.5H65V57.5H63V59.5Z" fill="#76FFCF" />
    <path d="M61 59.5H63V57.5H61V59.5Z" fill="#76FFCF" />
    <path d="M59 59.5H61V57.5H59V59.5Z" fill="#6BECD4" />
    <path d="M57 59.5H59V57.5H57V59.5Z" fill="black" />
    <path d="M83 57.5H85V55.5H83V57.5Z" fill="#6BECD4" />
    <path d="M81 57.5H83V55.5H81V57.5Z" fill="#6BECD4" />
    <path d="M79 57.5H81V55.5H79V57.5Z" fill="#6BECD4" />
    <path d="M77 57.5H79V55.5H77V57.5Z" fill="#6BECD4" />
    <path d="M75 57.5H77V55.5H75V57.5Z" fill="#76FFCF" />
    <path d="M73 57.5H75V55.5H73V57.5Z" fill="#76FFCF" />
    <path d="M71 57.5H73V55.5H71V57.5Z" fill="#76FFCF" />
    <path d="M69 57.5H71V55.5H69V57.5Z" fill="#76FFCF" />
    <path d="M67 57.5H69V55.5H67V57.5Z" fill="#76FFCF" />
    <path d="M65 57.5H67V55.5H65V57.5Z" fill="#76FFCF" />
    <path d="M63 57.5H65V55.5H63V57.5Z" fill="#76FFCF" />
    <path d="M61 57.5H63V55.5H61V57.5Z" fill="#76FFCF" />
    <path d="M59 57.5H61V55.5H59V57.5Z" fill="#76FFCF" />
    <path d="M57 57.5H59V55.5H57V57.5Z" fill="#6BECD4" />
    <path d="M55 57.5H57V55.5H55V57.5Z" fill="black" />
    <path d="M83 55.5H85V53.5H83V55.5Z" fill="black" />
    <path d="M81 55.5H83V53.5H81V55.5Z" fill="black" />
    <path d="M79 55.5H81V53.5H79V55.5Z" fill="black" />
    <path d="M75 55.5H77V53.5H75V55.5Z" fill="#6BECD4" />
    <path d="M77 55.5H79V53.5H77V55.5Z" fill="#6BECD4" />
    <path d="M73 55.5H75V53.5H73V55.5Z" fill="#76FFCF" />
    <path d="M71 55.5H73V53.5H71V55.5Z" fill="#76FFCF" />
    <path d="M69 55.5H71V53.5H69V55.5Z" fill="#76FFCF" />
    <path d="M67 55.5H69V53.5H67V55.5Z" fill="#76FFCF" />
    <path d="M65 55.5H67V53.5H65V55.5Z" fill="#76FFCF" />
    <path d="M63 55.5H65V53.5H63V55.5Z" fill="#76FFCF" />
    <path d="M61 55.5H63V53.5H61V55.5Z" fill="#76FFCF" />
    <path d="M59 55.5H61V53.5H59V55.5Z" fill="#76FFCF" />
    <path d="M57 55.5H59V53.5H57V55.5Z" fill="#6BECD4" />
    <path d="M55 55.5H57V53.5H55V55.5Z" fill="black" />
    <path d="M53 55.5H55V53.5H53V55.5Z" fill="black" />
    <path d="M83 53.5H85V51.5H83V53.5Z" fill="black" />
    <path d="M81 53.5H83V51.5H81V53.5Z" fill="black" />
    <path d="M79 53.5H81V51.5H79V53.5Z" fill="black" />
    <path d="M77 53.5H79V51.5H77V53.5Z" fill="black" />
    <path d="M75 53.5H77V51.5H75V53.5Z" fill="black" />
    <path d="M73 53.5H75V51.5H73V53.5Z" fill="#6BECD4" />
    <path d="M71 53.5H73V51.5H71V53.5Z" fill="#76FFCF" />
    <path d="M69 53.5H71V51.5H69V53.5Z" fill="#76FFCF" />
    <path d="M67 53.5H69V51.5H67V53.5Z" fill="#76FFCF" />
    <path d="M65 53.5H67V51.5H65V53.5Z" fill="#76FFCF" />
    <path d="M63 53.5H65V51.5H63V53.5Z" fill="#76FFCF" />
    <path d="M61 53.5H63V51.5H61V53.5Z" fill="#6BECD4" />
    <path d="M59 53.5H61V51.5H59V53.5Z" fill="#6BECD4" />
    <path d="M57 53.5H59V51.5H57V53.5Z" fill="#38859F" />
    <path d="M55 53.5H57V51.5H55V53.5Z" fill="#38859F" />
    <path d="M53 53.5H55V51.5H53V53.5Z" fill="black" />
    <path d="M83 51.5H85V49.5H83V51.5Z" fill="black" />
    <path d="M81 51.5H83V49.5H81V51.5Z" fill="black" />
    <path d="M79 51.5H81V49.5H79V51.5Z" fill="black" />
    <path d="M77 51.5H79V49.5H77V51.5Z" fill="#292E3D" />
    <path d="M75 51.5H77V49.5H75V51.5Z" fill="black" />
    <path d="M73 51.5H75V49.5H73V51.5Z" fill="black" />
    <path d="M71 51.5H73V49.5H71V51.5Z" fill="#6BECD4" />
    <path d="M69 51.5H71V49.5H69V51.5Z" fill="#76FFCF" />
    <path d="M67 51.5H69V49.5H67V51.5Z" fill="#76FFCF" />
    <path d="M65 51.5H67V49.5H65V51.5Z" fill="#6BECD4" />
    <path d="M63 51.5H65V49.5H63V51.5Z" fill="#6BECD4" />
    <path d="M61 51.5H63V49.5H61V51.5Z" fill="#38859F" />
    <path d="M59 51.5H61V49.5H59V51.5Z" fill="#38859F" />
    <path d="M57 51.5H59V49.5H57V51.5Z" fill="#56C2E7" />
    <path d="M55 51.5H57V49.5H55V51.5Z" fill="#56C2E7" />
    <path d="M53 51.5H55V49.5H53V51.5Z" fill="#8FE7FF" />
    <path d="M51 51.5H53V49.5H51V51.5Z" fill="black" />
    <path d="M83 49.5H85V47.5H83V49.5Z" fill="black" />
    <path d="M81 49.5H83V47.5H81V49.5Z" fill="black" />
    <path d="M79 49.5H81V47.5H79V49.5Z" fill="black" />
    <path d="M77 49.5H79V47.5H77V49.5Z" fill="black" />
    <path d="M75 49.5H77V47.5H75V49.5Z" fill="#292E3D" />
    <path d="M73 49.5H75V47.5H73V49.5Z" fill="black" />
    <path d="M71 49.5H73V47.5H71V49.5Z" fill="black" />
    <path d="M69 49.5H71V47.5H69V49.5Z" fill="#6BECD4" />
    <path d="M67 49.5H69V47.5H67V49.5Z" fill="#6BECD4" />
    <path d="M65 49.5H67V47.5H65V49.5Z" fill="#38859F" />
    <path d="M63 49.5H65V47.5H63V49.5Z" fill="#38859F" />
    <path d="M61 49.5H63V47.5H61V49.5Z" fill="#56C2E7" />
    <path d="M59 49.5H61V47.5H59V49.5Z" fill="#56C2E7" />
    <path d="M57 49.5H59V47.5H57V49.5Z" fill="#56C2E7" />
    <path d="M55 49.5H57V47.5H55V49.5Z" fill="#56C2E7" />
    <path d="M53 49.5H55V47.5H53V49.5Z" fill="#8FE7FF" />
    <path d="M51 49.5H53V47.5H51V49.5Z" fill="black" />
    <path d="M83 47.5H85V45.5H83V47.5Z" fill="black" />
    <path d="M81 47.5H83V45.5H81V47.5Z" fill="black" />
    <path d="M79 47.5H81V45.5H79V47.5Z" fill="black" />
    <path d="M77 47.5H79V45.5H77V47.5Z" fill="black" />
    <path d="M75 47.5H77V45.5H75V47.5Z" fill="black" />
    <path d="M73 47.5H75V45.5H73V47.5Z" fill="#292E3D" />
    <path d="M71 47.5H73V45.5H71V47.5Z" fill="black" />
    <path d="M69 47.5H71V45.5H69V47.5Z" fill="#6BECD4" />
    <path d="M67 47.5H69V45.5H67V47.5Z" fill="#38859F" />
    <path d="M65 47.5H67V45.5H65V47.5Z" fill="#56C2E7" />
    <path d="M63 47.5H65V45.5H63V47.5Z" fill="#56C2E7" />
    <path d="M61 47.5H63V45.5H61V47.5Z" fill="#56C2E7" />
    <path d="M59 47.5H61V45.5H59V47.5Z" fill="#56C2E7" />
    <path d="M57 47.5H59V45.5H57V47.5Z" fill="#56C2E7" />
    <path d="M55 47.5H57V45.5H55V47.5Z" fill="#56C2E7" />
    <path d="M53 47.5H55V45.5H53V47.5Z" fill="#8FE7FF" />
    <path d="M51 47.5H53V45.5H51V47.5Z" fill="black" />
    <path d="M83 45.5H85V43.5H83V45.5Z" fill="black" />
    <path d="M81 45.5H83V43.5H81V45.5Z" fill="black" />
    <path d="M79 45.5H81V43.5H79V45.5Z" fill="black" />
    <path d="M77 45.5H79V43.5H77V45.5Z" fill="black" />
    <path d="M75 45.5H77V43.5H75V45.5Z" fill="black" />
    <path d="M73 45.5H75V43.5H73V45.5Z" fill="#292E3D" />
    <path d="M71 45.5H73V43.5H71V45.5Z" fill="black" />
    <path d="M69 45.5H71V43.5H69V45.5Z" fill="black" />
    <path d="M67 45.5H69V43.5H67V45.5Z" fill="#56C2E7" />
    <path d="M65 45.5H67V43.5H65V45.5Z" fill="#56C2E7" />
    <path d="M63 45.5H65V43.5H63V45.5Z" fill="#56C2E7" />
    <path d="M61 45.5H63V43.5H61V45.5Z" fill="#56C2E7" />
    <path d="M59 45.5H61V43.5H59V45.5Z" fill="#56C2E7" />
    <path d="M57 45.5H59V43.5H57V45.5Z" fill="#56C2E7" />
    <path d="M55 45.5H57V43.5H55V45.5Z" fill="#56C2E7" />
    <path d="M53 45.5H55V43.5H53V45.5Z" fill="#8FE7FF" />
    <path d="M51 45.5H53V43.5H51V45.5Z" fill="black" />
    <path d="M83 43.5H85V41.5H83V43.5Z" fill="black" />
    <path d="M81 43.5H83V41.5H81V43.5Z" fill="black" />
    <path d="M79 43.5H81V41.5H79V43.5Z" fill="black" />
    <path d="M77 43.5H79V41.5H77V43.5Z" fill="black" />
    <path d="M75 43.5H77V41.5H75V43.5Z" fill="black" />
    <path d="M73 43.5H75V41.5H73V43.5Z" fill="black" />
    <path d="M71 43.5H73V41.5H71V43.5Z" fill="#292E3D" />
    <path d="M69 43.5H71V41.5H69V43.5Z" fill="black" />
    <path d="M67 43.5H69V41.5H67V43.5Z" fill="#56C2E7" />
    <path d="M65 43.5H67V41.5H65V43.5Z" fill="#56C2E7" />
    <path d="M63 43.5H65V41.5H63V43.5Z" fill="#56C2E7" />
    <path d="M61 43.5H63V41.5H61V43.5Z" fill="#56C2E7" />
    <path d="M59 43.5H61V41.5H59V43.5Z" fill="#56C2E7" />
    <path d="M57 43.5H59V41.5H57V43.5Z" fill="#56C2E7" />
    <path d="M55 43.5H57V41.5H55V43.5Z" fill="#56C2E7" />
    <path d="M53 43.5H55V41.5H53V43.5Z" fill="#8FE7FF" />
    <path d="M51 43.5H53V41.5H51V43.5Z" fill="black" />
    <path d="M83 41.5H85V39.5H83V41.5Z" fill="black" />
    <path d="M81 41.5H83V39.5H81V41.5Z" fill="black" />
    <path d="M79 41.5H81V39.5H79V41.5Z" fill="black" />
    <path d="M77 41.5H79V39.5H77V41.5Z" fill="black" />
    <path d="M75 41.5H77V39.5H75V41.5Z" fill="black" />
    <path d="M73 41.5H75V39.5H73V41.5Z" fill="black" />
    <path d="M71 41.5H73V39.5H71V41.5Z" fill="#292E3D" />
    <path d="M69 41.5H71V39.5H69V41.5Z" fill="black" />
    <path d="M67 41.5H69V39.5H67V41.5Z" fill="#56C2E7" />
    <path d="M65 41.5H67V39.5H65V41.5Z" fill="#56C2E7" />
    <path d="M63 41.5H65V39.5H63V41.5Z" fill="#56C2E7" />
    <path d="M61 41.5H63V39.5H61V41.5Z" fill="#FE62B0" />
    <path d="M59 41.5H61V39.5H59V41.5Z" fill="#EF3A78" />
    <path d="M57 41.5H59V39.5H57V41.5Z" fill="#56C2E7" />
    <path d="M55 41.5H57V39.5H55V41.5Z" fill="#56C2E7" />
    <path d="M53 41.5H55V39.5H53V41.5Z" fill="#8FE7FF" />
    <path d="M51 41.5H53V39.5H51V41.5Z" fill="black" />
    <path d="M83 39.5H85V37.5H83V39.5Z" fill="black" />
    <path d="M81 39.5H83V37.5H81V39.5Z" fill="black" />
    <path d="M79 39.5H81V37.5H79V39.5Z" fill="black" />
    <path d="M77 39.5H79V37.5H77V39.5Z" fill="black" />
    <path d="M75 39.5H77V37.5H75V39.5Z" fill="black" />
    <path d="M73 39.5H75V37.5H73V39.5Z" fill="black" />
    <path d="M71 39.5H73V37.5H71V39.5Z" fill="#292E3D" />
    <path d="M69 39.5H71V37.5H69V39.5Z" fill="black" />
    <path d="M67 39.5H69V37.5H67V39.5Z" fill="#56C2E7" />
    <path d="M65 39.5H67V37.5H65V39.5Z" fill="#56C2E7" />
    <path d="M63 39.5H65V37.5H63V39.5Z" fill="#56C2E7" />
    <path d="M61 39.5H63V37.5H61V39.5Z" fill="#FE62B0" />
    <path d="M59 39.5H61V37.5H59V39.5Z" fill="#EF3A78" />
    <path d="M57 39.5H59V37.5H57V39.5Z" fill="#56C2E7" />
    <path d="M55 39.5H57V37.5H55V39.5Z" fill="#56C2E7" />
    <path d="M53 39.5H55V37.5H53V39.5Z" fill="#8FE7FF" />
    <path d="M51 39.5H53V37.5H51V39.5Z" fill="black" />
    <path d="M101 11.5H99V13.5H101V11.5Z" fill="black" />
    <path d="M103 11.5H101V13.5H103V11.5Z" fill="black" />
    <path d="M99 13.5H97V15.5H99V13.5Z" fill="black" />
    <path d="M101 13.5H99V15.5H101V13.5Z" fill="black" />
    <path d="M101 15.5H99V17.5H101V15.5Z" fill="black" />
    <path d="M103 15.5H101V17.5H103V15.5Z" fill="black" />
    <path d="M99 17.5H97V19.5H99V17.5Z" fill="black" />
    <path d="M103 61.5098H105V59.5098H103V61.5098Z" fill="black" />
    <path d="M101 61.5098H103V59.5098H101V61.5098Z" fill="black" />
    <path d="M105 59.5098H107V57.5098H105V59.5098Z" fill="black" />
    <path d="M103 59.5098H105V57.5098H103V59.5098Z" fill="black" />
    <path d="M103 57.5098H105V55.5098H103V57.5098Z" fill="black" />
    <path d="M101 57.5098H103V55.5098H101V57.5098Z" fill="black" />
    <path d="M105 55.5098H107V53.5098H105V55.5098Z" fill="black" />
    <path d="M71 59.5H69V61.5H71V59.5Z" fill="black" />
    <path d="M73 59.5H71V61.5H73V59.5Z" fill="black" />
    <path d="M69 57.5H67V59.5H69V57.5Z" fill="black" />
    <path d="M71 57.5H69V59.5H71V57.5Z" fill="black" />
    <path d="M71 55.5H69V57.5H71V55.5Z" fill="black" />
    <path d="M73 55.5H71V57.5H73V55.5Z" fill="black" />
    <path d="M69 53.5H67V55.5H69V53.5Z" fill="black" />
    <path d="M116 39.5V37.5H114V39.5H116Z" fill="black" />
    <path d="M116 41.5V39.5H114V41.5H116Z" fill="black" />
    <path d="M114 37.5V35.5H112V37.5H114Z" fill="black" />
    <path d="M114 39.5V37.5H112V39.5H114Z" fill="black" />
    <path d="M112 39.5V37.5H110V39.5H112Z" fill="black" />
    <path d="M112 41.5V39.5H110V41.5H112Z" fill="black" />
    <path d="M110 37.5V35.5H108V37.5H110Z" fill="black" />
    <path d="M69.02 19.5H71.02V17.5H69.02V19.5Z" fill="#FE62B0" />
    <path d="M73.02 15.5H75.02V13.5H73.02V15.5Z" fill="#EF3A78" />
    <path d="M71.02 15.5H73.02V13.5H71.02V15.5Z" fill="#EF3A78" />
    <path d="M73.02 17.5H75.02V15.5H73.02V17.5Z" fill="#FE62B0" />
    <path d="M71.02 17.5H73.02V15.5H71.02V17.5Z" fill="#FE62B0" />
    <path d="M69.02 17.5H71.02V15.5H69.02V17.5Z" fill="#EF3A78" />
    <path d="M71.02 19.5H73.02V17.5H71.02V19.5Z" fill="#FE62B0" />
    <path d="M67.02 17.5H69.02V15.5H67.02V17.5Z" fill="#EF3A78" />
    <path d="M67.02 19.5H69.02V17.5H67.02V19.5Z" fill="#FE62B0" />
    <path d="M118.75 16.9502H117.7V18.0002H118.75V16.9502Z" fill="#008E6C" />
    <path d="M119.8 16.9502H118.75V18.0002H119.8V16.9502Z" fill="#008E6C" />
    <path d="M120.85 16.9502H119.8V18.0002H120.85V16.9502Z" fill="#008E6C" />
    <path d="M121.9 16.9502H120.85V18.0002H121.9V16.9502Z" fill="#008E6C" />
    <path d="M122.95 16.9502H121.9V18.0002H122.95V16.9502Z" fill="#008E6C" />
    <path d="M124 16.9502H122.95V18.0002H124V16.9502Z" fill="#008E6C" />
    <path d="M125.05 16.9502H124V18.0002H125.05V16.9502Z" fill="#008E6C" />
    <path d="M128.2 16.9502H127.15V18.0002H128.2V16.9502Z" fill="#008E6C" />
    <path d="M129.25 16.9502H128.2V18.0002H129.25V16.9502Z" fill="#00A556" />
    <path d="M130.3 16.9502H129.25V18.0002H130.3V16.9502Z" fill="#00A556" />
    <path d="M131.35 16.9502H130.3V18.0002H131.35V16.9502Z" fill="#00A556" />
    <path d="M132.4 16.9502H131.35V18.0002H132.4V16.9502Z" fill="#00A556" />
    <path d="M133.45 16.9502H132.4V18.0002H133.45V16.9502Z" fill="#00A556" />
    <path d="M134.5 16.9502H133.45V18.0002H134.5V16.9502Z" fill="#00A556" />
    <path d="M135.55 16.9502H134.5V18.0002H135.55V16.9502Z" fill="#00A556" />
    <path d="M136.6 16.9502H135.55V18.0002H136.6V16.9502Z" fill="#00C96E" />
    <path d="M137.65 16.9502H136.6V18.0002H137.65V16.9502Z" fill="#00C96E" />
    <path d="M138.7 16.9502H137.65V18.0002H138.7V16.9502Z" fill="#00C96E" />
    <path d="M139.75 16.9502H138.7V18.0002H139.75V16.9502Z" fill="#00C96E" />
    <path d="M140.8 16.9502H139.75V18.0002H140.8V16.9502Z" fill="#00C96E" />
    <path d="M141.85 16.9502H140.8V18.0002H141.85V16.9502Z" fill="#00C96E" />
    <path d="M142.9 16.9502H141.85V18.0002H142.9V16.9502Z" fill="#00A556" />
    <path d="M143.95 16.9502H142.9V18.0002H143.95V16.9502Z" fill="#00A556" />
    <path d="M145 16.9502H143.95V18.0002H145V16.9502Z" fill="#00A556" />
    <path d="M146.05 16.9502H145V18.0002H146.05V16.9502Z" fill="#008E6C" />
    <path d="M147.1 16.9502H146.05V18.0002H147.1V16.9502Z" fill="#008E6C" />
    <path d="M148.15 16.9502H147.1V18.0002H148.15V16.9502Z" fill="#008E6C" />
    <path d="M120.85 15.8994H119.8V16.9494H120.85V15.8994Z" fill="#008E6C" />
    <path d="M121.9 15.8994H120.85V16.9494H121.9V15.8994Z" fill="#008E6C" />
    <path d="M122.95 15.8994H121.9V16.9494H122.95V15.8994Z" fill="#008E6C" />
    <path d="M124 15.8994H122.95V16.9494H124V15.8994Z" fill="#008E6C" />
    <path d="M128.2 15.8994H127.15V16.9494H128.2V15.8994Z" fill="#008E6C" />
    <path d="M129.25 15.8994H128.2V16.9494H129.25V15.8994Z" fill="#008E6C" />
    <path d="M130.3 15.8994H129.25V16.9494H130.3V15.8994Z" fill="#008E6C" />
    <path d="M131.35 15.8994H130.3V16.9494H131.35V15.8994Z" fill="#00A556" />
    <path d="M132.4 15.8994H131.35V16.9494H132.4V15.8994Z" fill="#00A556" />
    <path d="M133.45 15.8994H132.4V16.9494H133.45V15.8994Z" fill="#00A556" />
    <path d="M134.5 15.8994H133.45V16.9494H134.5V15.8994Z" fill="#00A556" />
    <path d="M135.55 15.8994H134.5V16.9494H135.55V15.8994Z" fill="#00A556" />
    <path d="M136.6 15.8994H135.55V16.9494H136.6V15.8994Z" fill="#00A556" />
    <path d="M137.65 15.8994H136.6V16.9494H137.65V15.8994Z" fill="#00A556" />
    <path d="M138.7 15.8994H137.65V16.9494H138.7V15.8994Z" fill="#00A556" />
    <path d="M139.75 15.8994H138.7V16.9494H139.75V15.8994Z" fill="#00A556" />
    <path d="M140.8 15.8994H139.75V16.9494H140.8V15.8994Z" fill="#00A556" />
    <path d="M141.85 15.8994H140.8V16.9494H141.85V15.8994Z" fill="#00A556" />
    <path d="M142.9 15.8994H141.85V16.9494H142.9V15.8994Z" fill="#00A556" />
    <path d="M143.95 15.8994H142.9V16.9494H143.95V15.8994Z" fill="#00A556" />
    <path d="M145 15.8994H143.95V16.9494H145V15.8994Z" fill="#008E6C" />
    <path d="M146.05 15.8994H145V16.9494H146.05V15.8994Z" fill="#008E6C" />
    <path d="M147.1 15.8994H146.05V16.9494H147.1V15.8994Z" fill="#008E6C" />
    <path d="M131.35 14.8496H130.3V15.8996H131.35V14.8496Z" fill="#008E6C" />
    <path d="M132.4 14.8496H131.35V15.8996H132.4V14.8496Z" fill="#008E6C" />
    <path d="M133.45 14.8496H132.4V15.8996H133.45V14.8496Z" fill="#008E6C" />
    <path d="M134.5 14.8496H133.45V15.8996H134.5V14.8496Z" fill="#008E6C" />
    <path d="M135.55 14.8496H134.5V15.8996H135.55V14.8496Z" fill="#008E6C" />
    <path d="M136.6 14.8496H135.55V15.8996H136.6V14.8496Z" fill="#00A556" />
    <path d="M137.65 14.8496H136.6V15.8996H137.65V14.8496Z" fill="#00A556" />
    <path d="M138.7 14.8496H137.65V15.8996H138.7V14.8496Z" fill="#00A556" />
    <path d="M139.75 14.8496H138.7V15.8996H139.75V14.8496Z" fill="#00A556" />
    <path d="M140.8 14.8496H139.75V15.8996H140.8V14.8496Z" fill="#00A556" />
    <path d="M141.85 14.8496H140.8V15.8996H141.85V14.8496Z" fill="#00A556" />
    <path d="M142.9 14.8496H141.85V15.8996H142.9V14.8496Z" fill="#008E6C" />
    <path d="M143.95 14.8496H142.9V15.8996H143.95V14.8496Z" fill="#008E6C" />
    <path d="M145 14.8496H143.95V15.8996H145V14.8496Z" fill="#008E6C" />
    <path d="M146.05 14.8496H145V15.8996H146.05V14.8496Z" fill="#008E6C" />
    <path d="M133.45 13.7998H132.4V14.8498H133.45V13.7998Z" fill="#008E6C" />
    <path d="M134.5 13.7998H133.45V14.8498H134.5V13.7998Z" fill="#008E6C" />
    <path d="M135.55 13.7998H134.5V14.8498H135.55V13.7998Z" fill="#008E6C" />
    <path d="M136.6 13.7998H135.55V14.8498H136.6V13.7998Z" fill="#008E6C" />
    <path d="M137.65 13.7998H136.6V14.8498H137.65V13.7998Z" fill="#008E6C" />
    <path d="M138.7 13.7998H137.65V14.8498H138.7V13.7998Z" fill="#008E6C" />
    <path d="M139.75 13.7998H138.7V14.8498H139.75V13.7998Z" fill="#008E6C" />
    <path d="M140.8 13.7998H139.75V14.8498H140.8V13.7998Z" fill="#008E6C" />
    <path d="M141.85 13.7998H140.8V14.8498H141.85V13.7998Z" fill="#008E6C" />
    <path d="M142.9 13.7998H141.85V14.8498H142.9V13.7998Z" fill="#008E6C" />
    <path d="M143.95 13.7998H142.9V14.8498H143.95V13.7998Z" fill="#008E6C" />
    <path d="M149.2 13.7998H148.15V14.8498H149.2V13.7998Z" fill="#008E6C" />
    <path d="M126.1 12.75H125.05V13.8H126.1V12.75Z" fill="#008E6C" />
    <path d="M148.15 12.75H147.1V13.8H148.15V12.75Z" fill="#008E6C" />
    <path d="M149.2 12.75H148.15V13.8H149.2V12.75Z" fill="#00A556" />
    <path d="M122.95 11.7002H121.9V12.7502H122.95V11.7002Z" fill="#008E6C" />
    <path d="M124 11.7002H122.95V12.7502H124V11.7002Z" fill="#008E6C" />
    <path d="M125.05 11.7002H124V12.7502H125.05V11.7002Z" fill="#00A556" />
    <path d="M126.1 11.7002H125.05V12.7502H126.1V11.7002Z" fill="#00A556" />
    <path d="M127.15 11.7002H126.1V12.7502H127.15V11.7002Z" fill="#00A556" />
    <path d="M147.1 11.7002H146.05V12.7502H147.1V11.7002Z" fill="#008E6C" />
    <path d="M148.15 11.7002H147.1V12.7502H148.15V11.7002Z" fill="#008E6C" />
    <path d="M149.2 11.7002H148.15V12.7502H149.2V11.7002Z" fill="#00A556" />
    <path d="M124 10.6494H122.95V11.6994H124V10.6494Z" fill="#008E6C" />
    <path d="M125.05 10.6494H124V11.6994H125.05V10.6494Z" fill="#00A556" />
    <path d="M126.1 10.6494H125.05V11.6994H126.1V10.6494Z" fill="#00A556" />
    <path d="M127.15 10.6494H126.1V11.6994H127.15V10.6494Z" fill="#00A556" />
    <path d="M128.2 10.6494H127.15V11.6994H128.2V10.6494Z" fill="#008E6C" />
    <path d="M129.25 10.6494H128.2V11.6994H129.25V10.6494Z" fill="#008E6C" />
    <path d="M130.3 10.6494H129.25V11.6994H130.3V10.6494Z" fill="#008E6C" />
    <path d="M148.15 10.6494H147.1V11.6994H148.15V10.6494Z" fill="#008E6C" />
    <path d="M149.2 10.6494H148.15V11.6994H149.2V10.6494Z" fill="#008E6C" />
    <path d="M127.15 9.59961H126.1V10.6496H127.15V9.59961Z" fill="#008E6C" />
    <path d="M126.1 9.59961H125.05V10.6496H126.1V9.59961Z" fill="#008E6C" />
    <path d="M127.15 8.5498H126.1V9.5998H127.15V8.5498Z" fill="#008E6C" />
    <path d="M150.25 12.75H149.2V13.8H150.25V12.75Z" fill="#00C96E" />
    <path d="M150.25 11.7002H149.2V12.7502H150.25V11.7002Z" fill="#00C96E" />
    <path d="M150.25 10.6494H149.2V11.6994H150.25V10.6494Z" fill="#008E6C" />
    <path d="M151.3 12.75H150.25V13.8H151.3V12.75Z" fill="#00A556" />
    <path d="M151.3 11.7002H150.25V12.7502H151.3V11.7002Z" fill="#00A556" />
    <path d="M151.3 10.6494H150.25V11.6994H151.3V10.6494Z" fill="#008E6C" />
    <path d="M152.35 12.75H151.3V13.8H152.35V12.75Z" fill="#008E6C" />
    <path d="M152.35 11.7002H151.3V12.7502H152.35V11.7002Z" fill="#008E6C" />
    <path d="M152.35 10.6494H151.3V11.6994H152.35V10.6494Z" fill="#008E6C" />
    <path d="M153.4 12.75H152.35V13.8H153.4V12.75Z" fill="#008E6C" />
    <path d="M154.45 13.7998H153.4V14.8498H154.45V13.7998Z" fill="#008E6C" />
    <path d="M154.45 12.75H153.4V13.8H154.45V12.75Z" fill="#008E6C" />
    <path d="M155.5 16.9502H154.45V18.0002H155.5V16.9502Z" fill="#008E6C" />
    <path d="M156.55 16.9502H155.5V18.0002H156.55V16.9502Z" fill="#008E6C" />
    <path d="M118.75 18H117.7V19.05H118.75V18Z" fill="#008E6C" />
    <path d="M119.8 18H118.75V19.05H119.8V18Z" fill="#008E6C" />
    <path d="M116.65 18H115.6V19.05H116.65V18Z" fill="#008E6C" />
    <path d="M117.7 18H116.65V19.05H117.7V18Z" fill="#008E6C" />
    <path d="M109.31 18H108.26V19.05H109.31V18Z" fill="#008E6C" />
    <path d="M108.269 18H107.219V19.05H108.269V18Z" fill="#008E6C" />
    <path d="M107.22 18H106.17V19.05H107.22V18Z" fill="#008E6C" />
    <path
      d="M108.269 19.0498H107.219V20.0998H108.269V19.0498Z"
      fill="#00A556"
    />
    <path d="M107.22 19.0498H106.17V20.0998H107.22V19.0498Z" fill="#008E6C" />
    <path
      d="M108.269 20.0996H107.219V21.1496H108.269V20.0996Z"
      fill="#00A556"
    />
    <path d="M107.22 20.0996H106.17V21.1496H107.22V20.0996Z" fill="#00A556" />
    <path
      d="M108.269 21.1494H107.219V22.1994H108.269V21.1494Z"
      fill="#00C96E"
    />
    <path d="M107.22 21.1494H106.17V22.1994H107.22V21.1494Z" fill="#00C96E" />
    <path
      d="M108.269 22.2002H107.219V23.2502H108.269V22.2002Z"
      fill="#008E6C"
    />
    <path d="M107.22 22.2002H106.17V23.2502H107.22V22.2002Z" fill="#008E6C" />
    <path d="M106.17 20.0996H105.12V21.1496H106.17V20.0996Z" fill="#008E6C" />
    <path d="M106.17 21.1494H105.12V22.1994H106.17V21.1494Z" fill="#008E6C" />
    <path d="M106.17 22.2002H105.12V23.2502H106.17V22.2002Z" fill="#008E6C" />
    <path d="M120.85 18H119.8V19.05H120.85V18Z" fill="#008E6C" />
    <path d="M121.9 18H120.85V19.05H121.9V18Z" fill="#00A556" />
    <path d="M122.95 18H121.9V19.05H122.95V18Z" fill="#00A556" />
    <path d="M124 18H122.95V19.05H124V18Z" fill="#008E6C" />
    <path d="M125.05 18H124V19.05H125.05V18Z" fill="#008E6C" />
    <path d="M126.1 18H125.05V19.05H126.1V18Z" fill="#008E6C" />
    <path d="M128.2 18H127.15V19.05H128.2V18Z" fill="#008E6C" />
    <path d="M129.25 18H128.2V19.05H129.25V18Z" fill="#00A556" />
    <path d="M130.3 18H129.25V19.05H130.3V18Z" fill="#00A556" />
    <path d="M131.35 18H130.3V19.05H131.35V18Z" fill="#00A556" />
    <path d="M132.4 18H131.35V19.05H132.4V18Z" fill="#00A556" />
    <path d="M133.45 18H132.4V19.05H133.45V18Z" fill="#00A556" />
    <path d="M134.5 18H133.45V19.05H134.5V18Z" fill="#00C96E" />
    <path d="M135.55 18H134.5V19.05H135.55V18Z" fill="#00C96E" />
    <path d="M136.6 18H135.55V19.05H136.6V18Z" fill="#00C96E" />
    <path d="M137.65 18H136.6V19.05H137.65V18Z" fill="#03EAB3" />
    <path d="M138.7 18H137.65V19.05H138.7V18Z" fill="#04FFC8" />
    <path d="M139.75 18H138.7V19.05H139.75V18Z" fill="#04FFC8" />
    <path d="M140.8 18H139.75V19.05H140.8V18Z" fill="#04FFC8" />
    <path d="M141.85 18H140.8V19.05H141.85V18Z" fill="#00C96E" />
    <path d="M142.9 18H141.85V19.05H142.9V18Z" fill="#00C96E" />
    <path d="M143.95 18H142.9V19.05H143.95V18Z" fill="#00C96E" />
    <path d="M145 18H143.95V19.05H145V18Z" fill="#00A556" />
    <path d="M146.05 18H145V19.05H146.05V18Z" fill="#00A556" />
    <path d="M147.1 18H146.05V19.05H147.1V18Z" fill="#00A556" />
    <path d="M148.15 18H147.1V19.05H148.15V18Z" fill="#00A556" />
    <path d="M149.2 18H148.15V19.05H149.2V18Z" fill="#008E6C" />
    <path d="M153.4 18H152.35V19.05H153.4V18Z" fill="#008E6C" />
    <path d="M154.45 18H153.4V19.05H154.45V18Z" fill="#008E6C" />
    <path d="M155.5 18H154.45V19.05H155.5V18Z" fill="#008E6C" />
    <path d="M156.55 18H155.5V19.05H156.55V18Z" fill="#008E6C" />
    <path d="M157.6 18H156.55V19.05H157.6V18Z" fill="#008E6C" />
    <path d="M118.75 19.0498H117.7V20.0998H118.75V19.0498Z" fill="#00A556" />
    <path d="M119.8 19.0498H118.75V20.0998H119.8V19.0498Z" fill="#00A556" />
    <path d="M116.65 19.0498H115.6V20.0998H116.65V19.0498Z" fill="#008E6C" />
    <path d="M117.7 19.0498H116.65V20.0998H117.7V19.0498Z" fill="#008E6C" />
    <path d="M115.6 19.0498H114.55V20.0998H115.6V19.0498Z" fill="#008E6C" />
    <path d="M120.85 19.0498H119.8V20.0998H120.85V19.0498Z" fill="#00C96E" />
    <path d="M121.9 19.0498H120.85V20.0998H121.9V19.0498Z" fill="#00C96E" />
    <path d="M122.95 19.0498H121.9V20.0998H122.95V19.0498Z" fill="#00A556" />
    <path d="M124 19.0498H122.95V20.0998H124V19.0498Z" fill="#00C96E" />
    <path d="M125.05 19.0498H124V20.0998H125.05V19.0498Z" fill="#00A556" />
    <path d="M126.1 19.0498H125.05V20.0998H126.1V19.0498Z" fill="#008E6C" />
    <path d="M127.15 19.0498H126.1V20.0998H127.15V19.0498Z" fill="#008E6C" />
    <path d="M128.2 19.0498H127.15V20.0998H128.2V19.0498Z" fill="#00A556" />
    <path d="M129.25 19.0498H128.2V20.0998H129.25V19.0498Z" fill="#00A556" />
    <path d="M130.3 19.0498H129.25V20.0998H130.3V19.0498Z" fill="#00A556" />
    <path d="M131.35 19.0498H130.3V20.0998H131.35V19.0498Z" fill="#00C96E" />
    <path d="M132.4 19.0498H131.35V20.0998H132.4V19.0498Z" fill="#00C96E" />
    <path d="M133.45 19.0498H132.4V20.0998H133.45V19.0498Z" fill="#00C96E" />
    <path d="M134.5 19.0498H133.45V20.0998H134.5V19.0498Z" fill="#00C96E" />
    <path d="M135.55 19.0498H134.5V20.0998H135.55V19.0498Z" fill="#00C96E" />
    <path d="M136.6 19.0498H135.55V20.0998H136.6V19.0498Z" fill="#04FFC8" />
    <path d="M137.65 19.0498H136.6V20.0998H137.65V19.0498Z" fill="#04FFC8" />
    <path d="M138.7 19.0498H137.65V20.0998H138.7V19.0498Z" fill="#04FFC8" />
    <path d="M139.75 19.0498H138.7V20.0998H139.75V19.0498Z" fill="#03EAB3" />
    <path d="M140.8 19.0498H139.75V20.0998H140.8V19.0498Z" fill="#03EAB3" />
    <path d="M141.85 19.0498H140.8V20.0998H141.85V19.0498Z" fill="#03EAB3" />
    <path d="M142.9 19.0498H141.85V20.0998H142.9V19.0498Z" fill="#00C96E" />
    <path d="M143.95 19.0498H142.9V20.0998H143.95V19.0498Z" fill="#00A556" />
    <path d="M145 19.0498H143.95V20.0998H145V19.0498Z" fill="#00A556" />
    <path d="M146.05 19.0498H145V20.0998H146.05V19.0498Z" fill="#00A556" />
    <path d="M147.1 19.0498H146.05V20.0998H147.1V19.0498Z" fill="#00A556" />
    <path d="M148.15 19.0498H147.1V20.0998H148.15V19.0498Z" fill="#008E6C" />
    <path d="M149.2 19.0498H148.15V20.0998H149.2V19.0498Z" fill="#008E6C" />
    <path d="M150.25 19.0498H149.2V20.0998H150.25V19.0498Z" fill="#008E6C" />
    <path d="M151.3 19.0498H150.25V20.0998H151.3V19.0498Z" fill="#008E6C" />
    <path d="M152.35 19.0498H151.3V20.0998H152.35V19.0498Z" fill="#008E6C" />
    <path d="M153.4 19.0498H152.35V20.0998H153.4V19.0498Z" fill="#008E6C" />
    <path d="M154.45 19.0498H153.4V20.0998H154.45V19.0498Z" fill="#008E6C" />
    <path d="M155.5 19.0498H154.45V20.0998H155.5V19.0498Z" fill="#008E6C" />
    <path d="M156.55 19.0498H155.5V20.0998H156.55V19.0498Z" fill="#008E6C" />
    <path d="M157.6 19.0498H156.55V20.0998H157.6V19.0498Z" fill="#008E6C" />
    <path d="M158.65 19.0498H157.6V20.0998H158.65V19.0498Z" fill="#008E6C" />
    <path d="M159.7 19.0498H158.65V20.0998H159.7V19.0498Z" fill="#008E6C" />
    <path d="M118.75 20.0996H117.7V21.1496H118.75V20.0996Z" fill="#00A556" />
    <path d="M119.8 20.0996H118.75V21.1496H119.8V20.0996Z" fill="#00A556" />
    <path d="M116.65 20.0996H115.6V21.1496H116.65V20.0996Z" fill="#008E6C" />
    <path d="M117.7 20.0996H116.65V21.1496H117.7V20.0996Z" fill="#00A556" />
    <path d="M114.55 20.0996H113.5V21.1496H114.55V20.0996Z" fill="#008E6C" />
    <path d="M115.6 20.0996H114.55V21.1496H115.6V20.0996Z" fill="#008E6C" />
    <path d="M120.85 20.0996H119.8V21.1496H120.85V20.0996Z" fill="#00A556" />
    <path d="M121.9 20.0996H120.85V21.1496H121.9V20.0996Z" fill="#00A556" />
    <path d="M122.95 20.0996H121.9V21.1496H122.95V20.0996Z" fill="#00A556" />
    <path d="M124 20.0996H122.95V21.1496H124V20.0996Z" fill="#00A556" />
    <path d="M125.05 20.0996H124V21.1496H125.05V20.0996Z" fill="#00A556" />
    <path d="M126.1 20.0996H125.05V21.1496H126.1V20.0996Z" fill="#00A556" />
    <path d="M127.15 20.0996H126.1V21.1496H127.15V20.0996Z" fill="#008E6C" />
    <path d="M128.2 20.0996H127.15V21.1496H128.2V20.0996Z" fill="#00A556" />
    <path d="M129.25 20.0996H128.2V21.1496H129.25V20.0996Z" fill="#00A556" />
    <path d="M130.3 20.0996H129.25V21.1496H130.3V20.0996Z" fill="#00C96E" />
    <path d="M131.35 20.0996H130.3V21.1496H131.35V20.0996Z" fill="#00C96E" />
    <path d="M132.4 20.0996H131.35V21.1496H132.4V20.0996Z" fill="#00C96E" />
    <path d="M133.45 20.0996H132.4V21.1496H133.45V20.0996Z" fill="#04FFC8" />
    <path d="M134.5 20.0996H133.45V21.1496H134.5V20.0996Z" fill="#04FFC8" />
    <path d="M135.55 20.0996H134.5V21.1496H135.55V20.0996Z" fill="#04FFC8" />
    <path d="M136.6 20.0996H135.55V21.1496H136.6V20.0996Z" fill="#04FFC8" />
    <path d="M137.65 20.0996H136.6V21.1496H137.65V20.0996Z" fill="#04FFC8" />
    <path d="M138.7 20.0996H137.65V21.1496H138.7V20.0996Z" fill="#04FFC8" />
    <path d="M139.75 20.0996H138.7V21.1496H139.75V20.0996Z" fill="#04FFC8" />
    <path d="M140.8 20.0996H139.75V21.1496H140.8V20.0996Z" fill="#00C96E" />
    <path d="M141.85 20.0996H140.8V21.1496H141.85V20.0996Z" fill="#00C96E" />
    <path d="M142.9 20.0996H141.85V21.1496H142.9V20.0996Z" fill="#00A556" />
    <path d="M143.95 20.0996H142.9V21.1496H143.95V20.0996Z" fill="#00A556" />
    <path d="M145 20.0996H143.95V21.1496H145V20.0996Z" fill="#00A556" />
    <path d="M146.05 20.0996H145V21.1496H146.05V20.0996Z" fill="#00A556" />
    <path d="M147.1 20.0996H146.05V21.1496H147.1V20.0996Z" fill="#008E6C" />
    <path d="M148.15 20.0996H147.1V21.1496H148.15V20.0996Z" fill="#008E6C" />
    <path d="M149.2 20.0996H148.15V21.1496H149.2V20.0996Z" fill="#008E6C" />
    <path d="M150.25 20.0996H149.2V21.1496H150.25V20.0996Z" fill="#03EAB3" />
    <path d="M151.3 20.0996H150.25V21.1496H151.3V20.0996Z" fill="#03EAB3" />
    <path d="M152.35 20.0996H151.3V21.1496H152.35V20.0996Z" fill="#03EAB3" />
    <path d="M153.4 20.0996H152.35V21.1496H153.4V20.0996Z" fill="#03EAB3" />
    <path d="M154.45 20.0996H153.4V21.1496H154.45V20.0996Z" fill="#03EAB3" />
    <path d="M155.5 20.0996H154.45V21.1496H155.5V20.0996Z" fill="#03EAB3" />
    <path d="M156.55 20.0996H155.5V21.1496H156.55V20.0996Z" fill="#00A556" />
    <path d="M157.6 20.0996H156.55V21.1496H157.6V20.0996Z" fill="#008E6C" />
    <path d="M158.65 20.0996H157.6V21.1496H158.65V20.0996Z" fill="#008E6C" />
    <path d="M159.7 20.0996H158.65V21.1496H159.7V20.0996Z" fill="#008E6C" />
    <path d="M160.75 20.0996H159.7V21.1496H160.75V20.0996Z" fill="#008E6C" />
    <path d="M118.75 21.1494H117.7V22.1994H118.75V21.1494Z" fill="#00A556" />
    <path d="M119.8 21.1494H118.75V22.1994H119.8V21.1494Z" fill="#00C96E" />
    <path d="M116.65 21.1494H115.6V22.1994H116.65V21.1494Z" fill="#00A556" />
    <path d="M117.7 21.1494H116.65V22.1994H117.7V21.1494Z" fill="#00C96E" />
    <path d="M114.55 21.1494H113.5V22.1994H114.55V21.1494Z" fill="#008E6C" />
    <path d="M115.6 21.1494H114.55V22.1994H115.6V21.1494Z" fill="#008E6C" />
    <path d="M113.5 21.1494H112.45V22.1994H113.5V21.1494Z" fill="#008E6C" />
    <path d="M120.85 21.1494H119.8V22.1994H120.85V21.1494Z" fill="#00C96E" />
    <path d="M121.9 21.1494H120.85V22.1994H121.9V21.1494Z" fill="#00C96E" />
    <path d="M122.95 21.1494H121.9V22.1994H122.95V21.1494Z" fill="#00C96E" />
    <path d="M124 21.1494H122.95V22.1994H124V21.1494Z" fill="#00A556" />
    <path d="M125.05 21.1494H124V22.1994H125.05V21.1494Z" fill="#00A556" />
    <path d="M126.1 21.1494H125.05V22.1994H126.1V21.1494Z" fill="#00A556" />
    <path d="M127.15 21.1494H126.1V22.1994H127.15V21.1494Z" fill="#008E6C" />
    <path d="M128.2 21.1494H127.15V22.1994H128.2V21.1494Z" fill="#00A556" />
    <path d="M129.25 21.1494H128.2V22.1994H129.25V21.1494Z" fill="#00A556" />
    <path d="M130.3 21.1494H129.25V22.1994H130.3V21.1494Z" fill="#00C96E" />
    <path d="M131.35 21.1494H130.3V22.1994H131.35V21.1494Z" fill="#00C96E" />
    <path d="M132.4 21.1494H131.35V22.1994H132.4V21.1494Z" fill="#03EAB3" />
    <path d="M133.45 21.1494H132.4V22.1994H133.45V21.1494Z" fill="#04FFC8" />
    <path d="M134.5 21.1494H133.45V22.1994H134.5V21.1494Z" fill="#04FFC8" />
    <path d="M135.55 21.1494H134.5V22.1994H135.55V21.1494Z" fill="#04FFC8" />
    <path d="M136.6 21.1494H135.55V22.1994H136.6V21.1494Z" fill="#04FFC8" />
    <path d="M137.65 21.1494H136.6V22.1994H137.65V21.1494Z" fill="#03EAB3" />
    <path d="M138.7 21.1494H137.65V22.1994H138.7V21.1494Z" fill="#03EAB3" />
    <path d="M126.1 11.1748H125.05V12.2248H126.1V11.1748Z" fill="#03EAB3" />
    <path d="M139.75 21.1494H138.7V22.1994H139.75V21.1494Z" fill="#04FFC8" />
    <path d="M140.8 21.1494H139.75V22.1994H140.8V21.1494Z" fill="#03EAB3" />
    <path d="M141.85 21.1494H140.8V22.1994H141.85V21.1494Z" fill="#00C96E" />
    <path d="M142.9 21.1494H141.85V22.1994H142.9V21.1494Z" fill="#00A556" />
    <path d="M143.95 21.1494H142.9V22.1994H143.95V21.1494Z" fill="#00A556" />
    <path d="M145 21.1494H143.95V22.1994H145V21.1494Z" fill="#008E6C" />
    <path d="M146.05 21.1494H145V22.1994H146.05V21.1494Z" fill="#008E6C" />
    <path d="M147.1 21.1494H146.05V22.1994H147.1V21.1494Z" fill="#00A556" />
    <path d="M148.15 21.1494H147.1V22.1994H148.15V21.1494Z" fill="#00C96E" />
    <path d="M149.2 21.1494H148.15V22.1994H149.2V21.1494Z" fill="#00C96E" />
    <path d="M150.25 21.1494H149.2V22.1994H150.25V21.1494Z" fill="#00C96E" />
    <path d="M151.3 21.1494H150.25V22.1994H151.3V21.1494Z" fill="#00C96E" />
    <path d="M152.35 21.1494H151.3V22.1994H152.35V21.1494Z" fill="#03EAB3" />
    <path d="M153.4 21.1494H152.35V22.1994H153.4V21.1494Z" fill="#03EAB3" />
    <path d="M154.45 21.1494H153.4V22.1994H154.45V21.1494Z" fill="#04FFC8" />
    <path d="M155.5 21.1494H154.45V22.1994H155.5V21.1494Z" fill="#04FFC8" />
    <path d="M156.55 21.1494H155.5V22.1994H156.55V21.1494Z" fill="#00C96E" />
    <path d="M157.6 21.1494H156.55V22.1994H157.6V21.1494Z" fill="#00C96E" />
    <path d="M158.65 21.1494H157.6V22.1994H158.65V21.1494Z" fill="#00A556" />
    <path d="M159.7 21.1494H158.65V22.1994H159.7V21.1494Z" fill="#008E6C" />
    <path d="M160.75 21.1494H159.7V22.1994H160.75V21.1494Z" fill="#008E6C" />
    <path d="M161.8 21.1494H160.75V22.1994H161.8V21.1494Z" fill="#008E6C" />
    <path d="M118.75 22.2002H117.7V23.2502H118.75V22.2002Z" fill="#00C96E" />
    <path d="M119.8 22.2002H118.75V23.2502H119.8V22.2002Z" fill="#00C96E" />
    <path d="M116.65 22.2002H115.6V23.2502H116.65V22.2002Z" fill="#00A556" />
    <path d="M117.7 22.2002H116.65V23.2502H117.7V22.2002Z" fill="#00C96E" />
    <path d="M114.55 22.2002H113.5V23.2502H114.55V22.2002Z" fill="#008E6C" />
    <path d="M115.6 22.2002H114.55V23.2502H115.6V22.2002Z" fill="#00A556" />
    <path d="M113.5 22.2002H112.45V23.2502H113.5V22.2002Z" fill="#008E6C" />
    <path d="M120.85 22.2002H119.8V23.2502H120.85V22.2002Z" fill="#03EAB3" />
    <path d="M121.9 22.2002H120.85V23.2502H121.9V22.2002Z" fill="#03EAB3" />
    <path d="M122.95 22.2002H121.9V23.2502H122.95V22.2002Z" fill="#03EAB3" />
    <path d="M124 22.2002H122.95V23.2502H124V22.2002Z" fill="#00C96E" />
    <path d="M125.05 22.2002H124V23.2502H125.05V22.2002Z" fill="#00A556" />
    <path d="M126.1 22.2002H125.05V23.2502H126.1V22.2002Z" fill="#008E6C" />
    <path d="M127.15 22.2002H126.1V23.2502H127.15V22.2002Z" fill="#008E6C" />
    <path d="M128.2 22.2002H127.15V23.2502H128.2V22.2002Z" fill="#008E6C" />
    <path d="M129.25 22.2002H128.2V23.2502H129.25V22.2002Z" fill="#00A556" />
    <path d="M130.3 22.2002H129.25V23.2502H130.3V22.2002Z" fill="#00C96E" />
    <path d="M131.35 22.2002H130.3V23.2502H131.35V22.2002Z" fill="#00C96E" />
    <path d="M132.4 22.2002H131.35V23.2502H132.4V22.2002Z" fill="#03EAB3" />
    <path d="M133.45 22.2002H132.4V23.2502H133.45V22.2002Z" fill="#04FFC8" />
    <path d="M134.5 22.2002H133.45V23.2502H134.5V22.2002Z" fill="#03EAB3" />
    <path d="M135.55 22.2002H134.5V23.2502H135.55V22.2002Z" fill="#04FFC8" />
    <path d="M136.6 22.2002H135.55V23.2502H136.6V22.2002Z" fill="#04FFC8" />
    <path d="M137.65 22.2002H136.6V23.2502H137.65V22.2002Z" fill="#04FFC8" />
    <path d="M138.7 22.2002H137.65V23.2502H138.7V22.2002Z" fill="#04FFC8" />
    <path d="M139.75 22.2002H138.7V23.2502H139.75V22.2002Z" fill="#03EAB3" />
    <path d="M140.8 22.2002H139.75V23.2502H140.8V22.2002Z" fill="#00C96E" />
    <path d="M141.85 22.2002H140.8V23.2502H141.85V22.2002Z" fill="#00A556" />
    <path d="M142.9 22.2002H141.85V23.2502H142.9V22.2002Z" fill="#00A556" />
    <path d="M143.95 22.2002H142.9V23.2502H143.95V22.2002Z" fill="#008E6C" />
    <path d="M145 22.2002H143.95V23.2502H145V22.2002Z" fill="#008E6C" />
    <path d="M146.05 22.2002H145V23.2502H146.05V22.2002Z" fill="#00C96E" />
    <path d="M147.1 22.2002H146.05V23.2502H147.1V22.2002Z" fill="#00C96E" />
    <path d="M148.15 22.2002H147.1V23.2502H148.15V22.2002Z" fill="#00C96E" />
    <path d="M149.2 22.2002H148.15V23.2502H149.2V22.2002Z" fill="#00C96E" />
    <path d="M150.25 22.2002H149.2V23.2502H150.25V22.2002Z" fill="#03EAB3" />
    <path d="M151.3 22.2002H150.25V23.2502H151.3V22.2002Z" fill="#03EAB3" />
    <path d="M152.35 22.2002H151.3V23.2502H152.35V22.2002Z" fill="#03EAB3" />
    <path d="M153.4 22.2002H152.35V23.2502H153.4V22.2002Z" fill="#03EAB3" />
    <path d="M154.45 22.2002H153.4V23.2502H154.45V22.2002Z" fill="#03EAB3" />
    <path d="M155.5 22.2002H154.45V23.2502H155.5V22.2002Z" fill="#04FFC8" />
    <path d="M156.55 22.2002H155.5V23.2502H156.55V22.2002Z" fill="#04FFC8" />
    <path d="M157.6 22.2002H156.55V23.2502H157.6V22.2002Z" fill="#00C96E" />
    <path d="M158.65 22.2002H157.6V23.2502H158.65V22.2002Z" fill="#00C96E" />
    <path d="M159.7 22.2002H158.65V23.2502H159.7V22.2002Z" fill="#00A556" />
    <path d="M160.75 22.2002H159.7V23.2502H160.75V22.2002Z" fill="#008E6C" />
    <path d="M161.8 22.2002H160.75V23.2502H161.8V22.2002Z" fill="#008E6C" />
    <path d="M118.75 23.25H117.7V24.3H118.75V23.25Z" fill="#00C96E" />
    <path d="M119.8 23.25H118.75V24.3H119.8V23.25Z" fill="#03EAB3" />
    <path d="M116.65 23.25H115.6V24.3H116.65V23.25Z" fill="#00A556" />
    <path d="M117.7 23.25H116.65V24.3H117.7V23.25Z" fill="#00C96E" />
    <path d="M114.55 23.25H113.5V24.3H114.55V23.25Z" fill="#00A556" />
    <path d="M115.6 23.25H114.55V24.3H115.6V23.25Z" fill="#00A556" />
    <path d="M112.45 23.25H111.4V24.3H112.45V23.25Z" fill="#008E6C" />
    <path d="M113.5 23.25H112.45V24.3H113.5V23.25Z" fill="#008E6C" />
    <path d="M120.85 23.25H119.8V24.3H120.85V23.25Z" fill="#04FFC8" />
    <path d="M121.9 23.25H120.85V24.3H121.9V23.25Z" fill="#03EAB3" />
    <path d="M122.95 23.25H121.9V24.3H122.95V23.25Z" fill="#03EAB3" />
    <path d="M124 23.25H122.95V24.3H124V23.25Z" fill="#03EAB3" />
    <path d="M125.05 23.25H124V24.3H125.05V23.25Z" fill="#00A556" />
    <path d="M126.1 23.25H125.05V24.3H126.1V23.25Z" fill="#008E6C" />
    <path d="M127.15 23.25H126.1V24.3H127.15V23.25Z" fill="#008E6C" />
    <path d="M128.2 23.25H127.15V24.3H128.2V23.25Z" fill="#008E6C" />
    <path d="M129.25 23.25H128.2V24.3H129.25V23.25Z" fill="#00A556" />
    <path d="M130.3 23.25H129.25V24.3H130.3V23.25Z" fill="#00C96E" />
    <path d="M131.35 23.25H130.3V24.3H131.35V23.25Z" fill="#00C96E" />
    <path d="M132.4 23.25H131.35V24.3H132.4V23.25Z" fill="#00C96E" />
    <path d="M133.45 23.25H132.4V24.3H133.45V23.25Z" fill="#04FFC8" />
    <path d="M134.5 23.25H133.45V24.3H134.5V23.25Z" fill="#04FFC8" />
    <path d="M135.55 23.25H134.5V24.3H135.55V23.25Z" fill="#04FFC8" />
    <path d="M136.6 23.25H135.55V24.3H136.6V23.25Z" fill="#04FFC8" />
    <path d="M137.65 23.25H136.6V24.3H137.65V23.25Z" fill="#04FFC8" />
    <path d="M138.7 23.25H137.65V24.3H138.7V23.25Z" fill="#03EAB3" />
    <path d="M139.75 23.25H138.7V24.3H139.75V23.25Z" fill="#03EAB3" />
    <path d="M140.8 23.25H139.75V24.3H140.8V23.25Z" fill="#00C96E" />
    <path d="M141.85 23.25H140.8V24.3H141.85V23.25Z" fill="#00C96E" />
    <path d="M142.9 23.25H141.85V24.3H142.9V23.25Z" fill="#008E6C" />
    <path d="M143.95 23.25H142.9V24.3H143.95V23.25Z" fill="#00C96E" />
    <path d="M145 23.25H143.95V24.3H145V23.25Z" fill="#00C96E" />
    <path d="M146.05 23.25H145V24.3H146.05V23.25Z" fill="#00C96E" />
    <path d="M147.1 23.25H146.05V24.3H147.1V23.25Z" fill="#03EAB3" />
    <path d="M148.15 23.25H147.1V24.3H148.15V23.25Z" fill="#03EAB3" />
    <path d="M149.2 23.25H148.15V24.3H149.2V23.25Z" fill="#03EAB3" />
    <path d="M150.25 23.25H149.2V24.3H150.25V23.25Z" fill="#03EAB3" />
    <path d="M151.3 23.25H150.25V24.3H151.3V23.25Z" fill="#03EAB3" />
    <path d="M152.35 23.25H151.3V24.3H152.35V23.25Z" fill="#04FFC8" />
    <path d="M153.4 23.25H152.35V24.3H153.4V23.25Z" fill="#03EAB3" />
    <path d="M154.45 23.25H153.4V24.3H154.45V23.25Z" fill="#03EAB3" />
    <path d="M155.5 23.25H154.45V24.3H155.5V23.25Z" fill="#03EAB3" />
    <path d="M156.55 23.25H155.5V24.3H156.55V23.25Z" fill="#03EAB3" />
    <path d="M157.6 23.25H156.55V24.3H157.6V23.25Z" fill="#03EAB3" />
    <path d="M158.65 23.25H157.6V24.3H158.65V23.25Z" fill="#00C96E" />
    <path d="M159.7 23.25H158.65V24.3H159.7V23.25Z" fill="#00A556" />
    <path d="M160.75 23.25H159.7V24.3H160.75V23.25Z" fill="#00A556" />
    <path d="M161.8 23.25H160.75V24.3H161.8V23.25Z" fill="#008E6C" />
    <path d="M162.85 23.25H161.8V24.3H162.85V23.25Z" fill="#008E6C" />
    <path d="M166 23.25H164.95V24.3H166V23.25Z" fill="#008E6C" />
    <path d="M167.05 23.25H166V24.3H167.05V23.25Z" fill="#008E6C" />
    <path d="M168.1 23.25H167.05V24.3H168.1V23.25Z" fill="#008E6C" />
    <path d="M167.05 24.2998H166V25.3498H167.05V24.2998Z" fill="#008E6C" />
    <path d="M168.1 24.2998H167.05V25.3498H168.1V24.2998Z" fill="#00A556" />
    <path d="M167.05 25.3496H166V26.3996H167.05V25.3496Z" fill="#008E6C" />
    <path d="M168.1 25.3496H167.05V26.3996H168.1V25.3496Z" fill="#00C96E" />
    <path d="M167.05 26.3994H166V27.4494H167.05V26.3994Z" fill="#008E6C" />
    <path d="M168.1 26.3994H167.05V27.4494H168.1V26.3994Z" fill="#00C96E" />
    <path d="M167.05 27.4502H166V28.5002H167.05V27.4502Z" fill="#008E6C" />
    <path d="M166 27.4502H164.95V28.5002H166V27.4502Z" fill="#008E6C" />
    <path d="M168.1 27.4502H167.05V28.5002H168.1V27.4502Z" fill="#008E6C" />
    <path d="M169.15 24.2998H168.1V25.3498H169.15V24.2998Z" fill="#008E6C" />
    <path d="M169.15 25.3496H168.1V26.3996H169.15V25.3496Z" fill="#008E6C" />
    <path d="M169.15 26.3994H168.1V27.4494H169.15V26.3994Z" fill="#008E6C" />
    <path d="M118.75 24.2998H117.7V25.3498H118.75V24.2998Z" fill="#03EAB3" />
    <path d="M119.8 24.2998H118.75V25.3498H119.8V24.2998Z" fill="#04FFC8" />
    <path d="M116.65 24.2998H115.6V25.3498H116.65V24.2998Z" fill="#00A556" />
    <path d="M117.7 24.2998H116.65V25.3498H117.7V24.2998Z" fill="#00C96E" />
    <path d="M114.55 24.2998H113.5V25.3498H114.55V24.2998Z" fill="#00A556" />
    <path d="M115.6 24.2998H114.55V25.3498H115.6V24.2998Z" fill="#00A556" />
    <path d="M112.45 24.2998H111.4V25.3498H112.45V24.2998Z" fill="#008E6C" />
    <path d="M113.5 24.2998H112.45V25.3498H113.5V24.2998Z" fill="#008E6C" />
    <path d="M120.85 24.2998H119.8V25.3498H120.85V24.2998Z" fill="#04FFC8" />
    <path d="M121.9 24.2998H120.85V25.3498H121.9V24.2998Z" fill="#04FFC8" />
    <path d="M122.95 24.2998H121.9V25.3498H122.95V24.2998Z" fill="#04FFC8" />
    <path d="M124 24.2998H122.95V25.3498H124V24.2998Z" fill="#04FFC8" />
    <path d="M125.05 24.2998H124V25.3498H125.05V24.2998Z" fill="#00A556" />
    <path d="M126.1 24.2998H125.05V25.3498H126.1V24.2998Z" fill="#00A556" />
    <path d="M127.15 24.2998H126.1V25.3498H127.15V24.2998Z" fill="#00A556" />
    <path d="M128.2 24.2998H127.15V25.3498H128.2V24.2998Z" fill="#00A556" />
    <path d="M129.25 24.2998H128.2V25.3498H129.25V24.2998Z" fill="#00A556" />
    <path d="M130.3 24.2998H129.25V25.3498H130.3V24.2998Z" fill="#00C96E" />
    <path d="M131.35 24.2998H130.3V25.3498H131.35V24.2998Z" fill="#00C96E" />
    <path d="M132.4 24.2998H131.35V25.3498H132.4V24.2998Z" fill="#00C96E" />
    <path d="M133.45 24.2998H132.4V25.3498H133.45V24.2998Z" fill="#00C96E" />
    <path d="M134.5 24.2998H133.45V25.3498H134.5V24.2998Z" fill="#00C96E" />
    <path d="M135.55 24.2998H134.5V25.3498H135.55V24.2998Z" fill="#03EAB3" />
    <path d="M136.6 24.2998H135.55V25.3498H136.6V24.2998Z" fill="#03EAB3" />
    <path d="M137.65 24.2998H136.6V25.3498H137.65V24.2998Z" fill="#03EAB3" />
    <path d="M138.7 24.2998H137.65V25.3498H138.7V24.2998Z" fill="#03EAB3" />
    <path d="M139.75 24.2998H138.7V25.3498H139.75V24.2998Z" fill="#00C96E" />
    <path d="M140.8 24.2998H139.75V25.3498H140.8V24.2998Z" fill="#03EAB3" />
    <path d="M141.85 24.2998H140.8V25.3498H141.85V24.2998Z" fill="#00C96E" />
    <path d="M142.9 24.2998H141.85V25.3498H142.9V24.2998Z" fill="#00C96E" />
    <path d="M143.95 24.2998H142.9V25.3498H143.95V24.2998Z" fill="#00C96E" />
    <path d="M145 24.2998H143.95V25.3498H145V24.2998Z" fill="#00C96E" />
    <path d="M146.05 24.2998H145V25.3498H146.05V24.2998Z" fill="#03EAB3" />
    <path d="M147.1 24.2998H146.05V25.3498H147.1V24.2998Z" fill="#03EAB3" />
    <path d="M148.15 24.2998H147.1V25.3498H148.15V24.2998Z" fill="#03EAB3" />
    <path d="M149.2 24.2998H148.15V25.3498H149.2V24.2998Z" fill="#04FFC8" />
    <path d="M150.25 24.2998H149.2V25.3498H150.25V24.2998Z" fill="#04FFC8" />
    <path d="M151.3 24.2998H150.25V25.3498H151.3V24.2998Z" fill="#04FFC8" />
    <path d="M152.35 24.2998H151.3V25.3498H152.35V24.2998Z" fill="#04FFC8" />
    <path d="M153.4 24.2998H152.35V25.3498H153.4V24.2998Z" fill="#04FFC8" />
    <path d="M154.45 24.2998H153.4V25.3498H154.45V24.2998Z" fill="#04FFC8" />
    <path d="M155.5 24.2998H154.45V25.3498H155.5V24.2998Z" fill="#04FFC8" />
    <path d="M156.55 24.2998H155.5V25.3498H156.55V24.2998Z" fill="#03EAB3" />
    <path d="M157.6 24.2998H156.55V25.3498H157.6V24.2998Z" fill="#03EAB3" />
    <path d="M158.65 24.2998H157.6V25.3498H158.65V24.2998Z" fill="#00C96E" />
    <path d="M159.7 24.2998H158.65V25.3498H159.7V24.2998Z" fill="#00A556" />
    <path d="M160.75 24.2998H159.7V25.3498H160.75V24.2998Z" fill="#00A556" />
    <path d="M161.8 24.2998H160.75V25.3498H161.8V24.2998Z" fill="#008E6C" />
    <path d="M162.85 24.2998H161.8V25.3498H162.85V24.2998Z" fill="#008E6C" />
    <path d="M118.75 25.3496H117.7V26.3996H118.75V25.3496Z" fill="#04FFC8" />
    <path d="M119.8 25.3496H118.75V26.3996H119.8V25.3496Z" fill="#04FFC8" />
    <path d="M116.65 25.3496H115.6V26.3996H116.65V25.3496Z" fill="#00A556" />
    <path d="M117.7 25.3496H116.65V26.3996H117.7V25.3496Z" fill="#00C96E" />
    <path d="M114.55 25.3496H113.5V26.3996H114.55V25.3496Z" fill="#00A556" />
    <path d="M115.6 25.3496H114.55V26.3996H115.6V25.3496Z" fill="#00A556" />
    <path d="M112.45 25.3496H111.4V26.3996H112.45V25.3496Z" fill="#00A556" />
    <path d="M111.41 25.3496H110.36V26.3996H111.41V25.3496Z" fill="#008E6C" />
    <path d="M113.5 25.3496H112.45V26.3996H113.5V25.3496Z" fill="#00A556" />
    <path d="M120.85 25.3496H119.8V26.3996H120.85V25.3496Z" fill="#04FFC8" />
    <path d="M121.9 25.3496H120.85V26.3996H121.9V25.3496Z" fill="#04FFC8" />
    <path d="M122.95 25.3496H121.9V26.3996H122.95V25.3496Z" fill="#04FFC8" />
    <path d="M124 25.3496H122.95V26.3996H124V25.3496Z" fill="#04FFC8" />
    <path d="M125.05 25.3496H124V26.3996H125.05V25.3496Z" fill="#00C96E" />
    <path d="M126.1 25.3496H125.05V26.3996H126.1V25.3496Z" fill="#00C96E" />
    <path d="M127.15 25.3496H126.1V26.3996H127.15V25.3496Z" fill="#00C96E" />
    <path d="M128.2 25.3496H127.15V26.3996H128.2V25.3496Z" fill="#00C96E" />
    <path d="M129.25 25.3496H128.2V26.3996H129.25V25.3496Z" fill="#00C96E" />
    <path d="M130.3 25.3496H129.25V26.3996H130.3V25.3496Z" fill="#00A556" />
    <path d="M131.35 25.3496H130.3V26.3996H131.35V25.3496Z" fill="#00A556" />
    <path d="M132.4 25.3496H131.35V26.3996H132.4V25.3496Z" fill="#00C96E" />
    <path d="M133.45 25.3496H132.4V26.3996H133.45V25.3496Z" fill="#00C96E" />
    <path d="M134.5 25.3496H133.45V26.3996H134.5V25.3496Z" fill="#00C96E" />
    <path d="M135.55 25.3496H134.5V26.3996H135.55V25.3496Z" fill="#04FFC8" />
    <path d="M136.6 25.3496H135.55V26.3996H136.6V25.3496Z" fill="#03EAB3" />
    <path d="M137.65 25.3496H136.6V26.3996H137.65V25.3496Z" fill="#04FFC8" />
    <path d="M138.7 25.3496H137.65V26.3996H138.7V25.3496Z" fill="#03EAB3" />
    <path d="M139.75 25.3496H138.7V26.3996H139.75V25.3496Z" fill="#03EAB3" />
    <path d="M140.8 25.3496H139.75V26.3996H140.8V25.3496Z" fill="#03EAB3" />
    <path d="M141.85 25.3496H140.8V26.3996H141.85V25.3496Z" fill="#03EAB3" />
    <path d="M142.9 25.3496H141.85V26.3996H142.9V25.3496Z" fill="#00C96E" />
    <path d="M143.95 25.3496H142.9V26.3996H143.95V25.3496Z" fill="#03EAB3" />
    <path d="M145 25.3496H143.95V26.3996H145V25.3496Z" fill="#03EAB3" />
    <path d="M146.05 25.3496H145V26.3996H146.05V25.3496Z" fill="#00C96E" />
    <path d="M147.1 25.3496H146.05V26.3996H147.1V25.3496Z" fill="#03EAB3" />
    <path d="M148.15 25.3496H147.1V26.3996H148.15V25.3496Z" fill="#04FFC8" />
    <path d="M149.2 25.3496H148.15V26.3996H149.2V25.3496Z" fill="#04FFC8" />
    <path d="M150.25 25.3496H149.2V26.3996H150.25V25.3496Z" fill="#04FFC8" />
    <path d="M151.3 25.3496H150.25V26.3996H151.3V25.3496Z" fill="#04FFC8" />
    <path d="M152.35 25.3496H151.3V26.3996H152.35V25.3496Z" fill="#03EAB3" />
    <path d="M153.4 25.3496H152.35V26.3996H153.4V25.3496Z" fill="#03EAB3" />
    <path d="M154.45 25.3496H153.4V26.3996H154.45V25.3496Z" fill="#04FFC8" />
    <path d="M155.5 25.3496H154.45V26.3996H155.5V25.3496Z" fill="#04FFC8" />
    <path d="M156.55 25.3496H155.5V26.3996H156.55V25.3496Z" fill="#03EAB3" />
    <path d="M157.6 25.3496H156.55V26.3996H157.6V25.3496Z" fill="#03EAB3" />
    <path d="M158.65 25.3496H157.6V26.3996H158.65V25.3496Z" fill="#00C96E" />
    <path d="M159.7 25.3496H158.65V26.3996H159.7V25.3496Z" fill="#00A556" />
    <path d="M160.75 25.3496H159.7V26.3996H160.75V25.3496Z" fill="#00A556" />
    <path d="M161.8 25.3496H160.75V26.3996H161.8V25.3496Z" fill="#008E6C" />
    <path d="M162.85 25.3496H161.8V26.3996H162.85V25.3496Z" fill="#008E6C" />
    <path d="M118.75 26.3994H117.7V27.4494H118.75V26.3994Z" fill="#04FFC8" />
    <path d="M119.8 26.3994H118.75V27.4494H119.8V26.3994Z" fill="#04FFC8" />
    <path d="M116.65 26.3994H115.6V27.4494H116.65V26.3994Z" fill="#00C96E" />
    <path d="M117.7 26.3994H116.65V27.4494H117.7V26.3994Z" fill="#00C96E" />
    <path d="M114.55 26.3994H113.5V27.4494H114.55V26.3994Z" fill="#00A556" />
    <path d="M115.6 26.3994H114.55V27.4494H115.6V26.3994Z" fill="#00A556" />
    <path d="M112.45 26.3994H111.4V27.4494H112.45V26.3994Z" fill="#008E6C" />
    <path d="M111.41 26.3994H110.36V27.4494H111.41V26.3994Z" fill="#008E6C" />
    <path d="M113.5 26.3994H112.45V27.4494H113.5V26.3994Z" fill="#00A556" />
    <path d="M120.85 26.3994H119.8V27.4494H120.85V26.3994Z" fill="#04FFC8" />
    <path d="M121.9 26.3994H120.85V27.4494H121.9V26.3994Z" fill="#04FFC8" />
    <path d="M122.95 26.3994H121.9V27.4494H122.95V26.3994Z" fill="#04FFC8" />
    <path d="M124 26.3994H122.95V27.4494H124V26.3994Z" fill="#04FFC8" />
    <path d="M125.05 26.3994H124V27.4494H125.05V26.3994Z" fill="#04FFC8" />
    <path d="M126.1 26.3994H125.05V27.4494H126.1V26.3994Z" fill="#03EAB3" />
    <path d="M127.15 26.3994H126.1V27.4494H127.15V26.3994Z" fill="#03EAB3" />
    <path d="M128.2 26.3994H127.15V27.4494H128.2V26.3994Z" fill="#03EAB3" />
    <path d="M129.25 26.3994H128.2V27.4494H129.25V26.3994Z" fill="#00C96E" />
    <path d="M130.3 26.3994H129.25V27.4494H130.3V26.3994Z" fill="#00C96E" />
    <path d="M131.35 26.3994H130.3V27.4494H131.35V26.3994Z" fill="#00C96E" />
    <path d="M132.4 26.3994H131.35V27.4494H132.4V26.3994Z" fill="#03EAB3" />
    <path d="M133.45 26.3994H132.4V27.4494H133.45V26.3994Z" fill="#00C96E" />
    <path d="M134.5 26.3994H133.45V27.4494H134.5V26.3994Z" fill="#03EAB3" />
    <path d="M135.55 26.3994H134.5V27.4494H135.55V26.3994Z" fill="#04FFC8" />
    <path d="M136.6 26.3994H135.55V27.4494H136.6V26.3994Z" fill="#04FFC8" />
    <path d="M137.65 26.3994H136.6V27.4494H137.65V26.3994Z" fill="#04FFC8" />
    <path d="M138.7 26.3994H137.65V27.4494H138.7V26.3994Z" fill="#04FFC8" />
    <path d="M139.75 26.3994H138.7V27.4494H139.75V26.3994Z" fill="#04FFC8" />
    <path d="M140.8 26.3994H139.75V27.4494H140.8V26.3994Z" fill="#03EAB3" />
    <path d="M141.85 26.3994H140.8V27.4494H141.85V26.3994Z" fill="#03EAB3" />
    <path d="M142.9 26.3994H141.85V27.4494H142.9V26.3994Z" fill="#03EAB3" />
    <path d="M143.95 26.3994H142.9V27.4494H143.95V26.3994Z" fill="#00C96E" />
    <path d="M145 26.3994H143.95V27.4494H145V26.3994Z" fill="#03EAB3" />
    <path d="M146.05 26.3994H145V27.4494H146.05V26.3994Z" fill="#03EAB3" />
    <path d="M147.1 26.3994H146.05V27.4494H147.1V26.3994Z" fill="#03EAB3" />
    <path d="M148.15 26.3994H147.1V27.4494H148.15V26.3994Z" fill="#04FFC8" />
    <path d="M149.2 26.3994H148.15V27.4494H149.2V26.3994Z" fill="#04FFC8" />
    <path d="M150.25 26.3994H149.2V27.4494H150.25V26.3994Z" fill="#04FFC8" />
    <path d="M151.3 26.3994H150.25V27.4494H151.3V26.3994Z" fill="#04FFC8" />
    <path d="M152.35 26.3994H151.3V27.4494H152.35V26.3994Z" fill="#03EAB3" />
    <path d="M153.4 26.3994H152.35V27.4494H153.4V26.3994Z" fill="#03EAB3" />
    <path d="M154.45 26.3994H153.4V27.4494H154.45V26.3994Z" fill="#03EAB3" />
    <path d="M155.5 26.3994H154.45V27.4494H155.5V26.3994Z" fill="#03EAB3" />
    <path d="M156.55 26.3994H155.5V27.4494H156.55V26.3994Z" fill="#00C96E" />
    <path d="M157.6 26.3994H156.55V27.4494H157.6V26.3994Z" fill="#00C96E" />
    <path d="M158.65 26.3994H157.6V27.4494H158.65V26.3994Z" fill="#00C96E" />
    <path d="M159.7 26.3994H158.65V27.4494H159.7V26.3994Z" fill="#00A556" />
    <path d="M160.75 26.3994H159.7V27.4494H160.75V26.3994Z" fill="#00A556" />
    <path d="M161.8 26.3994H160.75V27.4494H161.8V26.3994Z" fill="#008E6C" />
    <path d="M118.75 27.4502H117.7V28.5002H118.75V27.4502Z" fill="#04FFC8" />
    <path d="M119.8 27.4502H118.75V28.5002H119.8V27.4502Z" fill="#04FFC8" />
    <path d="M116.65 27.4502H115.6V28.5002H116.65V27.4502Z" fill="#00C96E" />
    <path d="M117.7 27.4502H116.65V28.5002H117.7V27.4502Z" fill="#00C96E" />
    <path d="M114.55 27.4502H113.5V28.5002H114.55V27.4502Z" fill="#00A556" />
    <path d="M115.6 27.4502H114.55V28.5002H115.6V27.4502Z" fill="#00A556" />
    <path d="M112.45 27.4502H111.4V28.5002H112.45V27.4502Z" fill="#008E6C" />
    <path d="M111.41 27.4502H110.36V28.5002H111.41V27.4502Z" fill="#008E6C" />
    <path d="M113.5 27.4502H112.45V28.5002H113.5V27.4502Z" fill="#00A556" />
    <path d="M120.85 27.4502H119.8V28.5002H120.85V27.4502Z" fill="#04FFC8" />
    <path d="M121.9 27.4502H120.85V28.5002H121.9V27.4502Z" fill="#04FFC8" />
    <path d="M122.95 27.4502H121.9V28.5002H122.95V27.4502Z" fill="#04FFC8" />
    <path d="M124 27.4502H122.95V28.5002H124V27.4502Z" fill="#04FFC8" />
    <path d="M125.05 27.4502H124V28.5002H125.05V27.4502Z" fill="#04FFC8" />
    <path d="M126.1 27.4502H125.05V28.5002H126.1V27.4502Z" fill="#03EAB3" />
    <path d="M127.15 27.4502H126.1V28.5002H127.15V27.4502Z" fill="#04FFC8" />
    <path d="M128.2 27.4502H127.15V28.5002H128.2V27.4502Z" fill="#03EAB3" />
    <path d="M129.25 27.4502H128.2V28.5002H129.25V27.4502Z" fill="#03EAB3" />
    <path d="M130.3 27.4502H129.25V28.5002H130.3V27.4502Z" fill="#00C96E" />
    <path d="M131.35 27.4502H130.3V28.5002H131.35V27.4502Z" fill="#03EAB3" />
    <path d="M132.4 27.4502H131.35V28.5002H132.4V27.4502Z" fill="#03EAB3" />
    <path d="M133.45 27.4502H132.4V28.5002H133.45V27.4502Z" fill="#03EAB3" />
    <path d="M134.5 27.4502H133.45V28.5002H134.5V27.4502Z" fill="#04FFC8" />
    <path d="M135.55 27.4502H134.5V28.5002H135.55V27.4502Z" fill="#04FFC8" />
    <path d="M136.6 27.4502H135.55V28.5002H136.6V27.4502Z" fill="#04FFC8" />
    <path d="M137.65 27.4502H136.6V28.5002H137.65V27.4502Z" fill="#04FFC8" />
    <path d="M138.7 27.4502H137.65V28.5002H138.7V27.4502Z" fill="#03EAB3" />
    <path d="M139.75 27.4502H138.7V28.5002H139.75V27.4502Z" fill="#04FFC8" />
    <path d="M140.8 27.4502H139.75V28.5002H140.8V27.4502Z" fill="#04FFC8" />
    <path d="M141.85 27.4502H140.8V28.5002H141.85V27.4502Z" fill="#03EAB3" />
    <path d="M142.9 27.4502H141.85V28.5002H142.9V27.4502Z" fill="#03EAB3" />
    <path d="M143.95 27.4502H142.9V28.5002H143.95V27.4502Z" fill="#03EAB3" />
    <path d="M145 27.4502H143.95V28.5002H145V27.4502Z" fill="#03EAB3" />
    <path d="M146.05 27.4502H145V28.5002H146.05V27.4502Z" fill="#04FFC8" />
    <path d="M147.1 27.4502H146.05V28.5002H147.1V27.4502Z" fill="#04FFC8" />
    <path d="M148.15 27.4502H147.1V28.5002H148.15V27.4502Z" fill="#04FFC8" />
    <path d="M149.2 27.4502H148.15V28.5002H149.2V27.4502Z" fill="#04FFC8" />
    <path d="M150.25 27.4502H149.2V28.5002H150.25V27.4502Z" fill="#04FFC8" />
    <path d="M151.3 27.4502H150.25V28.5002H151.3V27.4502Z" fill="#03EAB3" />
    <path d="M152.35 27.4502H151.3V28.5002H152.35V27.4502Z" fill="#03EAB3" />
    <path d="M153.4 27.4502H152.35V28.5002H153.4V27.4502Z" fill="#03EAB3" />
    <path d="M154.45 27.4502H153.4V28.5002H154.45V27.4502Z" fill="#03EAB3" />
    <path d="M155.5 27.4502H154.45V28.5002H155.5V27.4502Z" fill="#03EAB3" />
    <path d="M156.55 27.4502H155.5V28.5002H156.55V27.4502Z" fill="#00C96E" />
    <path d="M157.6 27.4502H156.55V28.5002H157.6V27.4502Z" fill="#00C96E" />
    <path d="M158.65 27.4502H157.6V28.5002H158.65V27.4502Z" fill="#00A556" />
    <path d="M159.7 27.4502H158.65V28.5002H159.7V27.4502Z" fill="#00A556" />
    <path d="M160.75 27.4502H159.7V28.5002H160.75V27.4502Z" fill="#008E6C" />
    <path d="M161.8 27.4502H160.75V28.5002H161.8V27.4502Z" fill="#008E6C" />
    <path d="M118.75 28.5H117.7V29.55H118.75V28.5Z" fill="#04FFC8" />
    <path d="M119.8 28.5H118.75V29.55H119.8V28.5Z" fill="#04FFC8" />
    <path d="M116.65 28.5H115.6V29.55H116.65V28.5Z" fill="#00C96E" />
    <path d="M117.7 28.5H116.65V29.55H117.7V28.5Z" fill="#00C96E" />
    <path d="M114.55 28.5H113.5V29.55H114.55V28.5Z" fill="#00A556" />
    <path d="M115.6 28.5H114.55V29.55H115.6V28.5Z" fill="#00A556" />
    <path d="M112.45 28.5H111.4V29.55H112.45V28.5Z" fill="#008E6C" />
    <path d="M113.5 28.5H112.45V29.55H113.5V28.5Z" fill="#00A556" />
    <path d="M120.85 28.5H119.8V29.55H120.85V28.5Z" fill="#03EAB3" />
    <path d="M121.9 28.5H120.85V29.55H121.9V28.5Z" fill="#03EAB3" />
    <path d="M122.95 28.5H121.9V29.55H122.95V28.5Z" fill="#03EAB3" />
    <path d="M124 28.5H122.95V29.55H124V28.5Z" fill="#04FFC8" />
    <path d="M125.05 28.5H124V29.55H125.05V28.5Z" fill="#03EAB3" />
    <path d="M126.1 28.5H125.05V29.55H126.1V28.5Z" fill="#03EAB3" />
    <path d="M127.15 28.5H126.1V29.55H127.15V28.5Z" fill="#04FFC8" />
    <path d="M128.2 28.5H127.15V29.55H128.2V28.5Z" fill="#04FFC8" />
    <path d="M129.25 28.5H128.2V29.55H129.25V28.5Z" fill="#04FFC8" />
    <path d="M130.3 28.5H129.25V29.55H130.3V28.5Z" fill="#04FFC8" />
    <path d="M131.35 28.5H130.3V29.55H131.35V28.5Z" fill="#04FFC8" />
    <path d="M132.4 28.5H131.35V29.55H132.4V28.5Z" fill="#03EAB3" />
    <path d="M133.45 28.5H132.4V29.55H133.45V28.5Z" fill="#03EAB3" />
    <path d="M134.5 28.5H133.45V29.55H134.5V28.5Z" fill="#04FFC8" />
    <path d="M135.55 28.5H134.5V29.55H135.55V28.5Z" fill="#04FFC8" />
    <path d="M136.6 28.5H135.55V29.55H136.6V28.5Z" fill="#04FFC8" />
    <path d="M137.65 28.5H136.6V29.55H137.65V28.5Z" fill="#03EAB3" />
    <path d="M138.7 28.5H137.65V29.55H138.7V28.5Z" fill="#04FFC8" />
    <path d="M139.75 28.5H138.7V29.55H139.75V28.5Z" fill="#03EAB3" />
    <path d="M140.8 28.5H139.75V29.55H140.8V28.5Z" fill="#04FFC8" />
    <path d="M141.85 28.5H140.8V29.55H141.85V28.5Z" fill="#04FFC8" />
    <path d="M142.9 28.5H141.85V29.55H142.9V28.5Z" fill="#04FFC8" />
    <path d="M143.95 28.5H142.9V29.55H143.95V28.5Z" fill="#03EAB3" />
    <path d="M145 28.5H143.95V29.55H145V28.5Z" fill="#03EAB3" />
    <path d="M146.05 28.5H145V29.55H146.05V28.5Z" fill="#04FFC8" />
    <path d="M147.1 28.5H146.05V29.55H147.1V28.5Z" fill="#03EAB3" />
    <path d="M148.15 28.5H147.1V29.55H148.15V28.5Z" fill="#03EAB3" />
    <path d="M149.2 28.5H148.15V29.55H149.2V28.5Z" fill="#03EAB3" />
    <path d="M150.25 28.5H149.2V29.55H150.25V28.5Z" fill="#03EAB3" />
    <path d="M151.3 28.5H150.25V29.55H151.3V28.5Z" fill="#03EAB3" />
    <path d="M152.35 28.5H151.3V29.55H152.35V28.5Z" fill="#03EAB3" />
    <path d="M153.4 28.5H152.35V29.55H153.4V28.5Z" fill="#03EAB3" />
    <path d="M154.45 28.5H153.4V29.55H154.45V28.5Z" fill="#00C96E" />
    <path d="M155.5 28.5H154.45V29.55H155.5V28.5Z" fill="#00C96E" />
    <path d="M156.55 28.5H155.5V29.55H156.55V28.5Z" fill="#00C96E" />
    <path d="M157.6 28.5H156.55V29.55H157.6V28.5Z" fill="#00A556" />
    <path d="M158.65 28.5H157.6V29.55H158.65V28.5Z" fill="#00A556" />
    <path d="M159.7 28.5H158.65V29.55H159.7V28.5Z" fill="#00A556" />
    <path d="M118.75 29.5498H117.7V30.5998H118.75V29.5498Z" fill="#00C96E" />
    <path d="M119.8 29.5498H118.75V30.5998H119.8V29.5498Z" fill="#00C96E" />
    <path d="M116.65 29.5498H115.6V30.5998H116.65V29.5498Z" fill="#00A556" />
    <path d="M117.7 29.5498H116.65V30.5998H117.7V29.5498Z" fill="#00A556" />
    <path d="M114.55 29.5498H113.5V30.5998H114.55V29.5498Z" fill="#00A556" />
    <path d="M115.6 29.5498H114.55V30.5998H115.6V29.5498Z" fill="#00A556" />
    <path d="M112.45 29.5498H111.4V30.5998H112.45V29.5498Z" fill="#008E6C" />
    <path d="M113.5 29.5498H112.45V30.5998H113.5V29.5498Z" fill="#008E6C" />
    <path d="M120.85 29.5498H119.8V30.5998H120.85V29.5498Z" fill="#00C96E" />
    <path d="M121.9 29.5498H120.85V30.5998H121.9V29.5498Z" fill="#03EAB3" />
    <path d="M122.95 29.5498H121.9V30.5998H122.95V29.5498Z" fill="#00C96E" />
    <path d="M124 29.5498H122.95V30.5998H124V29.5498Z" fill="#03EAB3" />
    <path d="M125.05 29.5498H124V30.5998H125.05V29.5498Z" fill="#03EAB3" />
    <path d="M126.1 29.5498H125.05V30.5998H126.1V29.5498Z" fill="#03EAB3" />
    <path d="M127.15 29.5498H126.1V30.5998H127.15V29.5498Z" fill="#03EAB3" />
    <path d="M128.2 29.5498H127.15V30.5998H128.2V29.5498Z" fill="#03EAB3" />
    <path d="M129.25 29.5498H128.2V30.5998H129.25V29.5498Z" fill="#04FFC8" />
    <path d="M130.3 29.5498H129.25V30.5998H130.3V29.5498Z" fill="#04FFC8" />
    <path d="M131.35 29.5498H130.3V30.5998H131.35V29.5498Z" fill="#04FFC8" />
    <path d="M132.4 29.5498H131.35V30.5998H132.4V29.5498Z" fill="#04FFC8" />
    <path d="M133.45 29.5498H132.4V30.5998H133.45V29.5498Z" fill="#04FFC8" />
    <path d="M134.5 29.5498H133.45V30.5998H134.5V29.5498Z" fill="#04FFC8" />
    <path d="M135.55 29.5498H134.5V30.5998H135.55V29.5498Z" fill="#03EAB3" />
    <path d="M136.6 29.5498H135.55V30.5998H136.6V29.5498Z" fill="#03EAB3" />
    <path d="M137.65 29.5498H136.6V30.5998H137.65V29.5498Z" fill="#04FFC8" />
    <path d="M138.7 29.5498H137.65V30.5998H138.7V29.5498Z" fill="#04FFC8" />
    <path d="M139.75 29.5498H138.7V30.5998H139.75V29.5498Z" fill="#04FFC8" />
    <path d="M140.8 29.5498H139.75V30.5998H140.8V29.5498Z" fill="#04FFC8" />
    <path d="M141.85 29.5498H140.8V30.5998H141.85V29.5498Z" fill="#04FFC8" />
    <path d="M142.9 29.5498H141.85V30.5998H142.9V29.5498Z" fill="#03EAB3" />
    <path d="M143.95 29.5498H142.9V30.5998H143.95V29.5498Z" fill="#03EAB3" />
    <path d="M145 29.5498H143.95V30.5998H145V29.5498Z" fill="#03EAB3" />
    <path d="M146.05 29.5498H145V30.5998H146.05V29.5498Z" fill="#04FFC8" />
    <path d="M147.1 29.5498H146.05V30.5998H147.1V29.5498Z" fill="#03EAB3" />
    <path d="M148.15 29.5498H147.1V30.5998H148.15V29.5498Z" fill="#00C96E" />
    <path d="M149.2 29.5498H148.15V30.5998H149.2V29.5498Z" fill="#00C96E" />
    <path d="M150.25 29.5498H149.2V30.5998H150.25V29.5498Z" fill="#00C96E" />
    <path d="M151.3 29.5498H150.25V30.5998H151.3V29.5498Z" fill="#00C96E" />
    <path d="M152.35 29.5498H151.3V30.5998H152.35V29.5498Z" fill="#00C96E" />
    <path d="M153.4 29.5498H152.35V30.5998H153.4V29.5498Z" fill="#00C96E" />
    <path d="M154.45 29.5498H153.4V30.5998H154.45V29.5498Z" fill="#00C96E" />
    <path d="M155.5 29.5498H154.45V30.5998H155.5V29.5498Z" fill="#00C96E" />
    <path d="M156.55 29.5498H155.5V30.5998H156.55V29.5498Z" fill="#00A556" />
    <path d="M157.6 29.5498H156.55V30.5998H157.6V29.5498Z" fill="#00A556" />
    <path d="M158.65 29.5498H157.6V30.5998H158.65V29.5498Z" fill="#00A556" />
    <path d="M159.7 29.5498H158.65V30.5998H159.7V29.5498Z" fill="#008E6C" />
    <path d="M160.75 29.5498H159.7V30.5998H160.75V29.5498Z" fill="#008E6C" />
    <path d="M161.8 29.5498H160.75V30.5998H161.8V29.5498Z" fill="#008E6C" />
    <path d="M162.85 29.5498H161.8V30.5998H162.85V29.5498Z" fill="#008E6C" />
    <path d="M118.75 30.5996H117.7V31.6496H118.75V30.5996Z" fill="#00C96E" />
    <path d="M119.8 30.5996H118.75V31.6496H119.8V30.5996Z" fill="#00A556" />
    <path d="M116.65 30.5996H115.6V31.6496H116.65V30.5996Z" fill="#00A556" />
    <path d="M117.7 30.5996H116.65V31.6496H117.7V30.5996Z" fill="#00A556" />
    <path d="M114.55 30.5996H113.5V31.6496H114.55V30.5996Z" fill="#008E6C" />
    <path d="M115.6 30.5996H114.55V31.6496H115.6V30.5996Z" fill="#00A556" />
    <path d="M112.45 30.5996H111.4V31.6496H112.45V30.5996Z" fill="#008E6C" />
    <path d="M111.41 30.5996H110.36V31.6496H111.41V30.5996Z" fill="#008E6C" />
    <path d="M113.5 30.5996H112.45V31.6496H113.5V30.5996Z" fill="#00A556" />
    <path d="M120.85 30.5996H119.8V31.6496H120.85V30.5996Z" fill="#00C96E" />
    <path d="M121.9 30.5996H120.85V31.6496H121.9V30.5996Z" fill="#03EAB3" />
    <path d="M122.95 30.5996H121.9V31.6496H122.95V30.5996Z" fill="#03EAB3" />
    <path d="M124 30.5996H122.95V31.6496H124V30.5996Z" fill="#03EAB3" />
    <path d="M125.05 30.5996H124V31.6496H125.05V30.5996Z" fill="#04FFC8" />
    <path d="M126.1 30.5996H125.05V31.6496H126.1V30.5996Z" fill="#04FFC8" />
    <path d="M127.15 30.5996H126.1V31.6496H127.15V30.5996Z" fill="#03EAB3" />
    <path d="M128.2 30.5996H127.15V31.6496H128.2V30.5996Z" fill="#03EAB3" />
    <path d="M129.25 30.5996H128.2V31.6496H129.25V30.5996Z" fill="#04FFC8" />
    <path d="M130.3 30.5996H129.25V31.6496H130.3V30.5996Z" fill="#04FFC8" />
    <path d="M131.35 30.5996H130.3V31.6496H131.35V30.5996Z" fill="#04FFC8" />
    <path d="M132.4 30.5996H131.35V31.6496H132.4V30.5996Z" fill="#04FFC8" />
    <path d="M133.45 30.5996H132.4V31.6496H133.45V30.5996Z" fill="#04FFC8" />
    <path d="M134.5 30.5996H133.45V31.6496H134.5V30.5996Z" fill="#04FFC8" />
    <path d="M135.55 30.5996H134.5V31.6496H135.55V30.5996Z" fill="#04FFC8" />
    <path d="M136.6 30.5996H135.55V31.6496H136.6V30.5996Z" fill="#04FFC8" />
    <path d="M137.65 30.5996H136.6V31.6496H137.65V30.5996Z" fill="#04FFC8" />
    <path d="M138.7 30.5996H137.65V31.6496H138.7V30.5996Z" fill="#04FFC8" />
    <path d="M139.75 30.5996H138.7V31.6496H139.75V30.5996Z" fill="#04FFC8" />
    <path d="M140.8 30.5996H139.75V31.6496H140.8V30.5996Z" fill="#04FFC8" />
    <path d="M141.85 30.5996H140.8V31.6496H141.85V30.5996Z" fill="#04FFC8" />
    <path d="M142.9 30.5996H141.85V31.6496H142.9V30.5996Z" fill="#03EAB3" />
    <path d="M143.95 30.5996H142.9V31.6496H143.95V30.5996Z" fill="#03EAB3" />
    <path d="M145 30.5996H143.95V31.6496H145V30.5996Z" fill="#03EAB3" />
    <path d="M146.05 30.5996H145V31.6496H146.05V30.5996Z" fill="#03EAB3" />
    <path d="M147.1 30.5996H146.05V31.6496H147.1V30.5996Z" fill="#04FFC8" />
    <path d="M148.15 30.5996H147.1V31.6496H148.15V30.5996Z" fill="#00C96E" />
    <path d="M149.2 30.5996H148.15V31.6496H149.2V30.5996Z" fill="#00C96E" />
    <path d="M150.25 30.5996H149.2V31.6496H150.25V30.5996Z" fill="#00C96E" />
    <path d="M151.3 30.5996H150.25V31.6496H151.3V30.5996Z" fill="#00C96E" />
    <path d="M152.35 30.5996H151.3V31.6496H152.35V30.5996Z" fill="#00C96E" />
    <path d="M153.4 30.5996H152.35V31.6496H153.4V30.5996Z" fill="#00C96E" />
    <path d="M154.45 30.5996H153.4V31.6496H154.45V30.5996Z" fill="#00C96E" />
    <path d="M155.5 30.5996H154.45V31.6496H155.5V30.5996Z" fill="#00A556" />
    <path d="M156.55 30.5996H155.5V31.6496H156.55V30.5996Z" fill="#00C96E" />
    <path d="M157.6 30.5996H156.55V31.6496H157.6V30.5996Z" fill="#00C96E" />
    <path d="M158.65 30.5996H157.6V31.6496H158.65V30.5996Z" fill="#00A556" />
    <path d="M159.7 30.5996H158.65V31.6496H159.7V30.5996Z" fill="#008E6C" />
    <path d="M160.75 30.5996H159.7V31.6496H160.75V30.5996Z" fill="#008E6C" />
    <path d="M161.8 30.5996H160.75V31.6496H161.8V30.5996Z" fill="#008E6C" />
    <path d="M162.85 30.5996H161.8V31.6496H162.85V30.5996Z" fill="#008E6C" />
    <path d="M118.75 31.6494H117.7V32.6994H118.75V31.6494Z" fill="#00C96E" />
    <path d="M119.8 31.6494H118.75V32.6994H119.8V31.6494Z" fill="#00A556" />
    <path d="M116.65 31.6494H115.6V32.6994H116.65V31.6494Z" fill="#00A556" />
    <path d="M117.7 31.6494H116.65V32.6994H117.7V31.6494Z" fill="#00A556" />
    <path d="M114.55 31.6494H113.5V32.6994H114.55V31.6494Z" fill="#008E6C" />
    <path d="M115.6 31.6494H114.55V32.6994H115.6V31.6494Z" fill="#00A556" />
    <path d="M112.45 31.6494H111.4V32.6994H112.45V31.6494Z" fill="#008E6C" />
    <path d="M111.41 31.6494H110.36V32.6994H111.41V31.6494Z" fill="#008E6C" />
    <path d="M113.5 31.6494H112.45V32.6994H113.5V31.6494Z" fill="#00A556" />
    <path d="M120.85 31.6494H119.8V32.6994H120.85V31.6494Z" fill="#00C96E" />
    <path d="M121.9 31.6494H120.85V32.6994H121.9V31.6494Z" fill="#00C96E" />
    <path d="M122.95 31.6494H121.9V32.6994H122.95V31.6494Z" fill="#03EAB3" />
    <path d="M124 31.6494H122.95V32.6994H124V31.6494Z" fill="#03EAB3" />
    <path d="M125.05 31.6494H124V32.6994H125.05V31.6494Z" fill="#04FFC8" />
    <path d="M126.1 31.6494H125.05V32.6994H126.1V31.6494Z" fill="#04FFC8" />
    <path d="M127.15 31.6494H126.1V32.6994H127.15V31.6494Z" fill="#03EAB3" />
    <path d="M128.2 31.6494H127.15V32.6994H128.2V31.6494Z" fill="#03EAB3" />
    <path d="M129.25 31.6494H128.2V32.6994H129.25V31.6494Z" fill="#03EAB3" />
    <path d="M130.3 31.6494H129.25V32.6994H130.3V31.6494Z" fill="#03EAB3" />
    <path d="M131.35 31.6494H130.3V32.6994H131.35V31.6494Z" fill="#03EAB3" />
    <path d="M132.4 31.6494H131.35V32.6994H132.4V31.6494Z" fill="#04FFC8" />
    <path d="M133.45 31.6494H132.4V32.6994H133.45V31.6494Z" fill="#04FFC8" />
    <path d="M134.5 31.6494H133.45V32.6994H134.5V31.6494Z" fill="#04FFC8" />
    <path d="M135.55 31.6494H134.5V32.6994H135.55V31.6494Z" fill="#04FFC8" />
    <path d="M136.6 31.6494H135.55V32.6994H136.6V31.6494Z" fill="#04FFC8" />
    <path d="M137.65 31.6494H136.6V32.6994H137.65V31.6494Z" fill="#04FFC8" />
    <path d="M138.7 31.6494H137.65V32.6994H138.7V31.6494Z" fill="#04FFC8" />
    <path d="M139.75 31.6494H138.7V32.6994H139.75V31.6494Z" fill="#04FFC8" />
    <path d="M140.8 31.6494H139.75V32.6994H140.8V31.6494Z" fill="#04FFC8" />
    <path d="M141.85 31.6494H140.8V32.6994H141.85V31.6494Z" fill="#04FFC8" />
    <path d="M142.9 31.6494H141.85V32.6994H142.9V31.6494Z" fill="#03EAB3" />
    <path d="M143.95 31.6494H142.9V32.6994H143.95V31.6494Z" fill="#04FFC8" />
    <path d="M145 31.6494H143.95V32.6994H145V31.6494Z" fill="#04FFC8" />
    <path d="M146.05 31.6494H145V32.6994H146.05V31.6494Z" fill="#04FFC8" />
    <path d="M147.1 31.6494H146.05V32.6994H147.1V31.6494Z" fill="#04FFC8" />
    <path d="M148.15 31.6494H147.1V32.6994H148.15V31.6494Z" fill="#04FFC8" />
    <path d="M149.2 31.6494H148.15V32.6994H149.2V31.6494Z" fill="#04FFC8" />
    <path d="M150.25 31.6494H149.2V32.6994H150.25V31.6494Z" fill="#04FFC8" />
    <path d="M151.3 31.6494H150.25V32.6994H151.3V31.6494Z" fill="#04FFC8" />
    <path d="M152.35 31.6494H151.3V32.6994H152.35V31.6494Z" fill="#04FFC8" />
    <path d="M153.4 31.6494H152.35V32.6994H153.4V31.6494Z" fill="#00C96E" />
    <path d="M154.45 31.6494H153.4V32.6994H154.45V31.6494Z" fill="#00A556" />
    <path d="M155.5 31.6494H154.45V32.6994H155.5V31.6494Z" fill="#00A556" />
    <path d="M156.55 31.6494H155.5V32.6994H156.55V31.6494Z" fill="#00C96E" />
    <path d="M157.6 31.6494H156.55V32.6994H157.6V31.6494Z" fill="#00C96E" />
    <path d="M158.65 31.6494H157.6V32.6994H158.65V31.6494Z" fill="#00A556" />
    <path d="M159.7 31.6494H158.65V32.6994H159.7V31.6494Z" fill="#008E6C" />
    <path d="M160.75 31.6494H159.7V32.6994H160.75V31.6494Z" fill="#00C96E" />
    <path d="M161.8 31.6494H160.75V32.6994H161.8V31.6494Z" fill="#008E6C" />
    <path d="M162.85 31.6494H161.8V32.6994H162.85V31.6494Z" fill="#00A556" />
    <path d="M163.9 31.6494H162.85V32.6994H163.9V31.6494Z" fill="#008E6C" />
    <path d="M118.75 32.7002H117.7V33.7502H118.75V32.7002Z" fill="#00C96E" />
    <path d="M119.8 32.7002H118.75V33.7502H119.8V32.7002Z" fill="#00C96E" />
    <path d="M116.65 32.7002H115.6V33.7502H116.65V32.7002Z" fill="#00C96E" />
    <path d="M117.7 32.7002H116.65V33.7502H117.7V32.7002Z" fill="#03EAB3" />
    <path d="M114.55 32.7002H113.5V33.7502H114.55V32.7002Z" fill="#00C96E" />
    <path d="M115.6 32.7002H114.55V33.7502H115.6V32.7002Z" fill="#04FFC8" />
    <path d="M112.45 32.7002H111.4V33.7502H112.45V32.7002Z" fill="#00A556" />
    <path d="M111.41 32.7002H110.36V33.7502H111.41V32.7002Z" fill="#008E6C" />
    <path d="M113.5 32.7002H112.45V33.7502H113.5V32.7002Z" fill="#00C96E" />
    <path d="M120.85 32.7002H119.8V33.7502H120.85V32.7002Z" fill="#00C96E" />
    <path d="M121.9 32.7002H120.85V33.7502H121.9V32.7002Z" fill="#03EAB3" />
    <path d="M122.95 32.7002H121.9V33.7502H122.95V32.7002Z" fill="#00C96E" />
    <path d="M124 32.7002H122.95V33.7502H124V32.7002Z" fill="#04FFC8" />
    <path d="M125.05 32.7002H124V33.7502H125.05V32.7002Z" fill="#04FFC8" />
    <path d="M126.1 32.7002H125.05V33.7502H126.1V32.7002Z" fill="#03EAB3" />
    <path d="M127.15 32.7002H126.1V33.7502H127.15V32.7002Z" fill="#03EAB3" />
    <path d="M128.2 32.7002H127.15V33.7502H128.2V32.7002Z" fill="#03EAB3" />
    <path d="M129.25 32.7002H128.2V33.7502H129.25V32.7002Z" fill="#00C96E" />
    <path d="M130.3 32.7002H129.25V33.7502H130.3V32.7002Z" fill="#03EAB3" />
    <path d="M131.35 32.7002H130.3V33.7502H131.35V32.7002Z" fill="#03EAB3" />
    <path d="M132.4 32.7002H131.35V33.7502H132.4V32.7002Z" fill="#03EAB3" />
    <path d="M133.45 32.7002H132.4V33.7502H133.45V32.7002Z" fill="#04FFC8" />
    <path d="M134.5 32.7002H133.45V33.7502H134.5V32.7002Z" fill="#04FFC8" />
    <path d="M135.55 32.7002H134.5V33.7502H135.55V32.7002Z" fill="#04FFC8" />
    <path d="M136.6 32.7002H135.55V33.7502H136.6V32.7002Z" fill="#04FFC8" />
    <path d="M137.65 32.7002H136.6V33.7502H137.65V32.7002Z" fill="#04FFC8" />
    <path d="M138.7 32.7002H137.65V33.7502H138.7V32.7002Z" fill="#04FFC8" />
    <path d="M139.75 32.7002H138.7V33.7502H139.75V32.7002Z" fill="#04FFC8" />
    <path d="M140.8 32.7002H139.75V33.7502H140.8V32.7002Z" fill="#03EAB3" />
    <path d="M141.85 32.7002H140.8V33.7502H141.85V32.7002Z" fill="#03EAB3" />
    <path d="M142.9 32.7002H141.85V33.7502H142.9V32.7002Z" fill="#03EAB3" />
    <path d="M143.95 32.7002H142.9V33.7502H143.95V32.7002Z" fill="#04FFC8" />
    <path d="M145 32.7002H143.95V33.7502H145V32.7002Z" fill="#03EAB3" />
    <path d="M146.05 32.7002H145V33.7502H146.05V32.7002Z" fill="#03EAB3" />
    <path d="M147.1 32.7002H146.05V33.7502H147.1V32.7002Z" fill="#00C96E" />
    <path d="M148.15 32.7002H147.1V33.7502H148.15V32.7002Z" fill="#04FFC8" />
    <path d="M149.2 32.7002H148.15V33.7502H149.2V32.7002Z" fill="#03EAB3" />
    <path d="M150.25 32.7002H149.2V33.7502H150.25V32.7002Z" fill="#03EAB3" />
    <path d="M151.3 32.7002H150.25V33.7502H151.3V32.7002Z" fill="#03EAB3" />
    <path d="M152.35 32.7002H151.3V33.7502H152.35V32.7002Z" fill="#04FFC8" />
    <path d="M153.4 32.7002H152.35V33.7502H153.4V32.7002Z" fill="#04FFC8" />
    <path d="M154.45 32.7002H153.4V33.7502H154.45V32.7002Z" fill="#04FFC8" />
    <path d="M155.5 32.7002H154.45V33.7502H155.5V32.7002Z" fill="#04FFC8" />
    <path d="M156.55 32.7002H155.5V33.7502H156.55V32.7002Z" fill="#03EAB3" />
    <path d="M157.6 32.7002H156.55V33.7502H157.6V32.7002Z" fill="#03EAB3" />
    <path d="M158.65 32.7002H157.6V33.7502H158.65V32.7002Z" fill="#00A556" />
    <path d="M159.7 32.7002H158.65V33.7502H159.7V32.7002Z" fill="#04FFC8" />
    <path d="M160.75 32.7002H159.7V33.7502H160.75V32.7002Z" fill="#00C96E" />
    <path d="M161.8 32.7002H160.75V33.7502H161.8V32.7002Z" fill="#00A556" />
    <path d="M162.85 32.7002H161.8V33.7502H162.85V32.7002Z" fill="#00A556" />
    <path d="M163.9 32.7002H162.85V33.7502H163.9V32.7002Z" fill="#008E6C" />
    <path d="M118.75 33.75H117.7V34.8H118.75V33.75Z" fill="#03EAB3" />
    <path d="M119.8 33.75H118.75V34.8H119.8V33.75Z" fill="#00C96E" />
    <path d="M116.65 33.75H115.6V34.8H116.65V33.75Z" fill="#00A556" />
    <path d="M117.7 33.75H116.65V34.8H117.7V33.75Z" fill="#00A556" />
    <path d="M114.55 33.75H113.5V34.8H114.55V33.75Z" fill="#04FFC8" />
    <path d="M115.6 33.75H114.55V34.8H115.6V33.75Z" fill="#04FFC8" />
    <path d="M112.45 33.75H111.4V34.8H112.45V33.75Z" fill="#00C96E" />
    <path d="M111.41 33.75H110.36V34.8H111.41V33.75Z" fill="#008E6C" />
    <path d="M113.5 33.75H112.45V34.8H113.5V33.75Z" fill="#04FFC8" />
    <path d="M120.85 33.75H119.8V34.8H120.85V33.75Z" fill="#03EAB3" />
    <path d="M121.9 33.75H120.85V34.8H121.9V33.75Z" fill="#04FFC8" />
    <path d="M122.95 33.75H121.9V34.8H122.95V33.75Z" fill="#04FFC8" />
    <path d="M124 33.75H122.95V34.8H124V33.75Z" fill="#04FFC8" />
    <path d="M125.05 33.75H124V34.8H125.05V33.75Z" fill="#04FFC8" />
    <path d="M126.1 33.75H125.05V34.8H126.1V33.75Z" fill="#03EAB3" />
    <path d="M127.15 33.75H126.1V34.8H127.15V33.75Z" fill="#03EAB3" />
    <path d="M128.2 33.75H127.15V34.8H128.2V33.75Z" fill="#00C96E" />
    <path d="M129.25 33.75H128.2V34.8H129.25V33.75Z" fill="#00C96E" />
    <path d="M130.3 33.75H129.25V34.8H130.3V33.75Z" fill="#00C96E" />
    <path d="M131.35 33.75H130.3V34.8H131.35V33.75Z" fill="#03EAB3" />
    <path d="M132.4 33.75H131.35V34.8H132.4V33.75Z" fill="#03EAB3" />
    <path d="M133.45 33.75H132.4V34.8H133.45V33.75Z" fill="#03EAB3" />
    <path d="M134.5 33.75H133.45V34.8H134.5V33.75Z" fill="#04FFC8" />
    <path d="M135.55 33.75H134.5V34.8H135.55V33.75Z" fill="#04FFC8" />
    <path d="M136.6 33.75H135.55V34.8H136.6V33.75Z" fill="#04FFC8" />
    <path d="M137.65 33.75H136.6V34.8H137.65V33.75Z" fill="#04FFC8" />
    <path d="M138.7 33.75H137.65V34.8H138.7V33.75Z" fill="#03EAB3" />
    <path d="M139.75 33.75H138.7V34.8H139.75V33.75Z" fill="#03EAB3" />
    <path d="M140.8 33.75H139.75V34.8H140.8V33.75Z" fill="#03EAB3" />
    <path d="M141.85 33.75H140.8V34.8H141.85V33.75Z" fill="#04FFC8" />
    <path d="M142.9 33.75H141.85V34.8H142.9V33.75Z" fill="#03EAB3" />
    <path d="M143.95 33.75H142.9V34.8H143.95V33.75Z" fill="#03EAB3" />
    <path d="M145 33.75H143.95V34.8H145V33.75Z" fill="#03EAB3" />
    <path d="M146.05 33.75H145V34.8H146.05V33.75Z" fill="#03EAB3" />
    <path d="M147.1 33.75H146.05V34.8H147.1V33.75Z" fill="#00C96E" />
    <path d="M148.15 33.75H147.1V34.8H148.15V33.75Z" fill="#03EAB3" />
    <path d="M149.2 33.75H148.15V34.8H149.2V33.75Z" fill="#03EAB3" />
    <path d="M150.25 33.75H149.2V34.8H150.25V33.75Z" fill="#03EAB3" />
    <path d="M151.3 33.75H150.25V34.8H151.3V33.75Z" fill="#00C96E" />
    <path d="M152.35 33.75H151.3V34.8H152.35V33.75Z" fill="#00C96E" />
    <path d="M153.4 33.75H152.35V34.8H153.4V33.75Z" fill="#00C96E" />
    <path d="M154.45 33.75H153.4V34.8H154.45V33.75Z" fill="#00C96E" />
    <path d="M155.5 33.75H154.45V34.8H155.5V33.75Z" fill="#04FFC8" />
    <path d="M156.55 33.75H155.5V34.8H156.55V33.75Z" fill="#04FFC8" />
    <path d="M157.6 33.75H156.55V34.8H157.6V33.75Z" fill="#04FFC8" />
    <path d="M158.65 33.75H157.6V34.8H158.65V33.75Z" fill="#03EAB3" />
    <path d="M159.7 33.75H158.65V34.8H159.7V33.75Z" fill="#04FFC8" />
    <path d="M160.75 33.75H159.7V34.8H160.75V33.75Z" fill="#00C96E" />
    <path d="M161.8 33.75H160.75V34.8H161.8V33.75Z" fill="#00C96E" />
    <path d="M162.85 33.75H161.8V34.8H162.85V33.75Z" fill="#00A556" />
    <path d="M163.9 33.75H162.85V34.8H163.9V33.75Z" fill="#008E6C" />
    <path d="M164.95 33.75H163.9V34.8H164.95V33.75Z" fill="#008E6C" />
    <path d="M118.75 34.7998H117.7V35.8498H118.75V34.7998Z" fill="#00C96E" />
    <path d="M119.8 34.7998H118.75V35.8498H119.8V34.7998Z" fill="#00C96E" />
    <path d="M116.65 34.7998H115.6V35.8498H116.65V34.7998Z" fill="#00A556" />
    <path d="M117.7 34.7998H116.65V35.8498H117.7V34.7998Z" fill="#00C96E" />
    <path d="M114.55 34.7998H113.5V35.8498H114.55V34.7998Z" fill="#04FFC8" />
    <path d="M115.6 34.7998H114.55V35.8498H115.6V34.7998Z" fill="#00C96E" />
    <path d="M112.45 34.7998H111.4V35.8498H112.45V34.7998Z" fill="#00C96E" />
    <path d="M111.41 34.7998H110.36V35.8498H111.41V34.7998Z" fill="#008E6C" />
    <path d="M113.5 34.7998H112.45V35.8498H113.5V34.7998Z" fill="#00C96E" />
    <path d="M120.85 34.7998H119.8V35.8498H120.85V34.7998Z" fill="#03EAB3" />
    <path d="M121.9 34.7998H120.85V35.8498H121.9V34.7998Z" fill="#04FFC8" />
    <path d="M122.95 34.7998H121.9V35.8498H122.95V34.7998Z" fill="#04FFC8" />
    <path d="M124 34.7998H122.95V35.8498H124V34.7998Z" fill="#04FFC8" />
    <path d="M125.05 34.7998H124V35.8498H125.05V34.7998Z" fill="#04FFC8" />
    <path d="M126.1 34.7998H125.05V35.8498H126.1V34.7998Z" fill="#03EAB3" />
    <path d="M127.15 34.7998H126.1V35.8498H127.15V34.7998Z" fill="#00C96E" />
    <path d="M128.2 34.7998H127.15V35.8498H128.2V34.7998Z" fill="#00C96E" />
    <path d="M129.25 34.7998H128.2V35.8498H129.25V34.7998Z" fill="#00C96E" />
    <path d="M130.3 34.7998H129.25V35.8498H130.3V34.7998Z" fill="#00C96E" />
    <path d="M131.35 34.7998H130.3V35.8498H131.35V34.7998Z" fill="#03EAB3" />
    <path d="M132.4 34.7998H131.35V35.8498H132.4V34.7998Z" fill="#03EAB3" />
    <path d="M133.45 34.7998H132.4V35.8498H133.45V34.7998Z" fill="#04FFC8" />
    <path d="M134.5 34.7998H133.45V35.8498H134.5V34.7998Z" fill="#03EAB3" />
    <path d="M135.55 34.7998H134.5V35.8498H135.55V34.7998Z" fill="#04FFC8" />
    <path d="M136.6 34.7998H135.55V35.8498H136.6V34.7998Z" fill="#04FFC8" />
    <path d="M137.65 34.7998H136.6V35.8498H137.65V34.7998Z" fill="#04FFC8" />
    <path d="M138.7 34.7998H137.65V35.8498H138.7V34.7998Z" fill="#03EAB3" />
    <path d="M139.75 34.7998H138.7V35.8498H139.75V34.7998Z" fill="#04FFC8" />
    <path d="M140.8 34.7998H139.75V35.8498H140.8V34.7998Z" fill="#03EAB3" />
    <path d="M141.85 34.7998H140.8V35.8498H141.85V34.7998Z" fill="#04FFC8" />
    <path d="M142.9 34.7998H141.85V35.8498H142.9V34.7998Z" fill="#03EAB3" />
    <path d="M143.95 34.7998H142.9V35.8498H143.95V34.7998Z" fill="#00C96E" />
    <path d="M145 34.7998H143.95V35.8498H145V34.7998Z" fill="#03EAB3" />
    <path d="M146.05 34.7998H145V35.8498H146.05V34.7998Z" fill="#00C96E" />
    <path d="M147.1 34.7998H146.05V35.8498H147.1V34.7998Z" fill="#00C96E" />
    <path d="M148.15 34.7998H147.1V35.8498H148.15V34.7998Z" fill="#00C96E" />
    <path d="M149.2 34.7998H148.15V35.8498H149.2V34.7998Z" fill="#00C96E" />
    <path d="M150.25 34.7998H149.2V35.8498H150.25V34.7998Z" fill="#00C96E" />
    <path d="M151.3 34.7998H150.25V35.8498H151.3V34.7998Z" fill="#00C96E" />
    <path d="M152.35 34.7998H151.3V35.8498H152.35V34.7998Z" fill="#00C96E" />
    <path d="M153.4 34.7998H152.35V35.8498H153.4V34.7998Z" fill="#00A556" />
    <path d="M154.45 34.7998H153.4V35.8498H154.45V34.7998Z" fill="#00A556" />
    <path d="M155.5 34.7998H154.45V35.8498H155.5V34.7998Z" fill="#00A556" />
    <path d="M156.55 34.7998H155.5V35.8498H156.55V34.7998Z" fill="#00A556" />
    <path d="M157.6 34.7998H156.55V35.8498H157.6V34.7998Z" fill="#00A556" />
    <path d="M158.65 34.7998H157.6V35.8498H158.65V34.7998Z" fill="#03EAB3" />
    <path d="M159.7 34.7998H158.65V35.8498H159.7V34.7998Z" fill="#04FFC8" />
    <path d="M160.75 34.7998H159.7V35.8498H160.75V34.7998Z" fill="#03EAB3" />
    <path d="M161.8 34.7998H160.75V35.8498H161.8V34.7998Z" fill="#00C96E" />
    <path d="M162.85 34.7998H161.8V35.8498H162.85V34.7998Z" fill="#00A556" />
    <path d="M163.9 34.7998H162.85V35.8498H163.9V34.7998Z" fill="#008E6C" />
    <path d="M164.95 34.7998H163.9V35.8498H164.95V34.7998Z" fill="#008E6C" />
    <path d="M118.75 35.8496H117.7V36.8996H118.75V35.8496Z" fill="#00C96E" />
    <path d="M119.8 35.8496H118.75V36.8996H119.8V35.8496Z" fill="#00C96E" />
    <path d="M116.65 35.8496H115.6V36.8996H116.65V35.8496Z" fill="#00A556" />
    <path d="M117.7 35.8496H116.65V36.8996H117.7V35.8496Z" fill="#00C96E" />
    <path d="M114.55 35.8496H113.5V36.8996H114.55V35.8496Z" fill="#00C96E" />
    <path d="M115.6 35.8496H114.55V36.8996H115.6V35.8496Z" fill="#00A556" />
    <path d="M112.45 35.8496H111.4V36.8996H112.45V35.8496Z" fill="#00A556" />
    <path d="M111.41 35.8496H110.36V36.8996H111.41V35.8496Z" fill="#008E6C" />
    <path
      d="M110.361 31.6494H109.311V32.6994H110.361V31.6494Z"
      fill="#008E6C"
    />
    <path
      d="M110.361 32.7002H109.311V33.7502H110.361V32.7002Z"
      fill="#008E6C"
    />
    <path d="M110.361 33.75H109.311V34.8H110.361V33.75Z" fill="#008E6C" />
    <path
      d="M110.361 34.7998H109.311V35.8498H110.361V34.7998Z"
      fill="#008E6C"
    />
    <path
      d="M110.361 35.8496H109.311V36.8996H110.361V35.8496Z"
      fill="#008E6C"
    />
    <path d="M113.5 35.8496H112.45V36.8996H113.5V35.8496Z" fill="#00C96E" />
    <path d="M120.85 35.8496H119.8V36.8996H120.85V35.8496Z" fill="#03EAB3" />
    <path d="M121.9 35.8496H120.85V36.8996H121.9V35.8496Z" fill="#04FFC8" />
    <path d="M122.95 35.8496H121.9V36.8996H122.95V35.8496Z" fill="#04FFC8" />
    <path d="M124 35.8496H122.95V36.8996H124V35.8496Z" fill="#04FFC8" />
    <path d="M125.05 35.8496H124V36.8996H125.05V35.8496Z" fill="#03EAB3" />
    <path d="M126.1 35.8496H125.05V36.8996H126.1V35.8496Z" fill="#03EAB3" />
    <path d="M127.15 35.8496H126.1V36.8996H127.15V35.8496Z" fill="#00A556" />
    <path d="M128.2 35.8496H127.15V36.8996H128.2V35.8496Z" fill="#00A556" />
    <path d="M129.25 35.8496H128.2V36.8996H129.25V35.8496Z" fill="#00A556" />
    <path d="M130.3 35.8496H129.25V36.8996H130.3V35.8496Z" fill="#00C96E" />
    <path d="M131.35 35.8496H130.3V36.8996H131.35V35.8496Z" fill="#03EAB3" />
    <path d="M132.4 35.8496H131.35V36.8996H132.4V35.8496Z" fill="#03EAB3" />
    <path d="M133.45 35.8496H132.4V36.8996H133.45V35.8496Z" fill="#04FFC8" />
    <path d="M134.5 35.8496H133.45V36.8996H134.5V35.8496Z" fill="#03EAB3" />
    <path d="M135.55 35.8496H134.5V36.8996H135.55V35.8496Z" fill="#03EAB3" />
    <path d="M136.6 35.8496H135.55V36.8996H136.6V35.8496Z" fill="#03EAB3" />
    <path d="M137.65 35.8496H136.6V36.8996H137.65V35.8496Z" fill="#03EAB3" />
    <path d="M138.7 35.8496H137.65V36.8996H138.7V35.8496Z" fill="#04FFC8" />
    <path d="M139.75 35.8496H138.7V36.8996H139.75V35.8496Z" fill="#04FFC8" />
    <path d="M140.8 35.8496H139.75V36.8996H140.8V35.8496Z" fill="#04FFC8" />
    <path d="M141.85 35.8496H140.8V36.8996H141.85V35.8496Z" fill="#00C96E" />
    <path d="M142.9 35.8496H141.85V36.8996H142.9V35.8496Z" fill="#00C96E" />
    <path d="M143.95 35.8496H142.9V36.8996H143.95V35.8496Z" fill="#00C96E" />
    <path d="M145 35.8496H143.95V36.8996H145V35.8496Z" fill="#03EAB3" />
    <path d="M146.05 35.8496H145V36.8996H146.05V35.8496Z" fill="#03EAB3" />
    <path d="M147.1 35.8496H146.05V36.8996H147.1V35.8496Z" fill="#03EAB3" />
    <path d="M148.15 35.8496H147.1V36.8996H148.15V35.8496Z" fill="#03EAB3" />
    <path d="M149.2 35.8496H148.15V36.8996H149.2V35.8496Z" fill="#03EAB3" />
    <path d="M150.25 35.8496H149.2V36.8996H150.25V35.8496Z" fill="#03EAB3" />
    <path d="M151.3 35.8496H150.25V36.8996H151.3V35.8496Z" fill="#03EAB3" />
    <path d="M152.35 35.8496H151.3V36.8996H152.35V35.8496Z" fill="#00C96E" />
    <path d="M153.4 35.8496H152.35V36.8996H153.4V35.8496Z" fill="#00C96E" />
    <path d="M154.45 35.8496H153.4V36.8996H154.45V35.8496Z" fill="#00C96E" />
    <path d="M155.5 35.8496H154.45V36.8996H155.5V35.8496Z" fill="#008E6C" />
    <path d="M156.55 35.8496H155.5V36.8996H156.55V35.8496Z" fill="#008E6C" />
    <path d="M157.6 35.8496H156.55V36.8996H157.6V35.8496Z" fill="#008E6C" />
    <path d="M158.65 35.8496H157.6V36.8996H158.65V35.8496Z" fill="#00C96E" />
    <path d="M159.7 35.8496H158.65V36.8996H159.7V35.8496Z" fill="#00C96E" />
    <path d="M160.75 35.8496H159.7V36.8996H160.75V35.8496Z" fill="#00C96E" />
    <path d="M161.8 35.8496H160.75V36.8996H161.8V35.8496Z" fill="#00A556" />
    <path d="M162.85 35.8496H161.8V36.8996H162.85V35.8496Z" fill="#008E6C" />
    <path d="M163.9 35.8496H162.85V36.8996H163.9V35.8496Z" fill="#008E6C" />
    <path d="M164.95 35.8496H163.9V36.8996H164.95V35.8496Z" fill="#008E6C" />
    <path d="M118.75 36.8994H117.7V37.9494H118.75V36.8994Z" fill="#03EAB3" />
    <path d="M119.8 36.8994H118.75V37.9494H119.8V36.8994Z" fill="#03EAB3" />
    <path d="M116.65 36.8994H115.6V37.9494H116.65V36.8994Z" fill="#00A556" />
    <path d="M117.7 36.8994H116.65V37.9494H117.7V36.8994Z" fill="#00C96E" />
    <path d="M114.55 36.8994H113.5V37.9494H114.55V36.8994Z" fill="#00A556" />
    <path d="M115.6 36.8994H114.55V37.9494H115.6V36.8994Z" fill="#008E6C" />
    <path d="M112.45 36.8994H111.4V37.9494H112.45V36.8994Z" fill="#008E6C" />
    <path d="M111.41 36.8994H110.36V37.9494H111.41V36.8994Z" fill="#008E6C" />
    <path d="M113.5 36.8994H112.45V37.9494H113.5V36.8994Z" fill="#00A556" />
    <path d="M120.85 36.8994H119.8V37.9494H120.85V36.8994Z" fill="#03EAB3" />
    <path d="M121.9 36.8994H120.85V37.9494H121.9V36.8994Z" fill="#04FFC8" />
    <path d="M122.95 36.8994H121.9V37.9494H122.95V36.8994Z" fill="#04FFC8" />
    <path d="M124 36.8994H122.95V37.9494H124V36.8994Z" fill="#03EAB3" />
    <path d="M125.05 36.8994H124V37.9494H125.05V36.8994Z" fill="#00C96E" />
    <path d="M126.1 36.8994H125.05V37.9494H126.1V36.8994Z" fill="#00A556" />
    <path d="M127.15 36.8994H126.1V37.9494H127.15V36.8994Z" fill="#00A556" />
    <path d="M128.2 36.8994H127.15V37.9494H128.2V36.8994Z" fill="#00A556" />
    <path d="M129.25 36.8994H128.2V37.9494H129.25V36.8994Z" fill="#00A556" />
    <path d="M130.3 36.8994H129.25V37.9494H130.3V36.8994Z" fill="#00A556" />
    <path d="M131.35 36.8994H130.3V37.9494H131.35V36.8994Z" fill="#03EAB3" />
    <path d="M132.4 36.8994H131.35V37.9494H132.4V36.8994Z" fill="#03EAB3" />
    <path d="M133.45 36.8994H132.4V37.9494H133.45V36.8994Z" fill="#04FFC8" />
    <path d="M134.5 36.8994H133.45V37.9494H134.5V36.8994Z" fill="#04FFC8" />
    <path d="M135.55 36.8994H134.5V37.9494H135.55V36.8994Z" fill="#04FFC8" />
    <path d="M136.6 36.8994H135.55V37.9494H136.6V36.8994Z" fill="#04FFC8" />
    <path d="M137.65 36.8994H136.6V37.9494H137.65V36.8994Z" fill="#04FFC8" />
    <path d="M138.7 36.8994H137.65V37.9494H138.7V36.8994Z" fill="#04FFC8" />
    <path d="M139.75 36.8994H138.7V37.9494H139.75V36.8994Z" fill="#03EAB3" />
    <path d="M140.8 36.8994H139.75V37.9494H140.8V36.8994Z" fill="#00C96E" />
    <path d="M141.85 36.8994H140.8V37.9494H141.85V36.8994Z" fill="#00C96E" />
    <path d="M142.9 36.8994H141.85V37.9494H142.9V36.8994Z" fill="#00C96E" />
    <path d="M143.95 36.8994H142.9V37.9494H143.95V36.8994Z" fill="#00C96E" />
    <path d="M145 36.8994H143.95V37.9494H145V36.8994Z" fill="#00C96E" />
    <path d="M146.05 36.8994H145V37.9494H146.05V36.8994Z" fill="#03EAB3" />
    <path d="M147.1 36.8994H146.05V37.9494H147.1V36.8994Z" fill="#03EAB3" />
    <path d="M148.15 36.8994H147.1V37.9494H148.15V36.8994Z" fill="#04FFC8" />
    <path d="M149.2 36.8994H148.15V37.9494H149.2V36.8994Z" fill="#04FFC8" />
    <path d="M150.25 36.8994H149.2V37.9494H150.25V36.8994Z" fill="#04FFC8" />
    <path d="M151.3 36.8994H150.25V37.9494H151.3V36.8994Z" fill="#03EAB3" />
    <path d="M152.35 36.8994H151.3V37.9494H152.35V36.8994Z" fill="#00C96E" />
    <path d="M153.4 36.8994H152.35V37.9494H153.4V36.8994Z" fill="#00C96E" />
    <path d="M154.45 36.8994H153.4V37.9494H154.45V36.8994Z" fill="#00C96E" />
    <path d="M155.5 36.8994H154.45V37.9494H155.5V36.8994Z" fill="#00C96E" />
    <path d="M156.55 36.8994H155.5V37.9494H156.55V36.8994Z" fill="#008E6C" />
    <path d="M157.6 36.8994H156.55V37.9494H157.6V36.8994Z" fill="#008E6C" />
    <path d="M158.65 36.8994H157.6V37.9494H158.65V36.8994Z" fill="#008E6C" />
    <path d="M159.7 36.8994H158.65V37.9494H159.7V36.8994Z" fill="#00A556" />
    <path d="M160.75 36.8994H159.7V37.9494H160.75V36.8994Z" fill="#00A556" />
    <path d="M161.8 36.8994H160.75V37.9494H161.8V36.8994Z" fill="#008E6C" />
    <path d="M162.85 36.8994H161.8V37.9494H162.85V36.8994Z" fill="#008E6C" />
    <path d="M163.9 36.8994H162.85V37.9494H163.9V36.8994Z" fill="#008E6C" />
    <path d="M164.95 36.8994H163.9V37.9494H164.95V36.8994Z" fill="#008E6C" />
    <path d="M118.75 37.9502H117.7V39.0002H118.75V37.9502Z" fill="#00C96E" />
    <path d="M119.8 37.9502H118.75V39.0002H119.8V37.9502Z" fill="#04FFC8" />
    <path d="M116.65 37.9502H115.6V39.0002H116.65V37.9502Z" fill="#00A556" />
    <path d="M117.7 37.9502H116.65V39.0002H117.7V37.9502Z" fill="#00C96E" />
    <path d="M114.55 37.9502H113.5V39.0002H114.55V37.9502Z" fill="#008E6C" />
    <path d="M115.6 37.9502H114.55V39.0002H115.6V37.9502Z" fill="#008E6C" />
    <path d="M112.45 37.9502H111.4V39.0002H112.45V37.9502Z" fill="#008E6C" />
    <path d="M111.41 37.9502H110.36V39.0002H111.41V37.9502Z" fill="#008E6C" />
    <path d="M113.5 37.9502H112.45V39.0002H113.5V37.9502Z" fill="#008E6C" />
    <path d="M120.85 37.9502H119.8V39.0002H120.85V37.9502Z" fill="#03EAB3" />
    <path d="M121.9 37.9502H120.85V39.0002H121.9V37.9502Z" fill="#03EAB3" />
    <path d="M122.95 37.9502H121.9V39.0002H122.95V37.9502Z" fill="#03EAB3" />
    <path d="M124 37.9502H122.95V39.0002H124V37.9502Z" fill="#00C96E" />
    <path d="M125.05 37.9502H124V39.0002H125.05V37.9502Z" fill="#00C96E" />
    <path d="M126.1 37.9502H125.05V39.0002H126.1V37.9502Z" fill="#00C96E" />
    <path d="M127.15 37.9502H126.1V39.0002H127.15V37.9502Z" fill="#00C96E" />
    <path d="M128.2 37.9502H127.15V39.0002H128.2V37.9502Z" fill="#00A556" />
    <path d="M129.25 37.9502H128.2V39.0002H129.25V37.9502Z" fill="#008E6C" />
    <path d="M130.3 37.9502H129.25V39.0002H130.3V37.9502Z" fill="#008E6C" />
    <path d="M131.35 37.9502H130.3V39.0002H131.35V37.9502Z" fill="#00C96E" />
    <path d="M132.4 37.9502H131.35V39.0002H132.4V37.9502Z" fill="#00C96E" />
    <path d="M133.45 37.9502H132.4V39.0002H133.45V37.9502Z" fill="#03EAB3" />
    <path d="M134.5 37.9502H133.45V39.0002H134.5V37.9502Z" fill="#03EAB3" />
    <path d="M135.55 37.9502H134.5V39.0002H135.55V37.9502Z" fill="#03EAB3" />
    <path d="M136.6 37.9502H135.55V39.0002H136.6V37.9502Z" fill="#04FFC8" />
    <path d="M137.65 37.9502H136.6V39.0002H137.65V37.9502Z" fill="#04FFC8" />
    <path d="M138.7 37.9502H137.65V39.0002H138.7V37.9502Z" fill="#03EAB3" />
    <path d="M139.75 37.9502H138.7V39.0002H139.75V37.9502Z" fill="#00C96E" />
    <path d="M140.8 37.9502H139.75V39.0002H140.8V37.9502Z" fill="#00C96E" />
    <path d="M141.85 37.9502H140.8V39.0002H141.85V37.9502Z" fill="#00A556" />
    <path d="M142.9 37.9502H141.85V39.0002H142.9V37.9502Z" fill="#008E6C" />
    <path d="M143.95 37.9502H142.9V39.0002H143.95V37.9502Z" fill="#00C96E" />
    <path d="M145 37.9502H143.95V39.0002H145V37.9502Z" fill="#00C96E" />
    <path d="M146.05 37.9502H145V39.0002H146.05V37.9502Z" fill="#00C96E" />
    <path d="M147.1 37.9502H146.05V39.0002H147.1V37.9502Z" fill="#00C96E" />
    <path d="M148.15 37.9502H147.1V39.0002H148.15V37.9502Z" fill="#04FFC8" />
    <path d="M149.2 37.9502H148.15V39.0002H149.2V37.9502Z" fill="#03EAB3" />
    <path d="M150.25 37.9502H149.2V39.0002H150.25V37.9502Z" fill="#04FFC8" />
    <path d="M151.3 37.9502H150.25V39.0002H151.3V37.9502Z" fill="#04FFC8" />
    <path d="M152.35 37.9502H151.3V39.0002H152.35V37.9502Z" fill="#04FFC8" />
    <path d="M153.4 37.9502H152.35V39.0002H153.4V37.9502Z" fill="#03EAB3" />
    <path d="M154.45 37.9502H153.4V39.0002H154.45V37.9502Z" fill="#03EAB3" />
    <path d="M155.5 37.9502H154.45V39.0002H155.5V37.9502Z" fill="#00C96E" />
    <path d="M156.55 37.9502H155.5V39.0002H156.55V37.9502Z" fill="#00C96E" />
    <path d="M157.6 37.9502H156.55V39.0002H157.6V37.9502Z" fill="#008E6C" />
    <path d="M158.65 37.9502H157.6V39.0002H158.65V37.9502Z" fill="#008E6C" />
    <path d="M159.7 37.9502H158.65V39.0002H159.7V37.9502Z" fill="#008E6C" />
    <path d="M160.75 37.9502H159.7V39.0002H160.75V37.9502Z" fill="#008E6C" />
    <path d="M161.8 37.9502H160.75V39.0002H161.8V37.9502Z" fill="#008E6C" />
    <path d="M162.85 37.9502H161.8V39.0002H162.85V37.9502Z" fill="#008E6C" />
    <path d="M163.9 37.9502H162.85V39.0002H163.9V37.9502Z" fill="#008E6C" />
    <path d="M164.95 37.9502H163.9V39.0002H164.95V37.9502Z" fill="#008E6C" />
    <path d="M118.75 39H117.7V40.05H118.75V39Z" fill="#00C96E" />
    <path d="M119.8 39H118.75V40.05H119.8V39Z" fill="#00C96E" />
    <path d="M116.65 39H115.6V40.05H116.65V39Z" fill="#00A556" />
    <path d="M117.7 39H116.65V40.05H117.7V39Z" fill="#00A556" />
    <path d="M115.6 39H114.55V40.05H115.6V39Z" fill="#00A556" />
    <path d="M120.85 39H119.8V40.05H120.85V39Z" fill="#00C96E" />
    <path d="M121.9 39H120.85V40.05H121.9V39Z" fill="#00C96E" />
    <path d="M122.95 39H121.9V40.05H122.95V39Z" fill="#00C96E" />
    <path d="M124 39H122.95V40.05H124V39Z" fill="#00C96E" />
    <path d="M125.05 39H124V40.05H125.05V39Z" fill="#00C96E" />
    <path d="M126.1 39H125.05V40.05H126.1V39Z" fill="#00C96E" />
    <path d="M127.15 39H126.1V40.05H127.15V39Z" fill="#00C96E" />
    <path d="M128.2 39H127.15V40.05H128.2V39Z" fill="#008E6C" />
    <path d="M129.25 39H128.2V40.05H129.25V39Z" fill="#008E6C" />
    <path d="M130.3 39H129.25V40.05H130.3V39Z" fill="#008E6C" />
    <path d="M131.35 39H130.3V40.05H131.35V39Z" fill="#00C96E" />
    <path d="M132.4 39H131.35V40.05H132.4V39Z" fill="#00C96E" />
    <path d="M133.45 39H132.4V40.05H133.45V39Z" fill="#00C96E" />
    <path d="M134.5 39H133.45V40.05H134.5V39Z" fill="#03EAB3" />
    <path d="M135.55 39H134.5V40.05H135.55V39Z" fill="#03EAB3" />
    <path d="M136.6 39H135.55V40.05H136.6V39Z" fill="#03EAB3" />
    <path d="M137.65 39H136.6V40.05H137.65V39Z" fill="#03EAB3" />
    <path d="M138.7 39H137.65V40.05H138.7V39Z" fill="#00C96E" />
    <path d="M139.75 39H138.7V40.05H139.75V39Z" fill="#00C96E" />
    <path d="M140.8 39H139.75V40.05H140.8V39Z" fill="#00A556" />
    <path d="M141.85 39H140.8V40.05H141.85V39Z" fill="#00A556" />
    <path d="M142.9 39H141.85V40.05H142.9V39Z" fill="#008E6C" />
    <path d="M143.95 39H142.9V40.05H143.95V39Z" fill="#008E6C" />
    <path d="M145 39H143.95V40.05H145V39Z" fill="#00A556" />
    <path d="M146.05 39H145V40.05H146.05V39Z" fill="#00A556" />
    <path d="M147.1 39H146.05V40.05H147.1V39Z" fill="#00C96E" />
    <path d="M148.15 39H147.1V40.05H148.15V39Z" fill="#00C96E" />
    <path d="M149.2 39H148.15V40.05H149.2V39Z" fill="#03EAB3" />
    <path d="M150.25 39H149.2V40.05H150.25V39Z" fill="#04FFC8" />
    <path d="M151.3 39H150.25V40.05H151.3V39Z" fill="#03EAB3" />
    <path d="M152.35 39H151.3V40.05H152.35V39Z" fill="#03EAB3" />
    <path d="M153.4 39H152.35V40.05H153.4V39Z" fill="#00C96E" />
    <path d="M154.45 39H153.4V40.05H154.45V39Z" fill="#00C96E" />
    <path d="M155.5 39H154.45V40.05H155.5V39Z" fill="#00C96E" />
    <path d="M156.55 39H155.5V40.05H156.55V39Z" fill="#00C96E" />
    <path d="M157.6 39H156.55V40.05H157.6V39Z" fill="#00A556" />
    <path d="M158.65 39H157.6V40.05H158.65V39Z" fill="#00A556" />
    <path d="M159.7 39H158.65V40.05H159.7V39Z" fill="#008E6C" />
    <path d="M161.8 39H160.75V40.05H161.8V39Z" fill="#008E6C" />
    <path d="M162.85 39H161.8V40.05H162.85V39Z" fill="#008E6C" />
    <path d="M118.75 40.0498H117.7V41.0998H118.75V40.0498Z" fill="#008E6C" />
    <path d="M119.8 40.0498H118.75V41.0998H119.8V40.0498Z" fill="#00C96E" />
    <path d="M116.65 40.0498H115.6V41.0998H116.65V40.0498Z" fill="#00A556" />
    <path d="M117.7 40.0498H116.65V41.0998H117.7V40.0498Z" fill="#008E6C" />
    <path d="M114.55 40.0498H113.5V41.0998H114.55V40.0498Z" fill="#008E6C" />
    <path d="M107.2 40.0498H106.15V41.0998H107.2V40.0498Z" fill="#00A556" />
    <path d="M106.15 40.0498H105.1V41.0998H106.15V40.0498Z" fill="#00A556" />
    <path d="M106.15 39H105.1V40.05H106.15V39Z" fill="#00A556" />
    <path d="M106.15 37.9502H105.1V39.0002H106.15V37.9502Z" fill="#008E6C" />
    <path d="M107.2 41.0996H106.15V42.1496H107.2V41.0996Z" fill="#008E6C" />
    <path d="M107.2 42.1494H106.15V43.1994H107.2V42.1494Z" fill="#008E6C" />
    <path d="M106.15 41.0996H105.1V42.1496H106.15V41.0996Z" fill="#008E6C" />
    <path d="M104.05 41.0996H103V42.1496H104.05V41.0996Z" fill="#008E6C" />
    <path d="M116.65 54.4756H115.6V55.5256H116.65V54.4756Z" fill="#008E6C" />
    <path d="M116.65 53.4258H115.6V54.4758H116.65V53.4258Z" fill="#008E6C" />
    <path d="M117.7 53.4258H116.65V54.4758H117.7V53.4258Z" fill="#008E6C" />
    <path d="M105.1 40.0498H104.05V41.0998H105.1V40.0498Z" fill="#008E6C" />
    <path d="M115.6 40.0498H114.55V41.0998H115.6V40.0498Z" fill="#00A556" />
    <path d="M120.85 40.0498H119.8V41.0998H120.85V40.0498Z" fill="#00C96E" />
    <path d="M121.9 40.0498H120.85V41.0998H121.9V40.0498Z" fill="#00A556" />
    <path d="M122.95 40.0498H121.9V41.0998H122.95V40.0498Z" fill="#00C96E" />
    <path d="M124 40.0498H122.95V41.0998H124V40.0498Z" fill="#00A556" />
    <path d="M125.05 40.0498H124V41.0998H125.05V40.0498Z" fill="#00A556" />
    <path d="M126.1 40.0498H125.05V41.0998H126.1V40.0498Z" fill="#00C96E" />
    <path d="M127.15 40.0498H126.1V41.0998H127.15V40.0498Z" fill="#00A556" />
    <path d="M128.2 40.0498H127.15V41.0998H128.2V40.0498Z" fill="#008E6C" />
    <path d="M129.25 40.0498H128.2V41.0998H129.25V40.0498Z" fill="#008E6C" />
    <path d="M130.3 40.0498H129.25V41.0998H130.3V40.0498Z" fill="#008E6C" />
    <path d="M131.35 40.0498H130.3V41.0998H131.35V40.0498Z" fill="#00A556" />
    <path d="M132.4 40.0498H131.35V41.0998H132.4V40.0498Z" fill="#00C96E" />
    <path d="M133.45 40.0498H132.4V41.0998H133.45V40.0498Z" fill="#00C96E" />
    <path d="M134.5 40.0498H133.45V41.0998H134.5V40.0498Z" fill="#00C96E" />
    <path d="M135.55 40.0498H134.5V41.0998H135.55V40.0498Z" fill="#00C96E" />
    <path d="M136.6 40.0498H135.55V41.0998H136.6V40.0498Z" fill="#00C96E" />
    <path d="M137.65 40.0498H136.6V41.0998H137.65V40.0498Z" fill="#00C96E" />
    <path d="M138.7 40.0498H137.65V41.0998H138.7V40.0498Z" fill="#00C96E" />
    <path d="M139.75 40.0498H138.7V41.0998H139.75V40.0498Z" fill="#00A556" />
    <path d="M140.8 40.0498H139.75V41.0998H140.8V40.0498Z" fill="#00A556" />
    <path d="M141.85 40.0498H140.8V41.0998H141.85V40.0498Z" fill="#008E6C" />
    <path d="M142.9 40.0498H141.85V41.0998H142.9V40.0498Z" fill="#008E6C" />
    <path d="M143.95 40.0498H142.9V41.0998H143.95V40.0498Z" fill="#00A556" />
    <path d="M145 40.0498H143.95V41.0998H145V40.0498Z" fill="#00A556" />
    <path d="M146.05 40.0498H145V41.0998H146.05V40.0498Z" fill="#00A556" />
    <path d="M147.1 40.0498H146.05V41.0998H147.1V40.0498Z" fill="#00C96E" />
    <path d="M148.15 40.0498H147.1V41.0998H148.15V40.0498Z" fill="#04FFC8" />
    <path d="M149.2 40.0498H148.15V41.0998H149.2V40.0498Z" fill="#04FFC8" />
    <path d="M150.25 40.0498H149.2V41.0998H150.25V40.0498Z" fill="#04FFC8" />
    <path d="M151.3 40.0498H150.25V41.0998H151.3V40.0498Z" fill="#04FFC8" />
    <path d="M152.35 40.0498H151.3V41.0998H152.35V40.0498Z" fill="#04FFC8" />
    <path d="M153.4 40.0498H152.35V41.0998H153.4V40.0498Z" fill="#00C96E" />
    <path d="M154.45 40.0498H153.4V41.0998H154.45V40.0498Z" fill="#00C96E" />
    <path d="M155.5 40.0498H154.45V41.0998H155.5V40.0498Z" fill="#00C96E" />
    <path d="M156.55 40.0498H155.5V41.0998H156.55V40.0498Z" fill="#00C96E" />
    <path d="M157.6 40.0498H156.55V41.0998H157.6V40.0498Z" fill="#00A556" />
    <path d="M158.65 40.0498H157.6V41.0998H158.65V40.0498Z" fill="#008E6C" />
    <path d="M118.75 41.0996H117.7V42.1496H118.75V41.0996Z" fill="#008E6C" />
    <path d="M119.8 41.0996H118.75V42.1496H119.8V41.0996Z" fill="#008E6C" />
    <path d="M116.65 41.0996H115.6V42.1496H116.65V41.0996Z" fill="#00A556" />
    <path d="M117.7 41.0996H116.65V42.1496H117.7V41.0996Z" fill="#00A556" />
    <path d="M114.55 41.0996H113.5V42.1496H114.55V41.0996Z" fill="#008E6C" />
    <path d="M115.6 41.0996H114.55V42.1496H115.6V41.0996Z" fill="#00A556" />
    <path d="M120.85 41.0996H119.8V42.1496H120.85V41.0996Z" fill="#008E6C" />
    <path d="M121.9 41.0996H120.85V42.1496H121.9V41.0996Z" fill="#008E6C" />
    <path d="M122.95 41.0996H121.9V42.1496H122.95V41.0996Z" fill="#00A556" />
    <path d="M124 41.0996H122.95V42.1496H124V41.0996Z" fill="#00A556" />
    <path d="M125.05 41.0996H124V42.1496H125.05V41.0996Z" fill="#00C96E" />
    <path d="M126.1 41.0996H125.05V42.1496H126.1V41.0996Z" fill="#00A556" />
    <path d="M127.15 41.0996H126.1V42.1496H127.15V41.0996Z" fill="#008E6C" />
    <path d="M128.2 41.0996H127.15V42.1496H128.2V41.0996Z" fill="#008E6C" />
    <path d="M129.25 41.0996H128.2V42.1496H129.25V41.0996Z" fill="#00A556" />
    <path d="M130.3 41.0996H129.25V42.1496H130.3V41.0996Z" fill="#00A556" />
    <path d="M131.35 41.0996H130.3V42.1496H131.35V41.0996Z" fill="#00A556" />
    <path d="M132.4 41.0996H131.35V42.1496H132.4V41.0996Z" fill="#00C96E" />
    <path d="M133.45 41.0996H132.4V42.1496H133.45V41.0996Z" fill="#00C96E" />
    <path d="M134.5 41.0996H133.45V42.1496H134.5V41.0996Z" fill="#00C96E" />
    <path d="M135.55 41.0996H134.5V42.1496H135.55V41.0996Z" fill="#00C96E" />
    <path d="M136.6 41.0996H135.55V42.1496H136.6V41.0996Z" fill="#00C96E" />
    <path d="M137.65 41.0996H136.6V42.1496H137.65V41.0996Z" fill="#008E6C" />
    <path d="M138.7 41.0996H137.65V42.1496H138.7V41.0996Z" fill="#00A556" />
    <path d="M139.75 41.0996H138.7V42.1496H139.75V41.0996Z" fill="#00A556" />
    <path d="M140.8 41.0996H139.75V42.1496H140.8V41.0996Z" fill="#00A556" />
    <path d="M141.85 41.0996H140.8V42.1496H141.85V41.0996Z" fill="#008E6C" />
    <path d="M142.9 41.0996H141.85V42.1496H142.9V41.0996Z" fill="#008E6C" />
    <path d="M143.95 41.0996H142.9V42.1496H143.95V41.0996Z" fill="#00A556" />
    <path d="M145 41.0996H143.95V42.1496H145V41.0996Z" fill="#00A556" />
    <path d="M146.05 41.0996H145V42.1496H146.05V41.0996Z" fill="#00A556" />
    <path d="M147.1 41.0996H146.05V42.1496H147.1V41.0996Z" fill="#00C96E" />
    <path d="M148.15 41.0996H147.1V42.1496H148.15V41.0996Z" fill="#00C96E" />
    <path d="M149.2 41.0996H148.15V42.1496H149.2V41.0996Z" fill="#00C96E" />
    <path d="M150.25 41.0996H149.2V42.1496H150.25V41.0996Z" fill="#00C96E" />
    <path d="M151.3 41.0996H150.25V42.1496H151.3V41.0996Z" fill="#00C96E" />
    <path d="M152.35 41.0996H151.3V42.1496H152.35V41.0996Z" fill="#00C96E" />
    <path d="M153.4 41.0996H152.35V42.1496H153.4V41.0996Z" fill="#00C96E" />
    <path d="M154.45 41.0996H153.4V42.1496H154.45V41.0996Z" fill="#00C96E" />
    <path d="M155.5 41.0996H154.45V42.1496H155.5V41.0996Z" fill="#00C96E" />
    <path d="M156.55 41.0996H155.5V42.1496H156.55V41.0996Z" fill="#00A556" />
    <path d="M157.6 41.0996H156.55V42.1496H157.6V41.0996Z" fill="#00A556" />
    <path d="M158.65 41.0996H157.6V42.1496H158.65V41.0996Z" fill="#008E6C" />
    <path d="M159.7 41.0996H158.65V42.1496H159.7V41.0996Z" fill="#008E6C" />
    <path d="M118.75 42.1494H117.7V43.1994H118.75V42.1494Z" fill="#00A556" />
    <path d="M119.8 42.1494H118.75V43.1994H119.8V42.1494Z" fill="#008E6C" />
    <path d="M116.65 42.1494H115.6V43.1994H116.65V42.1494Z" fill="#03EAB3" />
    <path d="M117.7 42.1494H116.65V43.1994H117.7V42.1494Z" fill="#03EAB3" />
    <path d="M114.55 42.1494H113.5V43.1994H114.55V42.1494Z" fill="#008E6C" />
    <path d="M115.6 42.1494H114.55V43.1994H115.6V42.1494Z" fill="#008E6C" />
    <path d="M120.85 42.1494H119.8V43.1994H120.85V42.1494Z" fill="#008E6C" />
    <path d="M121.9 42.1494H120.85V43.1994H121.9V42.1494Z" fill="#008E6C" />
    <path d="M122.95 42.1494H121.9V43.1994H122.95V42.1494Z" fill="#008E6C" />
    <path d="M124 42.1494H122.95V43.1994H124V42.1494Z" fill="#00A556" />
    <path d="M125.05 42.1494H124V43.1994H125.05V42.1494Z" fill="#00A556" />
    <path d="M126.1 42.1494H125.05V43.1994H126.1V42.1494Z" fill="#00A556" />
    <path d="M127.15 42.1494H126.1V43.1994H127.15V42.1494Z" fill="#00A556" />
    <path d="M128.2 42.1494H127.15V43.1994H128.2V42.1494Z" fill="#008E6C" />
    <path d="M129.25 42.1494H128.2V43.1994H129.25V42.1494Z" fill="#00A556" />
    <path d="M130.3 42.1494H129.25V43.1994H130.3V42.1494Z" fill="#00A556" />
    <path d="M131.35 42.1494H130.3V43.1994H131.35V42.1494Z" fill="#00A556" />
    <path d="M132.4 42.1494H131.35V43.1994H132.4V42.1494Z" fill="#00A556" />
    <path d="M133.45 42.1494H132.4V43.1994H133.45V42.1494Z" fill="#00C96E" />
    <path d="M134.5 42.1494H133.45V43.1994H134.5V42.1494Z" fill="#00A556" />
    <path d="M135.55 42.1494H134.5V43.1994H135.55V42.1494Z" fill="#00A556" />
    <path d="M136.6 42.1494H135.55V43.1994H136.6V42.1494Z" fill="#00A556" />
    <path d="M137.65 42.1494H136.6V43.1994H137.65V42.1494Z" fill="#00A556" />
    <path d="M138.7 42.1494H137.65V43.1994H138.7V42.1494Z" fill="#00A556" />
    <path d="M139.75 42.1494H138.7V43.1994H139.75V42.1494Z" fill="#00C96E" />
    <path d="M140.8 42.1494H139.75V43.1994H140.8V42.1494Z" fill="#00A556" />
    <path d="M141.85 42.1494H140.8V43.1994H141.85V42.1494Z" fill="#008E6C" />
    <path d="M142.9 42.1494H141.85V43.1994H142.9V42.1494Z" fill="#008E6C" />
    <path d="M143.95 42.1494H142.9V43.1994H143.95V42.1494Z" fill="#00A556" />
    <path d="M145 42.1494H143.95V43.1994H145V42.1494Z" fill="#00A556" />
    <path d="M146.05 42.1494H145V43.1994H146.05V42.1494Z" fill="#00A556" />
    <path d="M147.1 42.1494H146.05V43.1994H147.1V42.1494Z" fill="#00A556" />
    <path d="M148.15 42.1494H147.1V43.1994H148.15V42.1494Z" fill="#00A556" />
    <path d="M149.2 42.1494H148.15V43.1994H149.2V42.1494Z" fill="#00C96E" />
    <path d="M150.25 42.1494H149.2V43.1994H150.25V42.1494Z" fill="#00C96E" />
    <path d="M151.3 42.1494H150.25V43.1994H151.3V42.1494Z" fill="#00C96E" />
    <path d="M152.35 42.1494H151.3V43.1994H152.35V42.1494Z" fill="#00C96E" />
    <path d="M153.4 42.1494H152.35V43.1994H153.4V42.1494Z" fill="#00C96E" />
    <path d="M154.45 42.1494H153.4V43.1994H154.45V42.1494Z" fill="#00C96E" />
    <path d="M155.5 42.1494H154.45V43.1994H155.5V42.1494Z" fill="#00A556" />
    <path d="M156.55 42.1494H155.5V43.1994H156.55V42.1494Z" fill="#00A556" />
    <path d="M157.6 42.1494H156.55V43.1994H157.6V42.1494Z" fill="#008E6C" />
    <path d="M158.65 42.1494H157.6V43.1994H158.65V42.1494Z" fill="#008E6C" />
    <path d="M118.75 43.2002H117.7V44.2502H118.75V43.2002Z" fill="#03EAB3" />
    <path d="M119.8 43.2002H118.75V44.2502H119.8V43.2002Z" fill="#00A556" />
    <path d="M116.65 43.2002H115.6V44.2502H116.65V43.2002Z" fill="#00A556" />
    <path d="M117.7 43.2002H116.65V44.2502H117.7V43.2002Z" fill="#03EAB3" />
    <path d="M115.6 43.2002H114.55V44.2502H115.6V43.2002Z" fill="#008E6C" />
    <path d="M120.85 43.2002H119.8V44.2502H120.85V43.2002Z" fill="#00A556" />
    <path d="M121.9 43.2002H120.85V44.2502H121.9V43.2002Z" fill="#00A556" />
    <path d="M122.95 43.2002H121.9V44.2502H122.95V43.2002Z" fill="#00A556" />
    <path d="M124 43.2002H122.95V44.2502H124V43.2002Z" fill="#00A556" />
    <path d="M125.05 43.2002H124V44.2502H125.05V43.2002Z" fill="#008E6C" />
    <path d="M126.1 43.2002H125.05V44.2502H126.1V43.2002Z" fill="#008E6C" />
    <path d="M127.15 43.2002H126.1V44.2502H127.15V43.2002Z" fill="#00A556" />
    <path d="M128.2 43.2002H127.15V44.2502H128.2V43.2002Z" fill="#00A556" />
    <path d="M129.25 43.2002H128.2V44.2502H129.25V43.2002Z" fill="#008E6C" />
    <path d="M130.3 43.2002H129.25V44.2502H130.3V43.2002Z" fill="#008E6C" />
    <path d="M131.35 43.2002H130.3V44.2502H131.35V43.2002Z" fill="#008E6C" />
    <path d="M132.4 43.2002H131.35V44.2502H132.4V43.2002Z" fill="#00A556" />
    <path d="M133.45 43.2002H132.4V44.2502H133.45V43.2002Z" fill="#00A556" />
    <path d="M134.5 43.2002H133.45V44.2502H134.5V43.2002Z" fill="#00C96E" />
    <path d="M135.55 43.2002H134.5V44.2502H135.55V43.2002Z" fill="#00C96E" />
    <path d="M136.6 43.2002H135.55V44.2502H136.6V43.2002Z" fill="#00C96E" />
    <path d="M137.65 43.2002H136.6V44.2502H137.65V43.2002Z" fill="#00C96E" />
    <path d="M138.7 43.2002H137.65V44.2502H138.7V43.2002Z" fill="#00C96E" />
    <path d="M139.75 43.2002H138.7V44.2502H139.75V43.2002Z" fill="#00A556" />
    <path d="M140.8 43.2002H139.75V44.2502H140.8V43.2002Z" fill="#008E6C" />
    <path d="M141.85 43.2002H140.8V44.2502H141.85V43.2002Z" fill="#008E6C" />
    <path d="M142.9 43.2002H141.85V44.2502H142.9V43.2002Z" fill="#008E6C" />
    <path d="M143.95 43.2002H142.9V44.2502H143.95V43.2002Z" fill="#008E6C" />
    <path d="M145 43.2002H143.95V44.2502H145V43.2002Z" fill="#008E6C" />
    <path d="M146.05 43.2002H145V44.2502H146.05V43.2002Z" fill="#008E6C" />
    <path d="M147.1 43.2002H146.05V44.2502H147.1V43.2002Z" fill="#00A556" />
    <path d="M148.15 43.2002H147.1V44.2502H148.15V43.2002Z" fill="#00A556" />
    <path d="M149.2 43.2002H148.15V44.2502H149.2V43.2002Z" fill="#00A556" />
    <path d="M150.25 43.2002H149.2V44.2502H150.25V43.2002Z" fill="#00A556" />
    <path d="M151.3 43.2002H150.25V44.2502H151.3V43.2002Z" fill="#00A556" />
    <path d="M152.35 43.2002H151.3V44.2502H152.35V43.2002Z" fill="#00A556" />
    <path d="M153.4 43.2002H152.35V44.2502H153.4V43.2002Z" fill="#008E6C" />
    <path d="M154.45 43.2002H153.4V44.2502H154.45V43.2002Z" fill="#008E6C" />
    <path d="M155.5 43.2002H154.45V44.2502H155.5V43.2002Z" fill="#008E6C" />
    <path d="M156.55 43.2002H155.5V44.2502H156.55V43.2002Z" fill="#008E6C" />
    <path d="M157.6 43.2002H156.55V44.2502H157.6V43.2002Z" fill="#008E6C" />
    <path d="M158.65 43.2002H157.6V44.2502H158.65V43.2002Z" fill="#008E6C" />
    <path d="M162.85 43.2002H161.8V44.2502H162.85V43.2002Z" fill="#008E6C" />
    <path d="M162.85 46.3496H161.8V47.3996H162.85V46.3496Z" fill="#008E6C" />
    <path d="M118.75 44.25H117.7V45.3H118.75V44.25Z" fill="#00A556" />
    <path d="M119.8 44.25H118.75V45.3H119.8V44.25Z" fill="#00A556" />
    <path d="M116.65 44.25H115.6V45.3H116.65V44.25Z" fill="#008E6C" />
    <path d="M117.7 44.25H116.65V45.3H117.7V44.25Z" fill="#008E6C" />
    <path d="M115.6 44.25H114.55V45.3H115.6V44.25Z" fill="#008E6C" />
    <path d="M120.85 44.25H119.8V45.3H120.85V44.25Z" fill="#008E6C" />
    <path d="M121.9 44.25H120.85V45.3H121.9V44.25Z" fill="#008E6C" />
    <path d="M122.95 44.25H121.9V45.3H122.95V44.25Z" fill="#008E6C" />
    <path d="M124 44.25H122.95V45.3H124V44.25Z" fill="#008E6C" />
    <path d="M125.05 44.25H124V45.3H125.05V44.25Z" fill="#008E6C" />
    <path d="M126.1 44.25H125.05V45.3H126.1V44.25Z" fill="#00A556" />
    <path d="M127.15 44.25H126.1V45.3H127.15V44.25Z" fill="#00A556" />
    <path d="M128.2 44.25H127.15V45.3H128.2V44.25Z" fill="#008E6C" />
    <path d="M129.25 44.25H128.2V45.3H129.25V44.25Z" fill="#008E6C" />
    <path d="M130.3 44.25H129.25V45.3H130.3V44.25Z" fill="#008E6C" />
    <path d="M131.35 44.25H130.3V45.3H131.35V44.25Z" fill="#008E6C" />
    <path d="M132.4 44.25H131.35V45.3H132.4V44.25Z" fill="#008E6C" />
    <path d="M133.45 44.25H132.4V45.3H133.45V44.25Z" fill="#008E6C" />
    <path d="M134.5 44.25H133.45V45.3H134.5V44.25Z" fill="#008E6C" />
    <path d="M135.55 44.25H134.5V45.3H135.55V44.25Z" fill="#008E6C" />
    <path d="M136.6 44.25H135.55V45.3H136.6V44.25Z" fill="#008E6C" />
    <path d="M137.65 44.25H136.6V45.3H137.65V44.25Z" fill="#00A556" />
    <path d="M138.7 44.25H137.65V45.3H138.7V44.25Z" fill="#00A556" />
    <path d="M139.75 44.25H138.7V45.3H139.75V44.25Z" fill="#00A556" />
    <path d="M140.8 44.25H139.75V45.3H140.8V44.25Z" fill="#00A556" />
    <path d="M141.85 44.25H140.8V45.3H141.85V44.25Z" fill="#008E6C" />
    <path d="M142.9 44.25H141.85V45.3H142.9V44.25Z" fill="#008E6C" />
    <path d="M145 44.25H143.95V45.3H145V44.25Z" fill="#008E6C" />
    <path d="M146.05 44.25H145V45.3H146.05V44.25Z" fill="#008E6C" />
    <path d="M147.1 44.25H146.05V45.3H147.1V44.25Z" fill="#008E6C" />
    <path d="M148.15 44.25H147.1V45.3H148.15V44.25Z" fill="#00A556" />
    <path d="M149.2 44.25H148.15V45.3H149.2V44.25Z" fill="#00A556" />
    <path d="M150.25 44.25H149.2V45.3H150.25V44.25Z" fill="#00A556" />
    <path d="M151.3 44.25H150.25V45.3H151.3V44.25Z" fill="#008E6C" />
    <path d="M152.35 44.25H151.3V45.3H152.35V44.25Z" fill="#008E6C" />
    <path d="M153.4 44.25H152.35V45.3H153.4V44.25Z" fill="#008E6C" />
    <path d="M154.45 44.25H153.4V45.3H154.45V44.25Z" fill="#008E6C" />
    <path d="M155.5 44.25H154.45V45.3H155.5V44.25Z" fill="#008E6C" />
    <path d="M156.55 44.25H155.5V45.3H156.55V44.25Z" fill="#008E6C" />
    <path d="M157.6 44.25H156.55V45.3H157.6V44.25Z" fill="#008E6C" />
    <path d="M161.8 44.25H160.75V45.3H161.8V44.25Z" fill="#00C96E" />
    <path d="M162.85 44.25H161.8V45.3H162.85V44.25Z" fill="#00C96E" />
    <path d="M163.9 44.25H162.85V45.3H163.9V44.25Z" fill="#008E6C" />
    <path d="M118.75 45.2998H117.7V46.3498H118.75V45.2998Z" fill="#008E6C" />
    <path d="M119.8 45.2998H118.75V46.3498H119.8V45.2998Z" fill="#008E6C" />
    <path d="M117.7 45.2998H116.65V46.3498H117.7V45.2998Z" fill="#008E6C" />
    <path d="M120.85 45.2998H119.8V46.3498H120.85V45.2998Z" fill="#008E6C" />
    <path d="M121.9 45.2998H120.85V46.3498H121.9V45.2998Z" fill="#008E6C" />
    <path d="M122.95 45.2998H121.9V46.3498H122.95V45.2998Z" fill="#008E6C" />
    <path d="M124 45.2998H122.95V46.3498H124V45.2998Z" fill="#008E6C" />
    <path d="M125.05 45.2998H124V46.3498H125.05V45.2998Z" fill="#008E6C" />
    <path d="M126.1 45.2998H125.05V46.3498H126.1V45.2998Z" fill="#008E6C" />
    <path d="M127.15 45.2998H126.1V46.3498H127.15V45.2998Z" fill="#008E6C" />
    <path d="M130.3 45.2998H129.25V46.3498H130.3V45.2998Z" fill="#00A556" />
    <path d="M131.35 45.2998H130.3V46.3498H131.35V45.2998Z" fill="#00A556" />
    <path d="M132.4 45.2998H131.35V46.3498H132.4V45.2998Z" fill="#00A556" />
    <path d="M133.45 45.2998H132.4V46.3498H133.45V45.2998Z" fill="#00A556" />
    <path d="M134.5 45.2998H133.45V46.3498H134.5V45.2998Z" fill="#008E6C" />
    <path d="M135.55 45.2998H134.5V46.3498H135.55V45.2998Z" fill="#008E6C" />
    <path d="M136.6 45.2998H135.55V46.3498H136.6V45.2998Z" fill="#008E6C" />
    <path d="M137.65 45.2998H136.6V46.3498H137.65V45.2998Z" fill="#008E6C" />
    <path d="M138.7 45.2998H137.65V46.3498H138.7V45.2998Z" fill="#00A556" />
    <path d="M139.75 45.2998H138.7V46.3498H139.75V45.2998Z" fill="#03EAB3" />
    <path d="M140.8 45.2998H139.75V46.3498H140.8V45.2998Z" fill="#00C96E" />
    <path d="M141.85 45.2998H140.8V46.3498H141.85V45.2998Z" fill="#00A556" />
    <path d="M142.9 45.2998H141.85V46.3498H142.9V45.2998Z" fill="#00A556" />
    <path d="M147.1 45.2998H146.05V46.3498H147.1V45.2998Z" fill="#008E6C" />
    <path d="M148.15 45.2998H147.1V46.3498H148.15V45.2998Z" fill="#008E6C" />
    <path d="M149.2 45.2998H148.15V46.3498H149.2V45.2998Z" fill="#008E6C" />
    <path d="M150.25 45.2998H149.2V46.3498H150.25V45.2998Z" fill="#008E6C" />
    <path d="M151.3 45.2998H150.25V46.3498H151.3V45.2998Z" fill="#008E6C" />
    <path d="M152.35 45.2998H151.3V46.3498H152.35V45.2998Z" fill="#008E6C" />
    <path d="M153.4 45.2998H152.35V46.3498H153.4V45.2998Z" fill="#008E6C" />
    <path d="M154.45 45.2998H153.4V46.3498H154.45V45.2998Z" fill="#008E6C" />
    <path d="M155.5 45.2998H154.45V46.3498H155.5V45.2998Z" fill="#008E6C" />
    <path d="M160.75 45.2998H159.7V46.3498H160.75V45.2998Z" fill="#008E6C" />
    <path d="M161.8 45.2998H160.75V46.3498H161.8V45.2998Z" fill="#00A556" />
    <path d="M162.85 45.2998H161.8V46.3498H162.85V45.2998Z" fill="#00A556" />
    <path d="M163.9 45.2998H162.85V46.3498H163.9V45.2998Z" fill="#008E6C" />
    <path d="M124 46.3496H122.95V47.3996H124V46.3496Z" fill="#008E6C" />
    <path d="M125.05 46.3496H124V47.3996H125.05V46.3496Z" fill="#008E6C" />
    <path d="M130.3 46.3496H129.25V47.3996H130.3V46.3496Z" fill="#008E6C" />
    <path d="M131.35 46.3496H130.3V47.3996H131.35V46.3496Z" fill="#008E6C" />
    <path d="M132.4 46.3496H131.35V47.3996H132.4V46.3496Z" fill="#00A556" />
    <path d="M133.45 46.3496H132.4V47.3996H133.45V46.3496Z" fill="#00A556" />
    <path d="M134.5 46.3496H133.45V47.3996H134.5V46.3496Z" fill="#00A556" />
    <path d="M135.55 46.3496H134.5V47.3996H135.55V46.3496Z" fill="#00A556" />
    <path d="M136.6 46.3496H135.55V47.3996H136.6V46.3496Z" fill="#00C96E" />
    <path d="M137.65 46.3496H136.6V47.3996H137.65V46.3496Z" fill="#00C96E" />
    <path d="M138.7 46.3496H137.65V47.3996H138.7V46.3496Z" fill="#03EAB3" />
    <path d="M139.75 46.3496H138.7V47.3996H139.75V46.3496Z" fill="#00C96E" />
    <path d="M140.8 46.3496H139.75V47.3996H140.8V46.3496Z" fill="#00C96E" />
    <path d="M141.85 46.3496H140.8V47.3996H141.85V46.3496Z" fill="#008E6C" />
    <path d="M142.9 46.3496H141.85V47.3996H142.9V46.3496Z" fill="#008E6C" />
    <path d="M131.35 47.3994H130.3V48.4494H131.35V47.3994Z" fill="#008E6C" />
    <path d="M132.4 47.3994H131.35V48.4494H132.4V47.3994Z" fill="#00A556" />
    <path d="M133.45 47.3994H132.4V48.4494H133.45V47.3994Z" fill="#00A556" />
    <path d="M134.5 47.3994H133.45V48.4494H134.5V47.3994Z" fill="#008E6C" />
    <path d="M135.55 47.3994H134.5V48.4494H135.55V47.3994Z" fill="#008E6C" />
    <path d="M136.6 47.3994H135.55V48.4494H136.6V47.3994Z" fill="#00A556" />
    <path d="M137.65 47.3994H136.6V48.4494H137.65V47.3994Z" fill="#00C96E" />
    <path d="M138.7 47.3994H137.65V48.4494H138.7V47.3994Z" fill="#03EAB3" />
    <path d="M139.75 47.3994H138.7V48.4494H139.75V47.3994Z" fill="#008E6C" />
    <path d="M140.8 47.3994H139.75V48.4494H140.8V47.3994Z" fill="#008E6C" />
    <path d="M141.85 47.3994H140.8V48.4494H141.85V47.3994Z" fill="#008E6C" />
    <path d="M132.4 48.4502H131.35V49.5002H132.4V48.4502Z" fill="#008E6C" />
    <path d="M133.45 48.4502H132.4V49.5002H133.45V48.4502Z" fill="#00A556" />
    <path d="M134.5 48.4502H133.45V49.5002H134.5V48.4502Z" fill="#008E6C" />
    <path d="M135.55 48.4502H134.5V49.5002H135.55V48.4502Z" fill="#008E6C" />
    <path d="M136.6 48.4502H135.55V49.5002H136.6V48.4502Z" fill="#008E6C" />
    <path d="M137.65 48.4502H136.6V49.5002H137.65V48.4502Z" fill="#00A556" />
    <path d="M138.7 48.4502H137.65V49.5002H138.7V48.4502Z" fill="#008E6C" />
    <path d="M139.75 48.4502H138.7V49.5002H139.75V48.4502Z" fill="#008E6C" />
    <path d="M132.4 49.5H131.35V50.55H132.4V49.5Z" fill="#008E6C" />
    <path d="M133.45 49.5H132.4V50.55H133.45V49.5Z" fill="#00A556" />
    <path d="M134.5 49.5H133.45V50.55H134.5V49.5Z" fill="#00A556" />
    <path d="M135.55 49.5H134.5V50.55H135.55V49.5Z" fill="#03EAB3" />
    <path d="M136.6 49.5H135.55V50.55H136.6V49.5Z" fill="#008E6C" />
    <path d="M137.65 49.5H136.6V50.55H137.65V49.5Z" fill="#00A556" />
    <path d="M138.7 49.5H137.65V50.55H138.7V49.5Z" fill="#03EAB3" />
    <path d="M139.75 49.5H138.7V50.55H139.75V49.5Z" fill="#008E6C" />
    <path d="M132.4 50.5498H131.35V51.5998H132.4V50.5498Z" fill="#008E6C" />
    <path d="M133.45 50.5498H132.4V51.5998H133.45V50.5498Z" fill="#008E6C" />
    <path d="M134.5 50.5498H133.45V51.5998H134.5V50.5498Z" fill="#03EAB3" />
    <path d="M135.55 50.5498H134.5V51.5998H135.55V50.5498Z" fill="#03EAB3" />
    <path d="M136.6 50.5498H135.55V51.5998H136.6V50.5498Z" fill="#008E6C" />
    <path d="M137.65 50.5498H136.6V51.5998H137.65V50.5498Z" fill="#00A556" />
    <path d="M138.7 50.5498H137.65V51.5998H138.7V50.5498Z" fill="#008E6C" />
    <path d="M139.75 50.5498H138.7V51.5998H139.75V50.5498Z" fill="#008E6C" />
    <path d="M131.35 51.5996H130.3V52.6496H131.35V51.5996Z" fill="#008E6C" />
    <path d="M133.45 51.5996H132.4V52.6496H133.45V51.5996Z" fill="#008E6C" />
    <path d="M134.5 51.5996H133.45V52.6496H134.5V51.5996Z" fill="#03EAB3" />
    <path d="M135.55 51.5996H134.5V52.6496H135.55V51.5996Z" fill="#008E6C" />
    <path d="M136.6 51.5996H135.55V52.6496H136.6V51.5996Z" fill="#008E6C" />
    <path d="M137.65 51.5996H136.6V52.6496H137.65V51.5996Z" fill="#00C96E" />
    <path d="M138.7 51.5996H137.65V52.6496H138.7V51.5996Z" fill="#008E6C" />
    <path d="M139.75 51.5996H138.7V52.6496H139.75V51.5996Z" fill="#008E6C" />
    <path d="M132.4 52.6494H131.35V53.6994H132.4V52.6494Z" fill="#008E6C" />
    <path d="M133.45 52.6494H132.4V53.6994H133.45V52.6494Z" fill="#008E6C" />
    <path d="M134.5 52.6494H133.45V53.6994H134.5V52.6494Z" fill="#03EAB3" />
    <path d="M135.55 52.6494H134.5V53.6994H135.55V52.6494Z" fill="#008E6C" />
    <path d="M136.6 52.6494H135.55V53.6994H136.6V52.6494Z" fill="#008E6C" />
    <path d="M137.65 52.6494H136.6V53.6994H137.65V52.6494Z" fill="#00C96E" />
    <path d="M138.7 52.6494H137.65V53.6994H138.7V52.6494Z" fill="#00A556" />
    <path d="M132.4 53.7002H131.35V54.7502H132.4V53.7002Z" fill="#008E6C" />
    <path d="M133.45 53.7002H132.4V54.7502H133.45V53.7002Z" fill="#008E6C" />
    <path d="M134.5 53.7002H133.45V54.7502H134.5V53.7002Z" fill="#03EAB3" />
    <path d="M135.55 53.7002H134.5V54.7502H135.55V53.7002Z" fill="#008E6C" />
    <path d="M136.6 53.7002H135.55V54.7502H136.6V53.7002Z" fill="#008E6C" />
    <path d="M137.65 53.7002H136.6V54.7502H137.65V53.7002Z" fill="#00A556" />
    <path d="M138.7 53.7002H137.65V54.7502H138.7V53.7002Z" fill="#008E6C" />
    <path d="M132.4 54.75H131.35V55.8H132.4V54.75Z" fill="#008E6C" />
    <path d="M133.45 54.75H132.4V55.8H133.45V54.75Z" fill="#008E6C" />
    <path d="M134.5 54.75H133.45V55.8H134.5V54.75Z" fill="#03EAB3" />
    <path d="M135.55 54.75H134.5V55.8H135.55V54.75Z" fill="#00A556" />
    <path d="M136.6 54.75H135.55V55.8H136.6V54.75Z" fill="#00C96E" />
    <path d="M137.65 54.75H136.6V55.8H137.65V54.75Z" fill="#00A556" />
    <path d="M138.7 54.75H137.65V55.8H138.7V54.75Z" fill="#008E6C" />
    <path d="M125.05 55.7998H124V56.8498H125.05V55.7998Z" fill="#008E6C" />
    <path d="M130.3 55.7998H129.25V56.8498H130.3V55.7998Z" fill="#008E6C" />
    <path d="M132.4 55.7998H131.35V56.8498H132.4V55.7998Z" fill="#008E6C" />
    <path d="M133.45 55.7998H132.4V56.8498H133.45V55.7998Z" fill="#03EAB3" />
    <path d="M134.5 55.7998H133.45V56.8498H134.5V55.7998Z" fill="#00C96E" />
    <path d="M135.55 55.7998H134.5V56.8498H135.55V55.7998Z" fill="#00A556" />
    <path d="M136.6 55.7998H135.55V56.8498H136.6V55.7998Z" fill="#008E6C" />
    <path d="M137.65 55.7998H136.6V56.8498H137.65V55.7998Z" fill="#00A556" />
    <path d="M138.7 55.7998H137.65V56.8498H138.7V55.7998Z" fill="#008E6C" />
    <path d="M129.25 56.8496H128.2V57.8996H129.25V56.8496Z" fill="#008E6C" />
    <path d="M130.3 56.8496H129.25V57.8996H130.3V56.8496Z" fill="#008E6C" />
    <path d="M132.4 56.8496H131.35V57.8996H132.4V56.8496Z" fill="#008E6C" />
    <path d="M133.45 56.8496H132.4V57.8996H133.45V56.8496Z" fill="#008E6C" />
    <path d="M134.5 56.8496H133.45V57.8996H134.5V56.8496Z" fill="#00C96E" />
    <path d="M135.55 56.8496H134.5V57.8996H135.55V56.8496Z" fill="#03EAB3" />
    <path d="M136.6 56.8496H135.55V57.8996H136.6V56.8496Z" fill="#008E6C" />
    <path d="M137.65 56.8496H136.6V57.8996H137.65V56.8496Z" fill="#00A556" />
    <path d="M138.7 56.8496H137.65V57.8996H138.7V56.8496Z" fill="#008E6C" />
    <path d="M142.9 56.8496H141.85V57.8996H142.9V56.8496Z" fill="#008E6C" />
    <path d="M146.05 56.8496H145V57.8996H146.05V56.8496Z" fill="#008E6C" />
    <path d="M147.1 56.8496H146.05V57.8996H147.1V56.8496Z" fill="#008E6C" />
    <path
      d="M154.647 55.7998H153.597V56.8498H154.647V55.7998Z"
      fill="#008E6C"
    />
    <path d="M155.696 54.75H154.646V55.8H155.696V54.75Z" fill="#008E6C" />
    <path d="M129.25 57.8994H128.2V58.9494H129.25V57.8994Z" fill="#008E6C" />
    <path d="M130.3 57.8994H129.25V58.9494H130.3V57.8994Z" fill="#00A556" />
    <path d="M132.4 57.8994H131.35V58.9494H132.4V57.8994Z" fill="#008E6C" />
    <path d="M133.45 57.8994H132.4V58.9494H133.45V57.8994Z" fill="#008E6C" />
    <path d="M134.5 57.8994H133.45V58.9494H134.5V57.8994Z" fill="#00C96E" />
    <path d="M135.55 57.8994H134.5V58.9494H135.55V57.8994Z" fill="#00A556" />
    <path d="M136.6 57.8994H135.55V58.9494H136.6V57.8994Z" fill="#03EAB3" />
    <path d="M137.65 57.8994H136.6V58.9494H137.65V57.8994Z" fill="#00A556" />
    <path d="M138.7 57.8994H137.65V58.9494H138.7V57.8994Z" fill="#008E6C" />
    <path d="M143.95 57.8994H142.9V58.9494H143.95V57.8994Z" fill="#008E6C" />
    <path d="M119.8 58.9502H118.75V60.0002H119.8V58.9502Z" fill="#008E6C" />
    <path d="M127.15 58.9502H126.1V60.0002H127.15V58.9502Z" fill="#008E6C" />
    <path d="M129.25 58.9502H128.2V60.0002H129.25V58.9502Z" fill="#008E6C" />
    <path d="M130.3 58.9502H129.25V60.0002H130.3V58.9502Z" fill="#00A556" />
    <path d="M132.4 58.9502H131.35V60.0002H132.4V58.9502Z" fill="#00A556" />
    <path d="M133.45 58.9502H132.4V60.0002H133.45V58.9502Z" fill="#00A556" />
    <path d="M134.5 58.9502H133.45V60.0002H134.5V58.9502Z" fill="#00C96E" />
    <path d="M135.55 58.9502H134.5V60.0002H135.55V58.9502Z" fill="#00A556" />
    <path d="M136.6 58.9502H135.55V60.0002H136.6V58.9502Z" fill="#03EAB3" />
    <path d="M137.65 58.9502H136.6V60.0002H137.65V58.9502Z" fill="#00A556" />
    <path d="M138.7 58.9502H137.65V60.0002H138.7V58.9502Z" fill="#00A556" />
    <path d="M141.85 58.9502H140.8V60.0002H141.85V58.9502Z" fill="#008E6C" />
    <path d="M126.1 60H125.05V61.05H126.1V60Z" fill="#008E6C" />
    <path d="M130.3 60H129.25V61.05H130.3V60Z" fill="#00A556" />
    <path d="M131.35 60H130.3V61.05H131.35V60Z" fill="#00C96E" />
    <path d="M132.4 60H131.35V61.05H132.4V60Z" fill="#00C96E" />
    <path d="M133.45 60H132.4V61.05H133.45V60Z" fill="#03EAB3" />
    <path d="M134.5 60H133.45V61.05H134.5V60Z" fill="#008E6C" />
    <path d="M135.55 60H134.5V61.05H135.55V60Z" fill="#008E6C" />
    <path d="M137.65 60H136.6V61.05H137.65V60Z" fill="#03EAB3" />
    <path d="M138.7 60H137.65V61.05H138.7V60Z" fill="#00A556" />
    <path d="M140.8 60H139.75V61.05H140.8V60Z" fill="#008E6C" />
    <path d="M141.85 60H140.8V61.05H141.85V60Z" fill="#008E6C" />
    <path d="M142.9 60H141.85V61.05H142.9V60Z" fill="#008E6C" />
    <path d="M145 60H143.95V61.05H145V60Z" fill="#008E6C" />
    <path d="M146.05 60H145V61.05H146.05V60Z" fill="#008E6C" />
    <path d="M147.1 60H146.05V61.05H147.1V60Z" fill="#008E6C" />
    <path d="M148.15 60H147.1V61.05H148.15V60Z" fill="#008E6C" />
    <path d="M120.85 61.0498H119.8V62.0998H120.85V61.0498Z" fill="#008E6C" />
    <path d="M122.95 61.0498H121.9V62.0998H122.95V61.0498Z" fill="#008E6C" />
    <path d="M125.05 61.0498H124V62.0998H125.05V61.0498Z" fill="#008E6C" />
    <path d="M127.15 61.0498H126.1V62.0998H127.15V61.0498Z" fill="#00C96E" />
    <path d="M128.2 61.0498H127.15V62.0998H128.2V61.0498Z" fill="#008E6C" />
    <path d="M129.25 61.0498H128.2V62.0998H129.25V61.0498Z" fill="#008E6C" />
    <path d="M130.3 61.0498H129.25V62.0998H130.3V61.0498Z" fill="#008E6C" />
    <path d="M131.35 61.0498H130.3V62.0998H131.35V61.0498Z" fill="#008E6C" />
    <path d="M132.4 61.0498H131.35V62.0998H132.4V61.0498Z" fill="#00C96E" />
    <path d="M133.45 61.0498H132.4V62.0998H133.45V61.0498Z" fill="#00C96E" />
    <path d="M134.5 61.0498H133.45V62.0998H134.5V61.0498Z" fill="#00C96E" />
    <path d="M135.55 61.0498H134.5V62.0998H135.55V61.0498Z" fill="#00A556" />
    <path d="M136.6 61.0498H135.55V62.0998H136.6V61.0498Z" fill="#00C96E" />
    <path d="M137.65 61.0498H136.6V62.0998H137.65V61.0498Z" fill="#00C96E" />
    <path d="M138.7 61.0498H137.65V62.0998H138.7V61.0498Z" fill="#008E6C" />
    <path d="M140.8 61.0498H139.75V62.0998H140.8V61.0498Z" fill="#008E6C" />
    <path d="M139.75 61.0498H138.7V62.0998H139.75V61.0498Z" fill="#00C96E" />
    <path d="M141.85 61.0498H140.8V62.0998H141.85V61.0498Z" fill="#008E6C" />
    <path d="M142.9 61.0498H141.85V62.0998H142.9V61.0498Z" fill="#008E6C" />
    <path d="M145 61.0498H143.95V62.0998H145V61.0498Z" fill="#008E6C" />
    <path d="M146.05 61.0498H145V62.0998H146.05V61.0498Z" fill="#008E6C" />
    <path d="M127.15 62.0996H126.1V63.1496H127.15V62.0996Z" fill="#00C96E" />
    <path d="M128.2 62.0996H127.15V63.1496H128.2V62.0996Z" fill="#00C96E" />
    <path d="M129.25 62.0996H128.2V63.1496H129.25V62.0996Z" fill="#00A556" />
    <path d="M132.4 62.0996H131.35V63.1496H132.4V62.0996Z" fill="#00A556" />
    <path d="M133.45 62.0996H132.4V63.1496H133.45V62.0996Z" fill="#00C96E" />
    <path d="M134.5 62.0996H133.45V63.1496H134.5V62.0996Z" fill="#00C96E" />
    <path d="M136.6 62.0996H135.55V63.1496H136.6V62.0996Z" fill="#00C96E" />
    <path d="M137.65 62.0996H136.6V63.1496H137.65V62.0996Z" fill="#00C96E" />
    <path d="M138.7 62.0996H137.65V63.1496H138.7V62.0996Z" fill="#008E6C" />
    <path d="M140.8 62.0996H139.75V63.1496H140.8V62.0996Z" fill="#00A556" />
    <path d="M141.85 62.0996H140.8V63.1496H141.85V62.0996Z" fill="#00C96E" />
    <path d="M142.9 62.0996H141.85V63.1496H142.9V62.0996Z" fill="#00C96E" />
    <path d="M125.05 63.1494H124V64.1994H125.05V63.1494Z" fill="#008E6C" />
    <path d="M126.1 63.1494H125.05V64.1994H126.1V63.1494Z" fill="#008E6C" />
    <path d="M127.15 63.1494H126.1V64.1994H127.15V63.1494Z" fill="#008E6C" />
    <path d="M128.2 63.1494H127.15V64.1994H128.2V63.1494Z" fill="#008E6C" />
    <path d="M129.25 63.1494H128.2V64.1994H129.25V63.1494Z" fill="#008E6C" />
    <path d="M132.4 63.1494H131.35V64.1994H132.4V63.1494Z" fill="#008E6C" />
    <path d="M133.45 63.1494H132.4V64.1994H133.45V63.1494Z" fill="#008E6C" />
    <path d="M135.55 63.1494H134.5V64.1994H135.55V63.1494Z" fill="#008E6C" />
    <path d="M136.6 63.1494H135.55V64.1994H136.6V63.1494Z" fill="#008E6C" />
    <path d="M137.65 63.1494H136.6V64.1994H137.65V63.1494Z" fill="#008E6C" />
    <path d="M138.7 63.1494H137.65V64.1994H138.7V63.1494Z" fill="#008E6C" />
    <path d="M139.75 63.1494H138.7V64.1994H139.75V63.1494Z" fill="#008E6C" />
    <path d="M140.8 63.1494H139.75V64.1994H140.8V63.1494Z" fill="#00A556" />
    <path d="M141.85 63.1494H140.8V64.1994H141.85V63.1494Z" fill="#008E6C" />
    <path d="M142.9 63.1494H141.85V64.1994H142.9V63.1494Z" fill="#008E6C" />
    <path d="M143.95 63.1494H142.9V64.1994H143.95V63.1494Z" fill="#008E6C" />
    <path d="M146.05 63.1494H145V64.1994H146.05V63.1494Z" fill="#008E6C" />
    <path d="M147.1 63.1494H146.05V64.1994H147.1V63.1494Z" fill="#008E6C" />
    <path d="M149.2 63.1494H148.15V64.1994H149.2V63.1494Z" fill="#008E6C" />
    <path d="M150.25 63.1494H149.2V64.1994H150.25V63.1494Z" fill="#008E6C" />
    <path d="M127.15 64.2002H126.1V65.2502H127.15V64.2002Z" fill="#008E6C" />
    <path d="M128.2 64.2002H127.15V65.2502H128.2V64.2002Z" fill="#008E6C" />
    <path d="M129.25 64.2002H128.2V65.2502H129.25V64.2002Z" fill="#008E6C" />
    <path d="M132.4 64.2002H131.35V65.2502H132.4V64.2002Z" fill="#008E6C" />
    <path d="M133.45 64.2002H132.4V65.2502H133.45V64.2002Z" fill="#008E6C" />
    <path d="M139.75 64.2002H138.7V65.2502H139.75V64.2002Z" fill="#008E6C" />
    <path d="M140.8 64.2002H139.75V65.2502H140.8V64.2002Z" fill="#008E6C" />
    <path d="M141.85 64.2002H140.8V65.2502H141.85V64.2002Z" fill="#008E6C" />
    <path d="M142.9 64.2002H141.85V65.2502H142.9V64.2002Z" fill="#008E6C" />
    <path d="M143.95 64.2002H142.9V65.2502H143.95V64.2002Z" fill="#008E6C" />
    <path d="M146.05 64.2002H145V65.2502H146.05V64.2002Z" fill="#008E6C" />
    <path d="M134.5 65.25H133.45V66.3H134.5V65.25Z" fill="#008E6C" />
    <path d="M135.55 65.25H134.5V66.3H135.55V65.25Z" fill="#008E6C" />
    <path d="M137.65 65.25H136.6V66.3H137.65V65.25Z" fill="#008E6C" />
    <path d="M139.75 65.25H138.7V66.3H139.75V65.25Z" fill="#008E6C" />
    <path d="M140.8 65.25H139.75V66.3H140.8V65.25Z" fill="#008E6C" />
  </svg>
);
