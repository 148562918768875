import { Flex, Box, Center } from '@chakra-ui/react';
import { Text } from '@/components/ui/atoms/Text';
import { Button } from '@/components/ui/molecules/Button';
import PrivyWalletProvider, {
  privyWalletList,
} from '@/components/auth/PrivyWalletProvider';
import { usePrivy, useWallets } from '@privy-io/react-auth';
import { useUserData } from '@/context/FirestoreContext';
import { useState, useEffect } from 'react';
import { USER_ADD_EVM_WALLET_ENDPOINT } from '@/config/endpoints';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import { Colors } from '@/components/ui/colors';
import { Ticket } from '@/components/ui/icons/Ticket';
import { Modal } from '../ui/molecules/Modal';
import WhittakrImg from '@/images/whittakr-no-margin.png';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { getRandomGif } from '@/images/spinner-gifs/getRandomGif';
import { Okx } from '@/components/ui/icons/Okx';

const MantleCheckInDesktopContainer = () => {
  return (
    <PrivyWalletProvider>
      <MantleCheckInDesktop />
    </PrivyWalletProvider>
  );
};

const MantleCheckInDesktop = () => {
  const { apiPut } = useAPI();
  const alert = useAlert();
  const { userData } = useUserData();

  const { wallets } = useWallets();
  const { ready, authenticated, connectWallet } = usePrivy();
  const isPrivyInitializing = !ready || !authenticated;

  const [isEvmWalletConnected, setIsEvmWalletConnected] = useState(false);
  const hasConnectedWallet = userData?.evmWallet || isEvmWalletConnected;
  const [
    connectWalletRewardModalTicketCount,
    setConnectWalletRewardModalTicketCount,
  ] = useState(0);

  const evmWallet = wallets.find(
    (wallet) =>
      (wallet as any).type === 'ethereum' &&
      wallet.chainId.startsWith(`eip155`) &&
      wallet.connectorType !== `embedded` &&
      wallet.walletClientType === `okx_wallet`,
  );

  // once the user has connected their wallet, save it to their game account
  useEffect(() => {
    // exit if no wallet is connected
    if (!isEvmWalletConnected || !evmWallet?.address) return;

    // if new wallet has been connected, save it to their game account
    if (userData?.evmWallet !== evmWallet?.address) {
      apiPut(USER_ADD_EVM_WALLET_ENDPOINT, {
        walletAddress: evmWallet?.address,
      })
        .then((data) => {
          if (data?.ticketAmount > 0) {
            setConnectWalletRewardModalTicketCount(data?.ticketAmount);
          }
        })
        .catch((error) => {
          alert({ title: `Err: ${error?.message}` });
        });
    }
  }, [isEvmWalletConnected, evmWallet?.address, userData?.evmWallet]);

  useEffect(() => {
    if (evmWallet && !isEvmWalletConnected) {
      evmWallet?.isConnected().then((result) => {
        if (result) {
          setIsEvmWalletConnected(true);
        } else {
          setIsEvmWalletConnected(false);
        }
      });
    } else if (isEvmWalletConnected && !evmWallet) {
      setIsEvmWalletConnected(false);
    }
  }, [evmWallet, isEvmWalletConnected]);

  return (
    <>
      {connectWalletRewardModalTicketCount > 0 && (
        <OkxWalletConnectedRewardModal
          ticketCount={connectWalletRewardModalTicketCount}
          onClose={() => setConnectWalletRewardModalTicketCount(0)}
        />
      )}

      <Flex
        w={`full`}
        border={`solid 2px black`}
        background={hasConnectedWallet ? Colors.DarkGreen : `transparent`}
      >
        <Flex w={`full`} alignItems={`center`}>
          <Box pl={`6px`}>
            <Okx />
          </Box>
          <Flex
            flexDirection={`column`}
            gap={`4px`}
            w={`full`}
            borderRight={`1px solid black`}
            padding={`4px 0 4px 6px`}
            minHeight={`50px`}
            justifyContent={`center`}
          >
            <Text
              colorId={hasConnectedWallet ? `White` : 'Black'}
              textAlign={`left`}
              style={{ textWrap: `balance` }}
            >
              <TranslatedText
                translationKey={`connectOkxWallet`}
                defaultMessage={`Connect okx wallet`}
              />
            </Text>

            {!userData?.telegram?.hasConnectedEvmWallet && (
              <Flex w={`full`} alignItems={`center`}>
                <Ticket />
                <Text
                  colorId={hasConnectedWallet ? `White` : 'Black'}
                  w="full"
                  width={`auto`}
                >
                  5
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>

        <Button
          borderLeft={`1px solid black`}
          colorId={hasConnectedWallet ? `DarkGreen` : `Yellow`}
          padding={`8px`}
          maxWidth={hasConnectedWallet ? `80px` : `60px`}
          minWidth={`0px`}
          w={`full`}
          isDisabled={isPrivyInitializing}
          onClick={() => {
            if (hasConnectedWallet) return;
            connectWallet({
              walletList: privyWalletList,
            });
          }}
        >
          <Text colorId={hasConnectedWallet ? `White` : 'Black'}>
            {isPrivyInitializing ? `...` : hasConnectedWallet ? `Done` : `Go`}
          </Text>
        </Button>
      </Flex>
    </>
  );
};

export const OkxWalletConnectedRewardModal = ({
  onClose,
  ticketCount,
}: {
  ticketCount: number;
  onClose: () => void;
}) => {
  return (
    <Modal
      isOpen
      onClose={() => {
        onClose();
      }}
      showBackButton={false}
    >
      <Box
        background={`var(--Yellow)`}
        w="calc(100% + 32px)"
        marginTop={-6}
        marginLeft={-4}
        padding={4}
        position="relative"
      >
        <Box position="absolute" top="-75px" left="-15px">
          <img
            src={WhittakrImg}
            alt="Witakar"
            style={{ height: '120px', width: 'auto' }}
          />
        </Box>
        <Text colorId="Black" w="full" textAlign={'center'}>
          <TranslatedText
            translationKey={`youEarned`}
            defaultMessage={`you earned`}
          />
        </Text>
        <Center alignItems={'center'} w="full" fontSize={`24px`}>
          <Ticket />
          <Text colorId="Black" fontSize={'20px'}>
            {ticketCount}
          </Text>
        </Center>
      </Box>
      <Flex
        mt={1}
        flexDirection={`column`}
        justifyContent={`center`}
        textAlign={`center`}
      >
        <Text colorId={`Black`}>
          <TranslatedText
            translationKey={`okxTask`}
            defaultMessage={`okx Task`}
          />
          :
        </Text>

        <Text colorId={`Black`}>
          <TranslatedText
            translationKey={`connectOkxWallet`}
            defaultMessage={`Connect okx wallet`}
          />
        </Text>
      </Flex>
      <Center w="full" mt={2}>
        <img
          src={getRandomGif()}
          alt="wheel"
          style={{ width: 'auto', height: '240px' }}
        />
      </Center>

      <Button colorId="Black" mt={2} onClick={onClose} w="full">
        <TranslatedText translationKey={`ok`} defaultMessage={`ok`} />
      </Button>
    </Modal>
  );
};

export default MantleCheckInDesktopContainer;
