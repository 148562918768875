import { useEffect, useState, useCallback, useMemo } from 'react';
import { TelegramTask, EnergyPointsSummary } from 'genopets-utils';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import useLocalStorage from '@/hooks/useLocalStorage';
import {
  TELEGRAM_TASKS_ENDPOINT,
  USER_ENERGY_POINTS_SUMMARY,
} from '@/config/endpoints';
import { VITE_TASK_ENERGY_REWARD_ENABLED } from '@/config/constants';

const VIEWED_TASKS_KEY = 'pixelton-viewed-tasks';

interface UseTasksOptions {
  markAsViewed?: boolean;
}

export function useTasks({ markAsViewed = false }: UseTasksOptions = {}) {
  const { apiGet } = useAPI();
  const alert = useAlert();
  const [tasks, setTasks] = useState<TelegramTask[]>();
  const [energyPointsSummary, setEnergyPointsSummary] =
    useState<EnergyPointsSummary>();
  const [isLoading, setIsLoading] = useState(true);
  const [viewedTasks, setViewedTasks] = useLocalStorage<
    Record<string, boolean>
  >(VIEWED_TASKS_KEY, {});

  const userTelegramTaskData = energyPointsSummary?.telegramTask;

  const markTasksAsViewed = useCallback(
    (taskIds: string[]) => {
      const newViewedTasks = { ...viewedTasks };
      taskIds.forEach((id) => {
        newViewedTasks[id] = true;
      });
      setViewedTasks(newViewedTasks);
    },
    [viewedTasks, setViewedTasks],
  );

  // unviewedCount does not count energy tasks
  const unviewedCount = useMemo(() => {
    if (!tasks) return 0;

    return tasks.reduce((count, task) => {
      const isCompleted = userTelegramTaskData?.[task.id] ?? false;
      const isViewed = viewedTasks[task.id];
      return !isCompleted && !isViewed && task.prizeType !== 'energy'
        ? count + 1
        : count;
    }, 0);
  }, [tasks, energyPointsSummary, viewedTasks]);

  const fetchEnergyPointsSummary = useCallback(async () => {
    if (!apiGet) return;
    try {
      const data = await apiGet(
        USER_ENERGY_POINTS_SUMMARY + `?isTelegram=true`,
        true,
      );
      setEnergyPointsSummary(data);
    } catch (error: any) {
      console.error(error);
      alert({ title: error?.message ?? 'Error' });
    }
  }, [apiGet, alert]);

  useEffect(() => {
    if (tasks) return;

    Promise.all([
      apiGet(TELEGRAM_TASKS_ENDPOINT, true),
      fetchEnergyPointsSummary(),
    ])
      .then(([tasksData]) => {
        setTasks(tasksData);
        setIsLoading(false);

        // Mark tasks as viewed if option is enabled
        if (markAsViewed && tasksData) {
          const taskIds = tasksData.map((task: TelegramTask) => task.id);
          markTasksAsViewed(taskIds);
        }
      })
      .catch((error: any) => {
        console.error(error);
        alert({ title: error?.message ?? 'Error' });
        setIsLoading(false);
      });
  }, [
    apiGet,
    alert,
    fetchEnergyPointsSummary,
    markAsViewed,
    markTasksAsViewed,
  ]);

  const sortedTasks = tasks
    ?.filter((t) =>
      VITE_TASK_ENERGY_REWARD_ENABLED ? true : t.prizeType !== 'energy',
    )
    ?.sort((a, b) => a.position - b.position)
    ?.sort((a, b) => {
      const aCompleted = userTelegramTaskData?.[a.id] ?? false;
      const bCompleted = userTelegramTaskData?.[b.id] ?? false;
      if (aCompleted && !bCompleted) return 1;
      if (!aCompleted && bCompleted) return -1;
      return 0;
    });

  return {
    tasks: sortedTasks,
    isLoading,
    userTelegramTaskData,
    mutateUserTelegramTaskData: fetchEnergyPointsSummary,
    unviewedCount,
    viewedTasks,
  };
}
