import { Center } from '@chakra-ui/react';
import { Text } from '../ui/atoms/Text';
import { TranslatedText } from '../ui/atoms/TranslatedText';
import { Energy } from '../ui/icons/Energy';
import { Button } from '../ui/molecules/Button';
import FileUploadButton from '../ui/molecules/FileUpload';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAlert } from '@/hooks/useAlert';
import {
  useGameSettings,
  GAME_SETTINGS_TELEGRAM_PET_FEED_ENERGY_AMOUNT,
} from '@/components/battle/hooks/useGameSettings';
import { DEFAULT_PET_FEED_ENERGY_AMOUNT } from '@/config/constants';

export const DefaultView = ({
  takePhoto,
  onValidateImage,
}: {
  takePhoto: () => void;
  onValidateImage: (img: string, file: File) => void;
}) => {
  const settings = useGameSettings();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const alert = useAlert();

  return (
    <>
      <Center color="var(--Green)" w="full">
        <Text as="span" colorId="Green">
          <TranslatedText translationKey="earn" defaultMessage="Earn" />
        </Text>
        <Energy />
        <Text as="span" colorId="Green">
          {settings
            ? settings?.[GAME_SETTINGS_TELEGRAM_PET_FEED_ENERGY_AMOUNT] ??
              DEFAULT_PET_FEED_ENERGY_AMOUNT
            : '...'}
        </Text>
      </Center>
      <Text colorId="Black">
        <TranslatedText
          translationKey="feedYourGenopet"
          defaultMessage="feed your genopet"
        />
        !
      </Text>
      <Text colorId="Black">
        <TranslatedText
          translationKey="takeAPictureOfFood"
          defaultMessage="take a picture of food"
        />
      </Text>
      <Button colorId="Black" w="full" onClick={() => takePhoto()}>
        <TranslatedText
          translationKey="snapPicture"
          defaultMessage="snap picture"
        />
      </Button>
      <FileUploadButton
        colorId="Black"
        w="full"
        onFileChange={(file) => {
          console.log('file', file);
          if (!file.type.includes('image')) {
            alert({ title: t('invalidFileType', 'Invalid file type') });
            return;
          }
          const reader = new FileReader();
          reader.onload = () => {
            onValidateImage(reader.result as string, file); // Set the image source as base64
          };
          reader.readAsDataURL(file);
        }}
      >
        <TranslatedText translationKey="upload" defaultMessage="upload" />
      </FileUploadButton>
      <Button colorId="White" w="full" onClick={() => navigate('home')}>
        <TranslatedText translationKey="goHome" defaultMessage="go home" />
      </Button>
    </>
  );
};
