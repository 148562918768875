import { useMusic } from '@/components/Sounds/useMusic';
import { MainContainer } from '@/components/battle/MainContainer';
import { bgSounds } from '@/lib/sounds';
import InitBackground from '../../images/bg-claim-rewards.png';
import GoldChestImg from '../../images/gold-chest.png';
import { Text } from '@/components/ui/atoms/Text';
import { Whittakr } from '@/components/ui/organisms/Whittakr';
import { Center, Box } from '@chakra-ui/react';
import { TonClaimRewards } from '@/components/earn/TonClaimRewards';
import { VITE_TON_CLAIM_REWARDS_ENABLED } from '@/config/constants';
import { ClaimRewards } from '@/components/earn/ClaimRewards';

export const ClaimRewardsPage = () => {
  useMusic(bgSounds.viralLoopPage);

  return (
    <MainContainer
      padding={0}
      background={InitBackground}
      backgroundPosition="top"
      backgroundSize="100% auto"
    >
      <Box>
        <Whittakr
          imgStyle={{ left: `-90px`, top: `30px`, width: `87px` }}
          textBoxProps={{ top: '30px', left: '165px', padding: 2 }}
          triangleProps={{ left: '0', right: 'none' }}
        >
          <Text w="full" fontSize={`10px`}>
            Claim your <br />
            rewards!
          </Text>
        </Whittakr>
      </Box>

      <Center mt={`40px`}>
        <img
          src={GoldChestImg}
          style={{
            width: 'auto',
            maxWidth: `150px`,
            position: 'relative',
          }}
        />
      </Center>
      {VITE_TON_CLAIM_REWARDS_ENABLED ? <TonClaimRewards /> : <ClaimRewards />}
    </MainContainer>
  );
};
