import { Flex, Box } from '@chakra-ui/react';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { Link } from '@/components/Link/Link';
import { Button } from '@/components/ui/molecules/Button';
import { Colors } from '@/components/ui/colors';
import { Text } from '@/components/ui/atoms/Text';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import { useUserData } from '@/context/FirestoreContext';
import { useState, useEffect } from 'react';
import {
  VITE_CLAIM_REWARDS_MANTLE_USDC,
  VITE_VERCEL_ENV,
} from '@/config/constants';
import { USER_ENDPOINT, TELEGRAM_BATTLE_ENDPOINT } from '@/config/endpoints';
import { getShortenAddress } from '@/utils/utils';
import { isMobile } from '@/utils/isMobile';
import ConnectOkxWalletMobile from '@/components/earn/ConnectOkxWalletMobile';
import ConnectOkxWalletDesktopContainer from '@/components/earn/ConnectOkxWalletDesktop';
import { SECOND_PLAYER_ID } from 'genopets-utils';
import { useNavigate } from 'react-router-dom';
import MantleLogo from '../../images/mantle-logo.png';

const MINIMUM_CLAIM_AMOUNT = 0.1;

export const ClaimRewards = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { apiPost, apiGet } = useAPI();
  const alert = useAlert();
  const { userData } = useUserData();

  let rewardsAmount = +(
    userData?.telegram?.mantleOkxCampaignRewardsAmount ?? 0
  );
  let isClaimProcessing =
    userData?.telegram?.mantleOkxCampaignRewardsStatus === 'processing';
  // claimStatus value could be "processing" or the claimed transaction hash
  let claimStatus = userData?.telegram?.mantleOkxCampaignRewardsStatus;
  let currency = 'MNT';
  let claimEndpoint = `${USER_ENDPOINT}/claim-mantle-okx-reward`;

  // if mantle usdc rewards are enabled, use them instead
  if (VITE_CLAIM_REWARDS_MANTLE_USDC) {
    rewardsAmount = userData?.telegram?.mantle_$USDC_rewardsAmount ?? 0;
    isClaimProcessing =
      userData?.telegram?.mantle_$USDC_rewardsStatus === 'processing';
    currency = 'USDC';
    claimEndpoint = `${USER_ENDPOINT}/claim-mantle-usdc-reward`;
    claimStatus = userData?.telegram?.mantle_$USDC_rewardsStatus;
  }

  const claimHash = !isClaimProcessing && claimStatus ? claimStatus : undefined;
  const claimThresholdMet =
    rewardsAmount !== undefined && rewardsAmount >= MINIMUM_CLAIM_AMOUNT;

  const [isClaiming, setIsClaiming] = useState(false);

  const explorerUrl =
    VITE_VERCEL_ENV === 'production'
      ? `https://explorer.mantle.xyz/tx/`
      : `https://explorer.sepolia.mantle.xyz/tx/`;

  const createBattle = async () => {
    if (isLoading) return;

    setIsLoading(true);
    apiPost(TELEGRAM_BATTLE_ENDPOINT, {
      opponent: SECOND_PLAYER_ID,
    })
      .then((resp) => {
        navigate(`/battle/${resp}`);
      })
      .catch((error: any) => {
        console.error(error);
        alert({ title: error?.message ?? 'Error' });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (rewardsAmount !== undefined) return;
    apiGet(`${USER_ENDPOINT}/mantle-okx-reward-eligibility`).catch(() =>
      alert({
        title: `Error verifying your eligibility. Pls re-open the app.`,
      }),
    );
  }, [apiGet, rewardsAmount]);

  return (
    <Flex
      position={`absolute`}
      bottom={0}
      w={`full`}
      h={`auto`}
      flexDirection={'column'}
      background={`white`}
    >
      <Box>
        <Flex
          background={claimThresholdMet ? Colors.Yellow : `none`}
          w={`full`}
          padding={`8px`}
          flexDirection={`column`}
          gap={`8px`}
          border={`solid 3px black`}
          alignItems={`center`}
        >
          <Text colorId={`Black`} fontSize={`12px`}>
            Your reward
          </Text>
          <Text colorId={`Black`} fontSize={`24px`}>
            {rewardsAmount !== undefined ? (
              <Flex alignItems={`center`} gap={`8px`}>
                {currency === 'MNT' && (
                  <img
                    src={MantleLogo}
                    style={{
                      width: `100%`,
                      maxWidth: `32px`,
                    }}
                  />
                )}
                <span>{rewardsAmount}</span>
                <span>{currency}</span>
              </Flex>
            ) : (
              `checking...`
            )}
          </Text>
          {rewardsAmount !== undefined && !claimThresholdMet && (
            <Text colorId={`Red`} fontSize={`12px`}>
              Minimum {MINIMUM_CLAIM_AMOUNT} {currency} to claim
            </Text>
          )}
          {claimThresholdMet && rewardsAmount !== undefined && (
            <Text colorId={`Black`} fontSize={`12px`}>
              {isClaimProcessing ? (
                `processing claim`
              ) : claimHash ? (
                <a
                  target="_blank"
                  href={`${explorerUrl}${claimHash}`}
                  rel="noreferrer"
                  style={{ textDecoration: `underline` }}
                >
                  Claimed! See transaction
                </a>
              ) : (
                `ready to claim`
              )}
            </Text>
          )}
        </Flex>

        <Flex
          background={`white`}
          w={`full`}
          padding={`12px`}
          flexDirection={`column`}
          gap={`8px`}
          border={`solid 3px black`}
          borderTop={`none`}
          borderBottom={`none`}
          alignItems={`center`}
        >
          <Text
            colorId={userData?.evmWallet ? `Green` : `Red`}
            fontSize={`12px`}
          >
            {userData?.evmWallet ? 'Wallet connected' : 'no wallet connected'}
          </Text>

          {userData?.evmWallet && (
            <Text colorId={`Black`} fontSize={`12px`}>
              {getShortenAddress(userData?.evmWallet, 4)}
            </Text>
          )}

          {isMobile() ? (
            <ConnectOkxWalletMobile />
          ) : (
            <ConnectOkxWalletDesktopContainer />
          )}
        </Flex>

        <Box border={`solid 3px black`} padding={`12px 12px 0`}>
          {(rewardsAmount !== undefined && !claimThresholdMet) || claimHash ? (
            <Button
              colorId={`Yellow`}
              w={`full`}
              border={`solid 3px black`}
              isDisabled={isLoading}
              onClick={createBattle}
              className="animate-color-cycle"
            >
              <Text colorId={`Black`}>Go battle!</Text>
            </Button>
          ) : (
            <Button
              colorId={`Yellow`}
              w={`full`}
              border={`solid 3px black`}
              isDisabled={
                rewardsAmount === undefined ||
                isClaiming ||
                !userData?.evmWallet
              }
              onClick={async () => {
                try {
                  setIsClaiming(true);
                  await apiPost(claimEndpoint, {});
                  alert({
                    title: `Claimed ${rewardsAmount} ${currency}`,
                  });
                } catch (error: any) {
                  alert({ title: `Err: ${error?.message}` });
                  console.log(error);
                } finally {
                  setIsClaiming(false);
                }
              }}
            >
              <Text colorId={`Black`}>
                {isClaiming ? `processing...` : `claim reward!`}
              </Text>
            </Button>
          )}

          <Link to="/home" style={{ width: '100%' }}>
            <Button colorId="White" w="full" isDisabled={isLoading}>
              <TranslatedText
                translationKey={`goHome`}
                defaultMessage={`Go Home`}
              />
            </Button>
          </Link>
        </Box>
      </Box>
    </Flex>
  );
};
