import { Box, BoxProps } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { Text } from './Text';

const colorTransition = keyframes`
  0% { background-color: #5EFED5; }
  33% { background-color: #F2FF00; }
  66% { background-color: #FF0080; }
  100% { background-color: #5EFED5; }
`;

const AnimatedContainer = styled(Box)`
  animation: ${colorTransition} 0.75s infinite;
  padding: 4px;
  border: 2px solid #000;
`;

interface NumberIndicatorProps extends BoxProps {
  num: number;
}

export function NumberIndicator({ num, ...props }: NumberIndicatorProps) {
  return (
    <AnimatedContainer {...props}>
      {typeof num === 'number' && (
        <Text
          colorId="Black"
          fontSize="xx-small"
          fontWeight="bold"
          lineHeight="1"
        >
          {num}
        </Text>
      )}
    </AnimatedContainer>
  );
}
