import { Flex, Box } from '@chakra-ui/react';
import { Text } from '@/components/ui/atoms/Text';
import { Button } from '@/components/ui/molecules/Button';
import { MANTLE_MAINNET_CHAIN_ID } from '@/config/constants';
import { useUserData } from '@/context/FirestoreContext';
import { useState, useEffect } from 'react';
import { USER_ADD_EVM_WALLET_ENDPOINT } from '@/config/endpoints';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import { Colors } from '@/components/ui/colors';
import { Ticket } from '@/components/ui/icons/Ticket';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { Okx } from '@/components/ui/icons/Okx';
import { OKXUniversalProvider } from '@okxconnect/universal-provider';
import { OkxWalletConnectedRewardModal } from '@/components/earn/MantleCheckInDesktop';

const MantleCheckInMobile = () => {
  const { apiPut } = useAPI();
  const alert = useAlert();
  const { userData } = useUserData();

  const [
    connectWalletRewardModalTicketCount,
    setConnectWalletRewardModalTicketCount,
  ] = useState(0);

  const [okxProvider, setOkxProvider] = useState<OKXUniversalProvider>();
  const [session, setSession] = useState<any>();
  const evmWalletAddress =
    session?.namespaces?.eip155?.accounts[0]?.split(`:`)?.[2];
  const hasConnectedWallet = userData?.evmWallet || evmWalletAddress;

  useEffect(() => {
    if (!okxProvider) {
      OKXUniversalProvider.init({
        dappMetaData: {
          name: 'Pixelton Arena',
          icon: 'https://cdn.genopets.me/Pixelton/pixelton-arena-title.png',
        },
      }).then((provider) => {
        setOkxProvider(provider);
        provider.setDefaultChain(
          `eip155:${MANTLE_MAINNET_CHAIN_ID}`,
          'https://rpc.mantle.xyz',
        );
      });
    }
  }, [okxProvider]);

  const connectWallet = async () => {
    if (!okxProvider) return;

    const sessionConnect = await okxProvider.connect({
      namespaces: {
        eip155: {
          chains: [`eip155:${MANTLE_MAINNET_CHAIN_ID}`],
          rpcMap: {
            [MANTLE_MAINNET_CHAIN_ID]: 'https://rpc.mantle.xyz',
          },
          defaultChain: String(MANTLE_MAINNET_CHAIN_ID),
        },
      },
      sessionConfig: {
        redirect: 'tg://resolve',
      },
    });

    setSession(sessionConnect);
  };

  // once the user has connected their wallet, save it to their game account
  useEffect(() => {
    // exit if no wallet is connected
    if (!evmWalletAddress) return;

    // if new wallet has been connected, save it to their game account
    if (userData?.evmWallet !== evmWalletAddress) {
      apiPut(USER_ADD_EVM_WALLET_ENDPOINT, {
        walletAddress: evmWalletAddress,
      })
        .then((data) => {
          if (data?.ticketAmount > 0) {
            setConnectWalletRewardModalTicketCount(data?.ticketAmount);
          }
        })
        .catch((error) => {
          alert({ title: `Err: ${error?.message}` });
        });
    }
  }, [evmWalletAddress, userData?.evmWallet]);

  if (!okxProvider) return null;

  return (
    <>
      {connectWalletRewardModalTicketCount > 0 && (
        <OkxWalletConnectedRewardModal
          ticketCount={connectWalletRewardModalTicketCount}
          onClose={() => setConnectWalletRewardModalTicketCount(0)}
        />
      )}

      <Flex
        w={`full`}
        border={`solid 2px black`}
        background={hasConnectedWallet ? Colors.DarkGreen : `transparent`}
      >
        <Flex w={`full`} alignItems={`center`}>
          <Box pl={`6px`}>
            <Okx />
          </Box>
          <Flex
            flexDirection={`column`}
            gap={`4px`}
            w={`full`}
            borderRight={`1px solid black`}
            padding={`4px 0 4px 6px`}
            minHeight={`50px`}
            justifyContent={`center`}
          >
            <Text
              colorId={hasConnectedWallet ? `White` : 'Black'}
              whiteSpace={`initial`}
              textAlign={`left`}
              style={{ textWrap: `balance` }}
            >
              <TranslatedText
                translationKey={`connectOkxWallet`}
                defaultMessage={`Connect okx wallet`}
              />
            </Text>

            {!userData?.telegram?.hasConnectedEvmWallet && (
              <Flex w={`full`} alignItems={`center`}>
                <Ticket />
                <Text
                  colorId={hasConnectedWallet ? `White` : 'Black'}
                  w="full"
                  width={`auto`}
                >
                  5
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>

        <Button
          borderLeft={`1px solid black`}
          colorId={hasConnectedWallet ? `DarkGreen` : `Yellow`}
          padding={`8px`}
          maxWidth={hasConnectedWallet ? `80px` : `60px`}
          minWidth={`0px`}
          w={`full`}
          onClick={async () => {
            if (hasConnectedWallet || session) return;
            connectWallet();
          }}
        >
          <Text colorId={hasConnectedWallet ? `White` : 'Black'}>
            {hasConnectedWallet ? `Done` : `Go`}
          </Text>
        </Button>
      </Flex>
    </>
  );
};

export default MantleCheckInMobile;
