import { Box, Flex } from '@chakra-ui/react';
import { getReferralMultiplier } from 'genopets-utils';
import { useGameSettings } from '../battle/hooks/useGameSettings';
import { Text } from '../ui/atoms/Text';
import { TranslatedText } from '../ui/atoms/TranslatedText';
import { Energy } from '../ui/icons/Energy';
import { Mail } from '../ui/icons/Mail';
import { Colors } from '@/components/ui/colors';

export const ReferralData = ({
  referredUsersCount: referredUsersCountTemp,
}: {
  referredUsersCount?: number;
}) => {
  const referredUsersCount = referredUsersCountTemp ?? 0;
  const gameSettings = useGameSettings();
  const referralEnergyPointsBonusConfig =
    gameSettings?.referralEnergyPointsBonusConfig;

  const myMultiplier = getReferralMultiplier(
    referredUsersCount,
    referralEnergyPointsBonusConfig,
  );

  const invitesLabelColor =
    referredUsersCountTemp === undefined
      ? 'Black'
      : referredUsersCount > 0
        ? Colors.DarkYellow
        : Colors.Red;

  return (
    <Box borderTop={`solid 3px black`} padding={`0 8px`}>
      <Box mt={`4px`}>
        <Text colorId={`Black`}>
          <TranslatedText
            translationKey="getEnergyBonusForEveryReferral"
            defaultMessage="get energy bonus for every referral"
          />
          !
        </Text>

        <Flex justifyContent={`center`} mt={`4px`}>
          <Flex alignItems={`center`}>
            <Mail color={invitesLabelColor} />
            <Text color={invitesLabelColor} ml="4px">
              {referredUsersCountTemp === undefined
                ? '...'
                : referredUsersCount}
            </Text>
            <Text color={invitesLabelColor} ml={`4px`}>
              <TranslatedText
                translationKey={`invites`}
                defaultMessage={`invites`}
                count={referredUsersCount}
              />
            </Text>
          </Flex>
        </Flex>

        <Flex
          minHeight={`220px`}
          flexDirection={`column`}
          gap={`4px`}
          mt={`4px`}
        >
          {!referralEnergyPointsBonusConfig ? (
            <Text colorId={`Black`}>
              {' '}
              <TranslatedText
                translationKey={`loading`}
                defaultMessage={`Loading`}
              />{' '}
              ...
            </Text>
          ) : (
            Object.entries(referralEnergyPointsBonusConfig || {}).map(
              ([referredCountTemp, multiplier]) => {
                const referredCount = +referredCountTemp;
                if (referredCount <= 0) return null;

                const isCurrentMultiplier = multiplier === myMultiplier;
                const isNextMultiplier =
                  referredUsersCount > 0 && multiplier > myMultiplier;

                const bonusBgColor = isNextMultiplier
                  ? 'Black'
                  : referredUsersCount > 0
                    ? Colors.Yellow
                    : Colors.Red;
                const bonusTextColor = isNextMultiplier
                  ? 'White'
                  : referredUsersCount > 0
                    ? 'Black'
                    : 'White';

                return (
                  <Flex
                    key={referredCount}
                    opacity={isCurrentMultiplier ? 1 : 0.4}
                    border={`solid 2px black`}
                  >
                    <Flex
                      w={`full`}
                      borderRight={`1px solid black`}
                      padding={`8px`}
                      justifyContent={`center`}
                    >
                      <Flex alignItems={`center`} gap={`4px`}>
                        <Mail color={`Black`} />
                        <Text colorId={`Black`}>{referredCount}</Text>
                      </Flex>
                    </Flex>

                    <Flex
                      padding={`4px 4px 0`}
                      w={`full`}
                      borderLeft={`1px solid black`}
                      flexDirection={`column`}
                      bgColor={bonusBgColor}
                    >
                      <Flex
                        alignItems={`center`}
                        gap={`4px`}
                        justifyContent={`center`}
                      >
                        <Energy color={bonusTextColor} />
                        <Text colorId={bonusTextColor}>
                          {multiplier}% bonus
                        </Text>
                      </Flex>
                      <Text
                        colorId={bonusTextColor}
                        fontSize={`10px`}
                        textAlign={`center`}
                      >
                        {isCurrentMultiplier || myMultiplier > multiplier
                          ? `boosting!`
                          : `Locked`}
                      </Text>
                    </Flex>
                  </Flex>
                );
              },
            )
          )}
        </Flex>
      </Box>
    </Box>
  );
};
