import { ManageWalletModal } from '@/components/home/ManageWalletModal';
import { Text } from '@/components/ui/atoms/Text';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { Energy } from '@/components/ui/icons/Energy';
import { Card } from '@/components/ui/molecules/Card';
import { MenuItemPop } from '@/components/ui/molecules/MenuItemPop';
import {
  VITE_BATTLE_REPLAY_ENABLED,
  VITE_FEED_TO_EARN_ENABLED,
  VITE_HOME_LEADERBOARD_TIMEFRAME,
  VITE_NEW_HOME_ENABLED,
  VITE_OKX_MANTLE_LEADERBOARD_ENABLED,
  VITE_CLAIM_REWARDS_ENABLED,
  DEFAULT_PET_FEED_ENERGY_AMOUNT,
} from '@/config/constants';
import {
  LEADERBOARD_ENDPOINT,
  TELEGRAM_BATTLE_ENDPOINT,
} from '@/config/endpoints';
import { useTelegramPet, useUserData } from '@/context/FirestoreContext';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import {
  Box,
  Center,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import {
  SECOND_PLAYER_ID,
  getTelegramPetImage,
  EnergyPointsSummary,
} from 'genopets-utils';
import { EnergyPointsLeaderboardResponse } from 'genopets-utils/src/models/dto/leaderboard.response';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TitleGeno from '../../images/pixelton-arena-title.png';
import { AirdropCard } from '../airdrop/AirdropCard';
import { MatchmakingButton } from '../battle/Matchmacking/MatchmakingButton';
import { ReplayButton } from '../battle/replay/ReplayButton';
import { TextWithShadow } from '../ui/atoms/TextWithShadow';
import { HomeBattle } from '../ui/icons/HomeBattle';
import { HomeEarnTickets } from '../ui/icons/HomeEarnTickets';
import { HomeFeedToEarn } from '../ui/icons/HomeFeedToEarn';
import { HomeLeaderboard } from '../ui/icons/HomeLeaderboard';
import { HomeLearn } from '../ui/icons/HomeLearn';
import { HomePetStats } from '../ui/icons/HomePetStats';
import { HomeWheel } from '../ui/icons/HomeWheel';
import { Ticket } from '../ui/icons/Ticket';
import { Trophy } from '../ui/icons/Trophy';
import { Button } from '../ui/molecules/Button';
import { ModalMessageManager } from '../ui/organisms/ModalMessageManager';
import { BattleButton } from './BattleButton';
import { HomeIcon } from './HomeIcon';
import { HomeIcon2 } from './HomeIcon2';
import { FeelingLuckyButton } from './FeelingLuckyButton';
import { Shop } from '../ui/icons/Shop';
import { Invite } from '../ui/icons/Invite';
import { HomePetIcon } from './HomePetIcon';
import { HomeCake } from '../ui/icons/HomeCake';
import {
  GAME_SETTINGS_TELEGRAM_PET_FEED_ENERGY_AMOUNT,
  useGameSettings,
} from '../battle/hooks/useGameSettings';
import { useTasks } from '@/hooks/useTasks';

export const Home = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [energyPointsSummary, setEnergyPointsSummary] = useState<
    EnergyPointsSummary | undefined
  >();

  const { pet } = useTelegramPet();

  const [searchParams] = useSearchParams();

  const { userData } = useUserData();

  const alert = useAlert();

  const navigate = useNavigate();

  const { onClose, isOpen, onToggle } = useDisclosure();

  const { apiPost, apiGet } = useAPI();

  const settings = useGameSettings();
  const { unviewedCount } = useTasks();

  const createBattle = async (vsCPU = true) => {
    if (isLoading) return;

    setIsLoading(true);

    const payload = vsCPU
      ? {
          opponent: SECOND_PLAYER_ID,
        }
      : {};

    apiPost(TELEGRAM_BATTLE_ENDPOINT, payload)
      .then((resp) => {
        navigate(`/battle/${resp}`);
      })
      .catch((error: any) => {
        console.error(error);
        alert({ title: error?.message ?? 'Error' });
      })
      .finally(() => setIsLoading(false));
  };

  const getLeaderboard = async () => {
    apiGet(
      `${LEADERBOARD_ENDPOINT(1, VITE_HOME_LEADERBOARD_TIMEFRAME)}${VITE_OKX_MANTLE_LEADERBOARD_ENABLED ? `&definition=OKXMantleEnergyPointsDefinition` : ``}`,
    )
      .then((resp: EnergyPointsLeaderboardResponse) => {
        console.log('resp', resp);
        if (resp) {
          setEnergyPointsSummary(resp.yourPetSummary);
        }
      })
      .catch((error: any) => {
        alert({ title: error?.message ?? 'Error' });
      });
  };

  useEffect(() => {
    if (userData && typeof energyPointsSummary === 'undefined') {
      getLeaderboard();
    }
  }, [userData, apiGet]);

  return (
    <>
      <AirdropCard
        cardProps={{
          borderBottom: 'none',
        }}
      />
      <Box h="full">
        <Flex
          justifyContent={'space-between'}
          position={'absolute'}
          w="full"
          zIndex={2}
        >
          <ManageWalletModal />
          <FeelingLuckyButton />
        </Flex>
        <Center
          w="full"
          h="full"
          flexDirection={'column'}
          justifyContent={'center'}
          position={'relative'}
        >
          <Center>
            <img
              src={TitleGeno}
              alt="genopets"
              style={{
                height: '20vh',
                maxHeight: '180px',
                width: 'auto',
                position: 'relative',
                top: '10px',
              }}
            />
          </Center>
          <Center flexDirection={'column'} zIndex={2} mb={`50px`}>
            {pet && (
              <Box position="relative">
                <img
                  src={getTelegramPetImage(pet.petConfigV2)}
                  alt="pet"
                  style={{ width: '150px', height: '150px' }}
                />
                <Box position="absolute" top="70px" left="-40px">
                  <HomePetIcon
                    color="#48C9CD"
                    height={66}
                    width={64}
                    translateKey="myStats"
                    arrowPosition="right"
                    onClick={() => {
                      navigate('/battle-stats');
                    }}
                  >
                    <HomePetStats style={{ width: '42px', height: '40px' }} />
                  </HomePetIcon>
                </Box>
                {VITE_FEED_TO_EARN_ENABLED && (
                  <Box position="absolute" top="20px" right="-70px">
                    <HomePetIcon
                      color="#FFC039"
                      height={84}
                      width={86}
                      translateKey="feedMe"
                      arrowPosition="left"
                      onClick={() => {
                        navigate('feed-to-earn');
                      }}
                    >
                      <Center flexDirection={'column'} gap={0.5}>
                        <HomeCake style={{ width: '40px', height: '35px' }} />
                        <Flex alignItems={'center'}>
                          <Energy
                            color="var(--Black)"
                            style={{ width: '18px', height: 'auto' }}
                          />
                          <Text colorId="Black" fontSize={'10px'}>
                            {settings?.[
                              GAME_SETTINGS_TELEGRAM_PET_FEED_ENERGY_AMOUNT
                            ] || DEFAULT_PET_FEED_ENERGY_AMOUNT}
                          </Text>
                        </Flex>
                      </Center>
                    </HomePetIcon>
                  </Box>
                )}
              </Box>
            )}

            <TextWithShadow>
              {pet?.nickname ?? pet?.name ?? '...'}
            </TextWithShadow>
            <TextWithShadow>
              <TranslatedText
                translationKey={`textLvl`}
                defaultMessage={`Lvl`}
              />
              {` ${pet?.telegramLevel ?? '...'}`}
            </TextWithShadow>
            <Flex alignItems={'center'} gap={4}>
              <Flex alignItems={'center'}>
                <Text>
                  <TranslatedText
                    translationKey={`rankNoSpace`}
                    defaultMessage={`rank`}
                  />
                  :
                </Text>
                <Text>
                  {typeof energyPointsSummary === 'undefined'
                    ? '...'
                    : energyPointsSummary?.rank === 0
                      ? '-'
                      : energyPointsSummary?.rank}
                </Text>
              </Flex>
              <Flex alignItems={'center'}>
                <Ticket />
                <TextWithShadow>{userData?.telegramTickets}</TextWithShadow>
              </Flex>
              <Flex gap={1} alignItems={'center'}>
                <Energy color="white" />
                <TextWithShadow>
                  {typeof energyPointsSummary === 'undefined'
                    ? '...'
                    : energyPointsSummary?.energyPoints ?? '...'}
                </TextWithShadow>
              </Flex>
            </Flex>
            {VITE_CLAIM_REWARDS_ENABLED && (
              <Button
                mt={`4px`}
                colorId={`Yellow`}
                padding={`4px`}
                border={`solid 2px black`}
                onClick={() => navigate('/claim-rewards')}
              >
                <Text colorId={`Black`}>Claim rewards</Text>
              </Button>
            )}
          </Center>
        </Center>
        <Box w="full">
          {VITE_NEW_HOME_ENABLED ? (
            <Card w="full" gap={0}>
              <Flex gap={2} alignItems={'flex-start'} w="full">
                <Popover
                  placement="bottom-end"
                  onClose={onClose}
                  isOpen={isOpen}
                >
                  <PopoverTrigger>
                    <Box
                      onClick={() => {
                        onToggle();
                      }}
                      as="a"
                      cursor={'pointer'}
                      w="full"
                      h="full"
                    >
                      <HomeIcon translateKey="battle">
                        <HomeBattle width="100%" />
                      </HomeIcon>
                    </Box>
                  </PopoverTrigger>
                  <PopoverContent
                    border={`none`}
                    sx={{
                      '&:focus, &:focus-visible': {
                        outline: 'none !important',
                        boxShadow: 'none !important',
                      },
                    }}
                  >
                    <Card>
                      <MatchmakingButton
                        auto={searchParams.get('auto') === 'true'}
                      />
                      <MenuItemPop
                        width="full"
                        color="black"
                        onClick={() => createBattle(false)}
                        disabled={isLoading}
                      >
                        <Text colorId={`Black`}>
                          {isLoading ? (
                            <>
                              <TranslatedText
                                translationKey={`loading`}
                                defaultMessage={`Loading`}
                              />
                              ...
                            </>
                          ) : (
                            <TranslatedText
                              translationKey={`vsFren`}
                              defaultMessage={`VS Fren`}
                            />
                          )}
                        </Text>
                      </MenuItemPop>
                      <Button colorId="Black" w="full" onClick={onClose}>
                        <TranslatedText
                          translationKey={`textBack`}
                          defaultMessage={`Back`}
                        />
                      </Button>
                    </Card>
                  </PopoverContent>
                </Popover>

                <HomeIcon
                  translateKey="rankNoSpace"
                  onClick={() => {
                    navigate('/battle-leaderboard');
                  }}
                >
                  <HomeLeaderboard width="100%" />
                </HomeIcon>
                {VITE_BATTLE_REPLAY_ENABLED ? (
                  <HomeIcon translateKey="replay">
                    <ReplayButton altDesign />
                  </HomeIcon>
                ) : null}
                {pet?.telegramRolledMove && (
                  <HomeIcon
                    translateKey="learn"
                    translateProps={{ moveType: '' }}
                    onClick={() => {
                      navigate('/battle-choose-move');
                    }}
                  >
                    <HomeLearn width="100%" />
                  </HomeIcon>
                )}
              </Flex>
              <Flex gap={2} alignItems={'flex-start'} mt={2}>
                <HomeIcon
                  translateKey="spinToEarn"
                  onClick={() => {
                    navigate('/spinner');
                  }}
                >
                  <HomeWheel width="100%" />
                </HomeIcon>
                <HomeIcon
                  translateKey="feedToEarn"
                  onClick={() => {
                    if (VITE_FEED_TO_EARN_ENABLED) {
                      navigate('feed-to-earn');
                    } else {
                      alert({
                        title: t('comingSoon', 'coming soon'),
                      });
                    }
                  }}
                >
                  <HomeFeedToEarn width="100%" />
                </HomeIcon>
                <HomeIcon
                  translateKey="petStats"
                  onClick={() => {
                    navigate('/battle-stats');
                  }}
                >
                  <HomePetStats width="100%" />
                </HomeIcon>
                <HomeIcon
                  translateKey="earnTickets"
                  onClick={() => {
                    navigate('/earn');
                  }}
                >
                  <HomeEarnTickets width="100%" />
                </HomeIcon>
              </Flex>
            </Card>
          ) : (
            // ================================================ ↓ Old Home ↓ ==================================================

            <Card
              w="full"
              mt="24px" // push the content above to avoid to clash with bigger battle button
              mb="8px"
              gap={0}
              height={'76px'}
              padding={0}
              flexDirection={'row'}
              alignItems={'center'}
              position={'absolute'}
              bottom={0}
              zIndex={2}
            >
              <HomeIcon2
                translateKey="shop"
                onClick={() => {
                  navigate('/shop');
                }}
              >
                <Shop style={{ width: '40px', height: 'auto' }} />
              </HomeIcon2>
              <HomeIcon2
                translateKey="earn"
                numIndicator={unviewedCount ?? 0}
                onClick={() => {
                  navigate('/earn');
                }}
              >
                <HomeEarnTickets style={{ width: '40px', height: 'auto' }} />
              </HomeIcon2>
              <BattleButton
                isLoading={isLoading}
                createBattle={createBattle}
                searchParams={searchParams}
              />
              <HomeIcon2
                translateKey="rankNoSpace"
                onClick={() => {
                  navigate('/battle-leaderboard');
                }}
              >
                <Trophy style={{ width: '40px', height: 'auto' }} />
              </HomeIcon2>
              <HomeIcon2
                translateKey="invite"
                onClick={() => {
                  navigate('/invite');
                }}
              >
                <Invite style={{ width: '40px', height: 'auto' }} />
              </HomeIcon2>
            </Card>
          )}
        </Box>
        <ModalMessageManager refreshHomeData={getLeaderboard} />
      </Box>
    </>
  );
};
