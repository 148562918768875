import { Text } from '@/components/ui/atoms/Text';
import { TranslatedText } from '../ui/atoms/TranslatedText';
import { Button } from '../ui/molecules/Button';
import { Modal } from '../ui/molecules/Modal';
import { Box, Grid } from '@chakra-ui/react';
import { Energy } from '../ui/icons/Energy';
import { HomeEarnTickets } from '../ui/icons/HomeEarnTickets';
import { Tick } from '../ui/icons/Tick';
import { GrandPrize } from '../ui/icons/GrandPrize';
import { HomeWheel } from '../ui/icons/HomeWheel';
import { useState } from 'react';
import { StreakClaimModal } from './StreakClaimModal';
import {
  getBaseRewardDay,
  getNextRewardDayForStreak,
  getRewardForStreak,
  StreakReward,
  StreakRewardType,
  TelegramStreakReward,
} from 'genopets-utils/src/pixelton-arena';
import { useNavigate } from 'react-router-dom';

export const PrizeIcon = ({
  type,
  height,
  width = height,
  maxHeight = '100px',
  color = 'black',
}: {
  type: StreakRewardType;
  height: string;
  width?: string;
  maxHeight?: string;
  color?: string;
}) => {
  switch (type) {
    case 'energy':
      return (
        <Energy
          color={color}
          style={{ width: width, height: height, maxHeight: maxHeight }}
        />
      );
    case 'ticket':
      return (
        <HomeEarnTickets
          style={{ width: width, height: height, maxHeight: maxHeight }}
        />
      );
    case 'freeSpin':
      return (
        <HomeWheel
          style={{ width: width, height: height, maxHeight: maxHeight }}
        />
      );
    default:
      return null;
  }
};

const PrizeItem = ({
  day,
  prizes,
  claimed,
  isCurrentDay,
  isGrandPrize,
}: {
  day: number;
  prizes: StreakReward[];
  claimed: boolean;
  isGrandPrize: boolean;
  isCurrentDay: boolean;
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      background={
        claimed ? 'var(--Teal)' : isCurrentDay ? 'var(--Yellow)' : undefined
      }
      border={`3px solid var(--Black)`}
      padding={isGrandPrize ? '8px' : '5px 5px'}
      textAlign="center"
      position="relative"
      height={isGrandPrize ? '139px' : '104px'}
      width={isGrandPrize ? '100%' : '83px'}
    >
      <Text colorId="Black" fontSize="10px" style={{ textAlign: 'center' }}>
        <TranslatedText translationKey="day" defaultMessage="day" />
        {` ${day}`}
      </Text>
      {claimed && (
        <Text
          colorId="Black"
          lineHeight={0.8}
          fontSize="8px"
          style={{ textAlign: 'center' }}
        >
          <TranslatedText translationKey="claimed" defaultMessage="claimed" />
        </Text>
      )}
      <Box
        flex="1"
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding="none"
        width="100%"
        pb={claimed ? undefined : 1}
      >
        {!isGrandPrize ? (
          !claimed ? (
            <PrizeIcon height="100%" maxHeight="48px" type={prizes[0].type} />
          ) : (
            <Tick style={{ height: '46px' }} />
          )
        ) : (
          <GrandPrize style={{ height: '75px' }} />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        {claimed && (
          <PrizeIcon
            width="40%"
            height="100%"
            maxHeight="16px"
            type={prizes[0].type}
          />
        )}
        <Text colorId="Black" fontWeight="bold">
          {!isGrandPrize ? (
            prizes[0].amount ?? ''
          ) : (
            <TranslatedText
              translationKey="surpriseGifts"
              defaultMessage="Surprise Gifts"
            />
          )}
        </Text>
      </Box>
    </Box>
  );
};

export const StreakModal = ({
  streak,
  lastStreakClaimed = 0,
  onPrizesClaimed: onPrizesClaimed,
  open,
  onClaim,
  streakRewards,
  title,
  subtitle,
  titleBgColor,
}: {
  streak: number;
  lastStreakClaimed: number;
  onPrizesClaimed?: () => void;
  onClaim: () => Promise<StreakReward[] | undefined>;
  open: boolean;
  streakRewards: TelegramStreakReward[];
  title?: 'dailyPrize' | 'earnBonus';
  subtitle?: 'dailyBattleMessage' | 'dailyPrizeMessage' | 'dailyFeedMessage';
  titleBgColor?: string;
}) => {
  const [showClaimModal, setShowClaimModal] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);

  const [claimedPrizes, setClaimedPrizes] = useState<StreakReward[]>();
  const navigate = useNavigate();

  const claimableReward = getRewardForStreak(streak, streakRewards);

  // const rewardDay = getRewardDay(streak, prizesPerDay.length);
  const baseDay = getBaseRewardDay(streak, streakRewards);

  const daysUntilNextReward = getNextRewardDayForStreak(
    streak,
    baseDay,
    streakRewards,
  );
  const isClaimable = daysUntilNextReward === 0;

  return (
    <>
      {claimedPrizes && (
        <StreakClaimModal
          onPrizesClaimed={() => {
            setShowClaimModal(false);
            onPrizesClaimed?.();
          }}
          claimedPrizes={claimedPrizes}
          onGoToSpin={() => {
            navigate('/spinner');
          }}
          open={showClaimModal}
          title={title}
        />
      )}
      <Modal
        isOpen={open && !showClaimModal}
        onClose={() => null}
        cardProps={{ gap: 0, padding: 'none' }}
        footerProps={{ mt: 1 }}
        contentProps={{ maxWidth: `300px`, padding: 'none' }}
        showBackButton={false}
      >
        <Box
          background={titleBgColor ?? 'var(--Yellow)'}
          borderBottom={`3px solid var(--Black)`}
          flexShrink={1}
          padding={'16px'}
        >
          <Text colorId="Black" style={{ textAlign: 'center' }}>
            <TranslatedText
              translationKey={title ?? 'dailyPrize'}
              defaultMessage="Daily Prize"
            />
          </Text>
        </Box>
        <Box padding={'8px 16px'}>
          <Text
            lineHeight={1.5}
            colorId="Black"
            fontSize="10px"
            style={{ textAlign: 'center' }}
          >
            <TranslatedText
              translationKey={'currentStreak'}
              defaultMessage="current streak"
            />
          </Text>
          <Text
            lineHeight={1.5}
            colorId="Black"
            fontSize="14px"
            style={{ textAlign: 'center' }}
          >
            {`${streak} `}
            <TranslatedText
              translationKey={'textDays'}
              defaultMessage="textDays"
            />
          </Text>
          <Text
            lineHeight={1.5}
            colorId="Black"
            fontSize="10px"
            style={{ textAlign: 'center' }}
          >
            {daysUntilNextReward === 0 ? (
              <TranslatedText
                translationKey={subtitle ?? 'dailyPrizeMessage'}
                defaultMessage="enter the game every day and get a free reward!"
              />
            ) : (
              <TranslatedText
                translationKey={`moreDayUntilNextPrize`}
                defaultMessage={`${daysUntilNextReward}  more day until next prize`}
                props={{ days: daysUntilNextReward }}
              />
            )}
            !
          </Text>
          <Grid templateColumns="repeat(3, 1fr)" gap={2} padding="8px 0px">
            {streakRewards.slice(0, -1).map((reward, index) => (
              <PrizeItem
                key={index}
                day={baseDay + reward.day}
                prizes={reward.rewards}
                isGrandPrize={false}
                isCurrentDay={reward.day == claimableReward.day}
                claimed={reward.day <= lastStreakClaimed - baseDay}
              />
            ))}
          </Grid>
          <PrizeItem
            key={streakRewards.length}
            day={baseDay + streakRewards[streakRewards.length - 1].day}
            prizes={streakRewards[streakRewards.length - 1].rewards}
            claimed={false}
            isGrandPrize={true}
            isCurrentDay={
              streakRewards[streakRewards.length - 1].day == claimableReward.day
            }
          />

          <Button
            isDisabled={isClaiming}
            mt={2}
            colorId="Black"
            w="full"
            onClick={async () => {
              if (daysUntilNextReward !== 0) {
                return onPrizesClaimed?.();
              }
              setIsClaiming(true);
              const rewards = await onClaim();
              setIsClaiming(false);
              if (rewards) {
                setClaimedPrizes(rewards);
                setShowClaimModal(true);
              }
            }}
            marginBottom="8px"
          >
            {isClaimable ? (
              <TranslatedText translationKey={`claim`} defaultMessage="claim" />
            ) : (
              <TranslatedText translationKey={`ok`} defaultMessage="ok" />
            )}
            !
          </Button>
        </Box>
      </Modal>
    </>
  );
};
