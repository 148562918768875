import {
  FEED_TO_EARN_PRESIGNED_URL_ENDPOINT,
  FEED_TO_EARN_INGREDIENTS_ENDPOINT,
  USER_CLAIM_TELEGRAM_FEED_DAY_STREAK_REWARDS_ENDPOINT,
} from '@/config/endpoints';
import { useTelegramPet, useUserData } from '@/context/FirestoreContext';
import useAPI from '@/hooks/useAPI';
import FeedBg from '@/images/feed-background.png';
import Heart from '@/images/heart.png';
import { Box, Flex } from '@chakra-ui/react';
import { getTelegramPetImage } from 'genopets-utils';
import { useEffect, useRef, useState } from 'react';
import { TranslatedText } from '../ui/atoms/TranslatedText';
import { Button } from '../ui/molecules/Button';
import { Card } from '../ui/molecules/Card';
import { CameraContainer } from './CameraContainer';
import { DefaultView } from './DefaultView';
import { SuccessView } from './SucessView';
import { ValidateView } from './ValidateView';
import { useAlert } from '@/hooks/useAlert';
import { AlreadyFedView } from './AlreadyFedView';
import { StreakModal } from '../streaks/StreakModal';
import {
  GAME_SETTINGS_TELEGRAM_FEED_DAY_STREAK_REWARDS,
  useGameSettings,
} from '../battle/hooks/useGameSettings';
import { TELEGRAM_FEED_DAY_REWARDS_DEFAULT } from 'genopets-utils/src/pixelton-arena';

export type FeedToEarnMode =
  | 'default'
  | 'confirmPhoto'
  | 'upload'
  | 'validate'
  | 'processing'
  | 'success'
  | 'fed';

export type ImageOrigin = 'camera' | 'file';

export const OUNCES_TO_GRAMS = 28.3495;

export enum UnitType {
  grams = 'grams',
  oz = 'ounces',
}

export type Ingredient = {
  name: string;
  quantity: number;
  unit: UnitType;
  calories: number;
};

export function getUnitSymbol(unit: UnitType): string {
  switch (unit) {
    case UnitType.grams:
      return 'g';
    case UnitType.oz:
      return 'oz';
    default:
      throw new Error(`Unsupported unit type: ${unit}`);
  }
}

const ProcessingView = () => {
  return (
    <>
      <Button colorId="Black" w="full" isDisabled>
        <TranslatedText
          translationKey="processing"
          defaultMessage="Processing..."
        />
      </Button>
    </>
  );
};

const getView = ({
  mode,
  setMode,
  imageUrl,
  setValidatedImage,
  handleValidateImage,
  food,
  takePhoto,
}: {
  mode: FeedToEarnMode;
  setMode: React.Dispatch<React.SetStateAction<FeedToEarnMode>>;
  imageUrl: string;
  setValidatedImage: React.Dispatch<React.SetStateAction<any>>;
  handleValidateImage: (img: string, file?: File) => void;
  food?: Ingredient[];
  takePhoto: () => void;
}) => {
  switch (mode) {
    case 'validate':
      return (
        <ValidateView
          setMode={setMode}
          setValidatedImage={setValidatedImage}
          food={food}
          imageUrl={imageUrl}
        />
      );
    case 'fed':
      return <AlreadyFedView />;
    case 'processing':
      return <ProcessingView />;
    case 'success':
      return (
        <SuccessView setMode={setMode} setValidatedImage={setValidatedImage} />
      );
    default:
      return (
        <DefaultView
          takePhoto={takePhoto}
          onValidateImage={handleValidateImage}
        />
      );
  }
};

export const FeedToEarn = () => {
  const { pet } = useTelegramPet();
  const { userData } = useUserData();
  const [mode, setMode] = useState<FeedToEarnMode>('default');
  const [validatedImage, setValidatedImage] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [food, setFood] = useState<Ingredient[]>();
  const camera = useRef<any>(null);
  const feedStreak = userData?.telegram?.feedDayStreak ?? 0;
  const lastFeedDayStreakClaimed =
    userData?.telegram?.lastFeedDayStreakClaimed ?? 0;
  const [showStreakModal, setShowStreakModal] = useState(false);
  const settings = useGameSettings();
  const feedDayStreakRewards =
    settings?.[GAME_SETTINGS_TELEGRAM_FEED_DAY_STREAK_REWARDS] ??
    TELEGRAM_FEED_DAY_REWARDS_DEFAULT;

  useEffect(() => {
    setShowStreakModal(feedStreak !== lastFeedDayStreakClaimed);
  }, [feedStreak, lastFeedDayStreakClaimed]);

  const { apiPost, apiGet } = useAPI();

  const alert = useAlert();

  const feedNextDayTimestampMs =
    userData?.telegram?.feedPetNextDayTimestampMs ?? 0;
  const hasPerformedFeedToday = feedNextDayTimestampMs > Date.now();

  useEffect(() => {
    if (mode === 'default' && hasPerformedFeedToday) {
      setMode('fed');
    }
  }, [hasPerformedFeedToday]);

  const takePhoto = () => {
    setValidatedImage(camera.current?.takePhoto());
    setMode('confirmPhoto');
  };

  const handleValidateImage = async (img: string, file?: File) => {
    const MAX_SIZE = 20971520;
    console.log('img', img);
    setMode('processing');
    setValidatedImage(img);
    let buffer;

    if (!file) {
      console.log('no file provided create one from img base64');
      // Remove the base64 header and get only the encoded image part
      const base64Data = img.replace(/^data:image\/\w+;base64,/, '');
      const binaryData = atob(base64Data); // Decode base64 to binary string
      buffer = new Uint8Array(binaryData.length);

      // Convert binary string to ArrayBuffer
      for (let i = 0; i < binaryData.length; i++) {
        buffer[i] = binaryData.charCodeAt(i);
      }
    }

    const fileSize = buffer ? buffer.length : file?.size;
    if (fileSize && fileSize > MAX_SIZE) {
      alert({
        title: 'The file size exceeds the limit of 20 MB.',
      });
      setValidatedImage(null);
      setMode('default');
      return;
    }

    if (!file && !buffer) {
      setValidatedImage(null);
      alert({ title: 'Error image not found' });
      setMode('default');
      return;
    }

    try {
      const presignedUrlRes = await apiPost(
        FEED_TO_EARN_PRESIGNED_URL_ENDPOINT,
        {},
      );

      console.log('presignedUrlRes', presignedUrlRes);

      if (presignedUrlRes.status != 200 && !presignedUrlRes.signedUrl) {
        console.log('Error getting presigned url');
        throw Error('Error getting presigned url');
      }

      const uploadImageResp = await fetch(presignedUrlRes.signedUrl, {
        method: 'PUT',
        body: file || buffer,
        headers: {
          'Content-Type': file ? file.type : 'image/jpeg', // Set the correct content type
        },
      });

      console.log('uploadImageResp', uploadImageResp);

      if (uploadImageResp.status != 200) {
        console.log(
          'Error uploading the image. Please ensure you are using a valid presigned URL',
        );
        throw Error('Error uploading the image');
      }

      console.log(
        'smallUrl',
        removeQueryParams(uploadImageResp.url.toString()),
      );

      const result = (await apiGet(FEED_TO_EARN_INGREDIENTS_ENDPOINT, true, {
        model: 'GEMINI',
      })) as {
        imageUrl: string;
        detectedIngredients: {
          name: string;
          grams: number;
          calories: number;
        }[];
      };

      const detectedIngredients: Ingredient[] = result.detectedIngredients.map(
        (ingredient) => {
          return {
            name: ingredient.name,
            quantity: ingredient.grams,
            unit: UnitType.grams,
            calories: ingredient.calories,
          };
        },
      );

      if (result) {
        console.log('result', result);
        setImageUrl(result.imageUrl);
        setMode('validate');
        setFood(detectedIngredients);
        return;
      }

      setMode('default');
    } catch (error: any) {
      console.error('Error:', error);
      setValidatedImage(null);
      alert({ title: error?.message ?? 'Error' });
      setMode('default');
    }
  };

  if (!pet) {
    return (
      <Box>
        <TranslatedText
          translationKey="cannotFeed"
          defaultMessage="Cannot feed as you do not have a pet"
        />
      </Box>
    );
  }

  if (mode === 'confirmPhoto') {
    return (
      <CameraContainer
        camera={camera}
        onBack={() => {
          setMode('default');
          setValidatedImage(null);
        }}
        img={validatedImage}
        onValidateImage={handleValidateImage}
      />
    );
  }

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-end"
      h="100vh"
      position="relative"
    >
      <StreakModal
        open={showStreakModal && (mode === 'fed' || mode === 'success')}
        streakRewards={feedDayStreakRewards}
        streak={feedStreak}
        lastStreakClaimed={lastFeedDayStreakClaimed}
        onPrizesClaimed={() => setShowStreakModal(false)}
        title={'earnBonus'}
        subtitle={'dailyFeedMessage'}
        titleBgColor={'var(--Teal)'}
        onClaim={async () => {
          try {
            const resp = await apiPost(
              USER_CLAIM_TELEGRAM_FEED_DAY_STREAK_REWARDS_ENDPOINT,
              {},
            );
            return resp.reward.rewards;
          } catch (err: any) {
            alert({ title: err.message, status: 'error' });
            return undefined;
          }
        }}
      />
      {mode === 'success' || mode === 'fed' ? (
        <>
          <img
            src={FeedBg}
            alt="feed background"
            style={{
              height: '100%',
              maxWidth: 'none',
              position: 'absolute',
              bottom: '150px',
            }}
          />
          <Box position="absolute" left="40%" bottom="300px" w="auto">
            {mode === 'success' && (
              <img
                src={Heart}
                alt="image"
                style={{
                  height: '70px',
                  maxWidth: 'none',
                  position: 'absolute',
                  right: '0',
                  top: '-25px',
                }}
              />
            )}
            <img
              src={getTelegramPetImage(pet.petConfigV2)}
              alt="image"
              style={{
                height: mode === 'fed' ? '150px' : '125px',
                width: mode === 'fed' ? '150px' : 'none',
                maxWidth: 'none',
                transform:
                  mode === 'fed' ? 'none' : 'rotateY(180deg) rotateZ(10deg)',
              }}
            />
          </Box>
        </>
      ) : validatedImage ? (
        <Box flex="1" w="full" overflow="hidden">
          <img
            src={validatedImage}
            alt="image"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Box>
      ) : (
        mode === 'default' && (
          <Box flex="1" w="full" overflow="hidden">
            <CameraContainer
              camera={camera}
              onBack={() => setMode('default')}
              onValidateImage={handleValidateImage}
              showControls={false}
              img={validatedImage}
            />
          </Box>
        )
      )}

      <Card
        w="full"
        flexShrink={0}
        position="relative"
        bottom="0"
        left="0"
        right="0"
      >
        {getView({
          mode,
          setMode,
          setValidatedImage,
          handleValidateImage,
          imageUrl,
          food,
          takePhoto,
        })}
      </Card>
    </Flex>
  );
};

const removeQueryParams = (url: string): string => {
  const urlObject = new URL(url);
  return `${urlObject.origin}${urlObject.pathname}`;
};
