import { Whittakr } from '@/components/ui/organisms/Whittakr';
import { Text } from '@/components/ui/atoms/Text';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { Box, Flex, Grid } from '@chakra-ui/react';
import { ticketBundles } from 'genopets-utils';
import { Ticket } from '@/components/ui/icons/Ticket';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import { initInvoice } from '@tma.js/sdk-react';
import { PURCHASE_TICKETS_ENDPOINT } from '@/config/endpoints';
import { TICKET_NOT_PAID_PREFIX } from '@/components/battle/ClaimDailyTicketsModal';
import { Button } from '@/components/ui/molecules/Button';
import { useNavigate } from 'react-router-dom';
import TelegramWhiteStar from '@/images/telegram-white-star.png';
import { HomeEarnTickets } from '../ui/icons/HomeEarnTickets';

const ShopItem = ({
  tickets,
  stars,
  mostPopular,
  bestValue,
  isLoading,
  buyTickets,
}: {
  tickets: number;
  stars: number;
  mostPopular: boolean;
  bestValue: boolean;
  isLoading: boolean;
  buyTickets: (qty?: number) => Promise<void>;
}) => {
  return (
    <Box
      key={tickets}
      border={`3px solid var(--Black)`}
      onClick={() => buyTickets(tickets)}
      cursor={'pointer'}
      opacity={isLoading ? 0.5 : 1}
      pointerEvents={isLoading ? 'none' : 'auto'}
      width={'100%'}
      height={mostPopular ? '137px' : '127px'}
      minHeight={'137px'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      background={
        mostPopular
          ? 'var(--Teal)'
          : bestValue
            ? 'var(--Yellow)'
            : 'var(--White)'
      }
      position="relative"
    >
      {mostPopular || bestValue ? (
        <Box
          position="absolute"
          background={'var(--Black)'}
          padding={'none'}
          width={'100%'}
          height={'18px'}
          alignContent={'center'}
        >
          <Text
            padding={'0px'}
            color={mostPopular ? 'var(--Teal)' : 'var(--Yellow)'}
            fontSize={'10px'}
            lineHeight={'0px'}
            style={{ textAlign: 'center' }}
            whiteSpace={`nowrap`}
          >
            <TranslatedText
              translationKey={mostPopular ? 'mostPopular' : 'bestValue'}
              defaultMessage={mostPopular ? 'most popular' : 'best value'}
            />
            !
          </Text>
        </Box>
      ) : null}

      <Box
        paddingTop={mostPopular ? '6' : 'auto'}
        display="flex"
        justifyContent={'center'}
        height={'auto'}
        alignItems={'center'}
        flexDirection={mostPopular ? undefined : 'column'}
      >
        <HomeEarnTickets style={{ width: '75px', height: '75px' }} />
        <Text
          colorId="Black"
          width={`auto`}
          pb={'4px'}
          fontSize={mostPopular ? '24px' : '12px'}
          whiteSpace={'nowrap'}
        >
          {tickets} {tickets == 1 ? 'tkt' : mostPopular ? 'tickets' : 'tkts'}
        </Text>
      </Box>

      <Box
        display="flex"
        background={'var(--Black)'}
        width={'100%'}
        minHeight={'32px'}
        alignItems={'center'}
        justifyContent={'center'}
        alignSelf={'center'}
        alignContent={'center'}
        gap={'4px'}
      >
        <img
          src={TelegramWhiteStar}
          alt={'TelegramWhiteStar'}
          style={{
            height: '24px',
            width: 'auto',
          }}
        />

        <Text pt={'4px'} fontSize={'14px'} colorId="White">
          {stars}
        </Text>
      </Box>
    </Box>
  );
};

const Shop = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { apiPost } = useAPI();
  const alert = useAlert();

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        // there's no event when telegram IAP modal is closed ie when user cancelled the IAP modal
        // so adding this to enable the buttons after few seconds
        setIsLoading(false);
      }, 5000);
    }
  }, [isLoading]);

  const buyTickets = async (qty = 1) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const invoice = initInvoice();
      const result = await apiPost(PURCHASE_TICKETS_ENDPOINT, { qty });
      const status = await invoice.open(result?.invoiceUrl, 'url');

      if (status !== 'paid') {
        alert({
          title: t(TICKET_NOT_PAID_PREFIX + status, 'cancelled'),
        });
      } else {
        alert({
          title: (
            <Flex alignItems={`center`} gap={`4px`}>
              <Ticket />
              <Text colorId={`Black`} mt={`1px`}>
                <TranslatedText
                  translationKey={`ticketsClaimed`}
                  defaultMessage={`${qty} claimed`}
                  props={{ amount: qty }}
                />
                !
              </Text>
            </Flex>
          ),
        });
      }
    } catch (error: any) {
      console.error('Error purchasing tickets:', error);
      alert({ title: error?.message ?? 'Error purchasing tickets' });
    }
    setIsLoading(false);
  };

  const sortedBundles = ticketBundles
    .map((t) => ({
      ...t,
      mostPopular: t.tickets === 27,
      bestValue: t.tickets === 58,
    }))
    .sort((a, b) => {
      if (a.mostPopular && !b.mostPopular) return -1;
      if (!a.mostPopular && b.mostPopular) return 1;
      if (a.tickets < b.tickets) return -1;
      if (a.tickets > b.tickets) return 1;
      return 0;
    });

  const mostPopularItem = sortedBundles.find((t) => t.mostPopular);
  const otherItems = sortedBundles.filter((t) => !t.mostPopular);

  return (
    <>
      <Whittakr
        imgStyle={{
          left: `-90px`,
          top: `calc(2vh)`,
          height: 'min(13vh)',
          width: 'auto',
          objectFit: 'contain',
        }}
        textBoxProps={{
          top: 'min(20px, 3vh)',
          left: 'max(140px, 40vw)',
        }}
      >
        <Text w="full">
          <TranslatedText
            translationKey={`moarTkts`}
            defaultMessage={`moar tkts `}
          />
          {` >`}
        </Text>
        <Text w="full">
          <TranslatedText
            translationKey={`moarEnergy`}
            defaultMessage={`moar energy`}
          />
          {` >`}
        </Text>
        <Text w="full">
          <TranslatedText
            translationKey={`moarAirdrop`}
            defaultMessage={`moar airdrop`}
          />
          !
        </Text>
      </Whittakr>
      <Box
        overflow={'auto'}
        background={`#FFF`}
        padding={2}
        position={`absolute`}
        bottom={0}
        w={`full`}
      >
        {mostPopularItem && (
          <Flex justifyContent="center">
            {ShopItem({
              tickets: mostPopularItem.tickets,
              stars: mostPopularItem.stars,
              mostPopular: mostPopularItem.mostPopular,
              bestValue: mostPopularItem.bestValue,
              isLoading,
              buyTickets,
            })}
          </Flex>
        )}
        <Grid
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            '@media (min-width: 375px)': {
              gridTemplateColumns: 'repeat(3, 1fr)',
            },
            gap: '8px',
            padding: '8px 0px',
          }}
        >
          {otherItems.map(({ tickets, stars, mostPopular, bestValue }) =>
            ShopItem({
              tickets,
              stars,
              mostPopular,
              bestValue,
              isLoading,
              buyTickets,
            }),
          )}
        </Grid>
        <Button colorId="Black" onClick={() => navigate('home')} w="full">
          <TranslatedText
            translationKey={`goHome`}
            defaultMessage={`Go home`}
          />
        </Button>
      </Box>
    </>
  );
};

export default Shop;
